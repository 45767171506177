import React from "react";
import {
    PAGE_PADDING,
    FONT_SIZE,
    LANDING_PAGE_URL,
    COLOR_BLUE,
    PRINT_SCALE,
    EditorInfoType
} from '../Constants/Constants';
import DocumentManager from "./DocumentManager";
import "./../CommonStyles/FontStyle.css"
import Utility, { getCapitalized, getIsDebitOrCreditNote, getIsAccountModule, getIsExpenseOrDeposit, getIsMakePayment, getVW, getPageWidth, getPageHeight, getFontSize, getFontSizeClass, getIsReceivePayment, getIsQuotation, getIsInvoice, getIsBill, getIsOrder, getIsSalesOrder, getLanguageFlagIcons, getIsPurchaseRequisition} from "../Utilities/Utility";
import {getLocalisedText} from '../Translate/LanguageManager';
import AppManager from "./AppManager";
import ExpenseMapper from "../DocumentMapper/ExpenseMapper";
import DebitNoteMapper from "../DocumentMapper/DebitNoteMapper";
import TableComponentManger from "./TableComponentManger";
import TemplateSettingsManager from "./TemplateSettingsManager";
import LabelComponentManger from "./LabelComponentManger";
import { HTMLTag } from "../Utilities/HTMLTag";
import { Asset } from "../assets";
import DKExchangeRateSection from "../DKUILibrary/DKExchangeRateSection";

export default class ComponentManager {

    static isProductNameVisible = false;
    static isProductDescriptionVisible = false;
    static isProductQuantityVisible = false;
    static isProductUnitPriceVisible = false;
    static isProductAmountVisible = false;

    static getEndDateLabel(type) {
        if ((type.toLowerCase() === 'quotation') || (type.toLowerCase() === 'estimate') || (type.toLowerCase() === 'purchase order')) {
            return getLocalisedText("valid_till")
        }
        else if (getIsMakePayment(type) || getIsReceivePayment(type)) {
            return getLocalisedText(ExpenseMapper.getLabel().referenceDate)
        }
        else if (getIsAccountModule(undefined, type)) {
            return getLocalisedText(ExpenseMapper.getLabel().referenceDate)
        }
        else {
            return getLocalisedText("due_date")
        }
    }

    static getStartDateLabel(type) {
        if (getIsExpenseOrDeposit(type)) {
            return getLocalisedText(ExpenseMapper.getLabel().paymentDate)
        }
        else if (getIsDebitOrCreditNote(type)) {
            return getLocalisedText(DebitNoteMapper.getLabel().documentDate)
        }
        else if (getIsMakePayment(type)) {
            return getLocalisedText('payment_label') + getLocalisedText('date')
        }
        else if (getIsReceivePayment(type)) {
            return getLocalisedText('receipt_label') + getLocalisedText('date')
        }
        else {
            return getCapitalized(getLocalisedText(type.toLowerCase())) + getLocalisedText('date')
        }
    }

    static getAdditionalDateLabel(type) {
        if (getIsQuotation(type)
            || getIsSalesOrder(type)
            || getIsInvoice(type)) {
            return getLocalisedText('ship_by')
        }
        else if (getIsBill(type) || getIsOrder(type) || getIsPurchaseRequisition(type)) {
            return getLocalisedText('receive_by')
        }
        else {
            return getLocalisedText('additional_date')
        }
    }
    ////////////////////////////////////////////
    ////////////////////////////////////////////
    ////////////////////////////////////////////

    static getButton(title, backgroundColor = 'rgb(235, 235, 235)', titleColor = 'rgb(65, 65, 65)', callback = null, icon = null, isBordered = false, isBig = false) {
        let padding = isBig ? 10 : 5
        return (
            <div
                style={{
                    backgroundColor: backgroundColor,
                    color: titleColor,
                    paddingLeft: padding * 2,
                    paddingRight: padding * 2,
                    paddingTop: padding,
                    paddingBottom: padding,
                    border: isBordered ? "solid" : "none",
                    borderWidth: getVW(1),
                    borderColor: backgroundColor,
                    fontSize: isBig ? 18 : 14,
                    borderRadius: isBig ? 10 : 8,
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={callback}
            >
                <div className="RowDiv">
                    {icon && ComponentManager.getIcon(icon)}
                    {icon && title && <div style={{width: getVW(padding)}}/>}
                    <text className={(isBig ? "" : "docFontStyle") + getFontSizeClass()} style={{
                        fontSize: 13,
                        height: 15,
                        overflow: 'hidden'
                    }}>{getLocalisedText(title)}</text>
                </div>
            </div>
        )
    }


    static getImageButton(image, callback, leftMargin = 18, size = 18) {
        return (
            <div
                style={{
                    height: size,
                    marginLeft: leftMargin,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    outline: "none",
                }}
                onClick={() => callback()}
            >
                <img src={image} alt="" style={{ height: "100%" }} />
            </div>
        );
    }

    static getLanguageSelectionButton(selectedLanguage, callback) {
        return (
            <div
                style={{
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={callback}
            >
                <div className="RowDiv">
                    <div className="RowDiv" style={{paddingRight: 5, paddingLeft: 5}}>
                        {ComponentManager.getIcon(getLanguageFlagIcons(selectedLanguage?.flag ?? 'us'), 25)}
                        {<div style={{width: 5}}/>}
                        <text className={"docFontStyle" + getFontSizeClass()} style={{
                            fontSize: 13,
                            // height: 15,
                            overflow: 'hidden',
                        }}>
                            {selectedLanguage?.name ?? 'en'}
                        </text>
                    </div>
                    <div className="RowDiv">
                        <img
                            className="ListPickerArrowStyle"
                            style={{ position: 'relative', top: 1, right: 0, bottom: 0 }}
                            src={Asset.icon.ic_listPickerArrow2}
                            alt=""
                        />
                    </div>

                </div>
            </div>
        )
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

     static getSaveButton(callback) {
        return (
            <div
                style={{
                    paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={callback}
            >
                <div className="RowDiv">
                    <div className="RowDiv" style={{paddingRight: 5, paddingLeft: 5}}>
                        <text className={"docFontStyle" + getFontSize()} style={{
                            fontSize: 13,
                            // height: 15,
                            overflow: 'hidden',
                        }}>
                            {getLocalisedText('save_template')}
                        </text>
                    </div>
                    <div style={{
                        display:'flex',
                    }}>
                        <img
                            className="ListPickerArrowStyle"
                            style={{ position: 'relative', top: 1, right: 0, bottom: 0 }}
                            src={Asset.icon.ic_listPickerArrow2}
                            alt=""
                        />
                    </div>

                </div>
            </div>
        )
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////


    static getLogoVisibility(showCompanyLogo, isReadOnlyMode, imageData) {
        if (showCompanyLogo) {
            if (isReadOnlyMode && imageData == null) {
                return false
            }
            return true
        }
        return false
    }

    static getLogo(image = null, size = 100, positionStyle) {
        return (
            <img
                id={HTMLTag.LOGO}
                src={image ? image : Asset.icon.ic_thumbnail_default2}
                alt=""
                style={{
                    ...{
                        position: "absolute",
                        height: getVW(size + Utility.getImageSize(EditorInfoType.logo)),
                        borderRadius: image ? "0%" : "50%",
                        border: "solid",
                        borderWidth: image ? 0 : getVW(1),
                        borderColor: "rgb(230, 230, 230)",
                        // backgroundColor: "rgb(230, 230, 230)",
                    },
                    ...positionStyle,
                }}
            />
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    static getSignature(image, printScale = PRINT_SCALE) {
        return (
            <div className="RowReverseDiv">
                <img
                    src={image ? image : Asset.icon.signature}
                    alt=""
                    style={{
                        // width: "8.4vw",
                        // height: getVW('4vw', true),
                        height: getVW(Utility.convertToPrintScale(57.2, printScale) + Utility.getImageSize(EditorInfoType.signature)),
                        marginRight: getVW(`${Utility.convertToPrintScale(2.79, printScale)}vw`, true)
                    }}
                />
            </div>
        );
    }

    static getIcon(image, size = 15) {
        return <img
            src={image}
            alt=""
            style={{
                width: size,
            }}
        />
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    static getDescriptionSection(
        title,
        description,
        width = "20%",
        titleColor = "black",
        headerSeparator = false,
        textColor="#000000",
        paddingTop=getVW(6),
        ownerType,
        printScale
    ) {
        var flexDirection = 'row'
        if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
            flexDirection = 'row-reverse'
        }

        var paddingHorizontal = undefined

        if (Utility.getIsUsingTableGrid()) {
            paddingHorizontal = getVW(10)
        }

        return (
            <div className="ColumnDiv" style={{
                width: width,
                paddingLeft: paddingHorizontal,
                paddingRight: paddingHorizontal,
                }}>
                {title && (
                    <div className="RowDiv" style={{ width: '100%', flexDirection: flexDirection }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                            color: titleColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: ownerType }, printScale)
                        }}>
                            {getLocalisedText(title).toUpperCase()}
                        </text>
                    </div>
                )}
                {title && headerSeparator && ComponentManager.addDivider({ backgroundColor: 'lightgray', height: getVW(1), width: '100%' })}
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: '100%',
                        marginTop: paddingTop,
                        color: textColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: ownerType }, printScale)
                        }}
                    dangerouslySetInnerHTML={{__html: getLocalisedText(description)}}>
                </div>
            </div>
        );
    }

    static getTermsAndConditionOrNotesSection(
        title,
        description,
        width = "20%",
        titleColor = "black",
        headerSeparator = false,
        textColor = "#000000",
        paddingTop = getVW(6),
        printScale,
        fontSettings
    ) {

        var flexDirection = 'row'
        if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
            flexDirection = 'row-reverse'
        }

        var paddingHorizontal = undefined

        if (Utility.getIsUsingTableGrid()) {
            paddingHorizontal = getVW(10)
        }

        return (
            <div className="ColumnDiv" style={{
                width: width,
                paddingLeft: paddingHorizontal,
                paddingRight: paddingHorizontal,
            }}>
                {title && (
                    <div className="RowDiv" style={{ width: '100%', flexDirection: flexDirection }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                            color: titleColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: fontSettings, headerTitle: true }, printScale)
                        }}>
                            {getLocalisedText(title).toUpperCase()}
                        </text>
                    </div>
                )}
                {title && headerSeparator && ComponentManager.addDivider({ backgroundColor: 'lightgray', height: getVW(1), width: '100%' })}
                <div className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        width: '100%',
                        marginTop: paddingTop,
                        color: textColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: fontSettings }, printScale)
                    }}
                    dangerouslySetInnerHTML={{ __html: getLocalisedText(description) }}>
                </div>
            </div>
        );
    }

    static getNoteSectionCustom(
        title,
        description,
        width = "20%",
        titleColor = "black",
        ValueSeparator = "",
        textColor = "#000000",
        paddingTop = getVW(6),
        printScale,
        fontSettings
    ) {
        let flexDirection = 'row'
        if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
            flexDirection = 'row-reverse'
        }

        let paddingHorizontal = undefined
        if (Utility.getIsUsingTableGrid()) {
            paddingHorizontal = getVW(10)
        }

        return (
            <div className="RowDiv" style={{
                paddingLeft: paddingHorizontal,
                paddingRight: paddingHorizontal,
                paddingTop: paddingTop,
            }}>
                <div className="RowDiv" style={{ width: width, flexDirection: flexDirection }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                        color: titleColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: fontSettings, headerTitle: true }, printScale)
                    }}>
                        {getLocalisedText(title)}
                    </text>
                </div>
                <div className="RowDiv" style={{ width: "auto" }}>
                    {ValueSeparator &&
                        <div className="mr-s">
                            {ValueSeparator}
                        </div>
                    }
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '100%',
                            color: textColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: fontSettings }, printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText(description) }}>
                    </div>
                </div>
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    static getTitleAndValueColumn(
        title,
        value,
        titleColor = "black",
        valueColor = "black",
        alignItems = "flex-start",
        titleFontSize = FONT_SIZE.regular,
        valueFontSize = FONT_SIZE.regular,
        valueFontWeight = "regular",
        spaceBetween = 0,
        direction = 'ltr',
        printScale
    ) {
        return (
            <div className="ColumnDiv" style={{ width: '100%', alignItems: alignItems }}>
                {title && (
                    <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            color: titleColor,
                            textAlign: 'right',
                            ...Utility.getFontStyleProp({fontSize: titleFontSize}, printScale),
                        }}>
                        {title}
                    </text>
                )}
                {ComponentManager.addVerticalSpace(spaceBetween)}
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        color: valueColor,
                        fontWeight: valueFontWeight,
                        direction: direction,
                        ...Utility.getFontStyleProp({ fontSize: valueFontSize }, printScale),
                    }}>
                    {value}
                </text>
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    static getRowValueSection(title, value, titleColor = "#000000", valueColor = "#000000", valueFontWeight="regular", isValueLeftAligned = false, printScale) {
        //template 22, 28 - isValueLeftAligned
        var flexDirection = 'row'
        var widthDiv = undefined
        if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
            flexDirection = 'row-reverse'
            widthDiv = '100%'
        }

        var borderBottom = undefined
        var paddingHorizontal = undefined

        if(Utility.getIsUsingTableGrid()) {
            borderBottom = 'solid 1px'
            paddingHorizontal = getVW(Utility.convertToPrintScale(10, printScale))
        }

        return (
            <div
                className="RowDiv"
                style={{
                    justifyContent: isValueLeftAligned ? null : "space-between",
                    paddingTop: getVW(Utility.convertToPrintScale(4, printScale)),
                    paddingBottom: getVW(Utility.convertToPrintScale(4, printScale)),
                    flex: 1,
                    paddingLeft: paddingHorizontal,
                    paddingRight: paddingHorizontal,
                    borderBottom: borderBottom,
                    width: '-webkit-fill-available'
                }}
            >
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: titleColor,
                        width: isValueLeftAligned ? getVW(Utility.convertToPrintScale(150, printScale)) : widthDiv,
                        flexDirection: flexDirection,
                        ...Utility.getFontStyleProp({fontSize: FONT_SIZE.regular}, printScale)
                    }}>
                    {title.toUpperCase()}
                </text>
                <text className={"docFontStyle" + getFontSizeClass()}
                    style={{
                        color: valueColor,
                        fontWeight: valueFontWeight,
                        flexDirection: flexDirection,
                        width: widthDiv,
                        textAlign: 'end',
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, printScale)
                }}>
                    {value}
                </text>
            </div>
        );
    }

    static getRowValueSectionCustom(
        title,
        value,
        titleWidth = "20%",
        titleColor = "black",
        valueColor = "#000000",
        valueSeparator = "",
        paddingTop = getVW(6),
        printScale
    ) {
        let flexDirection = 'row'
        if (TemplateSettingsManager.defaultTemplateSettings.languageInfo.code === 'ar') {
            flexDirection = 'row-reverse'
        }

        let paddingHorizontal = undefined
        if (Utility.getIsUsingTableGrid()) {
            paddingHorizontal = getVW(10)
        }

        return (
            <div className="RowDiv" style={{
                paddingLeft: paddingHorizontal,
                paddingRight: paddingHorizontal,
                paddingTop: paddingTop,
            }}>
                <div className="RowDiv" style={{ width: titleWidth, flexDirection: flexDirection }}>
                    <text className={"docFontStyleBold" + getFontSizeClass()} style={{
                        color: titleColor,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, headerTitle: true }, printScale)
                    }}>
                        {getLocalisedText(title)}
                    </text>
                </div>
                <div className="RowDiv" style={{ width: "auto" }}>
                    {valueSeparator &&
                        <div className="mr-s">
                            {valueSeparator}
                        </div>
                    }
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '100%',
                            color: valueColor,
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText(value) }}>
                    </div>
                </div>
            </div>
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    static getDiscountOnLineItems(items = []) {
        let discount = 0;
        for (let i = 0; i < items.length;i++) {
            let item = items[i];
            var itemDiscount = parseFloat(item.discount)
            if (item.discountInPercent !== undefined && item.discountInPercent !== null) {
                if(item.discountInPercent) {
                    var amount = parseFloat(item.quantity) * parseFloat(item.unitPrice)
                    itemDiscount = parseFloat(amount) * parseFloat(item.discountPercent) / 100
                }
            }
            discount = discount + itemDiscount
        }
        return discount
    }

    static getTaxOnLineItems(items = []) {
        let tax = 0.00;
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            let totalPrice = item.quantity * item.unitPrice
            let totalDiscount = item.discount
            totalPrice = totalPrice - totalDiscount;
            let totalTax = item.tax * 1
            tax = tax + totalTax
        }
        return parseFloat(tax.toFixed(AppManager.getDecimalScale()));
    }

    static getOutstandingAmount(data, roundingOffValue) {
        var totalAmount = this.getTotalOfLineItems(data.lineItems, false, false, roundingOffValue)
        if(data.payments === undefined || data.payments === null) {
            return totalAmount
        }
        if(data.payments.length === 0) {
            return totalAmount
        }

        return totalAmount - data.payments.map(x => x.amount).reduce((a, b) => a + b, 0)
    }

    static getTotalOfLineItems(items = [], isAccountingModule = false, isSubTotal = false, roundingOff = 0) {
        var total = 0;
        if (isAccountingModule === true) {
            //Accounting
            total = 0;
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                total += item.paymentAmount;
            }
            return total
        } else {
            //Document
            let totalDiscount = ComponentManager.getDiscountOnLineItems(items)
            let totaltax = ComponentManager.getTaxOnLineItems(items)
            total = 0;

            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if(item.priceInclusiveOfTax) {
                    let productTaxInclusivePrice = this.getTaxInclusiveSubtotalItemPrice(item)
                    total = total + productTaxInclusivePrice
                }else {
                    total = total + item.quantity * item.unitPrice;
                }
            }

            if(isSubTotal === false) {
                total = total - totalDiscount + totaltax + roundingOff;
            }
            if(!isNaN(total)) {
                return total
            }
            else {
                return items.map(x => parseFloat(x.amount)).reduce((prev, curr) => prev + curr);
            }

        }

    }

    static getTaxInclusiveSubtotalItemPrice(item) {
        let priceWithoutTax = 100 * item.unitPrice / (100 + item.taxInfo.percent)
        return parseFloat((priceWithoutTax * item.quantity).toFixed(AppManager.getDecimalScale()))
    }

    static getProductTaxInclusivePrice(item) {
        let taxPercent = 0
        if(item.taxInfo !== undefined && item.taxInfo !== null) {
            if(item.taxInfo.percent !== undefined && item.taxInfo.percent !== null) {
                taxPercent = item.taxInfo.percent
            }
        }
        let priceWithoutTax = (100 * item.unitPrice) / (100 + taxPercent)
        let priceAfterDiscount = priceWithoutTax - (item.discount/item.quantity)
        let taxOnItem = 0
        if(taxPercent > 0) {
            taxOnItem = priceAfterDiscount * taxPercent / 100
        }
        let finalPrice = priceAfterDiscount + taxOnItem
        return parseFloat((finalPrice * item.quantity).toFixed(AppManager.getDecimalScale()))
    }

    static getIsContainTaxBreakdown(data) {
        if (data.taxBreakdown !== undefined && data.taxBreakdown !== null) {
            if (data.taxBreakdown.length > 0) {
                return true
            } else {
                return false
            }
        }
        else {
            return false
        }
    }

    static getRoundingOffValue(data) {
        var roundingOff = 0
        if(this.getIsContainTaxBreakdown(data)) {
            data.taxBreakdown.forEach(element => {
                if(element.taxId === 'Rounding Off') {
                    if(element.taxAmount !== undefined && element.taxAmount !== null) {
                        roundingOff = element.taxAmount
                    }
                }
            });
        }
        return roundingOff
    }

    static getIsContainsPayments(data) {
        if (data.payments !== undefined && data.payments !== null) {
            if (data.payments.length > 0) {
                return true
            } else {
                return false
            }
        }
        else {
            return false
        }
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    static getTopBanner(img) {
        return (
            <img
                src={img}
                alt=""
                style={{
                    position: "absolute",
                    top: -PAGE_PADDING,
                    left: -PAGE_PADDING,
                    width: getPageWidth(),
                }}
            />
        );
    }

    //////////////////////////////////////////////////
    static getBottomBanner(img) {
        return (
            <img
                src={img}
                alt=""
                style={{
                    position: "absolute",
                    bottom: -PAGE_PADDING,
                    left: -PAGE_PADDING,
                    width: getPageWidth(),
                }}
            />
        );
    }
    //////////////////////////////////////////////////
    static getLeftBanner(img) {
        return (
            <img
                src={img}
                alt=""
                style={{
                    position: "absolute",
                    top: -PAGE_PADDING,
                    left: -PAGE_PADDING,
                    height: getPageHeight(),
                }}
            />
        );
    }
    //////////////////////////////////////////////////
    static getRightBanner(img) {
        return (
            <img
                src={img}
                alt=""
                style={{
                    position: "absolute",
                    top: -PAGE_PADDING,
                    right: -PAGE_PADDING,
                    height: getPageHeight(),
                }}
            />
        );
    }
    //////////////////////////////////////////////////
    static getBackgroundImage(img) {
        return (
            <img
                src={img}
                alt=""
                style={{
                    position: "absolute",
                    top: -PAGE_PADDING,
                    left: -PAGE_PADDING,
                    height: getPageHeight(),
                    width: getPageWidth(),
                }}
            />
        );
    }
    //////////////////////////////////////////////////

    static addVerticalSpace(height) {
        if (Utility.getIsLandscape()) {
            height = height * 0.25
        }
        return(
            <div style={{ height: getVW(height) }} />
        )
    }

    static addHorizontalSpace(width) {
        return (
            <div style={{ width: getVW(width) }} />
        )
    }
    static addDivider(style) {
        return(
            <div style={style} />
        )
    }

    ////////////////////////////////////////////
    ////////////////////////////////////////////
    ////////////////////////////////////////////

    static getClickableOptionsRow(icon, isIconRounded = false, text, value, callback) {
        return (
            <div className="RowDiv" style={{justifyContent: "space-between", cursor: "pointer", padding: 11, paddingRight: 0}} onClick={callback}>
                <div className="RowDiv" style={{alignItems: 'center',width: '80%'}}>
                    <img
                        src={icon}
                        alt=""
                        style={{ width: 25, marginRight: 10 , borderRadius: isIconRounded ? 12.5: 0}}
                    />
                    {getLocalisedText(text)}
                </div>
                <div style={{ color: 'gray'}}>
                    <text>{getLocalisedText(value)}</text>
                </div>
            </div>
        )
    }

    ////////////////////////////////////////////
    ////////////////////////////////////////////
    ////////////////////////////////////////////

    static getDeskeraBadge() {
        return (
            <div onClick={()=>Utility.openInNewTab(LANDING_PAGE_URL)} className="RowDiv Shadow" style={{cursor: 'pointer', padding: 10, borderRadius: 10, zIndex: 9999, width: 'auto', backgroundColor: 'white', position: 'absolute', bottom: 15, right: 15}}>
                <img alt="Deskera" src={Asset.icon.ico_deskLogo} style={{width: 25, borderRadius: 6}}/>
                <text style={{fontSize: 13, marginLeft: 6}}>Created by Deskera</text>
            </div>
        )
    }

    static getDKDocumentTable(
        tableColumnOptions,
        tableStyle = DocumentManager.getTableDefaultStyle(),
        data,
        callback,
        stockTableCallback,
        onUpdateDataCallback,
        paymentTableCallback,
        additionalTableCallback
    ) {
        return (
            <div className='ColumnDiv' style={{ width: "100%", zIndex: 2, alignItems: 'flex-end', position: 'relative' }}>
                { !data.isReadOnlyMode && this.renderTableColumnEditBlueButton(callback)}
                <div style={{ width: "100%" }} >
                    <TableComponentManger
                        tableColumnOptions={tableColumnOptions}
                        data={data}
                        tableStyle={tableStyle}
                        onUpdateDataCallback={onUpdateDataCallback}
                        stockTableCallback={stockTableCallback}
                        paymentTableCallback={paymentTableCallback}
                        additionalTableCallback={additionalTableCallback}
                    />
                </div>
            </div>
        );
    }

    static getDKDocumentTableWithSubtype(
        tableColumnOptions,
        tableStyle = DocumentManager.getTableDefaultStyle(),
        data,
        callback,
        stockTableCallback,
        onUpdateDataCallback,
        paymentTableCallback,
        additionalTableCallback, 
        subType
    ) {
        return (
            <div className='ColumnDiv' style={{ width: "100%", zIndex: 2, alignItems: 'flex-end', position: 'relative' }}>
                { !data.isReadOnlyMode && this.renderTableColumnEditBlueButton(callback)}
                <div style={{ width: "100%" }} >
                    <TableComponentManger
                        tableColumnOptions={tableColumnOptions}
                        data={data}
                        subType={subType}
                        tableStyle={tableStyle}
                        onUpdateDataCallback={onUpdateDataCallback}
                        stockTableCallback={stockTableCallback}
                        paymentTableCallback={paymentTableCallback}
                        additionalTableCallback={additionalTableCallback}
                    />
                </div>
            </div>
        );
    }


    static getDKProductTable(
        tableColumnOptions,
        tableStyle = DocumentManager.getTableDefaultStyle(),
        data,
        callback,
        stockTableCallback,
        onUpdateDataCallback,
        paymentTableCallback,
        additionalTableCallback
    ) {
        return (
            <div className='ColumnDiv' style={{ width: "100%", zIndex: 2, alignItems: 'flex-end', position: 'relative' }}>
                { !data.isReadOnlyMode && this.renderTableColumnEditBlueButton(() => callback('product'))}
                <div style={{ width: "100%" }} >
                    <TableComponentManger
                        tableColumnOptions={tableColumnOptions}
                        data={data}
                        tableStyle={tableStyle}
                        onUpdateDataCallback={onUpdateDataCallback}
                        stockTableCallback={stockTableCallback}
                        paymentTableCallback={paymentTableCallback}
                        additionalTableCallback={additionalTableCallback}
                        productLineItems={data.productLineItems}
                    />
                </div>
            </div>
        );
    }



    static getDKLabelTable(
        tableColumnOptions,
        tableStyle = DocumentManager.getTableDefaultStyle(),
        data,
        callback,
        stockTableCallback,
        onUpdateDataCallback,
        paymentTableCallback,
    ) {
        return (
            <div className='ColumnDiv' style={{ width: "100%", zIndex: 2, alignItems: 'flex-end', position: 'relative' }}>
                {!data.isReadOnlyMode && this.renderTableColumnEditBlueButton(callback)}
                <div style={{ width: "100%" }} >
                    <LabelComponentManger
                        tableColumnOptions={tableColumnOptions}
                        data={data}
                        tableStyle={tableStyle}
                        onUpdateDataCallback={onUpdateDataCallback}
                    />
                </div>
            </div>
        );
    }

    static renderTableColumnEditBlueButton(callback) {
        return <div id={'editTableButton'}
                    className="ColumnDiv"
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: getVW(-20),
                        right: getVW(-14),
                        backgroundColor: COLOR_BLUE,
                        width: getVW(34),
                        height: getVW(34),
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onClick={() => callback()}
        >
            <img src={Asset.icon.ic_edit_white} alt="edit" style={{ width: getVW(16)}}/>
        </div>;
    }

    static getExchangeRateTable(tableStyle, data) {
        return (
            <div className="parent-width">
                <DKExchangeRateSection
                    tableStyle={tableStyle}
                    data={data}
                />
            </div>
        );
    }

    static getGenerateDocumentSection(data, spacingRatio, paddingTop, paddingBottom) {
        const fontSettings = Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.generatedMessage)
        let textAlign = fontSettings?.fontSettings?.textAlign ?? 'center'
        return <div id={HTMLTag.GENERATED_TEXT} style={{
            paddingBottom: paddingBottom ?? getVW(PAGE_PADDING, true),
            paddingTop: paddingTop ?? getVW(5 * spacingRatio),
            width: '100%',
            textAlign: textAlign
        }}>
            <text className={'docFontStyle ' + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, tncOrNoteFontSettings: fontSettings }, data.printScale)
                }}>
                {Utility.getGeneratedMessage(data.type)}
            </text>
        </div>
    }

    static getPaperMargin() {
        const paperMarginSettings = TemplateSettingsManager.getPaperMargin()
        let paperMargin = <style>
            {`@media print {@page{margin: 4.76672vw 0 4.5vw 0;}}`}
        </style>
        if (paperMarginSettings && !Utility.getIsReceiptPaperSize()) {
            const isCustomMargin = paperMarginSettings.isCustomMargin ?? false
            const measurement = paperMarginSettings.measurement ?? 'mm'
            const margin = paperMarginSettings.margin ?? undefined

            if (isCustomMargin) {
                if (margin) {
                    let marginString = ''

                    let positionList = [
                        { index: 1, type: 'top', label: getLocalisedText('top'), value: margin?.top ?? 0 },
                        { index: 2, type: 'right', label: getLocalisedText('right'), value: margin?.right ?? 0 },
                        { index: 3, type: 'bottom', label: getLocalisedText('bottom'), value: margin?.bottom ?? 0 },
                        { index: 4, type: 'left', label: getLocalisedText('left'), value: margin?.left ?? 0 },
                    ]

                    positionList.forEach(element => {
                        marginString = marginString + element.value + measurement + ' '
                    });

                    paperMargin = <style>
                        {`@media print {@page{margin: ${marginString};}}`}
                    </style>
                }
            }
        }

        return paperMargin
    }

}
