import React, { Component } from 'react'
// import { ExportType } from '../../App';
import "../../DKUILibrary/DKUILibrary.css"
// import { getLocalisedText } from '../../Translate/LanguageManager';
// import { setPaperFormat } from '../../Utilities/Utility';
import PopupHeaderComponent from './PopupHeaderComponent';

import {
    ExportType,
    getLocalisedText,
    setPaperFormat,
    Asset
} from "deskera-doc-builder-lib";

export default class PaperFormatPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            format: 'A4'
        }
    }

    render() {
        return (
            <div className='ShadowMedium WindowPopup' style={{
                width: 350,
                height: 'auto',
                backgroundColor: 'white',
                padding: 0,
                paddingBottom: 10
            }}>
                <PopupHeaderComponent
                    headerText={this.getTitle()}
                    cancelText={this.props.exportType !== ExportType.VIEW_PDF ? 'cancel' : undefined}
                    submitText={'save'}
                    onCancelClicked={this.props.onCancelClicked}
                    onSubmitClicked={() => this.onSave()}
                />
                <div style={{
                    padding: 10,
                }}>
                    <div
                        style={{
                            fontSize: 12,
                            color: "black",
                            fontWeight: "600",
                            textAlign: 'left',
                            paddingLeft: 4,
                        }}
                    >
                        {getLocalisedText('please_provide_paper_format')}
                    </div>
                    {this.getPaperFormatListPicker()}
                </div>
                <div
                    style={{
                        fontSize: 12,
                        color: "black",
                        textAlign: 'left',
                        paddingLeft: 12,
                    }}
                >
                    {getLocalisedText('paper_format_popup_note')}
                </div>
            </div>
        )
    }

    getPaperFormatListPicker() {
        var paperFormat = ['A4','Letter', 'Legal', 'A3', 'A5']
        return (
            <div className="ListPickerHolderStyle" style={{marginTop:10}}>
                <div className="RowDiv">
                    <select
                        onChange={(event) => this.paperFormatChanged(event)}
                        className="TextField ListPicker"
                    >
                        {paperFormat.map((key) => {
                            return <>
                                <option
                                    value={key}
                                    selected={this.state.format === key}>
                                    {key}
                                </option>
                            </>
                        })}
                    </select>
                    <img
                        className="ListPickerArrowStyle"
                        src={Asset.icon.ic_listPickerArrow2}
                        alt=""
                    />
                </div>
            </div>
        );
    }


    getTitle() {
        return getLocalisedText('paper_format')
    }

    paperFormatChanged(event) {
        var newFormat = event.target.value
        this.setState({
            format: newFormat
        })
    }

    onSave() {
        setPaperFormat(this.state.format.toLocaleLowerCase())
        this.props.onPaperFormatSave(this.props.exportType)
    }
}
