import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpCurrency extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Currency')}
                {this.renderCurrency()}
            </div>
        </div>
    }

    renderCurrency() {
        var data = {
            header: "Currency",
            paragraph: [
                {
                    type: 'text',
                    value: "You can show/hide currency from the documet as well as update currency syntax.",
                },
                {
                    type: 'image',
                    value: require('./../asset/currency/currency.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "The actions that you can performance as as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Show/Hide Currency",
                        "Select Currency Name or Symbol or Both",
                        "Custom Syntax",
                    ]
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Hide Currency",
                },
                {
                    type: 'image',
                    value: require('./../asset/currency/currency-hide.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Currency Name",
                },
                {
                    type: 'image',
                    value: require('./../asset/currency/currency-name.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Currency Symbol",
                },
                {
                    type: 'image',
                    value: require('./../asset/currency/currency-symbol.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Currency Name and Symbol",
                },
                {
                    type: 'image',
                    value: require('./../asset/currency/currency-name-symbol.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Currency Custom Text",
                },
                {
                    type: 'image',
                    value: require('./../asset/currency/currency-custom-text.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}