import { PRINT_SCALE } from "../Constants/Constants";
import { watermarkPrintType } from "../Helper/types";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import { HTMLTag } from "./HTMLTag";
import MultiPageUtility from "./MultiPageUtility";
import { getPageHeight, getPageWidth, getPX } from "./Utility";
import WatermarkUtility from "./WatermarkUtility";

export default class NewMultiPageUtility {
    static type = "";
    static additionalTablePrefix = "";

    static TEMPLATE_WITH_BORDER = [51, 52, 53, 54, 55, 56, 57, 60, 61, 62, 63];

    static renderForPrintWithRepeatableHeaderFooter(data, printInfo) {
        if (data !== undefined && printInfo !== undefined) {
            this.type = data.type;

            const template = document.getElementById(HTMLTag.TEMPLATE);
            template.style.paddingBottom = 0;

            const dimensions = this.getMultiPageDimensions(template, data);
            const pages = this.getTotalPagesRequired(data, dimensions);
            this.getPageTemplate(pages, dimensions, data);

            const watermarkConfiguration = WatermarkUtility.getWatermarkConfiguration()
            if (watermarkConfiguration !== undefined && watermarkConfiguration !== null) {
                if (watermarkConfiguration.isVisible) {
                    this.addWatermarkCopies(template, printInfo);
                }
            }
        }
    }

    static getIsLandscape() {
        return TemplateSettingsManager.defaultTemplateSettings.templateInfo.orientation === 'landscape';
    }

    static getMultiPageDimensions(template, data) {
        //calculate the page height n compare to default page size
        const dimensions = {
            documentTemplate: template,
            documentData: data,
            paperFormat: TemplateSettingsManager.pageFormat,
            offsetPadding: this.getIsLandscape() ? getPX('5.6vw') : getPX('9.46672vw')
        }

        let height = 0
        let width = 0

        const element = template.getBoundingClientRect()
        if (element) {
            height = element.height
            width = element.width
        }

        dimensions.currentPageHeight = height
        dimensions.currentPageWidth = width

        const pageWidth = getPX(getPageWidth())
        const pageHeight = getPX(getPageHeight(PRINT_SCALE, true))

        dimensions.defaultPageHeight = pageHeight
        dimensions.defaultPageWidth = pageWidth

        const ratio = pageWidth / width
        const expectedHeight = height * ratio
        const expectedWidth = width * ratio

        dimensions.computeHeight = expectedHeight
        dimensions.computeWidth = expectedWidth
        dimensions.ratio = ratio

        const differentWidth = Math.abs(pageWidth - expectedWidth)
        dimensions.differentWidth = differentWidth
        dimensions.additionalOffset = this.getIsLandscape() ? 0 : getPX('4.1958vw')

        const differentHeight = Math.abs(pageHeight - expectedHeight)
        dimensions.differentHeight = differentHeight

        this.calculateWatermarkHeight(template, dimensions);
        this.calculateGeneratedTextHeight(template, dimensions);
        this.calculateHeaderDimensions(template, dimensions);
        this.calculateLineItemAboveDimensions(template, dimensions);
        this.calculateLineTableDimensions(template, dimensions);
        this.calculateLineItemBelowDimensions(template, dimensions);
        this.calculateFooterDimensions(template, dimensions);
        this.calculateOtherDimensions(dimensions);

        return dimensions
    }

    static calculateWatermarkHeight(template, dimensions) {
        const element = MultiPageUtility.getTemplateRect(template, HTMLTag.WATERMARK_TEXT);
        if (element) {
            const watermarkDimensions = {
                height: element.height,
                width: element.width,
                computeHeight: element.height * dimensions.ratio,
                computeWidth: element.width * dimensions.ratio
            }

            dimensions.watermarkDimensions = watermarkDimensions;
        }
    }

    static calculateGeneratedTextHeight(template, dimensions) {
        const element = MultiPageUtility.getTemplateRect(template, HTMLTag.GENERATED_TEXT);
        if (element) {
            const generatedTextDimensions = {
                height: element.height,
                width: element.width,
                computeHeight: element.height * dimensions.ratio,
                computeWidth: element.width * dimensions.ratio
            }

            dimensions.generatedTextDimensions = generatedTextDimensions;
        }
    }

    static calculateHeaderDimensions(template, dimensions) {
        const element = MultiPageUtility.getTemplateRect(template, HTMLTag.TEMPLATE_HEADER);
        if (element) {
            const headerDimensions = {
                height: element.height,
                width: element.width,
                computeHeight: element.height * dimensions.ratio,
                computeWidth: element.width * dimensions.ratio
            };

            dimensions.headerDimensions = headerDimensions
        }
    }

    static calculateLineItemAboveDimensions(template, dimensions) {
        const element = MultiPageUtility.getTemplateRect(template, HTMLTag.TEMPLATE_LT_ABOVE);
        if (element) {
            const ltAboveDimensions = {
                height: element.height,
                width: element.width,
                computeHeight: element.height * dimensions.ratio,
                computeWidth: element.width * dimensions.ratio
            };

            dimensions.ltAboveDimensions = ltAboveDimensions
        }
    }

    static calculateLineTableDimensions(template, dimensions) {
        const element = MultiPageUtility.getTemplateRect(template, HTMLTag.TEMPLATE_LINE_TABLE);
        if (element) {
            const tableDimensions = [];
            const tables = this.getLineItemTables(document);
            for (let table of tables) {
                const tableDimension = table.getBoundingClientRect();

                const rowDimensions = [];
                Array.from(table.firstChild.children).forEach((row, index) => {
                    const rowDimension = row.getBoundingClientRect();
                    rowDimensions.push({
                        height: rowDimension.height,
                        width: rowDimension.width,
                        computeHeight: rowDimension.height * dimensions.ratio,
                        computeWidth: rowDimension.width * dimensions.ratio
                    });
                });

                tableDimensions.push({
                    height: tableDimension.height,
                    width: tableDimension.width,
                    computeHeight: tableDimension.height * dimensions.ratio,
                    computeWidth: tableDimension.width * dimensions.ratio,
                    rowDimensions: rowDimensions
                });
            }

            const lineTableDimensions = {
                height: element.height,
                width: element.width,
                computeHeight: element.height * dimensions.ratio,
                computeWidth: element.width * dimensions.ratio,
                tableDimensions: tableDimensions
            };

            dimensions.lineTableDimensions = lineTableDimensions
        }
    }

    static calculateLineItemBelowDimensions(template, dimensions) {
        const element = MultiPageUtility.getTemplateRect(template, HTMLTag.TEMPLATE_LT_BELOW);
        if (element) {
            const ltBelowDimensions = {
                height: element.height,
                width: element.width,
                computeHeight: element.height * dimensions.ratio,
                computeWidth: element.width * dimensions.ratio
            };

            dimensions.ltBelowDimensions = ltBelowDimensions
        }
    }

    static calculateFooterDimensions(template, dimensions) {
        const element = MultiPageUtility.getTemplateRect(template, HTMLTag.TEMPLATE_FOOTER);
        if (element) {
            const footerDimensions = {
                height: element.height,
                width: element.width,
                computeHeight: element.height * dimensions.ratio,
                computeWidth: element.width * dimensions.ratio
            };

            dimensions.footerDimensions = footerDimensions
        }
    }

    static calculateOtherDimensions(dimensions) {
        let repeatableSectionHeight = 0;
        if (dimensions.headerDimensions) {
            repeatableSectionHeight += dimensions.headerDimensions.computeHeight;
        }

        if (dimensions.footerDimensions) {
            repeatableSectionHeight += dimensions.footerDimensions.computeHeight;
        }

        let contentHeight = 0;
        if (dimensions.ltAboveDimensions) {
            contentHeight += dimensions.ltAboveDimensions.computeHeight;
        }
        if (dimensions.lineTableDimensions) {
            contentHeight += dimensions.lineTableDimensions.computeHeight;
        }
        if (dimensions.ltBelowDimensions) {
            contentHeight += dimensions.ltBelowDimensions.computeHeight;
        }
        if (dimensions.generatedTextDimensions) {
            contentHeight += dimensions.generatedTextDimensions.computeHeight;
        }

        let usableHeightPerPage = dimensions.defaultPageHeight - dimensions.offsetPadding - dimensions.additionalOffset - repeatableSectionHeight;
        if (dimensions.watermarkDimensions) {
            usableHeightPerPage -= dimensions.watermarkDimensions.computeHeight
        }

        dimensions.repeatableSectionHeight = repeatableSectionHeight;
        dimensions.contentHeight = contentHeight;
        dimensions.usableHeightPerPage = usableHeightPerPage;
    }

    // get total pages required with repeatable header and footer on each page
    static getTotalPagesRequired(data, dimensions) {
        let contentHeight = dimensions.contentHeight;
        let totalPages = Math.ceil(contentHeight / dimensions.usableHeightPerPage);
        
        if (this.getIsTemplateWithBorder(data.templateId)) {
            contentHeight += totalPages * (dimensions.ratio * 4);
            totalPages = Math.ceil(contentHeight / dimensions.usableHeightPerPage);
        }

        return totalPages;
    }

    static getIsTemplateWithBorder(templateId) {
        return this.TEMPLATE_WITH_BORDER.includes(templateId);
    }

    static removeSectionFromTemplate(template) {
        const sections = [
            HTMLTag.TEMPLATE_HEADER,
            HTMLTag.TEMPLATE_LT_ABOVE,
            HTMLTag.TEMPLATE_LINE_TABLE,
            HTMLTag.TEMPLATE_LT_BELOW,
            HTMLTag.TEMPLATE_FOOTER
        ];

        sections.forEach((section) => {
            const ele = template.querySelector(`#${CSS.escape(section)}`);
            if (ele && template.contains(ele)) {
                template.removeChild(ele);
            }
        });
    }

    static copyClassAndStyle(target, source) {
        target.classList.add(...source.classList);

        const computedStyle = window.getComputedStyle(source);
        for (let property of computedStyle) {
            target.style[property] = computedStyle.getPropertyValue(property);
        }
    }

    static createNewPage(index, isTemplateWithBorder) {
        const page = document.createElement("div");
        page.classList.add("parent-width");

        if (isTemplateWithBorder) {
            page.style.border = "1px solid black";
        }

        if (index !== 0) {
            page.classList.add("break-before-always");
        }

        return page;
    }

    static getTemplateContent() {
        const templateContent = document.getElementById(HTMLTag.TEMPLATE_CONTENT);
        if (templateContent) {
            templateContent.style.removeProperty('min-height');
        }

        return templateContent;
    }

    static getTemplateSection(template) {
        const clonedTemplate = template.cloneNode(true);

        const header = clonedTemplate.querySelector(`#${CSS.escape(HTMLTag.TEMPLATE_HEADER)}`);
        const ltAbove = clonedTemplate.querySelector(`#${CSS.escape(HTMLTag.TEMPLATE_LT_ABOVE)}`);
        const lineTable = clonedTemplate.querySelector(`#${CSS.escape(HTMLTag.TEMPLATE_LINE_TABLE)}`);
        const ltBelow = clonedTemplate.querySelector(`#${CSS.escape(HTMLTag.TEMPLATE_LT_BELOW)}`);

        const footer = clonedTemplate.querySelector(`#${CSS.escape(HTMLTag.TEMPLATE_FOOTER)}`);
        if (footer) {
            footer.classList.remove('mt-auto');
        }

        return {
            header,
            ltAbove,
            lineTable,
            ltBelow,
            footer
        }
    }

    static appendSectionToPage(page, section, sectionId, elementList, remainingHeight, computeHeight) {
        page.appendChild(section.cloneNode(true));
        elementList.add(sectionId);
        return remainingHeight - computeHeight;
    }

    static getIsLineTableAdded(elementList) {
        let isLineTableAdded = false;

        const regex = /^lineTable_\d_rows$/;
        elementList.forEach(item => {
            if (regex.test(item)) {
                isLineTableAdded = true;
            }
        });

        return isLineTableAdded;
    }

    static getLineItemTables(section) {
        return section.querySelectorAll(`#${CSS.escape(HTMLTag.TABLE_PREFIX + this.type + '_' + this.additionalTablePrefix)}`);
    }

    static getTotalLineTableRows(section) {
        let totalLineTableRows = 0;

        const tables = this.getLineItemTables(section);
        for (let table of tables) {
            totalLineTableRows += table.firstChild.children.length;
        }

        return totalLineTableRows;
    }

    static getRemaningHeightDiv(dimensions, remainingHeight) {
        const div = document.createElement("div");
        div.classList.add("parent-width");

        let ratio = dimensions.currentPageWidth / dimensions.defaultPageWidth;
        if (dimensions.currentPageWidth > dimensions.defaultPageWidth) {
            ratio = dimensions.defaultPageWidth / dimensions.currentPageWidth;
        }

        div.style.height = `${remainingHeight * ratio}px`;

        return div;
    }

    // Get page template with repeatable header and footer
    static getPageTemplate(pages, dimensions, data) {
        const templateHolder = document.getElementById(HTMLTag.TEMPLATE_HOLDER);
        if (!templateHolder) {
            return;
        }

        const isTemplateWithBorder = this.getIsTemplateWithBorder(data.templateId);
        if (isTemplateWithBorder) {
            templateHolder.style.border = "none";
        }


        const templateContent = this.getTemplateContent();
        if (!templateContent) {
            return;
        }

        const section = this.getTemplateSection(templateContent);

        this.removeSectionFromTemplate(templateContent);

        const elementList = new Set();
        const totalLineTableRows = this.getTotalLineTableRows(section.lineTable);
        let addedLineTableRows = 0;

        for (let pIndex = 0; pIndex < pages; pIndex++) {
            const page = this.createNewPage(pIndex, isTemplateWithBorder);

            let remainingHeight = dimensions.usableHeightPerPage;
            if (isTemplateWithBorder) {
                remainingHeight -= dimensions.ratio * 4;
            }

            if (pIndex + 1 === pages && dimensions.generatedTextDimensions) {
                remainingHeight -= dimensions.generatedTextDimensions.computeHeight
            }

            // header section
            let sectionId = "header";
            remainingHeight = this.appendSectionToPage(page, section.header, sectionId, elementList, remainingHeight, 0);

            // line table above section
            sectionId = "ltAbove";
            const ltAboveHeight = dimensions.ltAboveDimensions.computeHeight;
            if (!elementList.has(sectionId) && remainingHeight > ltAboveHeight) {
                remainingHeight = this.appendSectionToPage(page, section.ltAbove, sectionId, elementList, remainingHeight, ltAboveHeight);
            }

            // line table section
            sectionId = "lineTable";
            if (!elementList.has(sectionId)) {
                const lineTableHeight = dimensions.lineTableDimensions.computeHeight;
                if (!this.getIsLineTableAdded(elementList) && remainingHeight > lineTableHeight) {
                    remainingHeight = this.appendSectionToPage(page, section.lineTable, sectionId, elementList, remainingHeight, lineTableHeight);
                    addedLineTableRows = totalLineTableRows;
                } else {
                    let breakLineTableLoop = false;
                    const tables = this.getLineItemTables(section.lineTable);

                    for (let tIndex = 0; tIndex < tables.length; tIndex++) {
                        sectionId = `lineTable_${tIndex}`;
                        const table = tables[tIndex];

                        if (!elementList.has(sectionId)) {
                            const tableDimensions = dimensions.lineTableDimensions.tableDimensions[tIndex];

                            if (!elementList.has(`lineTable_${tIndex}_rows`) && remainingHeight > tableDimensions.computeHeight) {
                                remainingHeight = this.appendSectionToPage(page, table, sectionId, elementList, remainingHeight, tableDimensions.computeHeight);
                                addedLineTableRows += table.firstChild.children.length;
                            } else {
                                const tEle = document.createElement("table");
                                this.copyClassAndStyle(tEle, table);
                                const tbody = document.createElement("tbody");
                                const trows = table.firstChild.children;

                                for (let rIndex = 0; rIndex < trows.length; rIndex++) {
                                    const row = trows[rIndex];
                                    const rowDimensions = tableDimensions.rowDimensions[rIndex];
                                    if (rowDimensions.computeHeight > remainingHeight) {
                                        breakLineTableLoop = true;
                                        break;
                                    } else {
                                        sectionId = `lineTable_${tIndex}_row_${rIndex}`;
                                        if (!elementList.has(sectionId) && remainingHeight > rowDimensions.computeHeight) {
                                            remainingHeight = this.appendSectionToPage(tbody, row, sectionId, elementList, remainingHeight, rowDimensions.computeHeight);
                                            addedLineTableRows += 1;
                                        }
                                    }
                                }

                                sectionId = `lineTable_${tIndex}_rows`;
                                remainingHeight = this.appendSectionToPage(tEle, tbody, sectionId, elementList, remainingHeight, 0);

                                page.appendChild(tEle);
                            }
                        }

                        if (breakLineTableLoop) {
                            break;
                        }
                    }
                }
            }

            // line table below section
            sectionId = "ltBelow"
            const ltBelowHeight = dimensions.ltBelowDimensions.computeHeight;
            if (totalLineTableRows === addedLineTableRows && !elementList.has(sectionId) && remainingHeight > ltBelowHeight) {
                remainingHeight = this.appendSectionToPage(page, section.ltBelow, sectionId, elementList, remainingHeight, ltBelowHeight);
            }

            // remaining height section
            sectionId = `div_${pIndex}`;
            if (remainingHeight > 0) {
                const div = this.getRemaningHeightDiv(dimensions, remainingHeight);
                remainingHeight = this.appendSectionToPage(page, div, sectionId, elementList, remainingHeight, remainingHeight);
            }

            // footer section
            sectionId = "footer";
            remainingHeight = this.appendSectionToPage(page, section.footer, sectionId, elementList, remainingHeight, 0);

            templateContent.appendChild(page);
        }
    }

    static addWatermarkCopies(template, printInfo) {
        if (printInfo.wmark === watermarkPrintType.all) {
            const wmarkCopies = [];

            let wmarkList = [watermarkPrintType.original, watermarkPrintType.duplicate, watermarkPrintType.triplicate, watermarkPrintType.quadruplicate];
            if (printInfo.mutliWmark !== undefined) {
                wmarkList = printInfo.mutliWmark
            }

            wmarkList.forEach((wmark, index) => {
                const text = WatermarkUtility.getWatermarkValueByType(wmark);
                let wmarkCopy;
                if (index === 0) {
                    wmarkCopy = MultiPageUtility.replaceWatermarkText(template, text);
                } else {
                    wmarkCopy = MultiPageUtility.replaceWatermarkText(template.cloneNode(true), text);
                }

                wmarkCopies.push(wmarkCopy);
            });

            const mainTemplate = document.getElementById(HTMLTag.MAIN_TEMPLATE);
            if (mainTemplate) {
                mainTemplate.style.backgroundColor = 'white';

                wmarkCopies.forEach((wmark, index) => {
                    if (index !== 0) {
                        wmark.classList.add("break-before-always");
                    }

                    mainTemplate.appendChild(wmark);
                });
            }
        }
    }
}
