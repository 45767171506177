import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpSendEmailWithSavedTemplate extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Send Email')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Send email with saved template",
            paragraph: [
                {
                    type: 'text',
                    value: "With Books+, users can send quotes, bills, orders, and even invoices documents to the respective recipients directly in the software.",
                },
                {
                    type: 'text',
                    value: "Once you have saved the document such as quote, invoice, and other, you can send an email with the template attached.",
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Follow the steps mentioned below:",
                },
                {
                    type: 'text',
                    value: "1. Go to your respective quote, order, bill, and invoice document.",
                },
                {
                    type: 'text',
                    value: "2. Click on the three dots and select email button.",
                },
                {
                    type: 'image',
                    value: require('./../asset/send-email-with-saved-template/email.png'),
                },
                {
                    type: 'text',
                    value: "3. This will open the pop-up menu.",
                },
                {
                    type: 'image',
                    value: require('./../asset/send-email-with-saved-template/email-popup.png'),
                },
                {
                    type: 'text',
                    value: "4. Next, indicate the sender name, recipient’s email, the reply to email, and the email's subject. You are allowed to edit the fields directly from the pop-up box.",
                },
                {
                    type: 'text',
                    value: "** You may send the documents to multiple emails at a time. Ensure that you indicate a semi-colon(;) to separate the multiple emails.",
                    isBold: true
                },
                {
                    type: 'text',
                    value: "5. You can also edit the email's body instead of using the default template shown. Click on the + Placeholder button to generate the system field for this email.",
                },
                {
                    type: 'text',
                    value: "6. The Placeholders that you select from are state here; Contact Name, User Name, Invoice Date, Invoice Amount, Due Date, Due Amount, Currency Code, Currency Symbol, Company Name, and Invoice Number.",
                },
                {
                    type: 'text',
                    value: "7. Click on the reset button to reset the email's body.",
                },
                {
                    type: 'text',
                    value: "8. Enable the apply to future emails checkbox to save the email's body. This is to ensure that the same email's body is saved for all the invoices created in the system so you don't have to retype the email content again.",
                },
                {
                    type: 'text',
                    value: "9. Tick the checkbox at the bottom of the screen if you wish to receive a copy of the email.",
                },
                {
                    type: 'text',
                    value: "10. To change the document template, click on the drop-down arrow next to document designer template. Choose your preferred template created earlier.",
                },
                {
                    type: 'image',
                    value: require('./../asset/send-email-with-saved-template/email-saved-template.png'),
                },
                {
                    type: 'text',
                    value: "11. After that, you should be able to view the selected document template on the right-hand side panel. Verify the information in the email body, email subject and recipient email ID. Click on the Send button.",
                },
                {
                    type: 'image',
                    value: require('./../asset/send-email-with-saved-template/email-saved-template1.png'),
                },
                {
                    type: 'text',
                    value: "12. The recipient will receive your email with the document template attached as per configuration above. If the recipients are not able to view the email in their inbox, please advise them to check their junk and spam folders as well.",
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}