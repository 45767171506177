import React, { Component } from 'react'
// import { FONT_FAMILY, TEXT_PLACEHOLDER } from '../../Constants/Constants';
import "../../DKUILibrary/DKUILibrary.css"
// import CustomFieldManager from '../../Manager/CustomFieldManager';
// import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
// import { getLocalisedText } from '../../Translate/LanguageManager';
// import TopSectionAboveTableUtility from '../../Utilities/TopSectionAboveTableUtility';
// import Utility, { getFontSizeClass, getPageWidth, getVW } from '../../Utilities/Utility';
import PopupHeaderComponent from './PopupHeaderComponent';

import {
    FONT_FAMILY, TEXT_PLACEHOLDER,
    CustomFieldManager,
    TemplateSettingsManager,
    getLocalisedText,
    Asset,
    Utility, 
    getFontSizeClass, 
    getPageWidth, 
    getVW,
    TopSectionAboveTableUtility
} from 'deskera-doc-builder-lib'
export default class TopSectionAboveTablePopup extends Component {
    constructor(props) {
        super(props);
        var textArea = TopSectionAboveTableUtility.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable.font.text

        var previewContent = ''
        if(textArea !== undefined) {
            previewContent = TopSectionAboveTableUtility.convertTopSectionStringToHtml(textArea, this.props.data)
        }
        this.state = {
            originalTopSectionConfiguration: JSON.parse(JSON.stringify(TopSectionAboveTableUtility.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable)),
            originalTextArea: textArea,
            topSectionConfiguration: TopSectionAboveTableUtility.getGlobalTopSectionAboveTableFontStyle().topSectionBeforeTable,
            previewContent: previewContent,
            textAreaValue: textArea,
            height: this.getScrollingHeight(0),
            customFields: CustomFieldManager.getActiveCustomFields()
        }
    }

    render() {
        return (
            <div className='ShadowMedium WindowPopup' style={{
                width: getPageWidth(),
                height: '75%',
                backgroundColor: 'white',
                padding: 0,
                paddingBottom: 10
            }}>
                <PopupHeaderComponent
                    headerText={'top_section_above_table'}
                    cancelText={'cancel'}
                    submitText={'save'}
                    onCancelClicked={() => this.onCancel()}
                    onSubmitClicked={() => this.onSave()}
                />
                <div
                    className="ColumnDiv"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: getVW(2),
                        // height: this.state.height,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        height: '100%',
                    }}>
                        {this.getPreviewPanel()}
                        {this.getTextEditorSection()}
                        {this.renderTextArea()}
                </div>
            </div>
        )
    }

    getList() {
        var rowItemList = []
        return rowItemList
    }

    getScrollingHeight(rowSize) {
        var defaultThreshold = 400 //default height without item level
        var innerHeight = window.innerHeight

        var rowHeight = 44
        var totalRowHeight = 0
        if (rowSize > 0) {
            totalRowHeight = rowHeight * rowSize
        }

        defaultThreshold = defaultThreshold + totalRowHeight

        if (defaultThreshold > (innerHeight * 0.8)) {
            return innerHeight * 0.8
        }
        return defaultThreshold
    }


    getPreviewPanel() {
        return <div
            style={{
                textAlign: 'left',
                // padding: 13,
                // paddingLeft: 16,
                // paddingRight: 16,
                // paddingTop: 5,
                display: 'block',
                width: '-webkit-fill-available',
                height: '40%',
                minHeight: '40%',
                overflowX: 'scroll',
                ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(this.state.topSectionConfiguration)
            }}
            dangerouslySetInnerHTML={{ __html: this.state.previewContent }}>
        </div>
    }

    getTextEditorSection() {
        return <div
                    className='ColumnDiv parent-width'
            >
            <div className='RowDiv'
                style={{
                    padding: 8,
                    backgroundColor: 'rgb(248,249,250)'
                }}>
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderCheckbox('system_default', 'systemDefault')}
                </div>
                &nbsp;
                &nbsp;
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderStyleButton('B', 'weight', 'bold')}
                    {this.renderStyleButton('I', 'style', 'italic')}
                    {this.renderStyleButton('U̳', 'textDecoration', 'underline')}
                </div>
                &nbsp;
                &nbsp;
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderStyleButton('A +', 'size', 'up')}
                    {this.renderStyleButton('A -', 'size', 'down')}
                    {this.renderStyleColor('color')}
                </div>
                &nbsp;
                &nbsp;
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderStyleDropdown('family')}
                </div>
                &nbsp;
                &nbsp;
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderStyleDropdown('textPlaceholder')}
                </div>
                &nbsp;
                &nbsp;
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderStyleDropdown('customField')}
                </div>
                &nbsp;
                &nbsp;
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                        <div className='pl-s pr-s'>
                            {getLocalisedText('text')}
                        </div>
                    {this.renderStyleButton('B', 'character-weight', 'bold')}
                    {this.renderStyleButton('I', 'character-style', 'italic')}
                </div>
            </div>
            <div className='RowDiv'
                style={{
                    padding: 8,
                    backgroundColor: 'rgb(248,249,250)'
                }}>
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderStyleButton(' ', 'border', 'none')}
                    {this.renderStyleButton('⬚', 'border', 'dashed')}
                    {this.renderStyleButton('▢', 'border', 'solid')}
                    {this.renderStyleColor('borderColor')}
                </div>
                &nbsp;
                &nbsp;
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderStyleDropdown('borderWidth')}
                </div>
                &nbsp;
                &nbsp;
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderCheckbox('padding_horizontal', 'paddingHorizontal')}
                    {this.renderStyleDropdown('paddingHorizontalWidth')}
                </div>
                &nbsp;
                &nbsp;
                <div className='RowDiv'
                    style={{
                        backgroundColor: 'rgb(235, 235, 235)',
                        width: 'auto',
                        borderRadius: 4,
                    }}>
                    {this.renderCheckbox('padding_vertical', 'paddingVertical')}
                    {this.renderStyleDropdown('paddingVerticalWidth')}
                </div>
            </div>
        </div>
    }

    renderStyleButton(title, property, event) {
        return (
            <div
                className='RowDiv justify-content-center'
                style={{
                    backgroundColor: this.getIsSelected(property) ? 'rgb(55, 115, 225)' : 'rgb(235, 235, 235)',
                    color: this.getIsSelected(property) ? 'white' : 'black',
                    border: "none",
                    borderWidth: getVW(1),
                    borderColor: 'rgb(235, 235, 235)',
                    fontSize: 14,
                    borderRadius: 4,
                    cursor: "pointer",
                    outline: "none",
                    width: 32,
                    height: 32,
                }}
                onClick={() => this.onFontStyleChange(property, event)}
            >
                <text className={getFontSizeClass()} style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                }}>{getLocalisedText(title)}</text>
            </div>
        )
    }

    onFontStyleChange(property, value) {
        var newStyle = this.state.topSectionConfiguration
        if (property === 'weight') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableProperty(this.state.topSectionConfiguration, 'weight', 'bold')
        }
        else if (property === 'style') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableProperty(this.state.topSectionConfiguration, 'style', 'italic')
        }
        else if (property === 'color') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableProperty(this.state.topSectionConfiguration, 'color', value)
        }
        else if (property === 'size') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableFontSize(this.state.topSectionConfiguration, value)
        }
        else if (property === 'family') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableProperty(this.state.topSectionConfiguration, 'family', value)
        }
        else if (property === 'border') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableProperty(this.state.topSectionConfiguration, 'border', value)
        }
        else if (property === 'borderColor') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableProperty(this.state.topSectionConfiguration, 'borderColor', value)
        }
        else if (property === 'borderWidth') {
            if (value !== '') {
                newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableProperty(this.state.topSectionConfiguration, 'borderWidth', value)
            }
        }
        else if (property === 'paddingHorizontalWidth') {
            if (value !== '') {
                newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableProperty(this.state.topSectionConfiguration, 'paddingHorizontal', value)
            }
        }
        else if (property === 'paddingVerticalWidth') {
            if (value !== '') {
                newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableProperty(this.state.topSectionConfiguration, 'paddingVertical', value)
            }
        }
        else if (property === 'character-weight') {
            this.onAppendCodeToString('#b# text #bb#', true)
        }
        else if (property === 'character-style') {
            this.onAppendCodeToString('#i# text #ii#', true)
        }
        this.setState({
            topSectionConfiguration: newStyle
        })
    }

    getIsSelected(property) {
        if(property === 'weight') {
            return this.getIsBold()
        }
        else if (property === 'style') {
            return this.getIsItalic()
        }
        return false
    }

    renderStyleColor(property) {
        return (
            <div
                className='RowDiv justify-content-center'
                style={{
                    backgroundColor: 'rgb(235, 235, 235)',
                    color: 'black',
                    border: "none",
                    borderWidth: getVW(1),
                    borderColor: 'rgb(235, 235, 235)',
                    fontSize: 14,
                    borderRadius: 4,
                    cursor: "pointer",
                    outline: "none",
                    width: 32,
                    height: 32,
                }}
            >
                <input
                    type="color"
                    className="ColorPicker"
                    value={this.getColorByProperty(property) ? this.getColorByProperty(property) : "#000000"}
                    onChange={(event) => this.onFontStyleChange(property, event.target.value)}
                />
            </div>
        )
    }

    getColorByProperty(property) {
        var newStyle = this.state.topSectionConfiguration
        if(property === 'color') {
            return newStyle.font.color
        }
        return undefined
    }

    renderStyleDropdown(property) {
        return (
            <div
                className='RowDiv justify-content-center'
                style={{
                    backgroundColor: 'rgb(235, 235, 235)',
                    color: 'black',
                    border: "none",
                    borderWidth: getVW(1),
                    borderColor: 'rgb(235, 235, 235)',
                    fontSize: 14,
                    borderRadius: 4,
                    cursor: "pointer",
                    outline: "none",
                    height: 32,
                    paddingLeft: 4,
                    paddingRight: 4,
                }}
            >
                <select
                    style={this.getPickerStyle()}
                    onChange={(event) => this.onSelectionChange(property, event)}
                    className="TextField ListPicker">
                    {this.getListByProperty(property).map((key) => {
                        return <>
                            <option
                                style={{
                                    fontFamily: key.value,
                                }}
                                value={key.value}
                                selected={this.getSelectedPropertyValue(property, key.value)}>
                                {getLocalisedText(key.label)}
                            </option>
                        </>
                    })}
                </select>
                <img
                    className="ListPickerArrowStyle"
                    style={{
                        position: 'unset',
                        paddingRight: 4,
                    }}
                    src={Asset.icon.ic_listPickerArrow2}
                    alt=""
                />
            </div>
        )
    }


    getPickerStyle() {
        if (Utility.getIsArabicLang()) {
            return {
                textAlignLast: 'right',
                direction: 'rtl',
                paddingRight: 24,
                backgroundColor: 'rgb(235, 235, 235)',
            }
        }
        return { backgroundColor: 'rgb(235, 235, 235)',}
    }

    getListByProperty(property) {
        if(property === 'family') {
            return FONT_FAMILY
        }
        else if (property === 'textPlaceholder') {
            return TEXT_PLACEHOLDER
        }
        else if (property === 'borderWidth') {
            return [
                { label: '-', value: '' },
                { label: '1px', value: '1px' },
                { label: '2px', value: '2px' },
                { label: '3px', value: '3px' },
                { label: '4px', value: '4px' },
                { label: '5px', value: '5px' },
                { label: '6px', value: '6px' },
                { label: '7px', value: '7px' },
                { label: '8px', value: '8px' },
                { label: '9px', value: '9px' },
            ]
        }
        else if (property === 'paddingHorizontalWidth' || property === 'paddingVerticalWidth' ) {
            return [
                { label: '-', value: '' },
                { label: '1px', value: '1px' },
                { label: '2px', value: '2px' },
                { label: '4px', value: '4px' },
                { label: '6px', value: '6px' },
                { label: '8px', value: '8px' },
                { label: '10px', value: '10px' },
                { label: '12px', value: '12px' },
                { label: '14px', value: '14px' },
                { label: '16px', value: '16px' },
                { label: '18px', value: '18px' },
                { label: '20px', value: '20px' },
                { label: '22px', value: '22px' },
                { label: '24px', value: '24px' },
                { label: '26px', value: '26px' },
                { label: '28px', value: '28px' },
                { label: '30px', value: '30px' },
                { label: '32px', value: '32px' },
            ]
        }
        else if (property === 'customField') {
            var customFields = this.state.customFields
            var list = []
            list.push({ label: 'none', value: '' })
            customFields.forEach(element => {
                var item = {
                    label: element.label,
                    value: 'customField_' + element.code
                }
                list.push(item)
            });
            return list
        }
        return []
    }

    getSelectedPropertyValue(property, value) {
        if (property === 'family') {
            return this.state.topSectionConfiguration.font.family === value
        }
        else if (property === 'borderWidth') {
            return this.state.topSectionConfiguration.font.border === value
        }
        else if (property === 'paddingHorizontalWidth') {
            return this.state.topSectionConfiguration.font.paddingHorizontal === value
        }
        else if (property === 'paddingVerticalWidth') {
            return this.state.topSectionConfiguration.font.paddingVertical === value
        }
        return undefined
    }

    onSelectionChange(property, event) {
        if(property === 'family') {
            this.onFontStyleChange(property, event.target.value)
        }
        else if (property === 'borderWidth') {
            this.onFontStyleChange(property, event.target.value)
        }
        else if (property === 'paddingHorizontalWidth') {
            this.onFontStyleChange(property, event.target.value)
        }
        else if (property === 'paddingVerticalWidth') {
            this.onFontStyleChange(property, event.target.value)
        }
        else if (property === 'textPlaceholder' || property === 'customField') {
            var value = event.target.value
            this.onAppendCodeToString(value, false, property)
        }
    }

    onAppendCodeToString(value, isContainCode, property) {
        var textAreaValue = this.state.textAreaValue

        if (textAreaValue === undefined) {
            textAreaValue = ''
        }

        if (property === 'customField') {
            var code = value.replace('customField_', '')
            var customField = this.state.customFields.find(x => x.code === code)
            if(customField !== undefined) {
                textAreaValue = textAreaValue + customField.label + ': '
            }
        }

        if(isContainCode) {
            textAreaValue = textAreaValue + value
        }
        else {
            textAreaValue = textAreaValue + '#_' + value + '_#'
        }

        this.setState({
            textAreaValue: textAreaValue,
            previewContent: TopSectionAboveTableUtility.convertTopSectionStringToHtml(textAreaValue, this.props.data)
        })
    }

    renderTextArea() {
        return <textarea
            className="TextField"
            style={{
                fontFamily: "helvetica",
                fontSize: 12,
                resize: 'none',
                height: '40%',
                minHeight: '40%',
                border: '1px solid rgb(248,249,250)'
            }}
            dir={Utility.getInputDirection()}
            placeholder=""
            type="text"
            value={this.state.textAreaValue ? getLocalisedText(this.state.textAreaValue) : ''}
            onChange={(e) => this.textAreaValueChange(e)}
        />
    }

    textAreaValueChange(event) {
        var value = event.target.value
        this.setState({
            textAreaValue: value,
            previewContent: TopSectionAboveTableUtility.convertTopSectionStringToHtml(value, this.props.data)
        })
    }

    renderCheckbox(title, property) {
        return <div className='RowDiv pl-s pr-s' style={{
            height: 32,
        }}>
            <input type="checkbox"
                checked={this.getIsCheckbox(property)}
                onClick={() => this.onCheckboxClick(property)}
                className='pr-s'
            />
            <div style={{
                textAlign: 'left',
                paddingTop: 6,
                paddingBottom: 6,
                fontSize: 12,
                color: "black",
                fontWeight: "600",
                width: 'max-content'
            }}>
                {getLocalisedText(title)}
            </div>
        </div>
    }

    getIsCheckbox(property) {
        if (property === 'systemDefault') {
            return !this.state.topSectionConfiguration.font.isEnable
        }
        else if (property === 'paddingHorizontal') {
            return this.state.topSectionConfiguration.font.isPaddingHorizontalEnable
        }
        else if (property === 'paddingVertical') {
            return this.state.topSectionConfiguration.font.isPaddingVerticalEnable
        }
    }

    onCheckboxClick(property) {
        var newStyle = this.state.topSectionConfiguration
        if(property === 'systemDefault') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableIsEnable(this.state.topSectionConfiguration)
        }
        else if (property === 'paddingHorizontal') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableIsPaddingHorizontalEnable(this.state.topSectionConfiguration)
        }
        else if (property === 'paddingVertical') {
            newStyle = TopSectionAboveTableUtility.updateGlobalTopSectionAboveTableIsPaddingVerticalEnable(this.state.topSectionConfiguration)
        }
        this.setState({
            topSectionConfiguration: newStyle
        })
    }



    getIsBold() {
        var style = this.state.topSectionConfiguration
        if(style.font !== undefined && style.font !== null) {
            if (style.font.weight !== undefined && style.font.weight !== null) {
                return style.font.weight === 'bold'
            }
        }
    }

    getIsItalic() {
        var style = this.state.topSectionConfiguration
        if (style.font !== undefined && style.font !== null) {
            if (style.font.style !== undefined && style.font.style !== null) {
                return style.font.style === 'italic'
            }
        }
    }

    onSave() {
        var data = this.props.data
        var globalStyle = TopSectionAboveTableUtility.getGlobalTopSectionAboveTableFontStyle()
        globalStyle.topSectionBeforeTable = this.state.topSectionConfiguration
        globalStyle.topSectionBeforeTable.font.text = this.state.textAreaValue
        TemplateSettingsManager.updateTemplateStyle(globalStyle)
        this.props.onSaveClicked(data)
    }

    onCancel() {
        var globalStyle = TopSectionAboveTableUtility.getGlobalTopSectionAboveTableFontStyle()
        globalStyle.topSectionBeforeTable = this.state.originalTopSectionConfiguration
        globalStyle.topSectionBeforeTable.font.text = this.state.originalTextArea
        TemplateSettingsManager.updateTemplateStyle(globalStyle)
        this.props.onCancelClicked()
    }
}
