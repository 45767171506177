import { CurrencyFormatType, DateFormat } from "../../Constants/Constants"
import AppManager from "../../Manager/AppManager"
import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import Utility, { getIsBill, getIsDebitOrCreditNote, getIsInvoice, getIsOrder, getIsQuotation, getIsSalesOrder } from "../../Utilities/Utility"
import TenantParser from "../TenantParser"

export default class TemplateParser {
    static parseTemplate(dataFromAPI, templateDataToReturn) {

        templateDataToReturn.clientName = dataFromAPI.clientName

        //document type
        templateDataToReturn.showDocumentType = TemplateSettingsManager.defaultTemplateSettings.documentInfo.type === undefined ? true : TemplateSettingsManager.defaultTemplateSettings.documentInfo.type.isVisible

        // get decimal scale
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale === undefined) {
            var scale = (dataFromAPI.decimalScale !== null && dataFromAPI.decimalScale !== undefined) ? dataFromAPI.decimalScale : 2
            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['decimalScale'] = { 'value': scale }
            AppManager.setDecimalScale(scale)
        }
        else {
            AppManager.setDecimalScale(TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.decimalScale.value)
        }

        //get number format
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.numberFormat !== undefined && dataFromAPI.tenantInfo.numberFormat !== null) {
                AppManager.setNumberFormat(dataFromAPI.tenantInfo.numberFormat)
            }
            if (dataFromAPI.tenantInfo.country !== undefined && dataFromAPI.tenantInfo.country !== null) {
                templateDataToReturn.country = dataFromAPI.tenantInfo.country
            }
            if (dataFromAPI.tenantInfo.decimalScale !== undefined && dataFromAPI.tenantInfo.decimalScale !== null) {
                AppManager.setTenantDecimalScale(dataFromAPI.tenantInfo.decimalScale)
            }
            else {
                AppManager.setTenantDecimalScale(AppManager.getDecimalScale())
            }
        }

        //id
        templateDataToReturn.templateId = TemplateSettingsManager.defaultTemplateSettings.templateInfo.id
        if (dataFromAPI.templateId) {
            templateDataToReturn.templateId = dataFromAPI.templateId
        }

        //theme color
        templateDataToReturn.themeColor = TemplateSettingsManager.defaultTemplateSettings.templateInfo.themeColor
        if (dataFromAPI.themeColor) {
            templateDataToReturn.themeColor = dataFromAPI.themeColor
        }

        //Language
        templateDataToReturn.languageInfo = TemplateSettingsManager.defaultTemplateSettings.languageInfo

        //Logo
        templateDataToReturn.logo = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.image
        templateDataToReturn.showCompanyLogo = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.isVisible === undefined ? true : TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.logo.isVisible

        templateDataToReturn.showCompanyName = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.name.isVisible

        templateDataToReturn.showFrom = TemplateSettingsManager.defaultTemplateSettings.documentInfo.companyDetails.address.isVisible

        // billing to address
        templateDataToReturn.billToTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.title
        templateDataToReturn.showClientBillToAddress = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.isVisible

        // shipping to address
        templateDataToReturn.shipToTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.title
        templateDataToReturn.showClientShipToAddress = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.isVisible


        if(TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipFromDetails) {
            templateDataToReturn.shipFromTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipFromDetails.title
            templateDataToReturn.showClientShipFromAddress = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipFromDetails.isVisible
        }
        else {
            templateDataToReturn.shipFromTitle = 'ship_from'
            templateDataToReturn.showClientShipFromAddress = true
        }

        //Currency
        if (dataFromAPI.currencySymbol !== undefined && dataFromAPI.currencySymbol !== null) {
            templateDataToReturn.currency = dataFromAPI.currencySymbol
            templateDataToReturn.currencyName = dataFromAPI.currency
        }
        else {
            templateDataToReturn.currencyName = dataFromAPI.currency
        }

        //Currency symbol
        templateDataToReturn = TemplateParser.assignCurrencySymbol(templateDataToReturn)

        templateDataToReturn.showRefNumber = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.referenceNumber.isVisible

        //Date Format
        templateDataToReturn.tenantDateFormat = DateFormat.DD_MM_YYYY_SLASH
        let dateFormat = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.format
        if (dataFromAPI.tenantInfo !== undefined && dataFromAPI.tenantInfo !== null) {
            if (dataFromAPI.tenantInfo.dateFormat !== undefined && dataFromAPI.tenantInfo.dateFormat !== null) {
                if (dataFromAPI.tenantInfo.dateFormat !== '') {
                    templateDataToReturn.tenantDateFormat = dataFromAPI.tenantInfo.dateFormat.toLowerCase()
                }
            }
        }

        CustomFieldManager.setTenantDateFormat(templateDataToReturn.tenantDateFormat)

        if (TemplateSettingsManager.getRemoteTemplateUID() === null || TemplateSettingsManager.getRemoteTemplateUID() === undefined) {
            dateFormat = templateDataToReturn.tenantDateFormat
        }

        templateDataToReturn.documentDateFormat = dateFormat

        CustomFieldManager.setDateFormat(dateFormat)

        if (TemplateSettingsManager.getDateFormatIsSystemDefault()) {
            templateDataToReturn.dateFormat = templateDataToReturn.tenantDateFormat
        }
        else {
            templateDataToReturn.dateFormat = templateDataToReturn.documentDateFormat
        }

        templateDataToReturn.showStartDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.startDate.isVisible

        //Signature
        templateDataToReturn.showSignature = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.isVisible
        templateDataToReturn.signature = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.signature.image

        //ExchangeRate
        templateDataToReturn.exchangeRateTable = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.exchangeRateTable
        if (templateDataToReturn.exchangeRateTable === undefined || templateDataToReturn.exchangeRateTable === undefined) {
            templateDataToReturn.exchangeRateTable = TemplateSettingsManager.getDefaultExchangeRate();
        }

        templateDataToReturn.showExchangeRate = templateDataToReturn.exchangeRateTable.isVisible
        templateDataToReturn.exchangeRateTitle = templateDataToReturn.exchangeRateTable.title

        // check table 

        templateDataToReturn.checkTable = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.checkTable
        if (templateDataToReturn.checkTable === undefined || templateDataToReturn.checkTable === undefined) {
            templateDataToReturn.checkTable = TemplateSettingsManager.getDefaultCheckTable();
        }

        templateDataToReturn.showCheckTable = templateDataToReturn.checkTable.isVisible
        templateDataToReturn.title = templateDataToReturn.checkTable.title

        // check table column
        templateDataToReturn.checkTableColumn = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.checkTableColumn
        if (templateDataToReturn.checkTableColumn === undefined || templateDataToReturn.checkTableColumn === undefined) {
            templateDataToReturn.checkTableColumn = TemplateSettingsManager.getDefaultCheckTableColumns();
        }

        // check table other fields
        templateDataToReturn.checkOtherFields = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.checkOtherFields
        if (templateDataToReturn.checkOtherFields === undefined || templateDataToReturn.checkOtherFields === undefined) {
            templateDataToReturn.checkOtherFields = TemplateSettingsManager.getDefaultCheckOtherFields();
        }

        // check address font 
        templateDataToReturn.addressFontStyle = TemplateSettingsManager.defaultTemplateSettings['chequeFontStyle']?.address
        if (templateDataToReturn.addressFontStyle === undefined || templateDataToReturn.addressFontStyle === undefined) {
            templateDataToReturn.addressFontStyle = TemplateSettingsManager.getCheckAddressFontStyle();
        }

        // check style
        templateDataToReturn.chequeStyle = TemplateSettingsManager.defaultTemplateSettings['chequeStyle']
        if (templateDataToReturn.chequeStyle === undefined || templateDataToReturn.chequeStyle === null) {
            templateDataToReturn.chequeStyle = TemplateSettingsManager.getCheckStyle();
        }

        //Terms and Conditions
        templateDataToReturn.showTermsAndCondition = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.isVisible
        if (dataFromAPI.termsAndCondition && dataFromAPI.termsAndCondition !== null && dataFromAPI.termsAndCondition !== '') {
            templateDataToReturn.termsAndCondition = dataFromAPI.termsAndCondition
        } else {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.text !== 'terms_and_condition_placeholder_text') {
                templateDataToReturn.termsAndCondition = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.termsAndConditions.text
            } else {
                templateDataToReturn.termsAndCondition = null
            }
        }

        //Notes
        templateDataToReturn.showNotes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.isVisible
        if (dataFromAPI.notes && dataFromAPI.notes !== null && dataFromAPI.notes !== '') {
            try {
                templateDataToReturn.notes = decodeURIComponent(dataFromAPI.notes);
            } catch (error) {
                templateDataToReturn.notes = dataFromAPI.notes
            }
        }
        else {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text !== 'notes_placeholder_text') {
                templateDataToReturn.notes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text
            } else {
                templateDataToReturn.notes = null
            }
        }

        //draggable Component List
        templateDataToReturn.componentList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList


        //repeatableTableHeader
        var repeatableTableHeader = TemplateSettingsManager.defaultTemplateSettings.repeatableTableHeader
        if (repeatableTableHeader !== undefined && repeatableTableHeader !== null) {
            if (repeatableTableHeader.isVisible !== undefined && repeatableTableHeader.isVisible !== null) {
                TemplateSettingsManager.updateRepeatableTableHeader(repeatableTableHeader.isVisible)
            }
            else {
                TemplateSettingsManager.updateRepeatableTableHeader(false)
            }
        }
        else {
            TemplateSettingsManager.updateRepeatableTableHeader(false)
        }

        //repeatable header and footer
        var repeatableHeaderFooter = TemplateSettingsManager.defaultTemplateSettings.repeatHeaderFooter
        if (repeatableHeaderFooter !== undefined && repeatableHeaderFooter !== null) {
            if (repeatableHeaderFooter.isVisible !== undefined && repeatableHeaderFooter.isVisible !== null) {
                TemplateSettingsManager.updateRepeatableHeaderFooterVisibility(repeatableHeaderFooter.isVisible)
            }
            else {
                TemplateSettingsManager.updateRepeatableHeaderFooterVisibility(false)
            }
            if (repeatableHeaderFooter.isFooterVisible !== undefined && repeatableHeaderFooter.isFooterVisible !== null) {
                TemplateSettingsManager.updateRepeatableFooterVisibility(repeatableHeaderFooter.isFooterVisible)
            }
            else {
                TemplateSettingsManager.updateRepeatableFooterVisibility(false)
            }
        }
        else {
            TemplateSettingsManager.updateRepeatableHeaderFooterVisibility(false)
            TemplateSettingsManager.updateRepeatableFooterVisibility(false)
		}

        templateDataToReturn.showPaymentFooter = true
        var paymentFooter = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentFooter
        if(paymentFooter !== undefined && paymentFooter !== null) {
            if(paymentFooter.isVisible !== undefined && paymentFooter.isVisible !== null) {
                templateDataToReturn.showPaymentFooter = paymentFooter.isVisible
            }
        }

        TemplateParser.getSupplyConfigurationFlag(templateDataToReturn)

        var generatedMessage = TemplateSettingsManager.getGeneratedMessage()
        templateDataToReturn.showGeneratedMessage = generatedMessage.isVisible

        TemplateParser.addAdditionalTenantSetting(dataFromAPI)

        templateDataToReturn.showHsnSacTaxTable = TemplateSettingsManager.getHsnSacTaxTable()?.isVisible ?? true

        //update e-invoice handling
        let isEInvoiceNeeded = false
        if ((getIsInvoice(templateDataToReturn.type) || getIsDebitOrCreditNote(templateDataToReturn.type))
            && Utility.getIsIndiaCountry(templateDataToReturn)) {
            isEInvoiceNeeded = true
        }

        if(!isEInvoiceNeeded) {
            templateDataToReturn.showEInvoiceSection = false
        }

        return templateDataToReturn
    }

    static getSupplyConfigurationFlag(templateDataToReturn) {

        var documentDetails = TemplateSettingsManager.defaultTemplateSettings.documentInfo
        if (getIsQuotation(templateDataToReturn.type)
            || getIsInvoice(templateDataToReturn.type)
            || getIsSalesOrder(templateDataToReturn.type)
            || getIsOrder(templateDataToReturn.type)
            || getIsBill(templateDataToReturn.type)) {
                if (documentDetails.supplyConfiguration === undefined) {
                    templateDataToReturn.showPlaceOfSupply = true
                    templateDataToReturn.showSourceOfSupply = true
                    templateDataToReturn.showDestinationOfSupply = true
                }
            }
    }

    static assignCurrencySymbol(templateDataToReturn) {
        var templateCurrency = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency
        if (templateCurrency !== undefined && templateCurrency !== null) {
            var newTemplateCurrency = templateCurrency
            if (templateCurrency.format !== null && templateCurrency.format !== undefined) {
                if (templateCurrency.format === CurrencyFormatType.SYMBOL &&
                    (templateDataToReturn.currency === null || templateDataToReturn.currency === undefined)) {
                    var currency = {
                        isVisible: templateCurrency.isVisible,
                        format: CurrencyFormatType.NAME,
                        customValue: undefined,
                        isVisibleCheckCurrency: templateCurrency.isVisibleCheckCurrency
                    }
                    newTemplateCurrency = currency
                }
            }
            templateDataToReturn.currencyFormat = newTemplateCurrency.format
            templateDataToReturn.currencyCustomValue = newTemplateCurrency.customValue

            //display the type according to document currency symbol/name
            if (newTemplateCurrency.format === CurrencyFormatType.SYMBOL) {
                templateDataToReturn.currencyCustomValue = templateDataToReturn.currency
            }
            else if (newTemplateCurrency.format === CurrencyFormatType.NAME) {
                templateDataToReturn.currencyCustomValue = templateDataToReturn.currencyName
            }
            else if (newTemplateCurrency.format === CurrencyFormatType.SYMBOL_NAME) {
                templateDataToReturn.currencyCustomValue = (templateDataToReturn.currencyName ? templateDataToReturn.currencyName : '$') + ' ' + (templateDataToReturn.currency ? templateDataToReturn.currency : '$')
            }

            templateDataToReturn.showCurrency = newTemplateCurrency.isVisible
            templateDataToReturn.showCheckCurrency = newTemplateCurrency.isVisibleCheckCurrency
        }
        else {
            var defaultCurrency = {
                isVisible: true,
                format: templateDataToReturn.currency !== undefined && templateDataToReturn.currency !== null ? CurrencyFormatType.CUSTOM : CurrencyFormatType.NAME,
                customValue: templateDataToReturn.currency !== undefined && templateDataToReturn.currency !== null ? templateDataToReturn.currency : templateDataToReturn.currencyName,
                isVisibleCheckCurrency: false
            }

            TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.currency = defaultCurrency
            templateDataToReturn.showCurrency = true
            templateDataToReturn.currencyFormat = defaultCurrency.format
            templateDataToReturn.currencyCustomValue = defaultCurrency.customValue
            templateDataToReturn.showCheckCurrency = false
        }
        return templateDataToReturn
    }

    static addAdditionalTenantSetting(dataFromAPI) {
        if (TemplateSettingsManager.getPrintInfo() === undefined) {
            TenantParser.addIdForExistingCustomField()
            TenantParser.addTypeForExistingProductCustomField()
        }
        TenantParser.parseTenantAdditionalSetting(dataFromAPI)
    }
}
