import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpRepeatableHeaderFooter extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Repeatable Header and Footer')}
                {this.renderRepeatableHeaderFooterDescription()}
                {this.renderTurnOnRepeatableHeaderFooter()}
                {this.renderTurnOffRepeatableHeaderFooter()}
            </div>

        </div>
    }


    renderRepeatableHeaderFooterDescription() {
        var data = {
            header: "Repeatable Header And Footer",
            paragraph: [
                {
                    type: 'text',
                    value: "The repeatable header and footer allows you to print multipage while still maintains all the document infomation such as company logo, address and term and condtion.",
                },
                {
                    type: 'text',
                    value: "The system restructures table content with repeatable header and footer, but the other content will remain visible on each pages.",
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderTurnOnRepeatableHeaderFooter() {
        var data = {
            header: "Turn on Repeatable Header Footer",
            paragraph: [
                {
                    type: 'text',
                    value: "You can turn on the option from the Repatable Header And Footer. The Repeatable Header And Footer showing 'Hide' as it already selected. The Repeatable Footer option is show if the Repeatable Header Footer is selected.",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-header-footer/repeatable-header-footer-on.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Once you save the template, kindly go to Book/Book+ to preview it. The preview should show the header and footer for each new page.",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-header-footer/repeatable-header-footer-on-preview.png"),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "*** The Repeatable Table And Footer option is disable once Repeatable Header And Footer is selected.",
                    isBold: true
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

    renderTurnOffRepeatableHeaderFooter() {
        var data = {
            header: "Turn off Repeatable Table Header",
            paragraph: [
                {
                    type: 'text',
                    value: "You can turn off the option from the Repatable Table Header. The Repeatable Header And Footer showing show as it already deselected",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-header-footer/repeatable-header-footer-off.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Once you save the template, kindly go to Book/Book+ to preview it. The preview should show continous page.",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-header-footer/repeatable-header-footer-off-preview.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

}