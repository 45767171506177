import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpRepeatableTableHeader extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Custom Fields')}
                {this.renderTurnOnRepeatableTableHeader()}
                {this.renderTurnOffRepeatableTableHeader()}
            </div>

        </div>
    }


    renderTurnOnRepeatableTableHeader() {
        var data = {
            header: "Turn on Repeatable Table Header",
            paragraph: [
                {
                    type: 'text',
                    value: "You can turn on the option from the Repatable Table Header. The Repeatable Table Header showing 'Hide' as it already selected",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-table-header/repeatable-table-on.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Once you save the template, kindly go to Book/Book+ to preview it. The preview should show the table header repeat for each new page.",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-table-header/repeatable-table-on-preview.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

    renderTurnOffRepeatableTableHeader() {
        var data = {
            header: "Turn off Repeatable Table Header",
            paragraph: [
                {
                    type: 'text',
                    value: "You can turn off the option from the Repatable Table Header. The Repeatable Table Header showing 'Show' as it already deselected.",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-table-header/repeatable-table-off.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Once you save the template, kindly go to Book/Book+ to preview it. The preview should show continous page without any additional table header.",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-table-header/repeatable-table-off-preview.png"),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}