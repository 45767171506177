import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpTaxBreakdownTable extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Tax Breakdown Table')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "The India template consist of Tax breakdown table at the bottom of the document. You can select those India templates if you want tax breakdown table.",
                },
                {
                    type: 'text',
                    value: "*** Please refer to Right Section -> Prefined Template Section for all the india templates. ",
                    isBold: true,
                },
                {
                    type: 'text',
                    value: "Sample tax breakdown table",
                },
                {
                    type: 'image',
                    value: require('./../asset/tax-breakdown-table/tax-breakdown.png'),
                    isFullWidth: false
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}