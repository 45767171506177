import React, { Component } from "react";
import "../../App.css";
// import ComponentManager from "../../Manager/ComponentManager";
// import {COLOR_BLUE, DESKERA_URL} from "../../Constants/Constants";
// import Utility, {getCapitalized} from "../../Utilities/Utility";
// import AppManager from "../../Manager/AppManager";
// import {getLocalisedText} from '../../Translate/LanguageManager';

import {
    ComponentManager,
    COLOR_BLUE,
    DESKERA_URL,
    Utility,
    getCapitalized,
    AppManager,
    getLocalisedText,
    Asset
} from "deskera-doc-builder-lib";
export default class SignInPopup extends Component{
    constructor(props) {
        super(props);
        this.state = {
            documentData: this.props.documentData,
            redirectionURL: this.props.redirectionURL,
            documentType: this.props.documentType
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            documentData: nextProps.documentData,
            redirectionURL: nextProps.redirectionURL,
            documentType: nextProps.documentType
        })
    }

    render() {
        const popup_width = Utility.isDevice() ? '70%' : 500
        const popup_height = 'auto'
        return <div
            className="ColumnDiv WindowPopup"
            style={{width: "100vw", height: "100vh", backgroundColor: 'rgba(0, 0, 0, 0.2)'}}
        >
            <div className="ColumnDiv WindowPopup" style={{width: popup_width, height: popup_height, paddingBottom: 50}}>
                <div className="RowDiv" style={{justifyContent: 'space-between'}}>
                    <img
                        src={Asset.icon.deskLogoNew}
                        alt=""
                        style={{ height: 24, cursor: 'pointer', opacity: 0}}
                        onClick={()=>{Utility.openInNewTab(DESKERA_URL)}}
                    />
                    <div style={{opacity: 0.4, marginTop: -20}}>
                    {ComponentManager.getImageButton(Asset.icon.ic_close, this.continueTapped, 0, 10)}
                    </div>
                </div>

                <div className="ColumnDiv" style={{alignItems: 'center', width: '80%', alignSelf: 'center', marginTop: 5}}>
                    <img
                        src={Asset.icon.deskLogoNew}
                        alt=""
                        style={{ height: 30, cursor: 'pointer'}}
                        onClick={()=>{Utility.openInNewTab(DESKERA_URL)}}
                    />

                    <div className="RowDiv" style={{justifyContent: 'center', marginTop: 25}}>
                    {/*<img*/}
                    {/*    src={Asset.icon.ic_email}*/}
                    {/*    alt=""*/}
                    {/*    style={{height: 30, opacity: 0.4}}*/}
                    {/*/>*/}

                <text style={{fontSize: 26, fontWeight: 'bold', color: 'rgb(60, 60, 60)', marginLeft: 10}}>{ getLocalisedText('send_your') + " " +getCapitalized(AppManager.getLocalisedType(this.state.documentType))+" " + getLocalisedText('quickly')}</text>
                        </div>
                <text style={{fontSize: 15, color: 'gray', marginTop: 5}}>{ getLocalisedText('please_sign_up_with_deskera_to_send_your') + " "+AppManager.getLocalisedType(this.state.documentType)+"."}</text>
                    <text style={{fontSize: 15, color: 'gray', marginTop: 16}}>{getLocalisedText('dont_worry_your') + " " + AppManager.getLocalisedType(this.state.documentType) +" " + getLocalisedText('will_still_be_here')}</text>

                     <div style={{marginTop: 30}}>
                        {ComponentManager.getButton(getLocalisedText('sign_up_for_free'), COLOR_BLUE, "white", this.gotoSignUpPage, null, false, true)}
                    </div>

                      <div className="ColumnDiv" style={{marginTop: 15, cursor: 'pointer'}} onClick={ this.gotoSignInPage}>

                        <text style={{color: 'gray', fontSize: 12}}>{getLocalisedText('already_a_user_Sign_in')}</text>
                        {<div style={{width: '100%', height:1, backgroundColor: 'gray', alignSelf: 'center', marginTop: 0 , opacity: 0.5  }}/>}
                    </div>
                </div>
                </div>
            </div>
    }

    continueTapped = () => {
        this.props.onClose()
    }
    gotoSignInPage = () => {
        AppManager.gotoLoginPage(this.state.documentData)
    }
    gotoSignUpPage = () => {
        AppManager.gotoSignupPage(this.state.documentData)
    }
}
