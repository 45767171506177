import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpCustomFields extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Custom Fields')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "You can add document custom fields as steps below,",
                },
                {
                    type: 'text',
                    value: "1. Click on the + Add button from the top of the left panel. A dropdown will display for you to select custom field option.",
                },
                {
                    type: 'image',
                    value: require("./../asset/custom-fields/click-custom-fields.png"),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "2. Custom fields popup display the current available list of it. You can select/deselect, edit title and reorder the custom fields. Once you save it, the corresponding custom fields will be added to the preview document.",
                },
                {
                    type: 'image',
                    value: require("./../asset/custom-fields/edit-custom-fields.png"),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'image',
                    value: require("./../asset/custom-fields/edit-custom-fields-preview.png"),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "*** If no custom fields from the book, the corresponding message will prompt.",
                    isBold: true,
                },
                {
                    type: 'image',
                    value: require("./../asset/custom-fields/no-custom-fields.png"),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}