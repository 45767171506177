import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpPaymentFooter extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Payment Footer')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "You can refer to payment section from the left panel to show/hide the payment footer.",
                },
                {
                    type: 'image',
                    value: require('./../asset/payment-footer/payment.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Show Payment Footer",
                },
                {
                    type: 'image',
                    value: require('./../asset/payment-footer/payment-show.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Hide Payment Footer",
                },
                {
                    type: 'image',
                    value: require('./../asset/payment-footer/payment-hide.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}