import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpTextConfiguration extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Text Configuration')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Configure Text Style, Font Size and Font Family",
            paragraph: [
                {
                    type: 'text',
                    value: "You can refer to text section in order to customize the text style. The actions that you can performance as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Font Resize - increase or decrease font size",
                        "Italic - change all the text to italic font style",
                        "Bold - set all the text to bold",
                        "Overwrite theme color - set all the text to other color",
                        "Font family - chnage font family for the document"
                    ]
                },
                {
                    type: 'image',
                    value: require('./../asset/text-customization/text-section.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "The font famuly that supported as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Arial",
                        "Arial Black",
                        "Arial narrow",
                        "Arial Nova",
                        "Calibri",
                        "Comic Sans MS",
                        "Helvetica",
                        "Impact",
                        "Japanese",
                        "Korean",
                        "Lao",
                        "Monospac",
                        "Montserrat",
                        "Sans-serif",
                        "Segoe UI",
                        "Simplified Arabic",
                        "Simplified Chinese",
                        "Serif",
                        "System UI",
                        "Times",
                    ]
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample Resize (small)",
                },
                {
                    type: 'image',
                    value: require('./../asset/text-customization/resize-small.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample Resize (big)",
                },
                {
                    type: 'image',
                    value: require('./../asset/text-customization/resize-big.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Italic",
                },
                {
                    type: 'image',
                    value: require('./../asset/text-customization/italic.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Bold",
                },
                {
                    type: 'image',
                    value: require('./../asset/text-customization/bold.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Color",
                },
                {
                    type: 'image',
                    value: require('./../asset/text-customization/color.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Font Family",
                },
                {
                    type: 'image',
                    value: require('./../asset/text-customization/font-family.png'),
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}