import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpSupportedPaperFormat extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Supported Paper Size')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "1. The document generator supports paper size as below except payroll check,",
                },
                {
                    type: 'list',
                    isSorted: false,
                    value: ['A4', 'Letter', 'Legal', 'A3', 'A5']
                },
                {
                    type: 'text',
                    value: "2. Additional thermal print format is supported for Quote, Invoice, Bill and Order",
                },
                {
                    type: 'list',
                    isSorted: false,
                    value: ['3.5', '4.6']
                },
                {
                    type: 'text',
                    value: "** Payroll check only support A4 format",
                    isBold: true,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}