import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpBillToAndShipToAddress extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Bill To and Ship To Address')}
                {this.renderBillToAndShipToAddress()}
                {this.renderBillToAndShipToAddressPopup()}
            </div>
        </div>
    }


    renderBillToAndShipToAddress() {
        var data = {
            header: "Bill To/Ship To",
            paragraph: [
                {
                    type: 'text',
                    value: "You can refer to Bill To/Ship To section from the left panel to show/hide Bill To/Ship To section. You can update the address text by click on edit button.",
                },
                {
                    type: 'image',
                    value: require('./../asset/bill-to-and-ship-to-address/bill-to-ship-to.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Show Bill To and Ship To address",
                },
                {
                    type: 'image',
                    value: require('./../asset/bill-to-and-ship-to-address/bill-to-ship-to-show.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Hide Bill To and Ship To address",
                },
                {
                    type: 'image',
                    value: require('./../asset/bill-to-and-ship-to-address/bill-to-ship-to-hide.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Edit Bill To and Ship To address title",
                },
                {
                    type: 'image',
                    value: require('./../asset/bill-to-and-ship-to-address/bill-to-ship-to-edit-title.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

    renderBillToAndShipToAddressPopup() {
        var data = {
            header: "Bill to/Ship to Address Popup",
            paragraph: [
                {
                    type: 'text',
                    value: "You can click on the edit buton from the address format within Bill To/Ship To address section to open address format popup.",
                },
                {
                    type: 'text',
                    value: "The actions that you can performance from the address popup as as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Reorder address type",
                        "Select/unselect address type",
                        "Apply same setting to other address",
                        "update address type title"
                    ]
                },
                {
                    type: 'text',
                    value: "The address type are supported for company address type as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Address 1",
                        "Address 2",
                        "City",
                        "State",
                        "Country",
                        "Postal Code",
                        "Phone Number(can update title)",
                        "Contact Code(can update title)",
                        "GSTIN - (only for India Tenant)",
                        "PAN Number - (only for India Tenant)",
                    ]
                },
                {
                    type: 'image',
                    value: require('./../asset/bill-to-and-ship-to-address/bill-to-ship-to-address-popup.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample 1",
                },
                {
                    type: 'image',
                    value: require('./../asset/bill-to-and-ship-to-address/bill-to-ship-to-sample-1-popup.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'image',
                    value: require('./../asset/bill-to-and-ship-to-address/bill-to-ship-to-sample-1.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "*** The same setting can be apply to ShipTo address section.",
                    isBold: true
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}