export const Constants = {
    title: {
        textColor: 'black',
    },
    calendar: {
        width: '200px',
        height: '200px',
        maxWidth: '200px',
        maxHeight: '170px',
        backgroundColor: 'white',
    },
    dayContainer: {
        padding: '0px',
        singleSelectedColor: 'rgb(211, 16, 17)',
        multiSelectedColor: 'grey',
        selectedTextColor: 'white',
        todayContainerColor: 'pink',
        textColor: 'black',
        textFontSize: '12px'
    },
    monthContainer: {
        isLongMonth: true
    },
    actionButton: {
        onSubmitTextColor: 'blue',
        onCancelTextColor: 'red',
        backgroundColor: 'rgba(201, 195, 195)',
        borderColor: 'rgba(201, 195, 195)'
    }
}
