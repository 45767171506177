import React, { Component } from 'react'
import "../../DKUILibrary/DKUILibrary.css"
import PopupHeaderComponent from './PopupHeaderComponent';
import {
    getLocalisedText,
    Asset
} from "deskera-doc-builder-lib";
import { ComponentManager } from 'deskera-doc-builder-lib';
import { TableColumnPopupType } from 'deskera-doc-builder-lib';
import { CustomFieldManager } from 'deskera-doc-builder-lib';
import { TemplateSettingsManager } from 'deskera-doc-builder-lib';
import { CUSTOM_FIELD_SHOW_OPTION } from 'deskera-doc-builder-lib';
import { Utility } from 'deskera-doc-builder-lib';

export default class QCInspectionTablePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.getQCInspectionTableColumn(),
            tableColumnData: this.props.tableColumnData ?? []
        }
    }

    getQCInspectionTableColumn() {
        return TemplateSettingsManager.getQCInspectionTable() ?? []
    }

    getPopupWidth() {
        return window.innerWidth * 0.8
    }

    getPopupHeight() {
        return window.innerHeight * 0.8
    }

    render() {
        return (
            <div className='ShadowMedium WindowPopup' style={{
                width: this.getPopupWidth(),
                height: this.getPopupHeight(),
                backgroundColor: 'white',
                padding: 0,
                paddingBottom: 10
            }}>
                <PopupHeaderComponent
                    headerText={getLocalisedText('edit_table')}
                    cancelText={'cancel'}
                    submitText={'save'}
                    onCancelClicked={this.props.onClosePressed}
                    onSubmitClicked={() => this.onSave()}
                />
                
                {this.getEditTableColumn()}
                {this.getTableHeaderRowColumnSection()}
                {this.getNewTableRow()}
                {this.getQCInspectionTableSection()}
            </div>
        )
    }

    getEditTableColumn() {
        return (
            <div className='RowDiv'>
                <div style={{
                    textAlign: 'left',
                    paddingLeft: 13,
                    paddingRight: 13,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 13,
                    fontWeight: 'bold',
                    width: '100%',
                }}>
                    <div className='RowDiv'>
                        <div className='RowDiv'>
                            {getLocalisedText('edit_table_columns')}
                        </div>
                        <div className='RowDiv' style={{
                            justifyContent: 'flex-end'
                        }}>
                            {ComponentManager.getButton(getLocalisedText('edit'), `rgba(55, 115, 225, 1.0)`, 'white', () => this.onEditTableColumnButtonClicked())}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onEditTableColumnButtonClicked() {
        TemplateSettingsManager.updateQCInspectionTable(this.state.data)
        this.props.onEditTableColumnButtonClicked()
    }

    getNewTableRow() {
        return (
            <div className='RowDiv'>
                <div style={{
                    textAlign: 'left',
                    paddingLeft: 13,
                    paddingRight: 13,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 13,
                    fontWeight: 'bold',
                    width: '100%',
                }}>
                    <div className='RowDiv'>
                        <div className='RowDiv' style={{
                            justifyContent: 'flex-end',
                        }}>
                            {ComponentManager.getButton(getLocalisedText('add'), `rgba(55, 115, 225, 1.0)`, 'white', () => this.onAddNewTableButtonClicked())}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onAddNewTableButtonClicked() {
        let newData = this.state.data ?? []
        let newRow = {
            index: 0,
            columns: []
        }

        newData.push(newRow)

        newData.forEach((element, index) => {
            element.index = index
        });

        this.setState({
            data: newData
        })
    }

    getQCInspectionTableSection() {
        let newData = this.state.data ?? []
        var tableSection = undefined

        if(newData.length > 0) {
            tableSection = newData.map((item, index) => {
                return this.getTableRowSection(item, index)
            })
        }

        return tableSection
    }

    getTableHeaderRowColumnSection() {
        let selectedColumns = []

        const tableColumns = this.state.tableColumnData ?? []
        let tableRow = undefined

        if (tableColumns.length > 0) {
            selectedColumns = tableColumns.filter(x => x.isSelected && x.type !== TableColumnPopupType.lineNumber)
        }
        if (selectedColumns.length > 0) {
            tableRow = selectedColumns.map((element, columnIndex) => {
                return this.getHeaderColumnSection(element, columnIndex)
            });

            return <div className='RowDiv' 
                style={{
                    width: '90%',
                    paddingLeft: 20,
                }}>
                {tableRow}
            </div>
        }

        return undefined
    }

    getHeaderColumnSection(column, columnIndex) {
        if (column && column !== null) {
            return (
                <div
                    id={'header_row_' + column.type + '_' + columnIndex}
                    className='RowDiv'
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    {column.name ? getLocalisedText(column.name) : ''}
                </div>
            )
        }

        return undefined
    }


    getTableRowSection(item, index) {
        const newIndex = index + 1
        return (
            <div
                id={'row_id_' + item.index}
                className={"RowDiv"}
                style={{
                    width: '100%',
                    paddingLeft: 13,
                    paddingRight: 13,
                    boxSizing: 'border-box',
                    position: 'relative'
                }}>
                <div className="RowDiv"
                    style={{
                        justifyContent: 'space-between',
                        boxSizing: 'border-box',
                        alignItems: 'baseline'
                    }}>
                    {newIndex}
                    {this.getTableRowColumnSection(item, index)}
                    {this.getReorderSection(item, index)}
                </div>
            </div>
        ); 
    }

    getTableRowColumnSection(item, rowIndex) {
        let selectedColumns = []

        const tableColumns = this.state.tableColumnData ?? []
        let columns = item.columns ?? []

        if(tableColumns.length > 0) {
            selectedColumns = tableColumns.filter(x => x.isSelected && x.type !== TableColumnPopupType.lineNumber)
        }

        if (selectedColumns.length > 0) {
            return selectedColumns.map((element, columnIndex) => {
                const column = columns.find(x => x.colType === element.type) ?? undefined
                return this.getColumnSection(item, rowIndex, element, selectedColumns.length, column, columnIndex)
            });
        }
        
        return undefined
    }

    getColumnSection(item, rowIndex, columnDefinition, columnSize, column, columnIndex) {
        const columnId = column?.id ?? -1
        if(columnDefinition && columnDefinition !== null) {
            return (
                <div
                    id={'row_' + rowIndex + '_' + columnDefinition.type + '_' + columnIndex}
                    className='RowDiv'
                >
                    <div className='ColumDiv parent-width pl-s pr-s'>
                        <div className='RowDiv'>
                            {this.getCustomFieldOptionSection(item, rowIndex, columnDefinition, columnSize, column, columnIndex)}
                        </div>
                        {
                            columnId > 0 && 
                            <div className='RowDiv'>
                                {this.getCustomFieldViewOption(item, rowIndex, columnDefinition, columnSize, column, columnIndex)}
                            </div>
                        }
                        {
                            columnId === 0 &&
                            <div className='RowDiv'>
                                {this.getCustomTextSection(item, rowIndex, columnDefinition, columnSize, column, columnIndex)}
                            </div>
                        }
                    </div>
                </div>
            )
        }

        return undefined
    }

    getCustomFieldOptionSection(item, rowIndex, columnDefinition, columnSize, column, columnIndex) {
        let woCustomFields = this.props.documentData?.woCustomFields ?? []
        let productMasterCustomFields = this.props.documentData?.productMasterCustomFields ?? []


        const selectText = [{
            id: -1,
            label: getLocalisedText('selection')
        }]

        const customText = [{
            id: 0,
            label: getLocalisedText('custom_text')
        }]

        if (woCustomFields.length > 0) {
            woCustomFields.forEach(element => {
                element.group = 'global'
            });
        }
        
        if (productMasterCustomFields.length > 0) {
            productMasterCustomFields.forEach(element => {
                element.group = 'product'
            });
        }

        let customFields = [...selectText, ...woCustomFields, ...productMasterCustomFields, ...customText]

        if(customFields.length > 0) {
            return (
                <div className="ListPickerHolderStyle" style={{ marginTop: 10 }}>
                    <div className="RowDiv">
                        <select
                            onChange={(event) => this.customFieldChanged(event, item, rowIndex, columnDefinition, columnSize, column, columnIndex)}
                            className="TextField ListPicker"
                        >
                            {customFields.map((cf) => {
                                let label = cf.label
                                if(cf.group) {
                                    if (cf.group === 'product') {
                                        label = cf.label + ' (Product Master)'
                                    }
                                    if (cf.group === 'global') {
                                        label = cf.label + ' (Work Order)'
                                    }
                                }
                                return <>
                                    <option
                                        value={this.parseCFDropDownValue(cf)}
                                        selected={this.isSelectCustomField(column, cf)}>
                                        {label}
                                    </option>
                                </>
                            })}
                        </select>
                        <img
                            className="ListPickerArrowStyle"
                            src={Asset.icon.ic_listPickerArrow2}
                            alt=""
                        />
                    </div>
                </div>
            )
        }

        return undefined
    }

    parseCFDropDownValue(customField) {
        const group = customField?.group ?? ''
        const id = customField?.id ?? ''

        return id + ',' + group
    }

    isSelectCustomField(column, cf) {
        const group = column?.colCFgroup ?? ''

        if(group !== '') {
            return group === cf?.group && column?.id === cf.id
        }
        else {
            return column?.id === cf.id
        }
    }

    customFieldChanged(event, item, rowIndex, columnDefinition, columnSize, column, columnIndex) {
        let newData = this.state.data
        const rawValue = event.target.value
        const values = rawValue?.split(',')
        const id = values.length > 1 ? parseInt(values[0]) : -1
        const group = values[1]

        newData.forEach((row, newRowIndex) => {
            if(newRowIndex === rowIndex) {
                let newColumns = row.columns ?? new Array(columnSize).fill(this.defaultColumnObject())
                
                if(newColumns.length < columnSize) {
                    let totalRemain = columnSize - newColumns.length
                    for (let fillIndex = 0; fillIndex < totalRemain; fillIndex++) {
                        newColumns.push(this.defaultColumnObject())
                    }
                }

                newColumns.forEach((col, colIndex) => {
                    if(colIndex === columnIndex) {
                        col.id = id
                        col.colType = columnDefinition.type
                        if(id > 0) {
                            const cf = this.getCustomFieldById(id, group)
                            const mCf = this.getMasterCustomFieldById(id)
                            col.colCFLabel = cf.label
                            col.colCFType = mCf.fieldType ?? ''
                            col.colCFgroup = cf.group ?? undefined
                            col.customText = ''
                            col.viewOption = CUSTOM_FIELD_SHOW_OPTION.SHOW_LABEL
                        }
                        else {
                            col.colCFLabel = ''
                            col.colCFType = ''
                        }
                    }
                });

                row.columns = newColumns
            }
        });

        this.setState({
            data: newData
        })
    }

    getCustomFieldViewOption(item, rowIndex, columnDefinition, columnSize, column, columnIndex) {
        const viewOptions = [
            CUSTOM_FIELD_SHOW_OPTION.SHOW_LABEL,
            CUSTOM_FIELD_SHOW_OPTION.SHOW_VALUE,
            CUSTOM_FIELD_SHOW_OPTION.SHOW_BOTH,
        ]

        const title = {}
        title[CUSTOM_FIELD_SHOW_OPTION.SHOW_LABEL] = 'label'
        title[CUSTOM_FIELD_SHOW_OPTION.SHOW_VALUE] = 'value'
        title[CUSTOM_FIELD_SHOW_OPTION.SHOW_BOTH] = 'both'
        title[CUSTOM_FIELD_SHOW_OPTION.NONE] = 'none'

        const viewOptionSection = viewOptions.map(viewOption => {
            const option = column.viewOption ?? CUSTOM_FIELD_SHOW_OPTION.SHOW_LABEL
            const isChecked = option === viewOption 
            return <div className='RowDiv'
                style={{ flex: 1 }} >
                <input type="checkbox"
                    checked={isChecked}
                    onClick={() => this.onCustomFieldViewOptionChecked(viewOption, item, rowIndex, columnDefinition, columnSize, column, columnIndex)}
                    style={{ margin: 0 }}
                />
                &nbsp;
                {getLocalisedText(title[viewOption])}
            </div>
        });

        return viewOptionSection
    }

    onCustomFieldViewOptionChecked(viewOption, item, rowIndex, columnDefinition, columnSize, column, columnIndex) {
        let newData = this.state.data

        newData.forEach((row, newRowIndex) => {
            if (newRowIndex === rowIndex) {
                let newColumns = row.columns ?? new Array(columnSize).fill(this.defaultColumnObject())

                if (newColumns.length < columnSize) {
                    let totalRemain = columnSize - newColumns.length
                    for (let fillIndex = 0; fillIndex < totalRemain; fillIndex++) {
                        newColumns.push(this.defaultColumnObject())
                    }
                }
                newColumns.forEach((col, colIndex) => {
                    if (colIndex === columnIndex) {
                        col.viewOption = viewOption
                    }
                });

                row.columns = newColumns
            }
        });

        this.setState({
            data: newData
        })
    }

    getCustomTextSection(item, rowIndex, columnDefinition, columnSize, column, columnIndex) {
        return <input
            className="TextField"
            style={{
                marginTop: 6,
                borderRadius: 4,
                color: 'rgb(30, 30, 30)',
                border: '1px solid',
                borderColor: 'rgb(245, 245, 245)'
            }}
            placeholder={''}
            type="text"
            dir={Utility.getInputDirection()}
            value={column?.customText ?? ''}
            onChange={(e) => this.customTextChanged(e, item, rowIndex, columnDefinition, columnSize, column, columnIndex)}
        />
    }

    customTextChanged(event, item, rowIndex, columnDefinition, columnSize, column, columnIndex) {
        let newData = this.state.data
        let value = event.target.value

        newData.forEach((row, newRowIndex) => {
            if (newRowIndex === rowIndex) {
                let newColumns = row.columns ?? new Array(columnSize).fill(this.defaultColumnObject())

                if (newColumns.length < columnSize) {
                    let totalRemain = columnSize - newColumns.length
                    for (let fillIndex = 0; fillIndex < totalRemain; fillIndex++) {
                        newColumns.push(this.defaultColumnObject())
                    }
                }
                newColumns.forEach((col, colIndex) => {
                    if (colIndex === columnIndex) {
                        col.customText = value
                    }
                });

                row.columns = newColumns
            }
        });

        this.setState({
            data: newData
        })
    }

    getReorderSection(item, index) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <img
                    className='arrow-button'
                    src={Asset.icon.ic_upArrow}
                    alt={''}
                    onClick={() => { this.onRowChanged(item, index, 'up') }}
                />
                <img
                    className='arrow-button'
                    src={Asset.icon.ic_downArrow}
                    alt={''}
                    onClick={() => { this.onRowChanged(item, index, 'down') }}
                />
                <img
                    className='arrow-button'
                    src={Asset.icon.ic_delete}
                    alt={''}
                    onClick={() => { this.onRowDelete(item, index) }}
                />
            </div>
        )
    }

    onRowChanged(item, index, action) {
        let newData = this.state.data ?? []
        let newIndex = index
        var newArray = []
        
        newData.forEach((element, rowIndex) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        
        if (action === 'up') {
            newIndex = index - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = index + 1
            if (newIndex >= newData.length) {
                newIndex = newData.length - 1
            }
        }

        var selectedItem = newData[index]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            element.index = index
        });

        this.setState({
            data: newArray
        })
    }

    onRowDelete(item, index) {
        let newData = this.state.data ?? []
        var newArray = []

        newData.forEach((element, rowIndex) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });

        newArray.forEach((element, index) => {
            element.index = index
        });

        this.setState({
            data: newArray
        })
    }

    getCustomFieldById(id, group) {
        const cfGroup = group ?? ''
        let customFields = []
        if(cfGroup !== '') {
            if (cfGroup === 'global') {
                const woCustomFields = this.props.documentData?.woCustomFields ?? []
                customFields = woCustomFields
            }
            if (cfGroup === 'product') {
                const productMasterCustomFields = this.props.documentData?.productMasterCustomFields ?? []
                customFields = productMasterCustomFields
            }
        }
        else {
            const woCustomFields = this.props.documentData?.woCustomFields ?? []
            const productMasterCustomFields = this.props.documentData?.productMasterCustomFields ?? []
            customFields = [...woCustomFields, ...productMasterCustomFields]
        }

        return customFields.find(x => x.id === id) ?? undefined
    }

    getMasterCustomFieldById(id) {
        return CustomFieldManager.masterCustomFieldOptions.find(x => x.id === id) ?? undefined
    }

    defaultColumnObject() {
        return {
            id: -1,
            colType: '',
            colCFCode: '',
            colCFType: '',
            colCFLabel: '',
            customText: '',
            colCFgroup: '',
            viewOption: CUSTOM_FIELD_SHOW_OPTION.NONE,
        }
    }


    onSave() {
        TemplateSettingsManager.updateQCInspectionTable(this.state.data)
        this.props.onClosePressed()
    }
}
