import React, { Component } from 'react';
// import { getLocalisedText } from '../Translate/LanguageManager';
import './help.css';
import HelpNavigationLinkUtil from './HelpNavigationLinkUtil';

import { getLocalisedText } from 'deskera-doc-builder-lib';
export default class Help extends Component {

    constructor(props) {
        var tempUrl = new URL(window.location);
        var isSelected = tempUrl.searchParams.get('selected');
        super(props);
        this.state = {
            selected: isSelected ? isSelected : 'guides/quick-start-guide',
        };
    }

    render() {
        return (
            <div style={{
                display: 'block'
            }}>
                {this.renderLeftPanelNav()}
                {this.renderRightContain()}
            </div>
        )
    }

    renderLeftPanelNav() {
        return <div className='left-nav'>
            <div style={{
                display: 'block'
            }}>
                <div className='left-nav-title-div'>
                    <div className='left-nav-title' style={{
                        display: 'block'
                    }}>
                        {getLocalisedText('document_generator')}
                    </div>
                </div>
                {this.renderLeftPanelLinkList()}
            </div>
        </div>
    }

    renderLeftPanelLinkList() {
        var navigationList = HelpNavigationLinkUtil.getNavigationList()
        var nav = navigationList.map(element => {
            var items = undefined

            if (element.items !== undefined && element.items.length > 0) {
                items = element.items.map(subPage => {
                    return this.renderLeftPanelLink(subPage, element.tag)
                });
            }
            if(element.header !== undefined) {
                return <>
                    {this.renderLeftPanelLinkParent(element)}
                    {items}
                </>
            }
            return <></>
        });
        return <div className='left-nav-link-div'>
            {nav}
        </div>
    }

    renderLeftPanelLinkParent(element) {
        return <div className='left-nav-link-div-header'>
            {element.header}
        </div>
    }

    renderLeftPanelLink(item, tag) {
        var param =  tag + '/' + item.href
        var url = process.env.REACT_APP_DOC_BUILDER_URL + 'help?selected=' + param
        // var url = 'http://local-dev.deskera.xyz:3000/' + 'help?selected=' + param
        return <div className='left-nav-link-div-item'>
            <a href={url} className='left-nav-link-div-item-href'
                style={{
                    color: this.getIsSelected(param) ? 'red': 'black'
                }}>
                {getLocalisedText(item.label)}
            </a>
        </div>
    }

    getIsSelected(value) {
        if(this.state.selected !== undefined && this.state.selected !== null) {
            return value === this.state.selected
        }
        return false
    }

    renderRightContain() {
        return <div style={{
            marginLeft: 250,
            paddingTop: 20,
            paddingBottom: 20,
            display: 'block',
        }}>
            {HelpNavigationLinkUtil.getNavigationLinkPage(this.state.selected)}
        </div>
    }
}
