import React, {Component} from 'react';
import '../../App.css';
// import {TableColumnPopupType} from '../Popup/TableColumnPopup';
// import { getLocalisedText } from '../../Translate/LanguageManager';
// import AppManager from '../../Manager/AppManager';
// import Utility from '../../Utilities/Utility';

import {
    Utility,
    AppManager,
    getLocalisedText,
    TableColumnPopupType
} from 'deskera-doc-builder-lib';
export default class PickingColumnEditor extends Component {

    dataChanged(data) {
        this.props.dataChanged(this.props.data);
    }

    render() {
        return this.getPickingListColumns(this.props.columnData)
    }

    getPickingListColumns(columnsData = []) {
        var arr = [];
        for (let i = 0; i < columnsData.length; i++) {
            arr.push(this.getPickingListColumn(i, columnsData[i]));
        }
        return arr;
    }

    getPickingListColumn(index, columnData) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                }}
            >
                {this.getShowHideValueForTablePopup(TableColumnPopupType.invoiceOrQuotation) && this.getColumnTextfields(
                    "invoice_or_quotation",
                    index,
                    columnData.invoiceOrQuotation,
                    this.columnInvoiceOrQuotationChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.code) && this.getColumnTextfields(
                    "product_code",
                    index,
                    columnData.code,
                    this.columnCodeChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.productName) && this.getColumnTextfields(
                    "product_name",
                    index,
                    columnData.name,
                    this.columnNameChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.barcode) && this.getColumnTextfields(
                    "barcode",
                    index,
                    columnData.barcode,
                    this.columnBarcodeChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.quantityToPick) && this.getColumnTextfields(
                    "quantity_to_pick",
                    index,
                    columnData.quantityToPick,
                    this.columnQuantityToPickChanged,
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.quantityRequiredForOrder) && this.getColumnTextfields(
                    "quantity_required_for_order",
                    index,
                    columnData.quantityForOrder,
                    this.columnQuantityForOrderChanged,
                )}
                <div className='RowDiv'
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                    }}>
                    <button
                        style={{
                            marginTop: 10,
                            cursor: "pointer",
                            color: "red",
                            border: "solid",
                            borderWidth: 1,
                            borderColor: "rgb(210, 210, 210)",
                            backgroundColor: "white",
                            borderRadius: 4,
                            marginBottom: 20,
                        }}
                        onClick={() => this.deleteColumn(index)}
                    >
                        {getLocalisedText("delete")}
                    </button>
                </div>
            </div>
        );
    }

    deleteColumn(index) {
        var data = this.props.data;
        data["lineItems"].splice(index, 1);
        this.dataChanged(data);
    }

    getColumnTextfields(placeholder, index, value, callback) {
        return (
            <input
                className="TextField"
                style={{
                    marginTop: 6,
                    borderRadius: 4,
                    color: 'rgb(30, 30, 30)'
                }}
                placeholder={getLocalisedText(placeholder)}
                type="text"
                dir={Utility.getInputDirection()}
                value={getLocalisedText(value)}
                onChange={(e) => callback(e, index)}
            />
        );
    }

    columnInvoiceOrQuotationChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["invoiceOrQuotation"] = event.target.value;
        this.dataChanged(data);
    };

    columnCodeChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["code"] = event.target.value;
        this.dataChanged(data);
    };

    columnNameChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["name"] = event.target.value;
        this.dataChanged(data);
    };

    columnBarcodeChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["barcode"] = event.target.value;
        this.dataChanged(data);
    };

    columnQuantityToPickChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["quantityToPick"] = value;
        this.dataChanged(data);
    };

    columnQuantityForOrderChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["quantityForOrder"] = value;
        this.dataChanged(data);
    };

    getShowHideValueForTablePopup(tableColumnPopupType) {
        var item = AppManager.getTableColumnOptions(this.props.data.tableColumnPopup, tableColumnPopupType)
        if(item === undefined || item  === null) {
            return false
        }
        else {
            return item.isSelected
        }
    }

}
