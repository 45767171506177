
import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
import PopupHeaderComponent from "./PopupHeaderComponent";
import {
    TemplateSettingsManager,
    getLocalisedText,
    Utility,  
} from 'deskera-doc-builder-lib';

export default class PaperMarginPopup extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            data: this.getPositionList(),
            isCustomMargin: this.getIsCustomMargin(),
            measurement: this.getMeasurement(),
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 'auto',
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={'paper_margin'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.renderMainContainerSection()}
        </>;
    }

    getDefaultPositionConfiguration () {
    
        return []
    }

    getPositionList() {
        let existingPaperMargin = TemplateSettingsManager.getPaperMargin()

        if(existingPaperMargin === undefined) {
            existingPaperMargin = {
                margin: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
                measurement: 'mm',
                isCustomMargin: false,
            }
        }

        let positionList = [
            { index: 1, type: 'top', label: getLocalisedText('top'), value: existingPaperMargin.margin?.top ?? 0 },
            { index: 2, type: 'right', label: getLocalisedText('right'), value: existingPaperMargin.margin?.right ?? 0 },
            { index: 3, type: 'bottom', label: getLocalisedText('bottom'), value: existingPaperMargin.margin?.bottom ?? 0 },
            { index: 4, type: 'left', label: getLocalisedText('left'), value: existingPaperMargin.margin?.left ?? 0 },
        ]

        return positionList
    }

    getIsCustomMargin() {
        return TemplateSettingsManager.getPaperMargin()?.isCustomMargin ?? false
    }

    getMeasurement() {
        return TemplateSettingsManager.getPaperMargin()?.measurement ?? 'mm'
    }

    renderMainContainerSection() {
        return <>
            <div style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                <div
                    style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 5,
                        paddingBottom: 10,
                    }}
                >
                    {this.renderIsCustomMarginCheckBox()}
                    {this.renderPositionListSection()}
                </div>
            </div>
        </>
    }

    renderPositionListSection() {
        if(this.state.data === undefined || this.state.data === null) {
            return <></>
        }

        if(this.state.data.length === 0) {
            return <></>
        }
        return this.state.data.map((element, index) => {
            return this.renderItemsContainer(element, index)
        });
    }

    renderItemsContainer(element, index) {
        return <>
            <div
                id={'row_id_' + index}
                className={"ColumnDiv"}
                style={{ width: '100%', paddingLeft: 13, paddingRight: 13, boxSizing: 'border-box', position: 'relative' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                    <div className="RowDiv" style={{ paddingTop: 5, paddingBottom: 5, boxSizing: 'border-box' }}>
                        <div className="ColumnDiv" style={{ width: '100%' }}>
                            <div className="RowDiv">
                                {this.renderCustomTextContainer(element, index)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Line" style={{marginLeft: 0, height: 2, width: '100%' }} />
            </div>
        </>
    }

    renderCustomTextContainer(element, index) {
        return <>
            <div className="parent-width"
            style={{
                color: this.state.isCustomMargin ? 'black' : 'grey'
            }}>
                <div className="parent-width">
                    <div className="RowDiv">
                        <div className="ColumnDiv"
                            style={{
                                width: '50%'
                            }}
                        >
                            {getLocalisedText(element.label)}:
                        </div>
                        <div className="ColumnDiv"
                            style={{
                                width: '39%'
                            }}>
                            {this.renderTextInput(element, (event, element) => this.onTextChanged(event, element, index))}
                        </div>
                        <div className="ColumnDiv"
                            style={{
                                width: '10%'
                            }}
                        >
                            {this.state.measurement}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    renderIsCustomMarginCheckBox() {
        return <div className="parent-width RowDiv" style={{ paddingLeft: 13 }}>
            <div>
                <input type="checkbox"
                    checked={this.state.isCustomMargin}
                    style={{ cursor: "pointer", textAlign: 'right' }}
                    id={'row_checkbox_id_is_custom_margin'}
                    onClick={() => {
                        this.onIsCustomMarginChecked()
                    }} />

            </div>
            <div className="ColumnDiv parent-width"
                style={{
                    paddingLeft: 5,
                }}
            >
                {getLocalisedText('is_using_custom_margin')}
            </div>
        </div>
    }

    renderTextInput(element, onChange) {
        return (
            <input
                className="EditableLabelTableColumn"
                type="text"
                disabled={!this.state.isCustomMargin}
                dir={Utility.getInputDirection()}
                name={element.label}
                style={{ fontSize: 14, padding: 4, width: '96%', fontWeight: 500, boxSizing: 'border-box', textAlign: 'right', color: this.state.isCustomMargin ? 'black' : 'grey' }}
                value={this.getDefaultText(element.value)}
                onChange={e => onChange(e, element)}
                onBlur={() => {
                    this.setState({ isTextFieldFocus: false })
                }}
            />
        )
    }

    getDefaultText(field) {
        if (field !== undefined || field !== null) {
            return getLocalisedText(field)
        }
        return ''
    }

    onTextChanged(event, item, itemIndex) {
        let newData = this.state.data
        let value = event.target.value
        let isValidData = false

        const result = parseFloat(value)
        isValidData = !isNaN(result)

        if(isValidData) {
            newData.forEach((element, index) => {
                if (itemIndex === index) {
                    if (element.value !== undefined && element.value !== null) {
                        element.value = result
                    }
                }
            });
            this.setState({
                data: newData
            })
        }
    }


    onIsCustomMarginChecked() {
        this.setState({
            isCustomMargin: !this.state.isCustomMargin
        })
    }

    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    saveButtonTapped = () => {
        let newPaperMargin = TemplateSettingsManager.getPaperMargin()

        if (newPaperMargin === undefined) {
            newPaperMargin = {
                margin: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
                measurement: 'mm',
                isCustomMargin: false,
            }
        }

        this.state.data.forEach(element => {
            const result = parseFloat(element.value)
            if (!isNaN(result)) {
                newPaperMargin.margin[element.type] = result
            }
        });

        newPaperMargin.isCustomMargin = this.state.isCustomMargin
        newPaperMargin.measurement = this.state.measurement

        TemplateSettingsManager.updatePaperMargin(newPaperMargin)
        this.props.onClosePressed()
    }
}
