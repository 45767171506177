import React, { Component } from 'react'
// import { COLOR_LIGHTGRAY2 } from '../../Constants/Constants';
// import ComponentManager from '../../Manager/ComponentManager';
// import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
// import { getLocalisedText } from '../../Translate/LanguageManager'
// import { getVW } from '../../Utilities/Utility';

import {
    COLOR_LIGHTGRAY2,
    ComponentManager,
    TemplateSettingsManager,
    getLocalisedText,
    getVW,
    Asset
} from 'deskera-doc-builder-lib';

export default class CustomTemplateListPopup extends Component {
    render() {
        return (
            <div
                className="ShadowMedium ColumnDiv"
                style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    position: "absolute",
                    top: 100,
                    right: '1.3vw',
                    padding: 20,
                    // paddingBottom: getVW(30),
                    minWidth: '10vw',
                    width:'auto',
                    zIndex: 200,
                }}
            >
                <img
                    src={Asset.icon.ic_upArrow_1}
                    alt=""
                    style={{ width: 25, position: "absolute", top: -13, right: 20 }}
                />
                <div className="ColumnDiv" style={{ fontSize: 13, width: '100%', textAlign: 'left' }}
                    onMouseLeave={this.props.onCancelTapped}>
                    <div className={'fontStyleBold pt-s pb-s'} style={{
                        paddingLeft: 6
                    }}>
                        {getLocalisedText('custom_template')}
                    </div>
                    {this.renderCustomTemplateList()}
                </div>
            </div>
        )
    }


    renderCustomTemplateList() {
        var list = this.props.templateList
        return list.map(template => {
            return this.customTemplateView(template)
        });
    }

    customTemplateView(item) {
        return <div className='parent-width'>
            <div
                style={{
                    // borderRadius: 4,
                    cursor: "pointer",
                    outline: "none",
                    border: "solid",
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 8,
                    paddingBottom: 8,
                    borderWidth: 2,
                    borderColor: 'white',
                }}
                onClick={() => {
                    this.props.onCustomTemplateTapped(item)
                }}
            >
                <div className="RowDiv justify-content-between">
                    <text style={{
                        fontSize: 13,
                        height: 15,
                        overflow: 'hidden',
                        paddingRight: 8
                    }}>{getLocalisedText(item.templateName)}</text>
                    <div className="RowDiv"
                        style={{
                            width: 'auto'
                        }}>
                        {this.getIsSelectedTemplate(item) && this.renderSelectedIcon()}
                        {this.getIsDefaultTemplate(item) && <div>&nbsp;</div> }
                        {this.getIsDefaultTemplate(item) && this.renderDefaultTemplateIcon(item)}
                        {!this.getIsSelectedTemplate(item) && !this.getIsDefaultTemplate(item) && this.renderDeleteIcon(item)}
                    </div>
                </div>
            </div>
            {ComponentManager.addDivider({
                width: '100%',
                height: getVW(1),
                backgroundColor: COLOR_LIGHTGRAY2,
                opacity: 1
            })}
        </div>
    }

    getIsSelectedTemplate(item) {
        return TemplateSettingsManager.getRemoteTemplateUID() === item.id
    }

    getIsDefaultTemplate(item) {
        return item.defaultTemplate !== null ? item.defaultTemplate : false
    }

    renderSelectedIcon() {
        return <img
            src={Asset.icon.ic_checkmarkCircle}
            alt=""
            style={{
                // position: "absolute",
                top: 10,
                right: 5,
                width: 20,
                height: 20,
            }}
        />
    }

    renderDeleteIcon(item) {
        return <div className='ColumnDiv'
            style={{
                top: 10,
                right: 5,
                width: 20,
                height: 20,
                backgroundColor: 'red',
                borderRadius: '50%',
                justifyContent: 'center',
            }}
                onClick={(event) => this.deleteItem(event, item)}
            >
                    <img
                        src={Asset.icon.ic_delete_white}
                        alt=""
                        style={{
                            width: 16,
                            height: 16,
                            alignSelf: 'center',
                        }}
                    />
        </div>
    }

    renderDefaultTemplateIcon(item) {
        return <div
            className='ColumnDiv'
            style={{
                top: 10,
                right: 5,
                width: 20,
                height: 20,
                backgroundColor: 'rgb(70,150, 243)',
                borderRadius: '50%',
                justifyContent: 'center',
            }}
        >
            <img
                src={Asset.icon.ic_bookmark}
                alt=""
                style={{
                    width: 16,
                    height: 16,
                    alignSelf: 'center',
                }}
            />
        </div>
    }

    deleteItem(event, item) {
        event.stopPropagation()
        this.props.deleteTemplateItem(item)
        return
    }
}
