import React, { Component } from "react";
import './ShareLinkPopup.css'
import '../../../App.css'
// import {getLocalisedText} from "../../../Translate/LanguageManager";
// import ComponentManager from "../../../Manager/ComponentManager";
// import {COLOR_BLUE} from "../../../Constants/Constants";
// import AppManager from "../../../Manager/AppManager";
// import ApiConstants from "../../../API/ApiConstants";
// import {ShareLinkRepository} from "../../../API/ShareLinkRepository";
// import Utility, {getDateFrom, getDateString, getFontSizeClass} from "../../../Utilities/Utility";
// import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
// import {DateFormat} from "../../TemplateEditors/DocumentEditor";
// import {AlertType, showAlert} from "../../AlertComponent/Alert";
// import { HTMLTag } from "../../../Utilities/HTMLTag";

import {
    ComponentManager,
    Utility,
    getDateFrom,
    getDateString,
    getFontSizeClass,
    AppManager,
    getLocalisedText,
    ApiConstants,
    TemplateSettingsManager,
    ShareLinkRepository,
    HTMLTag,
    showAlert,
    Asset,
    COLOR_BLUE,
    DateFormat
} from "deskera-doc-builder-lib";
import { AlertType } from "deskera-doc-builder-lib/src/Helper/types";


export default class ShareLinkPopup extends Component{
    ///////////////////////////////////////////////////////////////
    // Life cycle Related Methods
    ///////////////////////////////////////////////////////////////
    constructor(props) {
        super(props);
        this.state = {
            selectedExpiryOption: 0,
            selectedBurnAfterReadOption: false,
            isGeneratingShareableLink: this.props.isGeneratingShareableLink,
            urlCopied: false,
            currentDocumentData: this.props.currentDocumentData,
            passwordCreated: '',
            didPressGenerateButton: false,
            hasUpdatedShareCriteria: true
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isGeneratingShareableLink: nextProps.isGeneratingShareableLink,
            currentDocumentData: nextProps.currentDocumentData
        })
    }

    ///////////////////////////////////////////////////////////////
    // Render Related Methods
    ///////////////////////////////////////////////////////////////
    render() {
        return (
            <div className="ColumnDiv WindowPopup fullscreen-height fullscreen-width bg-popup displayOnTop">
                <div className="ShadowMedium ColumnDiv bg-color_white border-radius-m position-absolute popup-width padding-m"
                     style={{top: 70, right: 260}}>
                    {this.renderTopArrowImage()}
                    {this.renderTitleSection()}
                    {ComponentManager.addVerticalSpace(20)}
                    {this.renderExpirySection()}
                    {ComponentManager.addVerticalSpace(10)}
                    {this.renderBurnAfterReadingSection()}
                    {ComponentManager.addVerticalSpace(10)}
                    {this.renderPasswordSection()}
                    {this.state.didPressGenerateButton && ComponentManager.addVerticalSpace(30)}
                    {this.state.didPressGenerateButton && this.renderLinkInfoSection()}
                    {ComponentManager.addVerticalSpace(20)}
                    {this.state.hasUpdatedShareCriteria && this.renderGenerateButton()}
                    {ComponentManager.addVerticalSpace(5)}
                </div>
            </div>
        )
    }

    ///////////////////////////////////////////////////////////////
    // Render Related Methods - Level 1
    ///////////////////////////////////////////////////////////////
    renderTopArrowImage() {
        return <img
            src={Asset.icon.ic_upArrow_1}
            alt=""
            style={{width: 25, position: "absolute", top: -13, right: 65}}
        />;
    }

    renderTitleSection() {
        return <div className="RowReverseDiv" style={{justifyContent: 'space-between'}}>
            <div style={{opacity: 0.4}}>
                {ComponentManager.getImageButton(Asset.icon.ic_close, this.cancelTapped, 0, 10)}
            </div>
            <div className="RowDiv">
                <img
                    src={Asset.icon.get_link}
                    alt=""
                    style={{width: 35, marginRight: 5}}
                />
                <text className={"fontStyleBold" + getFontSizeClass()} style={{fontSize: 16}}>{getLocalisedText('generate_link_to_share')}</text>
            </div>
        </div>;
    }

    renderLinkInfoSection() {
        return <div className="RowDiv" style={{fontSize: 13, whiteSpace: 'nowrap'}}>
            <div style={{
                color: 'rgb(100, 100, 100)',
                width: '100%',
                padding: 10,
                borderRadius: 6,
                backgroundColor: 'rgb(235, 235, 235)',
                textAlign: 'left',
                overflow: "hidden",
                textOverflow: 'ellipsis',
                marginRight: 10,
            }}>
                <input type="text"
                    value={this.getShareableURL()}
                    id={HTMLTag.SHARE_URL_INPUT}
                    style={{
                        fontSize: 13,
                        color: 'rgb(100, 100, 100)',
                        outline: 'none',
                        width: '100%',
                        boxSizing: "border-box",
                        border: 'none',
                        backgroundColor: this.state.urlCopied === false ? 'transparent' : COLOR_BLUE + '1A',
                    }} />

            </div>
            {!this.state.isGeneratingShareableLink ? this.renderCopyLinkButton() : this.renderLoader()}
        </div>;
    }

    renderExpirySection() {
        return <div className="RowReverseDiv"  style={{justifyContent: 'space-between'}}>
            {this.renderExpiryOptions()}
            <text className={"fontStyleBold" + getFontSizeClass()}>{getLocalisedText('expiry')}</text>
        </div>;
    }

    renderBurnAfterReadingSection() {
        return <div className="RowReverseDiv" style={{justifyContent: 'space-between'}}>
            <input type="checkbox" checked={this.state.selectedBurnAfterReadOption} style={{marginLeft: 10}}
                   onClick={() => {
                       this.setState({selectedBurnAfterReadOption: !this.state.selectedBurnAfterReadOption, hasUpdatedShareCriteria: true, didPressGenerateButton: false })
                   }}/>
            <text className={"fontStyleBold" + getFontSizeClass()}>{getLocalisedText('burn_after_read')}</text>
        </div>;
    }

    renderPasswordSection() {
        return <div className="RowReverseDiv" style={{justifyContent: 'space-between'}}>
            <input style={{width: '48%', height: 20, border: 'solid', borderWidth: 1, borderColor: 'lightGray', borderRadius: 4}} value={this.state.passwordCreated} onChange={this.onTextChange}/>
            <text className={"fontStyleBold" + getFontSizeClass()}>{getLocalisedText('password')}</text>
        </div>;
    }

    renderGenerateButton() {
        return <div className="RowReverseDiv">
            {ComponentManager.getButton('generate_link', COLOR_BLUE, 'white', this.generateNewLinkTapped)}
        </div>;
    }


    ///////////////////////////////////////////////////////////////
    // Render Related Methods - Level 2
    ///////////////////////////////////////////////////////////////

    renderLoader() {
        return <div className="Loading-logo" style={{opacity: 1}}>
            {ComponentManager.getIcon(Asset.icon.ic_loading_icon, 20)}
        </div>;
    }

    renderCopyLinkButton() {
        return <div style={{cursor: 'pointer', color: COLOR_BLUE, fontWeight: '400'}} onClick={this.copyToClipboard}>
            {getLocalisedText('copy_link')}
        </div>;
    }

    renderExpiryOptions() {
        return (
            <div className="ListPickerHolderStyle" style={{maxWidth: 200, marginTop: -1}}>
                <select onChange={(e) => this.expiryOptionsTypeChanged(e)}
                        className="TextField ListPicker">
                    <option value='0' selected={this.state.selectedExpiryOption === 0}>
                        {getLocalisedText('no_never')}
                    </option>
                    <option value="1" selected={this.state.selectedExpiryOption === 1}>
                        {getLocalisedText('1_min')}
                    </option>
                    <option value="2" selected={this.state.selectedExpiryOption === 2}>
                        {getLocalisedText('1_hr')}
                    </option>
                    <option value="3" selected={this.state.selectedExpiryOption === 3}>
                        {getLocalisedText('1_day')}
                    </option>
                    <option value="4" selected={this.state.selectedExpiryOption === 4}>
                        {getLocalisedText('1_year')}
                    </option>
                </select>
                {this.renderArrow()}
            </div>
        )
    }

    ///////////////////////////////////////////////////////////////
    // Render Related Methods - Level 3
    ///////////////////////////////////////////////////////////////

    renderArrow() {
        return (
            <img
                className="ListPickerArrowStyle"
                src={Asset.icon.ic_listPickerArrow2}
                alt=""
            />
        );
    }

    ///////////////////////////////////////////////////////////////
    // User Action Callbacks
    ///////////////////////////////////////////////////////////////
    cancelTapped = () => {
        this.props.onClose()
    }

    expiryOptionsTypeChanged = (event) => {
        this.setState( {selectedExpiryOption: parseInt(event.target.value), hasUpdatedShareCriteria: true, didPressGenerateButton: false })
    };

    generateNewLinkTapped = () => {
        if (!this.isPasswordValid()) {
            return
        }
        this.setState({didPressGenerateButton : true})
        // selectedExpiryOption
        ///////////////////////////////////////////////////////////////
        // selectedExpiryOption Cheatsheet
        ///////////////////////////////////////////////////////////////
        // 0 -> never
        // 1 -> 1 min
        // 2 -> 1 hr
        // 3 -> 1 day
        // 4 -> 1 year
        ///////////////////////////////////////////////////////////////
        let expiryInSeconds = 0

        switch (this.state.selectedExpiryOption) {
            case 0:
                expiryInSeconds = 0;
                break;
            case 1:
                expiryInSeconds = 60;
                break;
            case 2:
                expiryInSeconds = 3600;
                break;
            case 3:
                expiryInSeconds = 86400;
                break;
            case 4:
                expiryInSeconds = 2628288;
                break;
            case 5:
                expiryInSeconds = 31536000;
                break;
            default:
                expiryInSeconds = 0
        }
        this.setState( {isGeneratingShareableLink : true, urlCopied: false})

        let payload = {...this.getERPData(this.state.currentDocumentData), ...{settings: TemplateSettingsManager.defaultTemplateSettings}}
        let payload_str = JSON.stringify(payload)
        let payload_encoded = Utility.encodeStringForTemplate(payload_str)
        let shareLinkRepo = new ShareLinkRepository()
        shareLinkRepo.generatePublicShareableLink(true, this.props.currentDocumentCode, this.props.currentDocumentType, this.state.selectedBurnAfterReadOption, payload_encoded, expiryInSeconds, this.state.passwordCreated, () => {
            this.setState( {isGeneratingShareableLink : false, hasUpdatedShareCriteria: false,})
        }, () => {
            this.setState( {isGeneratingShareableLink : false, hasUpdatedShareCriteria: false})
        })

    };

    copyToClipboard = () => {
        var copyText = document.getElementById(HTMLTag.SHARE_URL_INPUT);
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
        this.setState({
            urlCopied: true
        })
    };

    onTextChange = (event) => {
        this.setState( { passwordCreated: event.target.value, hasUpdatedShareCriteria: true, didPressGenerateButton: false })
    }

    getShareableURL() {
        if (this.state.isGeneratingShareableLink === true) {
            return getLocalisedText('loading')
        }
        let extendedURL = (this.state.passwordCreated.trim().length === 0) ? '?sharing=true' : '?share=true'
        let sharableLink = ApiConstants.URL.DOC_BUILDER + AppManager.getShareableLink() + extendedURL
        return sharableLink
    }

    getERPData(dataReceived) {
        let data = {...dataReceived}
        data["date"]= getDateString(getDateFrom(dataReceived.date, dataReceived.dateFormat),DateFormat.DD_MM_YYYY_DASH)
        data["type"]=this.getType(dataReceived.type)
        data["dueDate"]=getDateString(getDateFrom(dataReceived.dueDate, dataReceived.dateFormat),DateFormat.DD_MM_YYYY_DASH)
            data["dateFormat"]=DateFormat.DD_MM_YYYY_DASH
        data["templateId"]=null
        data["themeColor"]=null
        data["productTrackingInfo"]=dataReceived.stockItems
        return data
    }

    getType(type) {
        if (type === 'quotation') {
            return 'Quote'
        } else return type
    }

    isPasswordValid() {
        if (this.state.passwordCreated.length === 0) {
            return true
        } else {
            if (this.state.passwordCreated.trim().length === 0) {
                showAlert(AlertType.standard, getLocalisedText('password_empty_title'), getLocalisedText('password_empty_message'))
                return false
            } else {
                return true
            }
        }
    }
}
