import React, { Component } from 'react';
import "../../DKUILibrary/DKUILibrary.css";
import CustomFieldPopupComponent from '../CustomFieldComponent/CustomFieldPopupComponent';
import CustomFieldTablePopupRow from './CustomFieldTablePopupRow';
import PopupHeaderComponent from './PopupHeaderComponent';

import {
    AppManager,
    CustomFieldManager,
    TemplateSettingsManager,
    getLocalisedText,
    getIsCheque, 
    getIsDebitOrCreditNote, 
    getIsPayrollCheck, 
    getIsPayslip, 
    getVW,
    DocumentDetailType
} from 'deskera-doc-builder-lib';

export default class DocumentFormatPopup extends Component {

    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            documentFields: this.getList(),
            originalDocumentFields: this.getList(),
            selectedIndex: undefined,
            height: this.getScrollingHeight(this.getList().length)
        }
    }

    render() {
        return (
            <div className='ShadowMedium WindowPopup' style={{
                width: 350,
                height: 'auto',
                backgroundColor: 'white',
                padding: 0,
                paddingBottom: 10
            }}>
                <PopupHeaderComponent
                    headerText={'document_details'}
                    cancelText={'cancel'}
                    submitText={'save'}
                    onCancelClicked={this.props.onCancelClicked}
                    onSubmitClicked={() => this.onSave()}
                />
                <div
                    className="ColumnDiv"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: getVW(2),
                        height: this.state.height,
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}>
                    {!getIsCheque(this.props.data.type)
                        && !getIsPayrollCheck(this.props.data.type)
                        && !getIsPayslip(this.props.data.type) &&
                        <CustomFieldPopupComponent
                            existingCustomFields={this.state.documentFields}
                            addCustomField={(customField) => this.addCustomField(customField)}
                        />
                    }
                    <div
                        id='address_table_popup'
                        className="ColumnDiv"
                        style={{
                            backgroundColor: 'white',
                            borderRadius: getVW(2),
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        {this.getOptions()}
                    </div>
                </div>
            </div>
        )
    }

    getList() {
        var rowItemList = []
        var documentDetailsList = TemplateSettingsManager.getDocumentDetailsList(this.props.data)
        if(documentDetailsList.length > 0) {
            documentDetailsList.forEach(element => {
                var newRowItem = {}
                newRowItem.isSelected = element.isSelected
                newRowItem.type = element.type
                if (element.type === DocumentDetailType.customField) {
                    newRowItem.code = element.code
                }
                else {
                    newRowItem.code = element.type
                }
                newRowItem.index = element.index
                newRowItem.label = element.label
                newRowItem.key = element.key
                newRowItem.documentType = element.documentType
                rowItemList.push(newRowItem)
            });
        }
        return rowItemList
    }

    getScrollingHeight(rowSize) {
        var defaultThreshold = 40 //default height without item level
        var innerHeight = window.innerHeight

        var rowHeight = 44
        var totalRowHeight = 0
        if (rowSize > 0) {
            totalRowHeight = rowHeight * rowSize
        }

        defaultThreshold = defaultThreshold + totalRowHeight

        if (defaultThreshold > (innerHeight * 0.8)) {
            return innerHeight * 0.8
        }
        return defaultThreshold
    }

    getOptions() {
        var sortedList = this.state.documentFields.sort((a,b) => a.index - b.index)
        return sortedList.map((item) => {
            return <CustomFieldTablePopupRow
                item={item}
                customFields={sortedList}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectClick(item)}
                onUpdateLabel={(name) => this.onUpdateLabel(item, name)}
                isTextFieldFocus={true}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                isTextEditable={this.getIsContainEditableText(item)}
                isRemovable={this.getIsRemovable(item)}
                onDeleteItem={(item) => this.onDeleteItem(item)}
                isResetTitle={this.getIsContainEditableText(item)}
                onResetTitle={(item) => this.onResetTitle(item)}
            />
        })
    }

    getIsContainEditableText(item) {
        if (item.type === DocumentDetailType.customField) {
            if (item.originalLabel === undefined) {
                return true
            }
            return false
        }
        return true
    }

    getIsRemovable(item) {
        if (item.type === DocumentDetailType.customField) {
            return true
        }
        return false
    }

    onDeleteItem(item) {
        if(item !== undefined && item !== null) {
            var newDocumentList = this.state.documentFields.filter(x => x.code !== item.code)
            newDocumentList.forEach((element, index) => {
                element.index = index
            });

            this.setState({
                documentFields: newDocumentList,
                height: this.getScrollingHeight(newDocumentList.length),
            })
        }
    }

    onResetTitle(item) {
        var documentFields = this.state.documentFields
        documentFields.forEach(element => {
            if(element.type !== DocumentDetailType.customField && element.type === item.type) {
                var originalItem = this.state.originalDocumentFields.find(x => x.type === element.type)
                if(originalItem !== undefined) {
                    element.label = originalItem.label
                    if(getIsDebitOrCreditNote(this.props.data.type) && element.type === DocumentDetailType.linkDocumentNumber) {
                        if(this.props.data.linkDocumentType !== undefined && this.props.data.linkDocumentType !== null) {
                            element.label = AppManager.getTypeNoString(this.props.data.linkDocumentType)
                        }
                    }
                }
            }

            if (element.type === DocumentDetailType.customField && element.code === item.code ) {
                var customField = CustomFieldManager.getCustomFields().find(x => x.code === item.code)
                if (customField !== undefined) {
                    element.label = customField.label
                }
            }
        });

        this.setState({
            documentFields: documentFields,
        })
    }

    handleDrag = (ev) => {
        var startIndex = ev.currentTarget.id
        if(startIndex !== undefined && startIndex !== this.startIndex) {
            ev.dataTransfer.dropEffect = "move";
            this.startIndex = ev.currentTarget.id
        }
    };

    handleDrop = (ev) => {
        if (ev.currentTarget.id !== undefined) {
            this.onRowIndexChange(ev.currentTarget.id)
        }
    };

    onDragOver = (ev) => {
        var dragOverIndex = ev.currentTarget.id
        ev.preventDefault()
        if (dragOverIndex !== undefined) {
            if(dragOverIndex !== this.dragOverIndex && this.startIndex !== undefined) {
                this.removeTransition()
                this.addAnimation(ev.currentTarget.id, ev)
                this.dragOverIndex = dragOverIndex
            }
        }
    }

    onDragEnd = (ev) => {
        ev.preventDefault()
        this.removeTransition()
        if (this.startIndex !== undefined) {
            var startIndex = Number(this.startIndex.replace('row_id_', ''))
            this.showSelectedRow(startIndex)
            this.removeTransition()
        }
    }

    addAnimation = (targetIndex) => {
        var startIndex = undefined
        var endIndex = Number(targetIndex.replace('row_id_', ''))

        if(this.startIndex !== undefined) {
            startIndex = Number(this.startIndex.replace('row_id_', ''))
        }

        if (startIndex !== undefined && endIndex !== undefined) {
            if(startIndex !== endIndex) {
                let columnEle = document.getElementById('custom_field_table_popup');
                if (columnEle && columnEle.children) {
                    Array.from(columnEle.children).forEach((element, index) => {
                        if (endIndex > startIndex && endIndex > 0) {
                            if (index >= endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                        else if (endIndex !== 0) {
                            if (index > endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                    })
                }
            }
        }
    }

    removeTransition = () => {
        let columnEle = document.getElementById('custom_field_table_popup');
        if (columnEle && columnEle.children) {
            Array.from(columnEle.children).forEach((element, index) => {
                element.style.transform = null
                element.style.display = 'flex'
            })
        }
    }

    hideSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            if (textCol.innerHTML !== '&nbsp;') {
                this.startIndexText = textCol.innerHTML
            }
            textCol.innerHTML = '&nbsp;'
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'none'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'none'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'none'
        }
    }

    showSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            textCol.innerHTML = this.startIndexText
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'flex'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'flex'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'flex'
        }
    }

    onRowIndexChange(endIndex) {
        if (this.startIndex === undefined) {
            return
        }

        var startIndex = Number(this.startIndex.replace('row_id_', ''))
        if(this.startIndex === endIndex) {
            this.showSelectedRow(startIndex)
            this.startIndex = undefined
            return
        }

        this.showSelectedRow(startIndex)
        var newArray = []
        var documentFields = this.state.documentFields
        documentFields.forEach((element) => {
            if (Number(element.index) !== startIndex) {
                newArray.push(element)
            }
        });
        var newIndex = Number(endIndex.replace('row_id_', ''))

        var sortedList = []
        var selectedItem = documentFields.filter(x => x.index === startIndex)
        if(selectedItem.length > 0 ) {
            newArray.splice(newIndex, 0, selectedItem[0])
        }
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            documentFields: [],
            selectedIndex: undefined,
        }, () => {
            this.startIndex = undefined
            this.dragIndex = undefined
            this.removeTransition()
            this.setState({
                documentFields: sortedList,
            })
        })
    }

    onUpdateLabel(item, name) {
        var documentFields = this.state.documentFields

        documentFields.forEach(element => {
            if(element.code === item.code) {
                element.label = name
            }
        });

        this.setState({
            documentFields: documentFields,
        })
    }

    onSelectClick(item) {
        var documentFields = this.state.documentFields

        documentFields.forEach(element => {
            if (element.code === item.code) {
                element.isSelected = !element.isSelected
            }
        });
        this.setState({
            documentFields: documentFields,
        })
    }

    onRowChange(rowIndex, action) {
        var newArray = []
        var documentFields = this.state.documentFields
        documentFields.forEach((element, index) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= documentFields.length) {
                newIndex = documentFields.length - 1
            }
        }
        var sortedList = []
        var selectedItem = documentFields[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            documentFields: documentFields,
        })
    }

    addCustomField(customField) {
        if(customField !== undefined) {
            var newField = this.state.documentFields
            var newRowItem = {}
            newRowItem.isSelected = true
            newRowItem.code = customField.code
            newRowItem.type = DocumentDetailType.customField
            newRowItem.index = this.state.documentFields.length
            newRowItem.label = getLocalisedText(customField.label)
            newField.push(newRowItem)
            this.setState({
                documentFields: newField,
                height: this.getScrollingHeight(newField.length),
            })
        }
    }

    onSave() {
        var data = this.props.data
        TemplateSettingsManager.updateDocumentDetailsList(this.state.documentFields)
        this.props.onSaveClicked(data)
    }
}
