import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpSavedTemplateSelection extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Saved Template Selection')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "The design template that you saved will appear to the list.",
                },
                {
                    type: 'image',
                    value: require('./../asset/saved-template-selection/saved-template-list.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                // {
                //     type: 'list',
                //     value: [
                //         "Blue Circle - Currect selected template",
                //         "Red Trash Bin - remove template"
                //     ]
                // },
                {
                    type: 'text',
                    value: "You can switch to other saved template by click the template. The Blue Circle indicated which template is selected.",
                },
                {
                    type: 'text',
                    value: "You can remove the template by click on the Red Trash Can button. A popup will appear to confirm the deletion of the template.",
                },
                {
                    type: 'image',
                    value: require('./../asset/saved-template-selection/delete-template.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}