import React, {Component} from 'react';
import '../../App.css';
// import {TableColumnPopupType} from '../Popup/TableColumnPopup';
// import { getLocalisedText } from '../../Translate/LanguageManager';
// import AppManager from '../../Manager/AppManager';
// import Utility from '../../Utilities/Utility';

import {
    Utility,
    AppManager,
    getLocalisedText,
    TableColumnPopupType
} from 'deskera-doc-builder-lib';
export default class PaymentColumnEditor extends Component {

    dataChanged(data) {
        this.props.dataChanged(this.props.data);
    }

    render() {
        return this.getPaymentColumns(this.props.columnData)
    }

    getPaymentColumns(columnsData = []) {
        var arr = [];
        for (let i = 0; i < columnsData.length; i++) {
            arr.push(this.getPaymentColumn(i, columnsData[i]));
        }
        return arr;
    }

    getPaymentColumn(index, columnData) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                }}
            >
                {this.getShowHideValueForTablePopup(TableColumnPopupType.snNumber) && this.getColumnTextfields(
                    "sn_number",
                    index,
                    columnData.snNumber,
                    this.columnSnNumberChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.invoiceNumber) && this.getColumnTextfields(
                    "invoice_number",
                    index,
                    columnData.invoiceNumber,
                    this.columnInvoiceNumberChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.billNumber) && this.getColumnTextfields(
                    "bill_number",
                    index,
                    columnData.billNumber,
                    this.columnBillNumberChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.totalInvoiceAmount) && this.getColumnTextfields(
                    "total_invoice_amount",
                    index,
                    columnData.totalInvoiceAmount,
                    this.columnTotalInvoiceAmountChanged,
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.totalBillAmount) && this.getColumnTextfields(
                    "total_bill_amount",
                    index,
                    columnData.totalBillAmount,
                    this.columnTotalBillAmountChanged,
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.paymentMade) && this.getColumnTextfields(
                    "payment_made",
                    index,
                    columnData.paymentMade,
                    this.columnPaymentMadeChanged,
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.currentDueAmount) && this.getColumnTextfields(
                    "current_due_amount",
                    index,
                    columnData.currentDueAmount,
                    this.columnCurrentDueAmountChanged,
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.currentBalance) && this.getColumnTextfields(
                    "current_balance",
                    index,
                    columnData.currentBalance,
                    this.columnCurrentBalanceChanged,
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.paymentMedium) && this.getColumnTextfields(
                    "payment_medium",
                    index,
                    columnData.paymentMedium,
                    this.columnPaymentMediumChanged,
                )}
                <div className='RowDiv'
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                    }}>
                    <button
                        style={{
                            marginTop: 10,
                            cursor: "pointer",
                            color: "red",
                            border: "solid",
                            borderWidth: 1,
                            borderColor: "rgb(210, 210, 210)",
                            backgroundColor: "white",
                            borderRadius: 4,
                            marginBottom: 20,
                        }}
                        onClick={() => this.deleteColumn(index)}
                    >
                        {getLocalisedText("delete")}
                    </button>
                </div>
            </div>
        );
    }

    deleteColumn(index) {
        var data = this.props.data;
        data["lineItems"].splice(index, 1);
        this.dataChanged(data);
    }

    getColumnTextfields(placeholder, index, value, callback) {
        return (
            <input
                className="TextField"
                style={{
                    marginTop: 6,
                    borderRadius: 4,
                    color: 'rgb(30, 30, 30)'
                }}
                placeholder={getLocalisedText(placeholder)}
                type="text"
                dir={Utility.getInputDirection()}
                value={getLocalisedText(value)}
                onChange={(e) => callback(e, index)}
            />
        );
    }

    columnSnNumberChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["snNumber"] = event.target.value;
        this.dataChanged(data);
    };

    columnInvoiceNumberChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["invoiceNumber"] = event.target.value;
        this.dataChanged(data);
    };

    columnBillNumberChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["billNumber"] = event.target.value;
        this.dataChanged(data);
    };

    columnTotalInvoiceAmountChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["totalInvoiceAmount"] = value;
        this.dataChanged(data);
    };

    columnTotalBillAmountChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["totalBillAmount"] = value;
        this.dataChanged(data);
    };

    columnPaymentMadeChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["paymentMade"] = value;
        this.dataChanged(data);
    };

    columnCurrentDueAmountChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["currentDueAmount"] = value;
        this.dataChanged(data);
    };

    columnCurrentBalanceChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["currentBalance"] = value;
        this.dataChanged(data);
    };

    columnCurrentBalanceChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["currentBalance"] = value;
        this.dataChanged(data);
    };

    columnPaymentMediumChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["paymentMedium"] = event.target.value;
        this.dataChanged(data);
    };

    getShowHideValueForTablePopup(tableColumnPopupType) {
        var item = AppManager.getTableColumnOptions(this.props.data.tableColumnPopup, tableColumnPopupType)
        if(item === undefined || item  === null) {
            return false
        }
        else {
            return item.isSelected
        }
    }

}
