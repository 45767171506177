import { ComponentType } from "./Enum"
import { DocumentTemplateItemStyle } from "./Mobile/TemplateData"
export class TemplateItemStyle {
    constructor(type: string) {
        this.zIndex = 0
        this.opacity = 1
        this.textFieldActivated = false
        if(type === ComponentType.DKLabel) {
            this.fontColor = '#000000'
            this.fontSize = '12px'
            this.alignment = 'left'
            this.fontStyle = 'normal'
            this.fontWeight = 'normal'
            this.zIndex = 100
        }
        else if (type === ComponentType.DKImage) {
            this.zIndex = 100
        }
        else if (type === ComponentType.DKBanner) {
            this.zIndex = 1
        }
    }

    init(data: DocumentTemplateItemStyle) {
        this.zIndex = 100;
        this.opacity = data.opacity
        this.textFieldActivated = data.textFieldActivated
        this.fontColor = data.textFontColor
        this.fontSize = data.textFontSize.toFixed()
        this.fontStyle = data.textFontStyle
        this.fontWeight = data.textFontWeight
        this.alignment = data.textAlignment
    }
    zIndex?: number
    opacity?: number
    textFieldActivated?: boolean
    fontColor?: string
    fontSize?: string
    fontStyle?: string
    fontFamily?: string
    fontWeight?: string
    alignment?: string
}