import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
// import {getLocalisedText} from '../../Translate/LanguageManager';
import PopupHeaderComponent from "./PopupHeaderComponent";
// import TableFooterUtility from "../../Utilities/TableFooterUtility";
// import { TableColumnAlignmentType } from "../../Helper/TableColumnAlignmentParser";
// import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";

import {
    getLocalisedText,
    TemplateSettingsManager,
    TableFooterUtility,
    TableColumnAlignmentType
} from 'deskera-doc-builder-lib';

export default class TableColumnVerticalAlignmentPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            documentType: this.props.documentType,
            footerList: TableFooterUtility.getFooterListByDocument(this.props.documentData),
            tableColumnVerticalAlignmentList: this.getTableColumnAlignmentList(),
        };
    }

    getTableColumnAlignmentList() {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if(style !== undefined && style !== null) {
            if (style.table !== undefined && style.table !== null) {
                if (style.table.tableColumnVerticalAlignmentList !== undefined && style.table.tableColumnVerticalAlignmentList !== null) {
                    return style.table.tableColumnVerticalAlignmentList
                }
            }
        }
        return []
    }

    getTableHeaderList() {
        var newList = []
        if(this.state.data !== undefined && this.state.data !== null) {
            if(this.state.data.length > 0) {
                newList = this.state.data.filter(x => x.isSelected).sort((a,b) => a.index - b.index);
                if (this.state.footerList !== undefined && this.state.footerList !== null) {
                    if (this.state.footerList.length > 0) {
                        var footerType = this.state.footerList;
                        newList = newList.filter(x => !footerType.includes(x.type));
                    }
                }
            }
        }
        return newList;
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 350,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={'table_column_vertical_alignment'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.props.onClosePressed}
                onSubmitClicked={()=> this.onSaveClicked()}
            />
            {this.renderMainSection()}
        </>;
    }

    renderMainSection() {
        return <>
            <div style={{
                padding: 10,
            }}>
                <div
                    style={{
                        fontSize: 12,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 4,
                        paddingBottom: 10,
                    }}
                >
                    {this.renderSelectedTableHeaderList()}
                </div>
            </div>
        </>
    }

    renderSelectedTableHeaderList() {
        if(this.state.data !== undefined && this.state.data !== null) {
            if(this.state.data.length > 0) {
                var newList = this.getTableHeaderList();

                return newList.map((element, index) => {
                    var alignment = TableColumnAlignmentType.default
                    var savedColumnAlignment = undefined
                    if(element.code !== undefined) {
                        savedColumnAlignment = this.state.tableColumnVerticalAlignmentList.find(x => x.type === element.type & x.code === element.code)
                        if (savedColumnAlignment !== undefined) {
                            alignment = savedColumnAlignment.alignment
                        }
                    }
                    else {
                        savedColumnAlignment = this.state.tableColumnVerticalAlignmentList.find(x => x.type === element.type)
                        if (savedColumnAlignment !== undefined) {
                            alignment = savedColumnAlignment.alignment
                        }
                    }
                    return this.renderSelectedTableHeader(element, index, alignment)
                });
            }
        }

        return <></>
    }

    renderSelectedTableHeader(item, index, alignment) {
        return (
            <div
                id={'row_id_' + index}
                className={"ColumnDiv"}
                style={{ width: '100%', paddingRight: 13, boxSizing: 'border-box', position: 'relative' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                    <div className="RowDiv" style={{ paddingTop: 5, paddingBottom: 5, boxSizing: 'border-box' }}>
                        {getLocalisedText(item.name)}
                    </div>
                    {this.renderAlignmentButtonGroup(item, alignment)}
                </div>
                <div className="Line" style={{ width: '100%' }} />
            </div>
        )
    }

    renderAlignmentButtonGroup(item, alignment) {
        return (
            <div className="RowDiv" style={{
                justifyContent: 'space-between',
                backgroundColor: 'rgb(230, 230, 230)',
                borderRadius: 5,
            }}>
                {this.renderAlignmentButton(item, TableColumnAlignmentType.default, this.getIsSelectedValue(TableColumnAlignmentType.default, alignment))}
                {this.renderAlignmentButton(item, TableColumnAlignmentType.top, this.getIsSelectedValue(TableColumnAlignmentType.top, alignment))}
                {this.renderAlignmentButton(item, TableColumnAlignmentType.middle, this.getIsSelectedValue(TableColumnAlignmentType.middle, alignment))}
                {this.renderAlignmentButton(item, TableColumnAlignmentType.bottom, this.getIsSelectedValue(TableColumnAlignmentType.bottom, alignment))}
            </div>
        )
    }

    getIsSelectedValue(alignmentType, selectedAlignmentType) {
        return alignmentType === selectedAlignmentType
    }

    renderAlignmentButton(item, alignmentType, isSelected) {
        return <div className="HeaderButton"
            style={{
                color: 'rgb(50, 50, 50)',
                fontWeight: isSelected ? 'bold' : 'normal',
            }}
            onClick={() => { this.onAlignmentButtonClicked(item, alignmentType) }}>
            <div className='RowDiv'>
                <text>{getLocalisedText(alignmentType)}</text>
            </div>
        </div>
    }

    onAlignmentButtonClicked(item, alignmentType) {
        var newTableColumnVerticalAlignmentList = this.state.tableColumnVerticalAlignmentList
        var isFound = false
        newTableColumnVerticalAlignmentList.forEach(element => {
            if(item.code !== undefined) {
                if (item.code === element.code && element.type === item.type) {
                    isFound = true
                    element.alignment = alignmentType
                }
            }
            else if(element.type === item.type) {
                isFound = true
                element.alignment = alignmentType
            }
        });

        if(!isFound) {
            if(item.code !== undefined) {
                newTableColumnVerticalAlignmentList.push({ type: item.type, alignment: alignmentType, code: item.code })
            }
            else {
                newTableColumnVerticalAlignmentList.push({ type: item.type, alignment: alignmentType })
            }
        }

        this.setState({
            tableColumnVerticalAlignmentList: newTableColumnVerticalAlignmentList
        })
    }

    onSaveClicked() {
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style
        if (style === undefined || style === null) {
            style = TemplateSettingsManager.getDefaultTemplateStyle()
        }
        var newStyle = style

        var tableHeaderList = this.getTableHeaderList()
        var tableHeaderTypeList = []

        if (tableHeaderList.length > 0) {
            tableHeaderTypeList = tableHeaderList.map(x => x.type)
        }

        var newTableColumnVerticalAlignmentList = []

        this.state.tableColumnVerticalAlignmentList.forEach(element => {
            if (tableHeaderTypeList.includes(element.type)) {
                newTableColumnVerticalAlignmentList.push(element)
            }
        });

        if (newStyle.table !== undefined && newStyle !== null) {
            newStyle.table.tableColumnVerticalAlignmentList = newTableColumnVerticalAlignmentList
        }

        TemplateSettingsManager.updateTemplateStyle(newStyle)
        this.props.onClosePressed()
    }
}
