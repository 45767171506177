import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
// import ComponentManager from "../../Manager/ComponentManager";
// import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
// import { getLocalisedText } from "../../Translate/LanguageManager";
// import Utility, { getIsBill, getIsInvoice, getIsOrder, getIsQuotation, getIsSalesOrder } from "../../Utilities/Utility";
import PopupHeaderComponent from "./PopupHeaderComponent";
import QRCode from "react-qr-code";
// import { COLOR_BLUE, COLOR_LIGHTGRAY, IMG_MAX_UPLOAD_SIZE } from "../../Constants/Constants";
// import { HTMLTag } from "../../Utilities/HTMLTag";
// import { showToast, TOAST_TYPE } from "../ToastComponent/Toast";
import CustomFieldPopupComponent from "../CustomFieldComponent/CustomFieldPopupComponent";
// import CustomFieldManager from "../../Manager/CustomFieldManager";

import {
    ComponentManager,
    TemplateSettingsManager,
    getLocalisedText,
    Utility,  
    getIsBill, 
    getIsInvoice, 
    getIsOrder, 
    getIsQuotation, 
    getIsSalesOrder ,
    COLOR_BLUE, 
    COLOR_LIGHTGRAY, 
    IMG_MAX_UPLOAD_SIZE,
    HTMLTag,
    CustomFieldManager,
    showToast, 
    TOAST_TYPE,
    Asset,
    AdditionalTableType
} from 'deskera-doc-builder-lib';

export default class AdditionalTablePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.getAdditionalTableList(),
            selectedType: this.getDefaultSelectionType(),
            tableBorder: this.getTableBorder()
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 600,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>

            <PopupHeaderComponent
                headerText={'additional_table_configuration'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.renderMainContainerSection()}
        </>;
    }

    getDefaultAdditionalTableList () {
        var type = this.props.documentType
        if (getIsQuotation(type) ||
            getIsSalesOrder(type) ||
            getIsInvoice(type) ||
            getIsBill(type) ||
            getIsOrder(type)) {

            const indiaInWords = [
                { index: 16, label: 'cgst_in_words', type: AdditionalTableType.cgstInWords, isSelected: false },
                { index: 17, label: 'igst_in_words', type: AdditionalTableType.igstInWords, isSelected: false },
                { index: 18, label: 'sgst_in_words', type: AdditionalTableType.sgstInWords, isSelected: false },
            ]
        
            const defaultList = [
                { index: 0, label: 'amount_in_words', type: AdditionalTableType.amountInWords, isSelected: false },
                { index: 1, label: 'total', type: AdditionalTableType.total, isSelected: false },
                { index: 2, label: 'outstanding_balance', type: AdditionalTableType.outstandingBalance, isSelected: false },
                { index: 2, label: 'outstanding_balance_includes_current', type: AdditionalTableType.outstandingBalanceIncludesCurrent, isSelected: false },
                { index: 3, label: 'qr_code', type: AdditionalTableType.qrCode, isSelected: false },
                { index: 4, label: 'qr_code_with_text', type: AdditionalTableType.qrCodeWithText, isSelected: false },
                { index: 5, label: 'image', type: AdditionalTableType.image, isSelected: false },
                { index: 6, label: 'image_with_text', type: AdditionalTableType.imageWithText, isSelected: false },
                { index: 7, label: 'custom_text', type: AdditionalTableType.customText, isSelected: false },
                { index: 8, label: 'sub_total_in_words', type: AdditionalTableType.subTotalInWords, isSelected: false },
                { index: 9, label: 'rounding_off_in_words', type: AdditionalTableType.roundOffInWords, isSelected: false },
                { index: 10, label: 'outstanding_balance_in_words', type: AdditionalTableType.outstandingBalanceInWords, isSelected: false },
                { index: 11, label: 'outstanding_balance_includes_current_in_words', type: AdditionalTableType.outstandingBalanceIncludesCurrentInWords, isSelected: false },
                { index: 12, label: 'total_tax_amount_in_words', type: AdditionalTableType.totalTaxAmountInWords, isSelected: false },
                { index: 13, label: 'before_tax_in_words', type: AdditionalTableType.beforeTaxInWords, isSelected: false },
                { index: 14, label: 'additional_charges_in_words', type: AdditionalTableType.additionalChargeInWords, isSelected: false },
                { index: 15, label: 'global_discount_in_words', type: AdditionalTableType.globalDiscountInWords, isSelected: false },
            ]

            if (Utility.getIsIndiaCountry(this.props.documentData)) {
                return [...defaultList, ...indiaInWords]
            }
            return defaultList
        }

        return [
            { index: 0, label: 'qr_code', type: AdditionalTableType.qrCode, isSelected: false },
            { index: 1, label: 'qr_code_with_text', type: AdditionalTableType.qrCodeWithText, isSelected: false },
            { index: 2, label: 'image', type: AdditionalTableType.image, isSelected: false },
            { index: 3, label: 'image_with_text', type: AdditionalTableType.imageWithText, isSelected: false },
            { index: 4, label: 'custom_text', type: AdditionalTableType.customText, isSelected: false },
        ]
    }

    getTableBorder() {
        let borderTable = 'show'
        var additionalTable = TemplateSettingsManager.defaultTemplateSettings.additionalTable
        if (additionalTable && additionalTable !== null) {
            if (additionalTable.border && additionalTable.border !== null) {
                borderTable = additionalTable.border 
            }
        }
        return borderTable
    }

    getDefaultSelectionType() {
        var defaultList = this.getDefaultAdditionalTableList()
        return defaultList[0].type
    }

    getAdditionalTableList() {
        var defaultAdditionalTableList = this.getDefaultAdditionalTableList()

        var additionalTableList = TemplateSettingsManager.defaultTemplateSettings.additionalTable
        if(additionalTableList === undefined || additionalTableList === null) {
            additionalTableList = [defaultAdditionalTableList[0]]
        }

        if (additionalTableList.elements === undefined || additionalTableList.elements === null) {
            additionalTableList = [defaultAdditionalTableList[0]]
        }
        else {
            if (additionalTableList.elements.length === 0) {
                additionalTableList = []
            }
            else {
                additionalTableList = additionalTableList.elements
            }
        }

        return additionalTableList
    }

    getAdditionalTableList_old() {
        var defaultAdditionalTableList = this.getDefaultAdditionalTableList()

        var additionalTableList = TemplateSettingsManager.defaultTemplateSettings.additionalTable
        if (additionalTableList === undefined || additionalTableList === null) {
            additionalTableList = defaultAdditionalTableList
        }

        if (additionalTableList.elements === undefined || additionalTableList.elements === null) {
            additionalTableList = defaultAdditionalTableList
        }
        else {
            if (additionalTableList.elements.length === 0) {
                additionalTableList = defaultAdditionalTableList
            }
        }

        if (additionalTableList.length !== defaultAdditionalTableList.length) {
            var nextIndex = additionalTableList.length
            defaultAdditionalTableList.forEach(element => {
                var item = additionalTableList.find(x => x.type === element.type)
                if (item === undefined) {
                    var newItem = element
                    newItem.index = nextIndex
                    additionalTableList.push(newItem)
                    nextIndex = nextIndex + 1
                }
            });

        }

        return additionalTableList
    }

    renderMainContainerSection() {
        return <>
            <div style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                <div
                    style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 5,
                        paddingBottom: 10,
                    }}
                >
                    {this.renderTableBorderSection()}
                    {this.renderAddNewTableType()}
                    <CustomFieldPopupComponent
                        existingCustomFields={this.state.data}
                        addCustomField={(customField) => this.addCustomField(customField)}
                    />
                    {this.renderAdditionalTableListSection()}
                </div>
            </div>
        </>
    }

    renderTableBorderSection() {
        var borderList = ['show', 'hide']

        let borders = borderList.map(element => {
            const style = element === this.state.tableBorder ? {
                color: 'white',
                backgroundColor: 'rgb(55, 115, 225)'
            } : undefined
            return (
                <div
                    className="HeaderButton clicked-effect"
                    style={{
                        ...style,
                        marginLeft: 10,
                        userSelect: 'none',
                    }}
                    onClick={() => this.onTableBorderChanged(element)}
                >
                    {getLocalisedText(element)}
                </div>
            )
        });

        return <div className="RowDiv" style={{
            alignItems: 'baseline'
        }}>
            <div style={{
                color: "black",
                fontWeight: "600",
                paddingLeft: 18,
                paddingTop: 10,
            }}>
                {getLocalisedText('border')}
            </div>
            {borders}
        </div>
    }

    onTableBorderChanged(element) {
        this.setState({
            tableBorder: element ?? 'hide'
        })
    }

    renderAddNewTableType() {
        return <>
            <div className="parent-width">
                <div className="parent-width">
                    <div className="RowDiv">
                        <div className="ColumnDiv"
                            style={{
                                width: '25%',
                                paddingLeft: 13,
                                paddingRight: 13
                            }}
                        >
                            {getLocalisedText('select_new_type')} :
                        </div>
                        <div className="ColumnDiv pt-s pb-s"
                            style={{
                                width: '60%'
                            }}>
                            {this.getAdditionalTableTypeListPicker()}
                        </div>
                        <div className="ColumnDiv"
                            style={{
                                width: '15%',
                                alignItems: 'center'
                            }}>
                            {ComponentManager.getButton(getLocalisedText('add'), COLOR_BLUE, 'white', () => { this.onAddNewTableType()})}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    getAdditionalTableTypeListPicker() {
        var additionalTableType = this.getDefaultAdditionalTableList()
        var optionList = additionalTableType.map(element => {
            var isSelected = false
            if (this.state.selectedType !== undefined) {
                isSelected = this.state.selectedType.toLowerCase() === element.type
            }

            return <option value={element.type} selected={isSelected}>
                {getLocalisedText(element.label)}
            </option>
        });
        return (
            <div className="RowDiv">
                <select
                    onChange={(e) => this.onSelectedNewTableType(e)}
                    className="TextField ListPicker"
                    style={this.getPickerStyle()}
                >
                    {optionList}
                </select>
                <div
                    style={{
                        position: 'relative',
                        alignSelf: 'flex-end'
                    }}
                >
                    <img
                        className="ListPickerArrowStyle pt-l"
                        // style={{ top: 58, right: 25 }}
                        src={Asset.icon.ic_listPickerArrow2}
                        alt=""
                    />
                </div>
            </div>
        );
    }

    getPickerStyle() {
        if (Utility.getIsArabicLang()) {
            return {
                textAlignLast: 'right',
                direction: 'rtl',
                paddingRight: 24,
            }
        }
        return {}
    }

    renderAdditionalTableListSection() {
        if(this.state.data === undefined || this.state.data === null) {
            return <></>
        }

        if(this.state.data.length === 0) {
            return <></>
        }
        return this.state.data.map((element, index) => {
            return this.renderItemsContainer(element, index)
        });
    }

    renderItemsContainer(element, index) {
        return <>
            <div
                id={'row_id_' + index}
                className={"ColumnDiv"}
                style={{ width: '100%', paddingLeft: 13, paddingRight: 13, boxSizing: 'border-box', position: 'relative' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                    <div className="RowDiv" style={{ paddingTop: 5, paddingBottom: 5, boxSizing: 'border-box' }}>
                        {/* {this.getCheckBox(element, index)} */}
                        <div className="ColumnDiv" style={{ width: '100%' }}>
                            <div className="RowDiv">
                                <div className="ColumnDiv parent-width">
                                    {this.getTitleByType(element)}
                                </div>
                                {
                                    this.getIsResetButton(element) &&
                                    <div className="ColumnDiv">
                                        {this.renderResetTitleIcon(element, index)}
                                    </div>
                                }
                                {
                                    this.getIsShowTextAlignmentButton(element) &&
                                    <div className="ColumnDiv">
                                        {this.renderTextAlignmentIcon(element, index, 'vertical')}
                                    </div>
                                }
                                {
                                    this.getIsShowTextAlignmentButton(element) &&
                                    <div className="ColumnDiv">
                                        {this.renderTextAlignmentIcon(element, index, 'horizontal')}
                                    </div>
                                }
                                <div className="ColumnDiv">
                                    {this.renderDeleteIcon(element, index)}
                                </div>

                            </div>
                            <div className="RowDiv pb-s">
                                {this.renderTitleSection(element, index)}
                            </div>
                            {this.getElement(element, index) &&
                                <div className="RowDiv pb-s">
                                    {this.getElement(element, index)}
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div className="Line" style={{ marginLeft: 0, width: '100%' }} />
            </div>
        </>
    }

    getTitleByType(element) {
        if(element.type === AdditionalTableType.customField) {
            return getLocalisedText('custom_field')
        }
        else {
            return getLocalisedText(element.label)
        }
    }

    renderDeleteIcon(element, index) {
        return <div className='ColumnDiv'
            style={{
                top: 10,
                right: 5,
                width: 20,
                height: 20,
                backgroundColor: 'red',
                borderRadius: '50%',
                justifyContent: 'center',
            }}
            onClick={(event) => this.deleteItem(event, index)}
        >
            <img
                src={Asset.icon.ic_delete_white}
                alt=""
                style={{
                    width: 16,
                    height: 16,
                    alignSelf: 'center',
                }}
            />
        </div>
    }

    deleteItem(event, selectedIndex) {
        event.stopPropagation()
        var newData = []
        if (this.state.data === undefined || this.state.data === null) {
            return
        }
        if (this.state.data.length === 0) {
            return
        }

        this.state.data.forEach((element, index) => {
            if (selectedIndex !== index) {
                newData.push(element)
            }
        });

        if(newData.length > 0) {
            newData.forEach((element, index) => {
                element.index = index
            });

            this.setState({
                data: newData
            })
        }
        else {
            this.setState({
                data: []
            })
        }
        // this.props.deleteTemplateItem(element)
        return
    }


    getCheckBox(element, index) {
        return (
            <input type="checkbox" checked={element.isSelected} style={{ cursor: "pointer" }}
                id={'row_checkbox_id_' + index}
                onClick={() => {
                    this.onIsSelectedChanged(element)
                }} />
        )
    }

    getIsResetButton(element) {
        if(element.type === AdditionalTableType.customField) {
            if (element.originalLabel === undefined) {
                return true
            }
        }
        return false
    }

    renderResetTitleIcon(element, index) {
        return <div className='ColumnDiv'
            style={{
                top: 10,
                right: 5,
                width: 20,
                height: 20,
                cursor: 'pointer',
                backgroundColor: COLOR_LIGHTGRAY,
                borderRadius: '50%',
                justifyContent: 'center',
                marginRight: 5,
            }}
            onClick={() => this.onResetTitle(element)}
        >
            <img
                src={Asset.icon.ic_refresh_black}
                alt=""
                style={{
                    width: 20,
                    height: 20,
                    alignSelf: 'center',
                }}
            />
        </div>
    }

    getIsShowTextAlignmentButton(element) {
        const textAlignmentList =[
            AdditionalTableType.amountInWords,
            AdditionalTableType.total,
            AdditionalTableType.customField,
            AdditionalTableType.customText,
            AdditionalTableType.outstandingBalance,
            AdditionalTableType.outstandingBalanceIncludesCurrent,
            AdditionalTableType.subTotalInWords,
            AdditionalTableType.cgstInWords,
            AdditionalTableType.igstInWords,
            AdditionalTableType.sgstInWords,
            AdditionalTableType.roundOffInWords,
            AdditionalTableType.outstandingBalanceInWords,
            AdditionalTableType.outstandingBalanceIncludesCurrentInWords,
            AdditionalTableType.totalTaxAmountInWords,
            AdditionalTableType.beforeTaxInWords,
            AdditionalTableType.globalDiscountInWords,
            AdditionalTableType.additionalChargeInWords,
        ]

        return textAlignmentList.includes(element.type)
    }

    getAlignmentButtonStyle(isSelected) {
        const textColor = isSelected ? 'white': 'black'
        const backgroundColor = isSelected ? 'rgb(55, 115, 225)' : COLOR_LIGHTGRAY
        return {
            top: 10,
            right: 5,
            width: 20,
            height: 20,
            cursor: 'pointer',
            backgroundColor: backgroundColor,
            borderRadius: '50%',
            justifyContent: 'center',
            marginRight: 5,
            alignItems: 'center',
            color: textColor
        }
    }

    renderTextAlignmentIcon(element, index, direction) {
        const isSelected = this.getTextAlign(element) === direction
        const text = direction === 'vertical' ? 'V' : 'H'
        return <div className='ColumnDiv'
            style={this.getAlignmentButtonStyle(isSelected)}
            onClick={() => this.onSetTextAlignment(element, direction)}
        >
            {text}
        </div>
    }

    getTextAlign(element) {
        const newData = this.state.data
        let item = newData.find((x) => x.code === element.code && x.index === element.index)

        return item?.direction ?? 'vertical'
    }

    onSetTextAlignment(item, direction) {
        var newData = this.state.data
        newData.forEach(element => {
            if (element.code === item.code && item.index === element.index) {
                element.direction = direction
            }
        });

        this.setState({
            data: newData,
        })
    }

    onResetTitle(item) {
        var newData = this.state.data
        newData.forEach(element => {
            if (element.type === AdditionalTableType.customField && element.code === item.code) {
                var customField = CustomFieldManager.getCustomFields().find(x => x.code === item.code)
                if (customField !== undefined) {
                    element.label = customField.label
                    element.title = customField.label
                }
            }
        });

        this.setState({
            data: newData,
        })
    }

    renderTitleSection(element, index) {
        return <div className="RowDiv">
            <div className="ColumnDiv"
                style={{
                    width: '20%'
                }}
            >
                {getLocalisedText('title')}:
            </div>
            <div className="ColumnDiv"
                style={{
                    width: '80%'
                }}>
                {this.renderTextInput(element, 'title', (event, element, fieldName) => this.onTextChanged(event, element, fieldName, index))}
            </div>
        </div>
    }

    getElement(element, index) {
        switch (element.type) {
            case AdditionalTableType.qrCode:
                return this.renderQRCodeContainer(element, index)
            case AdditionalTableType.qrCodeWithText:
                return this.renderQRCodeWithCustomTextContainer(element, index)
            case AdditionalTableType.image:
                return this.renderImageContainer(element, index)
            case AdditionalTableType.imageWithText:
                return this.renderImageWithCustomTextContainer(element, index)
            case AdditionalTableType.amountInWords:
            case AdditionalTableType.subTotalInWords:
            case AdditionalTableType.cgstInWords:
            case AdditionalTableType.igstInWords:
            case AdditionalTableType.sgstInWords:
            case AdditionalTableType.roundOffInWords:
            case AdditionalTableType.outstandingBalanceInWords:
            case AdditionalTableType.outstandingBalanceIncludesCurrentInWords:
            case AdditionalTableType.totalTaxAmountInWords:
            case AdditionalTableType.beforeTaxInWords:
            case AdditionalTableType.globalDiscountInWords:
            case AdditionalTableType.additionalChargeInWords:
                return this.renderAmountInWordsContainer()
            case AdditionalTableType.total:
                return this.renderTotalContainer()
            case AdditionalTableType.outstandingBalance:
                return this.renderOutstandingBalanceContainer()
            case AdditionalTableType.outstandingBalanceIncludesCurrent:
                return this.renderOutstandingBalanceIncludesCurrentContainer()
            case AdditionalTableType.customText:
                return this.renderCustomTextContainer(element, index)
            case AdditionalTableType.customField:
                return undefined
            default:
                return undefined
        }
    }


    renderQRCode(value, index) {
        if(this.getIsContainQRCode(value)) {
            return <QRCode
                id={HTMLTag.QR_CODE_PREFIX + index}
                value={value}
                size={100}
            />
        }
        return null
    }

    getIsContainQRCode(value) {
        if (value !== undefined && value !== null && value.trim().length > 0) {
            return true
        }
        return false
    }

    renderQRCodeContainer(element, index) {
        return <>
            <div className="parent-width">
                <div className="parent-width">
                    <div className="RowDiv">
                        <div className="ColumnDiv"
                            style={{
                                width: '20%'
                            }}
                        >{getLocalisedText('qr_string')}:
                        </div>
                        <div className="ColumnDiv pt-s pb-s"
                            style={{
                                width: '80%'
                            }}>
                            {this.renderTextInput(element, undefined, (event, element, fieldName) => this.onTextChanged(event, element, fieldName, index))}
                        </div>
                    </div>
                    {this.getIsContainQRCode(this.getDefaultText(element, this.getFieldByType(element, undefined))) &&
                        <div className="RowDiv">
                            <div className="ColumnDiv"
                                style={{
                                    width: '20%'
                                }}
                            >
                                {getLocalisedText('generated_qr')}
                            </div>
                            <div className="ColumnDiv pt-s pb-s"
                                style={{
                                    width: '80%'
                                }}>
                                {this.renderQRCode(this.getDefaultText(element, this.getFieldByType(element, undefined)),index)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    }

    renderQRCodeWithCustomTextContainer(element, index) {
        return <>
            <div className="parent-width">
                <div className="parent-width">
                    <div className="RowDiv">
                        <div className="ColumnDiv"
                            style={{
                                width: '20%'
                            }}
                        >
                            {getLocalisedText('qr_string')}:
                        </div>
                        <div className="ColumnDiv pt-s pb-s"
                            style={{
                                width: '80%'
                            }}>
                            {this.renderTextInput(element, undefined, (event, element, fieldName) => this.onTextChanged(event, element, fieldName, index))}
                        </div>
                    </div>
                    {this.getIsContainQRCode(this.getDefaultText(element, this.getFieldByType(element, undefined))) &&
                        <div className="RowDiv">
                            <div className="ColumnDiv"
                                style={{
                                    width: '20%'
                                }}
                            >
                                {getLocalisedText('generated_qr')}
                            </div>
                            <div className="ColumnDiv pt-s pb-s"
                                style={{
                                    width: '80%'
                                }}>
                                {this.renderQRCode(this.getDefaultText(element, this.getFieldByType(element, undefined)),index)}
                            </div>
                        </div>
                    }
                    <div className="RowDiv" style={{ alignItems: 'baseline' }}>
                        <div className="ColumnDiv"
                            style={{
                                width: '20%'
                            }}
                        >
                            {getLocalisedText('custom_text')}:
                        </div>
                        <div className="ColumnDiv"
                            style={{
                                width: '80%'
                            }}>
                            {this.renderTextAreaInput(element, AdditionalTableType.customText, (event, element, fieldName) => this.onTextChanged(event, element, fieldName, index))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }


    renderImageContainer(element, index) {
        return <>
            <div className="parent-width">
                <div className="parent-width">
                    <div className="RowDiv">
                        <div className="ColumnDiv"
                            style={{
                                width: '20%'
                            }}
                        >
                            <div className="RowDiv">
                                {getLocalisedText('image')}:
                            </div>
                            <div className="RowDiv">
                                {this.renderFileInput((event) => this.onNewFileClick(event, index))}
                            </div>
                        </div>
                        <div className="ColumnDiv"
                            style={{
                                width: '80%'
                            }}
                        >
                            {this.renderImageThumbnail(element, this.getFieldByType(element, undefined))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    renderImageWithCustomTextContainer(element, index) {
        return <>
            <div className="parent-width">
                <div className="parent-width">
                    <div className="RowDiv">
                        <div className="ColumnDiv"
                            style={{
                                width: '20%'
                            }}
                        >
                            <div className="RowDiv">
                                {getLocalisedText('image')}:
                            </div>
                            <div className="RowDiv">
                                {this.renderFileInput((event) => this.onNewFileClick(event, index))}
                            </div>
                        </div>
                        <div className="ColumnDiv"
                            style={{
                                width: '80%'
                            }}
                        >
                            {this.renderImageThumbnail(element, this.getFieldByType(element, undefined))}
                        </div>
                    </div>
                </div>
                <div className="RowDiv pt-s" style={{ alignItems: 'baseline' }}>
                    <div className="ColumnDiv"
                        style={{
                            width: '20%'
                        }}
                    >
                        {getLocalisedText('custom_text')}:
                    </div>
                    <div className="ColumnDiv"
                        style={{
                            width: '80%'
                        }}>
                        {this.renderTextAreaInput(element, AdditionalTableType.customText, (event, element, fieldName) => this.onTextChanged(event, element, fieldName, index))}
                    </div>
                </div>
            </div>
        </>
    }


    renderImageThumbnail(element, field) {
        var imageSrc = Asset.icon.ic_thumbnail_default2
        if(field !== undefined && field !== null) {
            if(field.fieldName !== undefined && field.fieldName !== null) {
                if (element[field.fieldName] !== undefined && element[field.fieldName] !== null) {
                    imageSrc = element[field.fieldName]
                }
            }
        }
        return <img src={imageSrc}
            alt=""
            style={{
                ...{
                    height: 150,
                    borderRadius: '5%',
                    objectFit: "contain",
                    border: "solid",
                    borderWidth: 1,
                    borderColor: "rgb(230, 230, 230)",
                },
            }}
        />
    }

    renderCustomTextContainer(element, index) {
        return <>
            <div className="parent-width">
                <div className="parent-width">
                    <div className="RowDiv" style={{ alignItems: 'baseline' }}>
                        <div className="ColumnDiv"
                            style={{
                                width: '20%'
                            }}
                        >
                            {getLocalisedText('custom_text')}:
                        </div>
                        <div className="ColumnDiv"
                            style={{
                                width: '80%'
                            }}>
                            {this.renderTextAreaInput(element, undefined, (event, element, fieldName) => this.onTextChanged(event, element, fieldName, index))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    renderAmountInWordsContainer() {
        return undefined
    }

    renderTotalContainer() {
        return undefined
    }

    renderOutstandingBalanceContainer() {
        return undefined
    }

    renderOutstandingBalanceIncludesCurrentContainer() {
        return undefined
    }

    renderTextInput(element, fieldName, onChange) {
        return (
            <input
                className="EditableLabelTableColumn"
                type="text"
                dir={Utility.getInputDirection()}
                name={element.label}
                style={{ fontSize: 14, padding: 4, width: '96%', fontWeight: 500, boxSizing: 'border-box' }}
                value={this.getDefaultText(element, this.getFieldByType(element, fieldName))}
                onChange={e => onChange(e, element, this.getFieldByType(element, fieldName))}
                onBlur={() => {
                    this.setState({ isTextFieldFocus: false })
                }}
            />
        )
    }

    renderTextAreaInput(element, fieldName, onChange) {
        return (
            <textarea
                className="EditableLabelTableColumn"
                type="text"
                dir={Utility.getInputDirection()}
                name={element.label}
                style={{ height: 100, fontSize: 14, padding: 4, width: '96%', fontWeight: 500, boxSizing: 'border-box' }}
                value={this.getDefaultText(element, this.getFieldByType(element, fieldName))}
                onChange={e => onChange(e, element, this.getFieldByType(element, fieldName))}
            />
        )
    }

    renderFileInput(onSelectFile) {
        return <input
            type="file"
            accept=".png,.jpg,.jpeg,.bitmap"
            onChange={onSelectFile}
            style={{
                opacity: 1,
                fontSize: 12,
                color: "rgb(255, 255, 255)",
                border: "none",
                marginLeft: 4,
                marginTop: 10,
            }}
        />
    }

    getFieldByType(element, fieldName) {
        if(element !== undefined) {
            if(fieldName === 'title') {
                return { fieldName: 'title' }
            }
            switch (element.type) {
                case AdditionalTableType.qrCode:
                    return { fieldName: 'qrCode' }
                case AdditionalTableType.qrCodeWithText:
                    if(fieldName === AdditionalTableType.customText) {
                        return { fieldName: 'text' }
                    }
                    return { fieldName: 'qrCode' }
                case AdditionalTableType.image:
                    return { fieldName: 'image' }
                case AdditionalTableType.imageWithText:
                    if (fieldName === AdditionalTableType.customText) {
                        return { fieldName: 'text' }
                    }
                    return { fieldName: 'image' }
                case AdditionalTableType.amountInWords:
                case AdditionalTableType.subTotalInWords:
                case AdditionalTableType.cgstInWords:
                case AdditionalTableType.igstInWords:
                case AdditionalTableType.sgstInWords:
                case AdditionalTableType.roundOffInWords:
                case AdditionalTableType.outstandingBalanceInWords:
                case AdditionalTableType.outstandingBalanceIncludesCurrentInWords:
                case AdditionalTableType.totalTaxAmountInWords:
                case AdditionalTableType.beforeTaxInWords:
                case AdditionalTableType.globalDiscountInWords:
                case AdditionalTableType.additionalChargeInWords:
                    return undefined
                case AdditionalTableType.total:
                    return undefined
                case AdditionalTableType.outstandingBalance:
                    return undefined
                case AdditionalTableType.outstandingBalanceIncludesCurrent:
                    return undefined
                case AdditionalTableType.customText:
                    return { fieldName: 'text' }
                default:
                    return { fieldName: undefined }
            }
        }
        return undefined
    }

    getDefaultText(element, field) {
        if (field !== undefined || field !== null) {
            if (field.fieldName !== undefined && field.fieldName !== null) {
                if(field.fieldName === 'title') {
                    if (element[field.fieldName] === undefined) {
                        return getLocalisedText(element.label)
                    }
                }
                if(element[field.fieldName] !== undefined) {
                    return element[field.fieldName]
                }
            }
        }
        return ''
    }

    onTextChanged(event, item, field, itemIndex) {
        var newData = this.state.data
        var value = event.target.value
        newData.forEach((element, index) => {
            if (item.type === element.type && itemIndex === index ) {
                if (field !== undefined && field !== null) {
                    if (field.fieldName !== undefined && field.fieldName !== null) {
                        if (item[field.fieldName] === undefined) {
                            element[field.fieldName] = ''
                        }
                        element[field.fieldName] = value
                    }
                }
            }
        });
        this.setState({
            data: newData
        })
    }

    onIsSelectedChanged(item) {
        var newData = this.state.data

        newData.forEach(element => {
            if (item.type === element.type) {
                item.isSelected = !item.isSelected
            }
        });
        this.setState({
            data: newData
        })
    }

    onSelectedNewTableType(event) {
        var newType = event.target.value
        if (newType === undefined || newType === null) {
            return
        }

        this.setState({
            selectedType: newType
        })
    }

    onAddNewTableType() {
        if(this.state.selectedType === undefined || this.state.selectedType === null) {
            return
        }

        var newType = this.state.selectedType
        var newData = this.state.data

        if (newData === undefined || newData === null) {
            newData = []
        }

        var item = this.getDefaultAdditionalTableList().find(x => x.type.toLowerCase() === newType.toLowerCase())
        if (item !== undefined) {
            newData.push(item)
        }

        newData.forEach((element, index) => {
            element.index = index
        });

        this.setState({
            data: newData,
        })
    }

    onNewFileClick(event, selectedIndex) {
        console.log('onNewFileClick')
        var newData = this.state.data
        if(newData === undefined || newData === null) {
            return
        }
        if(newData.length === 0) {
            return
        }

        newData.forEach((element, index) => {
            if(selectedIndex === index) {
                var field = this.getFieldByType(element, undefined)
                if(field !== undefined && field !== null) {
                    if(field.fieldName !== undefined && field.fieldName !== null) {
                        if (event.target.files && event.target.files.length > 0) {
                            let file = event.target.files[0];
                            if (file.size > (IMG_MAX_UPLOAD_SIZE)) {
                                showToast(getLocalisedText("MAX_IMG_UPLOAD_ALERT"), TOAST_TYPE.WARNING);
                                return
                            }
                            const reader = new FileReader()
                            let self = this;
                            reader.onload = function (e) {
                                console.log(reader.result)
                                element[field.fieldName] = reader.result
                                self.setState({
                                    data: newData
                                })
                            }
                            reader.onerror = (e) => {
                                console.error(e)
                            }
                            reader.readAsDataURL(file);
                        }
                    }
                }
            }
        });
    }


    addCustomField(customField) {
        if (customField !== undefined) {
            var newData = this.state.data
            var newRowItem = {}
            newRowItem.isSelected = true
            newRowItem.code = customField.code
            newRowItem.type = AdditionalTableType.customField
            newRowItem.index = this.state.data.length
            newRowItem.label = getLocalisedText(customField.label)
            newRowItem.originalLabel = customField.originalLabel
            newData.push(newRowItem)
            this.setState({
                data: newData,
            })
        }
    }


    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    saveButtonTapped = () => {

        var newData = this.state.data
        newData.forEach((element, index) => {
            // if(element.type === AdditionalTableType.qrCode || element.type === AdditionalTableType.qrCodeWithText) {
            //     let qrCodeCanvas = document.getElementById(HTMLTag.QR_CODE_PREFIX + index )
            //     if(qrCodeCanvas !== undefined && qrCodeCanvas !== null) {
            //         console.log(qrCodeCanvas)
            //         let canvasDataUrl = qrCodeCanvas.toDataURL('image/png')
            //         if(canvasDataUrl !== undefined) {
            //             element.generatedQR = canvasDataUrl
            //         }
            //     }
            // }
            if(element.title === undefined) {
                element.title = getLocalisedText(element.label)
            }
        });



        var additionalTable = TemplateSettingsManager.defaultTemplateSettings.additionalTable
        if(additionalTable === undefined && additionalTable === null)  {
            additionalTable = TemplateSettingsManager.getDefaultAdditionalTableConfiguration()
        }

        additionalTable.elements = newData
        additionalTable.border = this.state.tableBorder
        TemplateSettingsManager.updateAdditionalTable(additionalTable)
        this.props.onClosePressed()
    }
}
