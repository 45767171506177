import React, { Component } from "react";
import "../App.css";
// import ComponentManager from "../Manager/ComponentManager";
// import { COLOR_LIGHTGRAY, DESKERA_URL } from "../Constants/Constants";
import DeskeraSuitesPopup from "./Popup/DeskeraSuitesPopup";
// import Utility, { getFontSize } from '../Utilities/Utility';
// import AppManager from "../Manager/AppManager";
// import { getLocalisedText } from '../Translate/LanguageManager';
import ShareLinkPopup from "./Popup/ShareLinkPopup/ShareLinkPopup";
// import ApiConstants from "../API/ApiConstants";
import SaveTemplateSelectionPopup from "./Popup/SaveTemplateSelectionPopup";
// import TemplateSettingsManager from "../Manager/TemplateSettingsManager";

import {
    ComponentManager,
    Utility,
    getFontSize,
    AppManager,
    getLocalisedText,
    ApiConstants,
    TemplateSettingsManager,
    getLanguageFlagIcons,
    Asset,

    COLOR_LIGHTGRAY,
    DESKERA_URL
} from "deskera-doc-builder-lib";

export default class HeaderMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            needLanguageSelectionPopup: false,
            needSavePopup: false,
            needDeskeraSuitesPopup: false,
            needExportPopup: false,
            sharableURL: this.props.sharableURL,
            urlCopied: false,
            userLoggedIn: this.props.userLoggedIn,
            generatingFile: this.props.generatingFile,
            currentLanguage: this.props.currentLanguage,
            isSavingTemplate: this.props.isSavingTemplate,
            isDownloadingPDF: this.props.isDownloadingPDF,
            isViewPdf: this.props.isViewPdf,
            isPrintingPDF: this.props.isPrintingPDF,
            isEmailPDF: this.props.isEmailPDF,
            isGeneratingShareableLink: this.props.isGeneratingShareableLink,
            isFromERP: this.props.isFromERP,
            isFromSharing: this.props.isFromSharing,
            currentDocumentData: this.props.currentDocumentData,
            isPreviewingPdf: this.props.isPreviewingPdf,
            isPreviewMode: this.props.isPreviewMode
        };
    }

    componentDidMount() {
        if (this.state.isDownloadingPDF === true) {
            this.props.onPdfExport()
        } else if (this.state.isPrintingPDF === true) {
            this.props.onPrint()
        } else if (this.state.isEmailPDF === true) {
            this.props.onEmail()
        } else if (this.state.isViewPdf === true) {
            this.props.viewPdf()
        }
        else if (this.state.isPreviewingPdf === true) {
            this.props.onPreviewPdf()
        }
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            // selectedTemplateID: nextProp.selectedTemplateID,
            sharableURL: nextProp.sharableURL,
            urlCopied: false,
            userLoggedIn: nextProp.userLoggedIn,
            generatingFile: nextProp.generatingFile,
            currentLanguage: nextProp.currentLanguage,
            isSavingTemplate: nextProp.isSavingTemplate,
            isDownloadingPDF: nextProp.isDownloadingPDF,
            isViewPdf: nextProp.isViewPdf,
            isPrintingPDF: nextProp.isPrintingPDF,
            isGeneratingShareableLink: nextProp.isGeneratingShareableLink,
            isFromERP: nextProp.isFromERP,
            isFromSharing: nextProp.isFromSharing,
            currentDocumentData: nextProp.currentDocumentData,
            isPreviewingPdf: nextProp.isPreviewingPdf,
        });
        if (nextProp.isTappedOutside) {
            this.setState({
                // needTemplateSelectionPopup: false,
                needExportPopup: false,
                needDeskeraSuitesPopup: false,
                needLanguageSelectionPopup: false,
                needSavePopup: false,
            });
        }
    }

    render() {
        return (
            <div
                className="DropShadow RowDiv"
                style={{
                    height: 55,
                    zIndex: 11,
                    justifyContent: "space-between",
                    position: "relative",
                    backgroundColor: 'white',
                }}
            >
                <div className="RowDiv">
                    <img
                        src={Asset.icon.deskLogoNew}
                        alt=""
                        style={{height: 28, borderRadius: 6, marginLeft: 20, cursor: "pointer"}}
                        onClick={this.logoTapped}
                    />
                    {!Utility.isDevice() &&
                    <text style={{fontSize: 13, fontWeight: "bold", marginLeft: 11, marginTop: 4, color: 'gray'}}>
                        {getLocalisedText('document_generator')}
                    </text>
                    }
                </div>
                <div className="RowReverseDiv" style={{marginRight: 20}}>
                    {!this.state.isFromSharing && this.renderLanguageButton()}
                    {!this.state.userLoggedIn && this.renderUserLoggedInIcon()}
                    {/* Do not remove the Following */}
                    {/*{!this.state.userLoggedIn && !Utility.isDevice() && ComponentManager.getButton('try_deskera', "rgb(211, 16, 17)", 'white', this.tryDeskeraTapped)}*/}
                    {/*{this.state.userLoggedIn && !Utility.isDevice() && ComponentManager.getButton('go_to_deskera', "rgb(211, 16, 17)", 'white', this.gotoDeskeraTapped)}*/}
                    {/*{ComponentManager.addHorizontalSpace(10)}*/}
                    {!this.state.isFromSharing && this.state.userLoggedIn && this.renderSaveButton()}
                    {/* {!this.state.isFromSharing && this.state.userLoggedIn && !this.state.isFromERP && this.renderShareButton()} */}
                    {/* {!this.state.isPreviewMode && this.renderPreviewPdfButton()} */}
                    {/* {!this.state.isFromERP && this.renderPrintButton()} */}
                    {/* {!this.state.isFromERP && this.renderDownloadButton()} */}
                    {/* {!this.state.isFromERP && this.renderEmailButton()} */}
                    {this.renderHeaderPage()}
                </div>
                {this.state.needExportPopup && <ShareLinkPopup currentDocumentType={this.props.currentDocumentType}
                                                               currentDocumentCode={this.props.currentDocumentCode}
                                                               currentDocumentData={this.state.currentDocumentData}
                                                               isGeneratingShareableLink={this.props.isGeneratingShareableLink}
                                                               onClose={this.clickedOutside}/>}
                {this.state.needDeskeraSuitesPopup && this.getDeskeraSuitesPopup()}
                {this.state.needLanguageSelectionPopup && this.getLanguageSelectionPopup()}
                {this.state.needSavePopup && <SaveTemplateSelectionPopup
                    onCancelTapped={() => this.setState({needSavePopup: false})}
                    onSubmitClicked={(action) => this.saveTapped(action)}/>}
            </div>
        );
    }

    renderEmailButton() {
        return <>
            {ComponentManager.getButton(Utility.isDevice() ? null : this.state.generatingFile ? 'please_wait' : 'email', COLOR_LIGHTGRAY, 'black', this.props.onEmail, Asset.icon.ic_email)}
            {ComponentManager.addHorizontalSpace(10)}
        </>;
    }

    renderDownloadButton() {
        return <>
            {ComponentManager.getButton(this.state.isDownloadingPDF ? 'downloading' : 'download', COLOR_LIGHTGRAY, 'black', this.props.onPdfExport, Asset.icon.ic_download)}
            {ComponentManager.addHorizontalSpace(10)}
        </>;
    }

    renderPreviewPdfButton() {
        return <>
            {ComponentManager.getButton(this.state.isPreviewingPdf ? 'please_wait' : 'preview_pdf', COLOR_LIGHTGRAY, 'black', this.props.onPreviewPdf, Asset.icon.ic_preview_pdf)}
            {ComponentManager.addHorizontalSpace(10)}
        </>;
    }

    renderPrintButton() {
        return <>
            {ComponentManager.getButton(this.state.isPrintingPDF ? 'printing' : 'print', COLOR_LIGHTGRAY, 'black', this.props.onPrint, Asset.icon.ic_printer)}
            {ComponentManager.addHorizontalSpace(10)}
        </>;
    }

    renderLanguageButton() {
        return<>
            {ComponentManager.getLanguageSelectionButton(this.state.currentLanguage, this.selectLanguageButtonTapped)}
            {ComponentManager.addHorizontalSpace(10)}
        </>
    }


    renderSaveButton() {
        return<>
            {ComponentManager.getSaveButton(this.saveButtonTapped)}
            {ComponentManager.addHorizontalSpace(10)}
        </>
    }

    renderUserLoggedInIcon() {
        return<>
            {ComponentManager.getImageButton(Asset.icon.ic_user, this.gotoDeskeraTapped, 0, 22)}
            {ComponentManager.addHorizontalSpace(10)}
        </>
    }

    renderShareButton() {
        return<>
            {ComponentManager.getButton("share", COLOR_LIGHTGRAY, 'black', this.exportTapped, Asset.icon.ic_share)}
            {ComponentManager.addHorizontalSpace(10)}
        </>
    }
    clickedOutside = () => {
        this.props.onExport()
        this.setState({
            needDeskeraSuitesPopup: false,
            needExportPopup: false,
            needLanguageSelectionPopup: false,
        });
    }

    tryDeskeraTapped = () => {
        Utility.openInNewTab(DESKERA_URL)
    }

    gotoDeskeraTapped = () => {
        Utility.openInNewTab(ApiConstants.URL.BASE_WITHOUT_VERSION)
    }

    themePaletteTapped = () => {
        this.setState({
            needDeskeraSuitesPopup: false,
            needExportPopup: false,
            needLanguageSelectionPopup: false,
        });
    };

    saveTapped = (action) => {
        // this.props.onExport()
        TemplateSettingsManager.setIsSaveAsNewTemplate(false)
        if(action === 'save_as') {
            TemplateSettingsManager.setIsSaveAsNewTemplate(true)
        }
        this.setState({
            // needTemplateSelectionPopup: false,
            needSavePopup: false,
            needDeskeraSuitesPopup: false,
            needExportPopup: false,
            needLanguageSelectionPopup: false,
        }, () => {
            this.props.onSave();
        });
    };

    exportTapped = () => {
        this.props.onExport()
        this.setState({
            needExportPopup: !this.state.needExportPopup,
            needDeskeraSuitesPopup: false,
            needLanguageSelectionPopup: false,
        });
    };

    selectLanguageButtonTapped = () => {
        this.props.onExport()
        this.setState({
            needLanguageSelectionPopup: !this.state.needLanguageSelectionPopup,
            needDeskeraSuitesPopup: false,
            // needTemplateSelectionPopup: false,
            needExportPopup: false,
        }, );
    }


    saveButtonTapped = () => {
        // this.props.onExport()
        this.setState({
            needLanguageSelectionPopup: false,
            needSavePopup: !this.state.needSavePopup,
            needDeskeraSuitesPopup: false,
            // needTemplateSelectionPopup: false,
            needExportPopup: false,
        }, );
    }

    logoTapped = () => {
        this.props.onLogo()
        this.closeDeskeraSuitePopup()
    };

    // getExportPopup() {
    //     return (
    //         <div
    //             className="ShadowMedium ColumnDiv"
    //             style={{
    //                 backgroundColor: "white",
    //                 borderRadius: 8,
    //                 position: "absolute",
    //                 top: 65,
    //                 right: 120,
    //                 padding: 20,
    //                 width: 400,
    //                 zIndex: 200,
    //             }}
    //         >
    //             <img
    //                 src={Asset.icon.ic_upArrow_1}
    //                 alt=""
    //                 style={{ width: 25, position: "absolute", top: -13, right: 195 }}
    //             />
    //             <div className="RowDiv" style={{alignItems: 'center'}}>
    //                 <img
    //                     src={Asset.icon.ic_web}
    //                     alt=""
    //                     style={{ width: 38, marginRight: 5 }}
    //                 />
    //                 <div className="ColumnDiv" style={{justifyContent: 'center'}}>
    //                     <text style={{fontSize: 14, fontWeight: "bold"}}>{getLocalisedText('share_on_the_web')}</text>
    //                     {ComponentManager.addVerticalSpace(4)}
    //                     <text style={{fontSize: 13, color: 'gray', textAlign: 'left'}}>{getLocalisedText('anyone_with_the_below_link_can_view')}</text>
    //                 </div>
    //             </div>
    //
    //             {ComponentManager.addVerticalSpace(15)}
    //
    //
    //             <div className="RowDiv" style={{fontSize: 13,whiteSpace: 'nowrap'}}>
    //                 <div style={{color: 'rgb(100, 100, 100)', width: '100%', padding: 10, borderRadius: 6, backgroundColor: 'rgb(235, 235, 235)', textAlign: 'left', overflow: "hidden", textOverflow: 'ellipsis',marginRight: 10, }}>
    //                     <input type="text" value={this.state.sharableURL} id="myInput" style={{ fontSize: 13, color: 'rgb(100, 100, 100)', outline: 'none', width: '100%',
    //                         boxSizing: "border-box", border: 'none', backgroundColor:this.state.urlCopied === false ? 'transparent' : COLOR_BLUE+'1A',}}/>
    //                 </div>
    //                 <div style={{cursor: 'pointer', color: COLOR_BLUE}} onClick={this.copyToClipboard}>
    //                     {getLocalisedText('copy_link')}
    //                 </div>
    //             </div>
    //
    //             {ComponentManager.addVerticalSpace(25)}
    //             <div style={{width: '100%', height: getVW(1), backgroundColor: 'lightGray', opacity: 0.3}}/>
    //             {ComponentManager.addVerticalSpace(20)}
    //
    //             {this.getOtherShareOptions()}
    //         </div>
    //     );
    // }

    getLanguageSelectionPopup() {
        return (
            <div
                className="ShadowMedium ColumnDiv"
                style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    position: "absolute",
                    top: 65,
                    right: 20,
                    padding: 20,
                    // paddingBottom: getVW(30),
                    width: 200,
                    zIndex: 200,
                }}
            >
                <img
                    src={Asset.icon.ic_upArrow_1}
                    alt=""
                    style={{ width: 25, position: "absolute", top: -13, right: 20 }}
                />
                <div className="ColumnDiv" style={{fontSize: 13, width: '95%'}}>
                    {this.getLanguageSelectionOptions()}
                </div>
            </div>
        );
    }

    getLanguageSelectionOptions() {
        let arr = [];
        let list = AppManager.getLanguageOptions()
        if (Utility.getIsSaudiArabiaCountry(this.props.currentDocumentData) && Utility.getIsProductDocument(this.props.currentDocumentData)) {
            list.push({ name: 'Arabic', code: 'ar', flag: 'ar' })
        }

        for (let i = 0; i < list.length; i++) {
            arr.push(ComponentManager.getClickableOptionsRow(getLanguageFlagIcons(list[i].flag), false,list[i].name, null , () => this.languageSelected(list[i])));
        }
        return arr;
    }

    languageSelected = (selectedLanguage) => {
        this.props.onExport()
        this.setState({
            needLanguageSelectionPopup: !this.state.needLanguageSelectionPopup,
            currentLanguage: selectedLanguage}, () => {this.props.onLanguageSelectionChanged(selectedLanguage)})
    }

    getDeskeraSuitesPopup() {
        return (
            <DeskeraSuitesPopup onDeskeraSuitesOptionTapped={() => this.deskeraSuitesOptionTapped()}/>
        );
    }

    // getOtherShareOptions() {
    //     return (
    //         <div className="ColumnDiv" style={{fontSize: 13, width: '95%'}}>
    //             <text style={{color: 'gray', fontWeight: 'bold'}}>{getLocalisedText('share_on_the_web')}</text>
    //             {ComponentManager.addVerticalSpace(15)}
    //             {ComponentManager.getClickableOptionsRow(require("../Assets/exportIcons/ic_pdf.png"), false,'download_as_pdf', 'download' , this.props.onPdfExport)}
    //             {ComponentManager.getClickableOptionsRow(require("../Assets/exportIcons/ic_print.png"),false, "print_this_document", "print", this.props.onPrint)}
    //             {ComponentManager.getClickableOptionsRow(require("../Assets/exportIcons/ic_mail.png"),false, "send_email_to_clients", "email", this.props.onEmail)}
    //             {/*{this.getOtherShareOptionRow(require("./Assets/exportIcons/ic_html.png"), "Get HTML page", "Download", this.props.onPdfExport)}*/}
    //         </div>
    //     )
    // }

    // copyToClipboard = () => {
    //     var copyText = document.getElementById("myInput");
    //     copyText.select();
    //     copyText.setSelectionRange(0, 99999)
    //     document.execCommand("copy");
    //
    //     this.setState({
    //         urlCopied: true
    //     })
    // };

    deskeraSuitesOptionTapped = () => {
        this.props.onDeskeraSuitesOptionTapped()
        this.closeDeskeraSuitePopup()
    }

    closeDeskeraSuitePopup = () => {
        this.setState({
            needDeskeraSuitesPopup: !this.state.needDeskeraSuitesPopup,
            needExportPopup: false,
            // needTemplateSelectionPopup: false,
            needLanguageSelectionPopup: false,
        });
    }

    renderHeaderPage = () => {
        return <>
            <div
                style={{
                    // paddingRight: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    cursor: "pointer",
                    outline: "none",
                }}
                onClick={() => this.goToHelpPage()}
            >
                <div className="RowDiv">
                    <div className="RowDiv" style={{ paddingRight: 5, paddingLeft: 5 }}>
                        <text className={"fontStyle" + getFontSize()} style={{
                            fontSize: 13,
                            // height: 15,
                            overflow: 'hidden',
                        }}>
                            {getLocalisedText('help')} ?
                        </text>
                    </div>
                </div>
            </div>
            {/* {ComponentManager.addHorizontalSpace(10)} */}
        </>
    }

    goToHelpPage = () => {
        let url = window.location.origin + "/help"
        window.open(
            url,
            "_blank"
        );
    }

}



