import React, { Component } from 'react'
// import ComponentManager from '../../Manager/ComponentManager';
// import CustomFieldManager from '../../Manager/CustomFieldManager';
// import { getLocalisedText } from '../../Translate/LanguageManager';
// import Utility from '../../Utilities/Utility';

import {
    ComponentManager,
    CustomFieldManager,
    getLocalisedText,
    Utility,
    Asset
} from 'deskera-doc-builder-lib';

export default class CustomFieldPopupComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAddButtonClick: false,
            selectedCustomField: undefined,
        }
    }

    getAvailableCustomFields() {
        var customFieldList = CustomFieldManager.getActiveCustomFields()
        var existingCustomFieldList = this.props.existingCustomFields.filter(x => x.type === 'customField')
        if (existingCustomFieldList.length > 0) {
            var customFieldCodes = existingCustomFieldList.map(x => x.code)
            customFieldList = customFieldList.filter(x => !customFieldCodes.includes(x.code))
        }
        return customFieldList
    }

    render() {
        return (
            <div className='RowDiv'>
                <div style={{
                    textAlign: 'left',
                    paddingLeft: 13,
                    paddingRight: 13,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontSize: 13,
                    fontWeight: 'bold',
                    width: '100%',
                }}>
                    <div className='RowDiv'>
                        <div className='RowDiv'>
                            {getLocalisedText('custom_field')}
                        </div>
                        <div className='RowDiv' style={{
                            justifyContent: 'flex-end'
                        }}>
                            {ComponentManager.getButton(getLocalisedText('add'), `rgba(55, 115, 225, 1.0)`, 'white', () => this.onAddButtonClick())}
                        </div>
                    </div>
                    {this.state.isAddButtonClick && this.renderCustomFieldSelectionSection()}
                </div>
            </div>
        )
    }

    renderCustomFieldSelectionSection() {
        return <div className='RowDiv'>
            <div className='RowDiv' style={{
                width: 'auto',
                paddingRight: 13,
            }}>
                {getLocalisedText('selection')}
            </div>
            <div className='RowDiv'>
                {this.renderCustomListPicker()}
            </div>
            <div className='RowDiv' style={{
                justifyContent: 'flex-end',
                width: 'auto',
                paddingLeft: 13,
            }}>
                {ComponentManager.getButton(getLocalisedText('confirm'), `rgba(55, 115, 225, 1.0)`, 'white', () => this.onConfirmButtonClick())}
            </div>
        </div>
    }

    renderCustomListPicker() {
        var customFieldList = []
        customFieldList.push({code: '-', label: '-' })
        this.getAvailableCustomFields().forEach(element => {
            customFieldList.push(element)
        });
        var optionList = customFieldList.map(element => {
            return <option value={element.code} selected={false}>
                {getLocalisedText(element.label)}
            </option>
        });
        return (
            <div className="ListPickerHolderStyle" style={{
                margin: 0,
            }}>
                <select
                    onChange={(e) => this.customFieldChanged(e)}
                    className="TextField ListPicker"
                    style={this.getPickerStyle()}
                >
                    {optionList}
                </select>
                <img
                    className="ListPickerArrowStyle"
                    src={Asset.icon.ic_listPickerArrow2}
                    alt=""
                />
            </div>
        );
    }

    onAddButtonClick() {
        this.setState({
            isAddButtonClick: true
        })
    }

    onConfirmButtonClick() {
        if (this.state.selectedCustomField !== undefined) {
            var selectedCustomField = this.state.selectedCustomField
            this.setState({
                isAddButtonClick: false,
                selectedCustomField: undefined,
            })
            this.props.addCustomField(selectedCustomField)
        }
        this.setState({
            isAddButtonClick: false,
        })
    }

    customFieldChanged(e) {
        var customFieldList = this.getAvailableCustomFields()
        if(customFieldList !== undefined && customFieldList !== null) {
            if(customFieldList.length > 0) {
                var customField = customFieldList.find(x => x.code === e.target.value)
                this.setState({
                    selectedCustomField: customField
                })
            }
        }
    }

    getPickerStyle() {
        if (Utility.getIsArabicLang()) {
            return {
                textAlignLast: 'right',
                direction: 'rtl',
                paddingRight: 24,
            }
        }
        return {}
    }

}
