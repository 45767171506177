import { TableColumnPopupType } from "../Constants/Constants";

export default class LineItemsGlobalDiscountParser {
    static getIsContainsGlobalDiscount(documentData) {
        if (documentData !== undefined && documentData !== null) {
            let lineItems = documentData.lineItems || []
            let isContains = false
            lineItems.forEach(lineItem => {
                if (!isContains) {
                    if (lineItem.additionalCharges !== undefined && lineItem.additionalCharges !== null) {
                        if (lineItem.additionalCharges.globalDiscount !== undefined && lineItem.additionalCharges.globalDiscount !== null) {
                            isContains = true
                        }
                        if (lineItem.additionalCharges.globalDiscounts !== undefined && lineItem.additionalCharges.globalDiscounts !== null) {
                            isContains = true
                        }
                    }
                }
            });
            return !isContains
        }
        return true
    }

    static getGlobalDiscountList(documentData) {
        var list = []
        var accountCodeList = []
        if (documentData !== undefined && documentData !== null) {
            let lineItems = documentData.lineItems
            lineItems.forEach(lineItem => {
                if (lineItem.additionalCharges !== undefined && lineItem.additionalCharges !== null) {
                    if (lineItem.additionalCharges.globalDiscount !== undefined && lineItem.additionalCharges.globalDiscount !== null) {
                        if (!accountCodeList.includes(lineItem.additionalCharges.globalDiscount.accountCode)) {
                            list.push(this.convertProductGlobalDiscount(lineItem.additionalCharges.globalDiscount))
                            accountCodeList.push(lineItem.additionalCharges.globalDiscount.accountCode)
                        }
                    }
                    if (lineItem.additionalCharges.globalDiscounts !== undefined && lineItem.additionalCharges.globalDiscounts !== null) {
                        lineItem.additionalCharges.globalDiscounts.forEach(element => {
                            if(!accountCodeList.includes(element.id)) {
                                list.push(this.convertProductGlobalDiscount(element))
                                accountCodeList.push(element.id)
                            }
                        })
                    }
                }
            })
        }
        return list
    }

    static convertProductGlobalDiscount(element) {
        var item = {}
        item.globalDiscountAccountCode = element.id
        item.accountCode = element.accountCode
        item.label = element.name
        item.type = TableColumnPopupType.productGlobalDiscount
        item.isSelected = false
        return item
    }
}