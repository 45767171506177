import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
import PopupHeaderComponent from "./PopupHeaderComponent";
import CustomFieldTablePopupRow from "./CustomFieldTablePopupRow";

import {
    TemplateSettingsManager,
    getLocalisedText,
    ComponentManager,
    documentLabelPosition,
    DocumentLabelUtility,
} from 'deskera-doc-builder-lib'
import { PageCountFormattingType } from "deskera-doc-builder-lib";
import { PageCountPosition } from "deskera-doc-builder-lib";
import { getTextAlign } from "deskera-doc-builder-lib";

export default class PageCountPopup extends Component {

    pageCountFormattingList = [
        { index: 0, label: getLocalisedText('single_digit'), type: PageCountFormattingType.singleDigit, isSelected: false },
        { index: 1, label: getLocalisedText('page_with_numeric'), type: PageCountFormattingType.pageWithNumeric, isSelected: false },
        { index: 2, label: getLocalisedText('page_x_of_total_page'), type: PageCountFormattingType.pageOfTotalPage, isSelected: false },
        { index: 3, label: getLocalisedText('page_x_of_total_page_slash'), type: PageCountFormattingType.pageOfTotalPageSlash, isSelected: false },
    ]

    constructor(props) {
        super(props);
        this.state = {
            pageCountFormattingList: this.getPageCountFormatting(),
            selectedPosition: TemplateSettingsManager.getPageCountSettings()?.position ?? PageCountPosition.bottomMiddle,
            pageCountString: ''
        };
    }

    getPageCountFormatting() {
        let pageCountFormatting = TemplateSettingsManager.getPageCountSettings()?.formatting ?? PageCountPosition.single_digit

        let newData = this.pageCountFormattingList
        newData.forEach(element => {
            if(element.type === pageCountFormatting) {
                element.isSelected = true
            }
            else {
                element.isSelected = false
            }
        });

        return newData
    }


    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 350,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={'page_count_settings'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.renderMainContainerSection()}
        </>;
    }

    renderMainContainerSection() {
        return <>
            <div style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                <div
                    style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 5,
                        paddingBottom: 10,
                    }}
                >
                    {this.getPreviewPanel()}
                    {this.renderPageCountFormattingSection()}
                    {this.renderPositionPicker()}
                </div>
            </div>
        </>
    }

    getFormattedString() {
        const pageCountFormatting = this.state.pageCountFormattingList.find(x => x.isSelected)?.type ?? PageCountFormattingType.singleDigit

        const maxPage = 3
        let formattedString = ''

        for (let index = 1; index <= maxPage; index++) {
            switch (pageCountFormatting) {
                case PageCountFormattingType.singleDigit:
                    formattedString += index + '<br/>'
                    break;
                case PageCountFormattingType.pageWithNumeric:
                    formattedString += 'Page ' + index + '<br/>'
                    break;
                case PageCountFormattingType.pageOfTotalPage:
                    formattedString += 'Page ' + index + ' of ' + maxPage + '<br/>'
                    break;
                case PageCountFormattingType.pageOfTotalPageSlash:
                    formattedString += 'Page ' + index + ' / ' + maxPage + '<br/>'
                    break;
                default:
                    break;
            }
        }

        return formattedString
    }

    getPageCountTextAlign() {
        const pageCountPosition = this.state.selectedPosition ?? PageCountPosition.bottomMiddle
        let textAlign = undefined
        switch (pageCountPosition) {
            case PageCountPosition.topLeft:
                textAlign = 'left'
                break;
            case PageCountPosition.topRight:
                textAlign = 'right'
                break;
            case PageCountPosition.topMiddle:
                textAlign = 'center'
                break;             
            case PageCountPosition.bottomLeft:
                textAlign = 'left'
                break;
            case PageCountPosition.bottomRight:
                textAlign = 'right'
                break;
            case PageCountPosition.bottomMiddle:
                textAlign = 'center'
                break;
            default:
                break;
        }
        return textAlign
    }

    getPreviewPanel() {
        const formattedString = this.getFormattedString()
        const textAlign = getTextAlign(this.getPageCountTextAlign())
        return <>
            <div
                style={{
                    textAlign: textAlign,
                    paddingTop: 13,
                    paddingLeft: 16,
                    paddingRight: 16,
                    // paddingTop: 5,
                    display: 'block',
                    width: '-webkit-fill-available'
                }}
                dangerouslySetInnerHTML={{ __html: formattedString }}>
            </div>
        
        </>
    }


    renderPageCountFormattingSection() {
        return this.state.pageCountFormattingList.map((item, index) => {
            return <CustomFieldTablePopupRow
                item={item}
                customFields={this.state.pageCountFormattingList}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectClick(item)}
                onUpdateLabel={undefined}
                isTextFieldFocus={true}
                handleDrag={undefined}
                handleDrop={undefined}
                onDragOver={undefined}
                onDragEnd={undefined}
                options={undefined}
                onUpdateOptions={undefined}
                isTextEditable={false}
                isRemovable={false}
                onDeleteItem={undefined}
                isResetTitle={false}
                onResetTitle={undefined}
                isNonDraggable={true}
            />
        });
    }

    onSelectClick(item) {
        var pageCountFormatting = this.state.pageCountFormattingList
        pageCountFormatting.forEach((element) => {
            if (element.type === item.type) {
                element.isSelected = true
            }
            else {
                element.isSelected = false
            }
        });
        this.setState({
            pageCountFormattingList: pageCountFormatting,
        })
    }

    renderPositionPicker() {
        return <div className="ColumnDiv pt-l pb-s"
            style={{
                paddingLeft: 13,
                paddingRight: 13,
                boxSizing: 'border-box',
            }}
        >
            <div className="RowDiv">
                {getLocalisedText('position')}
            </div>
            <div className="RowDiv pt-s">
                {getLocalisedText('top')}
            </div>
            <div className="RowDiv">
                {this.renderPortionList(documentLabelPosition.top)}
            </div>
            {ComponentManager.addVerticalSpace(60)}
            <div className="RowDiv">
                {getLocalisedText('bottom')}
            </div>
            <div className="RowDiv">
                {this.renderPortionList(documentLabelPosition.bottom)}
            </div>
        </div>
    }

    renderPortionList(portion) {
        var portionList = DocumentLabelUtility.getDefaultPositionList().filter(x => x.portion === portion)
        if (portionList.length > 0) {
            return portionList.map((element) => {
                return this.renderPositionText(element)
            });
        }
        return undefined
    }

    renderPositionText(element) {
        var position = 'center'
        if(element.label !== 'middle') {
            position = element.label
        }

        var className = 'RowDiv'
        if(element.label === 'right') {
            className = 'RowReverseDiv'
        }
        if (this.state.selectedPosition !== undefined && this.state.selectedPosition !== null) {
            if(element.type === this.state.selectedPosition) {
                return <div className={className} style={{ justifyContent: position }}>
                    {ComponentManager.getButton(element.label, 'rgba(55, 115, 225, 1.0)', 'white', () => this.onWatermarkPositionClick(element), undefined, false, false)}
                </div>
            }
        }
        return <div className={className} style={{ justifyContent: position }}>
            {ComponentManager.getButton(element.label, 'white', 'rgba(55, 115, 225, 1.0)', () => this.onWatermarkPositionClick(element), undefined, false, false)}
        </div>
    }

    onWatermarkPositionClick(element) {
        this.setState({
            selectedPosition: element.type
        })
    }


    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    saveButtonTapped = () => { 
        let settings = TemplateSettingsManager.getPageCountSettings()
        let formatting = PageCountFormattingType.singleDigit

        let format = this.state.pageCountFormattingList.find(x => x.isSelected)
        if(format) {
            formatting = format.type
        }
        settings.formatting = formatting
        settings.position =  this.state.selectedPosition
        TemplateSettingsManager.setPageCountSettings(settings)

        this.props.onClosePressed()
    }
}
