import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpSubTableConfiguration extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Sub Table Conguration')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Configure Sub-Table",
            paragraph: [
                {
                    type: 'text',
                    value: "Please go to sub-Table Configuration section from the left panel in order to hide/show sub-table. Once the sub-table is selected, the edit button will be display within the section.",
                },
                {
                    type: 'image',
                    value: require('./../asset/sub-table-configuration/sub-table-on.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "You can click on the edit icon to edit sub-table. A popup will display for select the additional content such as amount in work, qr or custom text.",
                },
                {
                    type: 'image',
                    value: require('./../asset/sub-table-configuration/sub-table-popup.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "The actions that you can perform as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Update Ttile",
                        "Add QR code",
                        "Add Custom Text",
                        "Add Image",
                        "Add Amount in words"
                    ]
                },
                {
                    type: 'image',
                    value: require('./../asset/sub-table-configuration/sub-table-type-list.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "*** Amount in words only visible for product related modules.",
                    isBold: true,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: 'Sample sub-table configuration 1',
                    isBold: true,
                },
                {
                    type: 'image',
                    value: require('./../asset/sub-table-configuration/sub-table-sample-1.png'),
                    isFullWidth: false,
                },
                {
                    type: 'image',
                    value: require('./../asset/sub-table-configuration/sub-table-sample-1-preview.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: 'Sample sub-table configuration 2',
                    isBold: true,
                },
                {
                    type: 'image',
                    value: require('./../asset/sub-table-configuration/sub-table-sample-2.png'),
                    isFullWidth: false,
                },
                {
                    type: 'image',
                    value: require('./../asset/sub-table-configuration/sub-table-sample-2-preview.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "You can hide the sub-table configuration by click on 'show' button. The result as below,",
                },
                {
                    type: 'image',
                    value: require('./../asset/sub-table-configuration/sub-table-off.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}