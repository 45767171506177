import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpTaxFieldsForAddress extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Tax Fields For Address')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "The document builder consists few India specified templates which includes additional informations such as tax breakdown tax. ",
                },
                {
                    type: 'text',
                    value: "*** Please refer to Right Section -> Prefined Template Section for all the india templates. ",
                    isBold: true,
                },
                {
                    type: 'text',
                    value: "For those India templates, the address section included state name and state code.",
                },
                {
                    type: 'image',
                    value: require('./../asset/tax-fields-for-address/state-name-and-code.png'),
                    isFullWidth: false
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "You can add other addresss field for India address such as GSTIN, PAN Number and CIN Numnber. The similar configuration is applicable for all the addresses such as company address, billing address and shipping address.",
                },
                {
                    type: 'image',
                    value: require('./../asset/tax-fields-for-address/india-address-popup.png'),
                    isFullWidth: false
                },
                {
                    type: 'text',
                    value: "Sample result,",
                },
                {
                    type: 'image',
                    value: require('./../asset/tax-fields-for-address/address-additional-address-field.png'),
                    isFullWidth: false
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}