import BaseAPIManager from "./BaseAPIManager";
import ApiConstants from "./ApiConstants";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import Utility, { getCapitalized, getIsBOMModule, getIsJobWorkOutOrder, getIsPayrollCheck, getIsPayslip, getIsQCDocument, getIsQCInspection, getIsStockIssue, getIsStockRequest, getIsWorkOrder } from "../Utilities/Utility";
import { PAPER_ORIENTATION } from "../Constants/Constants";

export default class TemplateRepository extends BaseAPIManager {

    uploadTemplateScreenShot(screenShotData, success, failure) {
        this.uploadAttachment(screenShotData, (response) => success(response), (error) => failure(error))
    }

    getIsMRPModule(templateType) {
        return (getIsWorkOrder(templateType) ||
            getIsQCDocument(templateType) ||
            getIsQCInspection(templateType) ||
            getIsJobWorkOutOrder(templateType) ||
            getIsStockRequest(templateType) ||
            getIsStockIssue(templateType) ||
            getIsBOMModule(templateType))
    }

    saveTemplateSettings(templateType, templateName, relativeImagePath, success, failure) {
        let urlString = ApiConstants.URL.BASE + ApiConstants.URL.TEMPLATE_SETTINGS
        let app = 'ERP'

        if(getIsPayslip(templateType) || getIsPayrollCheck(templateType)) {
            app = 'PEOPLE'
        }
        else if (this.getIsMRPModule(templateType)) {
            app = 'MRP'
        }
        let data = {
            appName: app,
            description: this.getPaperOrientationForSave(app, templateType),
            templateData: JSON.stringify(TemplateSettingsManager.defaultTemplateSettings),
            templateName: templateName,
            type: templateType.toUpperCase(),
            pageFormat: this.getPaperFormatForSave(),
            defaultTemplate: TemplateSettingsManager.getIsDefaultTemplate(),
        }
        this.post(urlString, data,
            (response) =>  {
                // console.log('This is a response *******' + JSON.stringify(response))
                success(response)
            },
            (error) =>  {
                alert(JSON.stringify(error))
                failure(error)
            }

        )
    }

    fetchTemplateSettings(type, success, failure) {
        var subUrl = ''
        if(type && type !== null) {
            subUrl = ApiConstants.URL.GET_ERP_TEMPLATE_SETTINGS + type
            if (getIsPayslip(type.toLowerCase()) || getIsPayrollCheck(type.toLowerCase())) {
                subUrl = ApiConstants.URL.GET_PEOPLE_TEMPLATE_SETTINGS + type
            }
            else if (this.getIsMRPModule(type.toLowerCase())) {
                subUrl = ApiConstants.URL.GET_MRP_TEMPLATE_SETTINGS + type
            }
        }

        let urlString = ApiConstants.URL.BASE + subUrl
        this.get(urlString,(response) => success(response), (error) => failure(error))
    }

    updateTemplateSettings(templateType, templateID, templateName, relativeImagePath, success, failure) {
        let urlString = ApiConstants.URL.BASE + ApiConstants.URL.TEMPLATE_SETTINGS + '/' + templateID
        let app = 'ERP'

        if (getIsPayslip(templateType) || getIsPayrollCheck(templateType)) {
            app = 'PEOPLE'
        }
        else if (this.getIsMRPModule(templateType)) {
            app = 'MRP'
        }
        let data = {
            appName: app,
            description: this.getPaperOrientationForSave(app, templateType),
            templateData: JSON.stringify(TemplateSettingsManager.defaultTemplateSettings),
            templateName: templateName,
            type: templateType.toUpperCase(),
            pageFormat: this.getPaperFormatForSave(),
            defaultTemplate: TemplateSettingsManager.getIsDefaultTemplate(),
        }
        this.put(urlString, data,
            (response) =>  {
                // console.log('This is a response *******' + JSON.stringify(response))
                success(response)
            },
            (error) => failure(error)
        )
    }

    deleteTemplateSettings(templateID, success, failure) {
        let urlString = ApiConstants.URL.BASE + ApiConstants.URL.TEMPLATE_SETTINGS + '/' + templateID
        this.delete(urlString,
            (response) => {
                success(response)
            },
            (error) => failure(error)
        )
    }

    getPaperFormatForSave() {
        let paperSize = 'A4'
        if (TemplateSettingsManager.getPageFormat() !== undefined &&
            TemplateSettingsManager.getPageFormat() !== null) {
            if (TemplateSettingsManager.getPageFormat() === 'four_six' || TemplateSettingsManager.getPageFormat() === 'three_five' ) {
                paperSize = TemplateSettingsManager.getPageFormat()
            }
            else {
                paperSize = getCapitalized(TemplateSettingsManager.getPageFormat())
            }
        }

        return paperSize
    }

    getPaperOrientationForSave(app, templateType) {
        let paperOrientation = ''
        if (Utility.getIsLandscape()) {
            paperOrientation = PAPER_ORIENTATION.landscape
        }
        else {
            paperOrientation = PAPER_ORIENTATION.portrait
        }

        return paperOrientation + "Saved Template-" + app + '-' + templateType
    }
}
