import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpDecimalPoint extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Decimal Point and Qty Decimal Point')}
                {this.renderDecimalPoint()}
                {this.renderQtyDecimalPoint()}
            </div>
        </div>
    }

    renderDecimalPoint() {
        var data = {
            header: "Decimal Point",
            paragraph: [
                {
                    type: 'text',
                    value: "You can change the decimal point for all number fields from the decimal point section.",
                },
                {
                    type: 'image',
                    value: require('./../asset/decimal-point/decimal-point.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "The actions that you can performance as as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Change the decimal point",
                        "Select/Unselect default decimal point(using tenant predifiend value)",
                    ]
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample result with 4 decimal point",
                },
                {
                    type: 'image',
                    value: require('./../asset/decimal-point/decimal-point-sample-1.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample result with default value",
                },
                {
                    type: 'image',
                    value: require('./../asset/decimal-point/decimal-point-default-value-1.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderQtyDecimalPoint() {
        var data = {
            header: "Qty - Decimal Point",
            paragraph: [
                {
                    type: 'text',
                    value: "You can change the decimal point for qty fields from the decimal point section.",
                },
                {
                    type: 'text',
                    value: "Set Qty Decimal Point to 0",
                },
                {
                    type: 'image',
                    value: require('./../asset/decimal-point/qty-decimal-point.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample result with 0 decimal point",
                },
                {
                    type: 'image',
                    value: require('./../asset/decimal-point/qty-decimal-point-0-preview.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample result with 4 decimal point",
                },
                {
                    type: 'image',
                    value: require('./../asset/decimal-point/qty-decimal-point-4-preview.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}