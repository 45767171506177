import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpQuickStartGuide extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Quick Start Guide')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Design and save a template",
            paragraph: [
                {
                    type: 'text',
                    value: "Designing a document template is made easy with Deskera Books Plus' document generator feature.",
                },
                {
                    type: 'text',
                    value: "As a small business owner, if you have limited time to create a brand new quote, invoice or invoice template, you can quickly generate these templates directly from Deskera Books Plus.",
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "To change your quote, bill, invoice, or order template, you can follow the steps below:",
                },
                {
                    type: 'text',
                    value: "1. Login to your Books+ account.",
                },
                {
                    type: 'text',
                    value: "2. Next, go to Document Modules such as Quote, Invoice, Order and etc.",
                },
                {
                    type: 'text',
                    value: "3. In the respective Module, you can click on the three dots and select design button.",
                },
                {
                    type: 'image',
                    value: require('./../asset/quick-start-guide/design.png'),
                },
                {
                    type: 'text',
                    value: "4. The system will re-direct you to Document Generator page.",
                },
                {
                    type: 'text',
                    value: "5. On the right-hand side panel, you have the option to choose various document designer templates. Choose your preferred template.",
                },
                {
                    type: 'image',
                    value: require('./../asset/quick-start-guide/document-generator.png'),
                },
                {
                    type: 'text',
                    value: "6. Once you are done designing the document template, you can click on the save template button. Click on the save button to create a new template. Enter the template name and save it. You can create multiple templates in the system.",
                },
                {
                    type: 'image',
                    value: require('./../asset/quick-start-guide/save-popup.png'),
                    isFullWidth: false
                },
                {
                    type: 'text',
                    value: "6. The saved templates will appear on the template list.",
                },
                {
                    type: 'image',
                    value: require('./../asset/quick-start-guide/template-list.png'),
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}