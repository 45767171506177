import React, { Component } from 'react'
// import { COLOR_LIGHTGRAY2 } from '../../Constants/Constants';
// import ComponentManager from '../../Manager/ComponentManager';
// import { getLocalisedText } from '../../Translate/LanguageManager'
// import Utility, { getVW } from '../../Utilities/Utility';

import {
    ComponentManager,
    Utility,
    getVW,
    getLocalisedText,
    Asset,
    COLOR_LIGHTGRAY2
} from "deskera-doc-builder-lib";

export default class SaveTemplateSelectionPopup extends Component {
    render() {
        return (
            <div
                className="ShadowMedium ColumnDiv"
                style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    position: "absolute",
                    top: 50,
                    right: 120,
                    padding: 20,
                    // paddingBottom: 30,
                    width: '10vw',
                    zIndex: 200,
                }}
            >
                <img
                    src={Asset.icon.ic_upArrow_1}
                    alt=""
                    style={{ width: 25, position: "absolute", top: -13, right: 20 }}
                />
                <div className="ColumnDiv" style={{ fontSize: 13, width: '100%', textAlign: 'left' }}
                    onMouseLeave={this.props.onCancelTapped}>
                    {/* <div className={'fontStyleBold pt-s pb-s'} style={{
                        paddingLeft: 6
                    }}>
                        {getLocalisedText('action')}
                    </div> */}
                    {this.renderSaveActionList()}
                </div>
            </div>
        )
    }


    renderSaveActionList() {
        var list = ['save', 'save_as']
        return list.map(action => {
            return this.renderSaveAction(action)
        });
    }

    renderSaveAction(action) {
        return <div className='parent-width'>
            <div
                style={{
                    // borderRadius: 4,
                    cursor: "pointer",
                    outline: "none",
                    border: "solid",
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 8,
                    paddingBottom: 8,
                    borderWidth: 2,
                    borderColor: 'white',
                }}
                onClick={() => {
                    this.props.onSubmitClicked(action)
                }}
            >
                <div className="RowDiv justify-content-between"
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                    }}>
                    <text style={{
                        fontSize: 13,
                        height: 15,
                        overflow: 'hidden'
                    }}>{getLocalisedText(action)}</text>
                </div>
            </div>
            {ComponentManager.addDivider({
                width: '100%',
                height: getVW(1),
                backgroundColor: COLOR_LIGHTGRAY2,
                opacity: 1
            })}
        </div>
    }
}
