import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpSaveTemplate extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Save Template')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "1. You can save your existing template design for print/email from book.",
                },
                {
                    type: 'image',
                    value: require('./../asset/save-template/save-template.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "2. You can select save or save as for the existing template.",
                },
                {
                    type: 'list',
                    value: [
                        "save - create/overwrite the template",
                        "save as - create a new copy of existing template"
                    ]
                },
                {
                    type: 'image',
                    value: require('./../asset/save-template/save-template-popup.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'image',
                    value: require('./../asset/save-template/save-as-template-popup.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "3. Once the template is saved, you can preview the templates from the list",
                },
                {
                    type: 'image',
                    value: require('./../asset/save-template/saved-template-list.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}