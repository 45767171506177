import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpDocumentNumberRelated extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Document Number Related')}
                {this.renderContent()}
            </div>
        </div>
    }

    renderContent() {
        var data = {
            header: "Decimal Point",
            paragraph: [
                {
                    type: 'text',
                    value: "You can hide/show the document number from the left panel. such as Invoice No.",
                },
                {
                    type: 'image',
                    value: require('./../asset/document-number/invoice-no.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "Some document consist of Link Document No which is the parent document of it",
                },
                {
                    type: 'image',
                    value: require('./../asset/document-number/link-document-no.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}