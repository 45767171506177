/* eslint-disable no-useless-concat */
import React, { Component } from "react";
import "../App.css";
// import ComponentManager from "../Manager/ComponentManager";
// import {COLOR_BLUE, COLOR_LIGHTGRAY2, DESKERA_URL, LANDING_PAGE_URL} from '../Constants/Constants';
// import Utility, {getCapitalized, getFontSizeClass, getVW} from '../Utilities/Utility';
// import ApiManager from '../API/ApiManager';
// import {getLocalisedText} from '../Translate/LanguageManager';
// import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
// import {showToast, TOAST_TYPE} from "./ToastComponent/Toast";
// import ApiConstants from "../API/ApiConstants";
// import { HTMLTag } from "../Utilities/HTMLTag";
import {
    ComponentManager,
    COLOR_BLUE,
    COLOR_LIGHTGRAY2,
    DESKERA_URL,
    LANDING_PAGE_URL,
    Utility,
    getCapitalized,
    getVW,
    getFontSizeClass,
    ApiManager,
    getLocalisedText,
    TemplateSettingsManager,
    showToast,
    TOAST_TYPE,
    
    ApiConstants,
    HTMLTag
} from "deskera-doc-builder-lib";
import { EmailComposerFieldType } from "deskera-doc-builder-lib/src/Helper/types";

///////////////////////////////////////////////////////////////
//  EmailComposerFieldType Enums
///////////////////////////////////////////////////////////////

export default class EmailComposer extends Component {
    ///////////////////////////////////////////////////////////////
    // Constructors
    ///////////////////////////////////////////////////////////////
    constructor(props) {

        super(props);
        this.state = {
            senderName: this.props.documentData.companyName,
            ownerEmail: this.props.documentData.ownerEmail ? this.props.documentData.ownerEmail : ApiConstants.USER_EMAIL,
            replyTo: this.props.documentData.ownerEmail ? this.props.documentData.ownerEmail : ApiConstants.USER_EMAIL,
            emailAddress: this.getEmailIDs(this.props.documentData.clientEmailIDs),
            subject: this.getSubject(this.props.documentData),
            messageString: this.getMessage(this.props.documentData),
            documentData: this.props.documentData,
            loading: false,
            pdfURL: this.props.pdfURL,
            isSelected: false,
            isSendCopyEmail: false,
        };
    }

    ///////////////////////////////////////////////////////////////
    // Life Cycle
    ///////////////////////////////////////////////////////////////
    componentWillReceiveProps(nextProp) {
        this.setState({
            documentData: nextProp.documentData,
            pdfURL: nextProp.pdfURL,
        }, ()=> {
        });
    }

    ///////////////////////////////////////////////////////////////
    // Render Related
    ///////////////////////////////////////////////////////////////
    render() {
        return (
            <div className="ShadowMedium WindowPopup" style={{ backgroundColor:'white', width: '40%', height: 'auto'}}
            >
                {this.renderEmailPopup()}
            </div>
        );
    }

    renderEmailPopup() {
        return (
            <div className="ColumnDiv">
                {this.renderTitleLabel(getLocalisedText('share_on_mail'))}
                {ComponentManager.addVerticalSpace(20)}
                {this.renderTitleAndInputField(EmailComposerFieldType.SENDER, getCapitalized(getLocalisedText('sender')), this.state.senderName, getLocalisedText('enter_sender_name'), (value) => {
                    this.setState({
                        senderName: value
                    })
                })}
                {this.renderTitleAndInputField(EmailComposerFieldType.RECIPIENTS, getCapitalized(getLocalisedText('to')), this.state.emailAddress, getLocalisedText('enter_recipient_email_address'), (value) => {
                    this.setState({
                        emailAddress: value
                    })
                })}
                {this.renderTitleAndInputField(EmailComposerFieldType.REPLY_TO, getCapitalized(getLocalisedText('reply_to')), this.state.replyTo, getLocalisedText('enter_reply_to_email'), (value) => {
                    this.setState({
                        replyTo: value
                    })
                })}
                {this.renderTitleAndInputField(EmailComposerFieldType.SUBJECT, getCapitalized(getLocalisedText('subject')), this.state.subject, getLocalisedText('enter_subject_here'), (value) => {
                    this.setState({
                        subject: value
                    }, () => {
                        // if (value.length !== 0) {
                        //     TemplateSettingsManager.updateDefaultEmailSubjectText(value)
                        // } else {
                        //     TemplateSettingsManager.updateDefaultEmailSubjectText(null)
                        // }

                    })
                })}
                {Utility.isValidEmail(this.state.ownerEmail) && this.renderSendACopySection(getCapitalized(getLocalisedText('send_a_copy_to_myself_at')))}
                {this.renderTitleAndInputField(EmailComposerFieldType.MESSAGE, getCapitalized(getLocalisedText('message')), this.state.messageString, getLocalisedText('enter_message_here'), (value) => {
                    this.setState({
                        messageString: value
                    })
                })}
                {ComponentManager.addVerticalSpace(20)}
                {this.renderButtons()}
            </div>
        );
    }

    renderTitleLabel(title) {
        return(
            <div className="RowDiv">
                <div className="ColumnDiv">
                    <text style={{fontSize: 16, fontWeight: "bold"}}>{title}</text>
                </div>
            </div>
        )
    }

    renderTitleAndInputField(type , title, value, placeholder, onChange) {
        return(
            <div className="ColumnDiv" style={{width: '100%'}}>
                <div className="RowDiv" style={{paddingTop: 10, paddingBottom: 10}}>
                    {(type !== EmailComposerFieldType.MESSAGE) && <text className={"fontStyleBold" + getFontSizeClass()}
                                                                        style={{fontSize: 13, color: COLOR_BLUE, marginRight: 10, whiteSpace:'nowrap'}}>{title}</text>}
                    {(type === EmailComposerFieldType.MESSAGE) ? this.renderTextArea(type, placeholder, onChange, value) : this.renderTextField(type, placeholder, onChange, value)}
                </div>
                {(type !== EmailComposerFieldType.MESSAGE) && ComponentManager.addDivider({
                    width: '100%',
                    height: getVW(1),
                    backgroundColor: COLOR_LIGHTGRAY2,
                    opacity: 1
                })}
            </div>
        )
    }

    renderButtons() {
        return(
            <div className="RowDiv" style={{justifyContent: 'space-between' }}>
                <div className="RowDiv" style={{width: '100%'}}>
                    {this.state.loading ? this.renderLoadingView() : this.renderNonLoadingView()}
                </div>
            </div>
        )
    }

    renderSendACopySection(title) {
        return (
            <div className="ColumnDiv" style={{ width: '100%' }}>
                <div className="RowDiv" style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <input type="checkbox" checked={this.state.isSendCopyEmail} style={{ cursor: "pointer", marginRight: '10px' }}
                        onClick={() => {
                            this.setState({ isSendCopyEmail: !this.state.isSendCopyEmail, })
                        }} />
                    <text className={"fontStyle" + getFontSizeClass()} style={{ fontSize: 13, whiteSpace: 'nowrap' }}>
                        {title}
                    </text>
                    <text className={"fontStyleBold" + getFontSizeClass()} style={{ fontSize: 13, color: COLOR_BLUE, fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                        &nbsp;{this.state.ownerEmail}
                    </text>
                </div>
                {ComponentManager.addDivider({
                    width: '100%',
                    height: getVW(1),
                    backgroundColor: COLOR_LIGHTGRAY2,
                    opacity: 1
                })}
            </div>
        )
    }


    ///////////////////////////////////////////////////////////////
    // renderTitleAndInputField Related
    ///////////////////////////////////////////////////////////////
    renderTextField(type, placeholder, onChange, value) {
        return <div className="RowDiv">
            <input className={"fontStyle" + getFontSizeClass()}
                type={type}
                placeholder={placeholder}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
                value={getLocalisedText(value)}
                id="myInput"
                style={{ outline: 'none', width: '100%', border: 'none', fontSize: 13, marginTop: 1 }}
            />
            {/*{(type === EmailComposerFieldType.SUBJECT) && this.renderSetAsDefaultButton()}*/}
        </div>;
    }

    renderTextArea(type, placeholder, onChange, value) {
        return (
            <div contentEditable='true'
                id={HTMLTag.EMAIL_MESSAGE}
                dangerouslySetInnerHTML={{ __html: value }}
                style={{
                    zIndex: 2, textAlign: 'left', fontSize: 12, padding: 10, width: '100%', outlineWidth: 0
                }}
            />
        )
    }

    ///////////////////////////////////////////////////////////////
    // renderButtons Related
    ///////////////////////////////////////////////////////////////
    renderLoadingView() {
        return ComponentManager.getButton(getLocalisedText('sending'), COLOR_BLUE, 'white', null)
    }

    renderNonLoadingView() {
        return (
            <div className="RowDiv">
                {ComponentManager.getButton(getLocalisedText('send'), COLOR_BLUE, 'white', this.sendButtonTapped)}
                {ComponentManager.addHorizontalSpace(10)}
                {ComponentManager.getButton(getLocalisedText('cancel'), `rgba(150,150,150, 0.25)`, 'black', this.cancelButtonTapped)}
            </div>
        )
    }

    ///////////////////////////////////////////////////////////////
    // renderTextField Related
    ///////////////////////////////////////////////////////////////
    renderSetAsDefaultButton() {
        return (
            <div className="RowReverseDiv"
                style={{width: '30%', cursor: "pointer"}}
                onClick={ () => this.setAsDefaultButtonTapped()}>
                <input type="checkbox" checked={this.getSelectedState()} style={{marginLeft: 5}} />
                <text style={{color: COLOR_BLUE, fontSize: 13}}>{getLocalisedText('set_as_default')}</text>
            </div>
        )
    }

    ///////////////////////////////////////////////////////////////
    // Helper Methods
    ///////////////////////////////////////////////////////////////


    getEmailIDs(emailArray) {
        return emailArray.join("; ")
    }

    getSubject(data) {
        return getCapitalized(data.type) + ' No ' + data.refNumber  + ' from ' + getLocalisedText(data.companyName)
    }

    getMessage(data) {
        if (data === undefined) {
            return
        }
        let messageString =  'Hi,' +
            '<br>' +
            '<br>' +
            'Thank you for reaching out to us. Please find the ' + getCapitalized(data.type) + ' [' + data.refNumber + '] attached with this mail, in response to your inquiry.' +
            '<br>' +
            '<br>' +
            'Kindly review the same and contact us for any further queries or details.' +
            '<br>' +
            '<br>' +
            '<b>'+getCapitalized(data.type) + ' No: </b>' + data.refNumber +
            '<br>' +
            '<b>Send By: </b>' + getLocalisedText(data.companyName) +
            '<br>' +
            '<b>Amount: </b>' + data.currency + ' ' + Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(data.lineItems)) +
            '<br>' +
            '<br>' +
            'We look forward to doing business with you.' +
            '<br>' +
            '<br>' +
            'Thank you, ' +
            '<br>' +
            getLocalisedText(data.companyName) +
            '<br>' +
            '<br>' +
            '<br>' +
            '<< This amazing looking ' + data.type.toLowerCase() + ' was crafted with <a href="' + DESKERA_URL + '">' + ' Deskera</a>. You can <a href="' + LANDING_PAGE_URL + '">' + ' create one too for FREE!' + '</a> >> '

        return messageString
    }

    getSelectedState() {
        return TemplateSettingsManager.defaultTemplateSettings.emailInfo.defaultEmailSubject === this.state.subject
    }

    getArticle(type) {
        if (type.toLowerCase() === 'bill' || type.toLowerCase() === 'quotation') {
            return 'a'
        }
        return 'an'
    }

    // createMessage(data) {
    //     if (data === undefined) {
    //         return
    //     }
    //     let messageString =  'Hi,' +
    //         '<br>' +
    //         '<br>' +
    //         'You have received ' + this.getArticle(data.type) + ' ' + data.type.toLowerCase() + ' from ' + getLocalisedText(data.companyName) + '. Please find the attached file.' +
    //         '<br>' +
    //         '<br>' +
    //         '<b>'+getCapitalized(data.type) + ' No: </b>' + data.refNumber +
    //         '<br>' +
    //         '<b>Send By: </b>' + getLocalisedText(data.companyName) +
    //         '<br>' +
    //         '<b>Amount: </b>' + data.currency + ' ' + Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(data.lineItems)) +
    //         '<br>' +
    //         '<br>' +
    //         'Thank you, ' +
    //         '<br>' +
    //         getLocalisedText(data.companyName) +
    //         '<br>' +
    //         '<br>' +
    //         '<br>' +
    //         '<< This amazing looking ' + data.type.toLowerCase() + ' was crafted with <a href="' + DESKERA_URL + '">' + ' Deskera</a>. You can <a href="' + LANDING_PAGE_URL + '">' + ' create one too for FREE!' + '</a> >> '
    //     let subject = getCapitalized(data.type) + ' No ' + data.refNumber  + ' send by ' + getLocalisedText(data.companyName)
    //     this.setState({
    //         messageString:messageString,
    //         subject: subject
    //     })
    // }

    ///////////////////////////////////////////////////////////////
    // Button Press Callbacks Related
    ///////////////////////////////////////////////////////////////
    sendButtonTapped = () => {
        this.setState({ loading:true })
        let attachment = new File([this.state.pdfURL], "MyDocument")
        let fileName = this.state.documentData.type +"_" + this.state.documentData.refNumber
        var sendCopy = ''
        if(this.state.isSendCopyEmail) {
            sendCopy = Utility.isValidEmail(this.state.ownerEmail) ? this.state.ownerEmail : ''
        }
        let bodyData = {
            "senderName": getLocalisedText(this.state.senderName),
            "body": document.getElementById(HTMLTag.EMAIL_MESSAGE).innerHTML,
            "replyTo": this.state.replyTo,
            "recipientEmail": this.state.emailAddress,
            "subject": this.state.subject,
            "sendCopy": sendCopy,
            "attachmentName": fileName,
            "attachment": attachment,
        }
        ApiManager.sendEmail1(this, bodyData)
    }

    cancelButtonTapped = () => {
        this.props.removeEmailPopUp()
    }

    setAsDefaultButtonTapped = () => {

        let {isSelected} = this.state
        isSelected = !isSelected

        if (isSelected === true) {
            TemplateSettingsManager.updateDefaultEmailSubjectText(this.state.subject)
        } else {
            TemplateSettingsManager.updateDefaultEmailSubjectText(null)
        }
        this.setState({isSelected: isSelected})
    }

    ///////////////////////////////////////////////////////////////
    // API Callbacks Related
    ///////////////////////////////////////////////////////////////
    emailSendWithStatue(success,  message) {
        if (success) {
            this.setState({
                loading: false
            })
            this.props.removeEmailPopUp()
            showToast(message, TOAST_TYPE.SUCCESS)
        } else {
            showToast(message, TOAST_TYPE.FAILURE)
            this.setState({
                loading: false
            })
        }
    }
}
