import React, { Component } from "react";
import "../../App.css";
// import ComponentManager from "../../Manager/ComponentManager";
import "../../CommonStyles/FontStyle.css"
// import {getLocalisedText} from '../../Translate/LanguageManager';
import PopupHeaderComponent from "./PopupHeaderComponent";

import {
    ComponentManager,
    getLocalisedText
} from 'deskera-doc-builder-lib';
export default class DeleteTemplatePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSpinnerRequired: false
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 350,
                    height: 'auto',
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.state.isSpinnerRequired ? this.getSpinner() : this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={getLocalisedText('delete_template') + ' - ' + this.props.template.templateName}
                cancelText={undefined}
                submitText={undefined}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.getMessageSection()}
        </>;
    }

    getMessageSection() {
        return <>
            <div style={{
                padding: 10,
            }}>
                <div
                    style={{
                        fontSize: 13,
                        color: "black",
                        textAlign: 'left',
                        paddingLeft: 4,
                        paddingBottom: 10,
                    }}
                >
                    {getLocalisedText('delete_template_message')}
                </div>
                <div className="RowReverseDiv" style={{ boxSizing: 'border-box' }}>
                    {ComponentManager.getButton(getLocalisedText('delete_template_ok_label'), `red`, 'white', this.props.onDeleteTemplate)}
                    {ComponentManager.addHorizontalSpace(12)}
                    {ComponentManager.getButton(getLocalisedText('cancel'), `rgba(55, 115, 225, 1.0)`, 'white', this.props.onCancelClicked)}
                </div>
            </div>
        </>
    }
}
