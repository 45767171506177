import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
// import {getLocalisedText} from '../../Translate/LanguageManager';
import PopupHeaderComponent from "./PopupHeaderComponent";

import {
    getLocalisedText,
    TemplateSettingsManager,
} from 'deskera-doc-builder-lib';
import { DKComponentListTableHeader } from "deskera-doc-builder-lib/src/DKUILibrary/DKComponentListTableHeader";
export default class BOMComponentTablePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            documentType: this.props.documentType,
            operationView: 'card',
            costView: 'card',
            columnList: []
        };
    }

    componentDidMount() {
        this.getBomTableConfiguration()
    }

    getBomTableConfiguration() {
        const BOMProductTableConfiguration = TemplateSettingsManager.getBomProductTableConfiguration()
        
        const defaultHeaderList = Object.entries(DKComponentListTableHeader.header)
        let headerList = []
        if(defaultHeaderList && defaultHeaderList !== null) {
            if(defaultHeaderList.length > 0) {
                defaultHeaderList.forEach(element => {
                    var newHeader = {...element[1]}
                    newHeader.isSelected = BOMProductTableConfiguration.columnList.find(x => x === element[1].key) !== undefined ? true : false
                    headerList.push(newHeader)
                });
            }
        }
        this.setState({
            operationView: BOMProductTableConfiguration.operationView,
            costView: BOMProductTableConfiguration.costView,
            columnList: headerList
        })
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 350,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={'bom_product_table'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.props.onClosePressed}
                onSubmitClicked={()=> this.onSaveClicked()}
            />
            {this.renderMainSection()}
        </>;
    }

    renderMainSection() {
        return <>
            <div style={{
                padding: 10,
            }}>
                <div
                    style={{
                        fontSize: 12,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 4,
                        paddingBottom: 10,
                    }}
                >
                    {this.renderHeaderRowList()}
                    {this.renderViewSelection('Operation', 'operationView')}
                    {this.renderViewSelection('Cost', 'costView')}
                </div>
            </div>
        </>
    }

    renderHeaderRowList() {
        const columnList = this.state.columnList

        if(columnList && columnList !== null) {
            if(columnList.length > 0) {
                return columnList.map((element, index) => {
                    return this.renderHeaderRow(element, index)
                });
            }
        }
    }

    renderHeaderRow(item, index) {
        return (
            <div
                id={'row_id_' + item.label}
                className={"ColumnDiv"}
                style={{ width: '100%', paddingRight: 13, boxSizing: 'border-box', position: 'relative' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                    <input type="checkbox"
                        disabled={false}
                        checked={item.isSelected}
                        style={{ cursor: "pointer" }}
                        id={'row_checkbox_id_' + item.label}
                        onClick={() => {
                            this.onIsSelectClick(item, index)
                        }} />
                    <div className="RowDiv" style={{ paddingTop: 5, paddingBottom: 5, boxSizing: 'border-box' }}>
                        {getLocalisedText(item.label)}
                    </div>
                </div>
                <div className="Line" style={{ width: '100%' }} />
            </div>
        )
    }

    onIsSelectClick(item, selectedIndex) {
        let newColumnList = this.state.columnList

        newColumnList.forEach((element, index) => {
            if (index === selectedIndex) {
                element.isSelected = !item.isSelected
            }
        });

        this.setState({
            columnList: newColumnList
        })
    }

    renderViewSelection(item, stateName) {
        const view = this.state[stateName] ?? undefined
        return (
            <div
                id={'row_id_' + item}
                className={"ColumnDiv"}
                style={{ width: '100%', paddingRight: 13, boxSizing: 'border-box', position: 'relative' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                    <div className="RowDiv" style={{ paddingTop: 5, paddingBottom: 5, boxSizing: 'border-box' }}>
                        {getLocalisedText(item)}
                    </div>
                    {this.renderViewButtonGroup(item, view, stateName)}
                </div>
                <div className="Line" style={{ width: '100%' }} />
            </div>
        )
    }

    renderViewButtonGroup(item, view, stateName) {
        return (
            <div className="RowDiv" style={{
                justifyContent: 'space-between',
                backgroundColor: 'rgb(230, 230, 230)',
                borderRadius: 5,
                width: '45%'
            }}>
                {this.renderViewButton(stateName, 'card', this.getIsSelectedValue('card', view))}
                {this.renderViewButton(stateName, 'list', this.getIsSelectedValue('list', view))}
            </div>
        )
    }

    getIsSelectedValue(viewType, selectedViewType) {
        return viewType === selectedViewType
    }

    renderViewButton(stateName, viewType, isSelected) {
        return <div className="HeaderButton"
            style={{
                color: 'rgb(50, 50, 50)',
                fontWeight: isSelected ? 'bold' : 'normal',
            }}
            onClick={() => { this.onViewButtonClicked(stateName, viewType) }}>
            <div className='RowDiv'>
                <text>{getLocalisedText(viewType)}</text>
            </div>
        </div>
    }

    onViewButtonClicked(stateName, viewType) {
        if (stateName === 'operationView') {
            this.setState({
                operationView: viewType
            })
        }
        else if (stateName === 'costView') {
            this.setState({
                costView: viewType
            })
        }
    }

    onSaveClicked() {
        let newBOMProductTableConfiguration = TemplateSettingsManager.getBomProductTableConfiguration()
        newBOMProductTableConfiguration.operationView = this.state.operationView
        newBOMProductTableConfiguration.costView = this.state.costView

        var newColumnList = []
        const selectedList = this.state.columnList.filter(x => x.isSelected)
        if(selectedList.length > 0) {
            newColumnList = selectedList.map(x => x.key)
            newBOMProductTableConfiguration.columnList = newColumnList
        }
        else {
            newBOMProductTableConfiguration.columnList = []
        }
        
        TemplateSettingsManager.setBomProductTableConfiguration(newBOMProductTableConfiguration)
        this.props.onClosePressed()
    }
}
