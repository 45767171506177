import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpCompanyLogo extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Company Logo')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Company Logo",
            paragraph: [
                {
                    type: 'text',
                    value: "You can refer to comapny logo section from the left panel. The actions that you can performance as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Logo resize",
                        "Show/Hide",
                    ]
                },
                {
                    type: 'image',
                    value: require('./../asset/company-logo/company-logo.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Logo Resize (Large)",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-logo/company-logo-large.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Logo Resize (Small)",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-logo/company-logo-small.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Hide Logo",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-logo/company-logo-hide.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}