import React, {Component} from 'react';
// import { PIXEL_TO_VW } from '../../Constants/Constants';
// import {getLocalisedText} from '../../Translate/LanguageManager';
// import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
// import ComponentManager from '../../Manager/ComponentManager';
// import { getFontSizeClass } from '../../Utilities/Utility';
import CustomTemplateListPopup from './CustomTemplateListPopup';

import {
    PIXEL_TO_VW,
    getLocalisedText,
    TemplateSettingsManager,
    ComponentManager,
    getFontSizeClass,
    Asset
} from 'deskera-doc-builder-lib'

export default class TemplatesListScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTemplateID: parseInt(this.props.selectedTemplateID),
            templates: this.props.templatesData,
            isShowCustomTemplateList: false,
            isFromERP: this.props.isFromERP,
            templateList: TemplateSettingsManager.customTemplateList.sort((a, b) => b.id - a.id)
        };
    }

    componentWillReceiveProps(nextProp) {
        var list = this.state.templateList
        if (TemplateSettingsManager.customTemplateList.length !== this.state.templateList.length) {
            list = TemplateSettingsManager.customTemplateList.sort((a, b) => b.id - a.id)
        }
        this.setState({
            // selectedTemplateID: parseInt(this.props.selectedTemplateID),
            templates: nextProp.templatesData,
            isFromERP: nextProp.isFromERP,
            templateList: list
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    render() {
        let gapX = 25;
        let gapY = 15;
        // let thumbnailWidth = 90
        // let thumbnailHeight = 150

        return (
            <div
                className="ColumnDiv"
                style={{
                    width: '100%',
                    height: '100%',
                    zIndex: 9,
                    border: "solid",
                    borderWidth: 1,
                    borderColor: 'rgb(235, 235, 235)'
                }}
            >
                {/* {this.state.isFromERP && this.renderAddCustomTemplateView()} */}
                {this.getIsRenderCustomTemplateView() && this.getCustomTemplateButton()}
                {this.state.isShowCustomTemplateList && <CustomTemplateListPopup
                    templateList={this.state.templateList}
                    onCustomTemplateTapped={(item) => this.onCustomTemplateTapped(item)}
                    onCancelTapped={() => this.setState({isShowCustomTemplateList: false})}
                    deleteTemplateItem={this.props.deleteTemplateItem}
                />
                }

                <text
                    style={{
                        width: '100%',
                        fontSize: 13,
                        marginTop: 20,
                        marginBottom: 15,
                        color: "rgb(90, 90, 90)",
                    }}
                >
                    {getLocalisedText('choose_a_template')}
                </text>
                <div
                    className="RowDiv"
                    style={{
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        overflowY: "scroll",
                        overflowX: "visible",
                        flexWrap: "wrap",
                        paddingBottom: gapY,
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                    }}
                >
                    {this.getAllTemplateThumbnails(gapX, gapY)}
                </div>
            </div>
        );
    }

    getAllTemplateThumbnails(gapX, gapY) {

        let thumbnailWidth = (350 - gapX*2)/PIXEL_TO_VW ;//140;
        // let thumbnailHeight = (((200 - gapX*2)/PIXEL_TO_VW) * 1.4)+'vw' ;
        let arr = [];
        for (let i = 0; i < this.state.templates.length; i++) {
            arr.push(
                this.getThumbnail(
                    this.state.templates[i].id,
                    this.state.templates[i].thumbnailImage,
                    thumbnailWidth,
                    i,
                    gapX,
                    gapY
                )
            );
        }
        return arr;
    }
    getThumbnail(id, image, thumbnailWidth, index, gapX, gapY) {
        return (
            <div
                key={'thumbnail_' + index}
                style={{
                    width: '75%' ,
                    paddingTop: '105%',
                    // height: '50%',
                    // marginLeft: getVW(gapX),
                    // marginRight: getVW(gapX),
                    marginTop: index === 0 ? 0 : 20,
                    position: "relative",
                }}
            >

                <text style={{zIndex: 0, left: thumbnailWidth/2.2, top: (thumbnailWidth * 1.4) / 2.2, color: 'rgb(230, 230, 230)', position: 'absolute', textAlign: 'center'}}>•••</text>
                <img
                    className="ShadowSmall"
                    src={image}
                    alt=""
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "solid",
                        borderWidth: this.props.selectedTemplateID === id ? 2 : 1,
                        borderColor:
                            this.props.selectedTemplateID === id
                                ? "rgb(30, 150, 245)"
                                : "rgb(230, 230, 230)",
                        // backgroundColor: "white",
                        cursor: "pointer",
                        position: 'absolute',
                        left: 0,
                        top: 0
                    }}
                    onClick={() => this.thumbnailTapped(id)}
                />
                {this.props.selectedTemplateID === id && (
                    <img
                        src={Asset.icon.ic_checkmarkCircle}
                        alt=""
                        style={{
                            position: "absolute",
                            top: 10,
                            right: 5,
                            width: 20,
                            height: 20,
                        }}
                    />
                )}
                {/*<text style={{left: 5, top: 5, backgroundColor: 'white', position: 'absolute'}}>{id}</text>*/}

            </div>
        );
    }

    thumbnailTapped = (id) => {
        this.setState({
            selectedTemplateID: id,
            isShowCustomTemplateList: false
        });
        this.props.onTemplateSelect(id);
        this.props.onClose()
    };


    getCustomTemplateButton() {
        return (
            <div
                className='RowDiv pl-r pt-r'
                style={{
                    cursor: "pointer",
                    outline: "none",
                    width: '75%',
                    alignSelf: 'center'
                }}
                onClick={ () => {
                    this.setState({
                        isShowCustomTemplateList: !this.state.isShowCustomTemplateList
                    })
                }}
            >
                <div className="RowDiv">
                    <div className="RowDiv" style={{ paddingRight: 5, paddingLeft: 5 }}>
                        <text className={"fontStyle" + getFontSizeClass()} style={{
                            fontSize: 13,
                            // height: 15,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            {this.getCustomTemplateName()}
                        </text>
                    </div>
                    <div className="RowDiv" style={{
                        width: 'min-content'
                    }}>
                        <img
                            className="ListPickerArrowStyle"
                            style={{ position: 'relative', top: 1, right: 0, bottom: 0 }}
                            src={Asset.icon.ic_listPickerArrow2}
                            alt=""
                        />
                    </div>

                </div>
            </div>
        )
    }

    onCustomTemplateTapped(item) {
        this.setState({
            isShowCustomTemplateList: false
        }, () => {
            if (item.id !== undefined && item.id !== null) {
                this.props.onCustomTemplateTapped(item)
            }
            else {
                this.props.onAddCustomTemplateTapped()
            }
        })
    }

    renderAddCustomTemplateView() {
        var text = "+ " + getLocalisedText('add_new_template')
        return <>
            <div className='RowDiv'
                style={{
                    marginTop: 20,
                    width: '75%',
                    alignSelf: 'center',
                }}>
                {this.renderAddCustomTemplateButton(text)}
            </div>
        </>
    }

    renderAddCustomTemplateButton(text) {
        var data = this.state.templateList.filter(x => x.id === undefined)

        if(data.length > 0) {
            return ComponentManager.getButton(text, `rgba(150, 150, 150, 0.25)`, 'black', () => {})
        }
        return ComponentManager.getButton(text, `rgba(55, 115, 225, 1.0)`, 'white', () => this.onAddCustomTemplateTapped())
    }

    onAddCustomTemplateTapped() {
        var newItem = {templateName: 'New Template'}
        var newList = []
        newList.push(newItem)
        this.state.templateList.forEach(element => {
            newList.push(element)
        });
        this.setState({
            templateList: newList,
            isShowCustomTemplateList: false
        }, () => {
            this.props.onAddCustomTemplateTapped()
        })

    }

    getIsRenderCustomTemplateView() {
        var list = this.state.templateList
        if(list === undefined) {
            return false
        }
        else if (list.length === 0) {
            return false
        }
        else {
            return true
        }
    }

    getCustomTemplateName() {
        if (this.getIsRenderCustomTemplateView()) {
            var customTemplateName = this.state.templateList.filter(x => x.id === TemplateSettingsManager.getRemoteTemplateUID())
            if(customTemplateName.length > 0) {
                return customTemplateName[0].templateName
            }
        }
        return undefined
    }
}
