import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpSupportedDocument extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Supported Document')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "The document generator supports document modules as below,",
                },
                {
                    type: 'list',
                    isSorted: false,
                    value: [
                        "Estimate",
                        "Quotation",
                        "Invoice",
                        "Order",
                        "Bill",
                        "Expense",
                        "Deposit",
                        "Credit Note",
                        "Debit Note",
                        "Fulfillment",
                        "Goods Receipt",
                        "Make Payment",
                        "Receive Payment",
                        "Journal Entry",
                        "Pick List",
                        "Pack List",
                        "Ship List",
                        "Payslip",
                        "Cheque",
                        "Payroll Check",
                    ]
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}