import React, { Component } from 'react';
import '../../DKUILibrary/DKUILibrary.css';
import CustomFieldTablePopupRow from './CustomFieldTablePopupRow';
import PopupHeaderComponent from './PopupHeaderComponent';

import {
    TemplateSettingsManager,
    getVW
} from 'deskera-doc-builder-lib';

export default class ChequeDetailsPopup extends Component {
    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            chequeFields: this.getList(),
            originalChequeFields: this.getList(),
            selectedIndex: undefined,
            height: this.getScrollingHeight(this.getList().length)
        }
    }

    render() {
        return (
            <div className='ShadowMedium WindowPopup' style={{
                width: 350,
                height: 'auto',
                backgroundColor: 'white',
                padding: 0,
                paddingBottom: 10
            }}>
                <PopupHeaderComponent
                    headerText={'cheque_fields'}
                    cancelText={'cancel'}
                    submitText={'save'}
                    onCancelClicked={this.props.onCancelClicked}
                    onSubmitClicked={() => this.onSave()}
                />
                <div
                    className="ColumnDiv"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: getVW(2),
                        height: this.state.height,
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}>
                    <div
                        id='address_table_popup'
                        className="ColumnDiv"
                        style={{
                            backgroundColor: 'white',
                            borderRadius: getVW(2),
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        {this.getOptions()}
                    </div>
                </div>
            </div>
        )
    }

    getList() {
        var rowItemList = []
        var chequeDetailsList = TemplateSettingsManager.getChequeDetailsList()
        if (chequeDetailsList.length > 0) {
            chequeDetailsList.forEach(element => {
                var newRowItem = {}
                newRowItem.index = element.index
                newRowItem.isSelected = element.isSelected
                newRowItem.type = element.type
                newRowItem.code = element.code
                newRowItem.label = element.label
                newRowItem.defaultPosition = element.defaultPosition
                newRowItem.position = element.position

                rowItemList.push(newRowItem);
            });
        }
        return rowItemList
    }

    getScrollingHeight(rowSize) {
        var defaultThreshold = 40 //default height without item level
        var innerHeight = window.innerHeight

        var rowHeight = 44
        var totalRowHeight = 0
        if (rowSize > 0) {
            totalRowHeight = rowHeight * rowSize
        }

        defaultThreshold = defaultThreshold + totalRowHeight

        if (defaultThreshold > (innerHeight * 0.8)) {
            return innerHeight * 0.8
        }
        return defaultThreshold
    }

    getOptions() {
        var sortedList = this.state.chequeFields.sort((a, b) => a.index - b.index)
        return sortedList.map((item) => {
            return <CustomFieldTablePopupRow
                item={item}
                customFields={sortedList}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectClick(item)}
                isTextFieldFocus={true}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                isTextEditable={false}
                isRemovable={false}
                isNonDraggable={true}
                isResetTitle={true}
                onResetTitle={(item) => this.onResetTitle(item)}
            />
        })
    }

    handleDrag = (ev) => {
        var startIndex = ev.currentTarget.id
        if (startIndex !== undefined && startIndex !== this.startIndex) {
            ev.dataTransfer.dropEffect = "move";
            this.startIndex = ev.currentTarget.id
        }
    };

    handleDrop = (ev) => {
        if (ev.currentTarget.id !== undefined) {
            this.onRowIndexChange(ev.currentTarget.id)
        }
    };

    onDragOver = (ev) => {
        var dragOverIndex = ev.currentTarget.id
        ev.preventDefault()
        if (dragOverIndex !== undefined) {
            if (dragOverIndex !== this.dragOverIndex && this.startIndex !== undefined) {
                this.removeTransition()
                this.addAnimation(ev.currentTarget.id, ev)
                this.dragOverIndex = dragOverIndex
            }
        }
    }

    onDragEnd = (ev) => {
        ev.preventDefault()
        this.removeTransition()
        if (this.startIndex !== undefined) {
            var startIndex = Number(this.startIndex.replace('row_id_', ''))
            this.showSelectedRow(startIndex)
            this.removeTransition()
        }
    }

    onResetTitle(item) {
        const chequeFields = this.state.chequeFields
        chequeFields.forEach(element => {
            if(element.type === item.type) {
                element.position = element.defaultPosition
            }
        });

        this.setState({
            chequeFields: chequeFields,
        }, () => {
            TemplateSettingsManager.updateChequeDetailsList(chequeFields)
            this.props.onResetItem(this.props.data)
        });
    }

    addAnimation = (targetIndex) => {
        var startIndex = undefined
        var endIndex = Number(targetIndex.replace('row_id_', ''))

        if (this.startIndex !== undefined) {
            startIndex = Number(this.startIndex.replace('row_id_', ''))
        }

        if (startIndex !== undefined && endIndex !== undefined) {
            if (startIndex !== endIndex) {
                let columnEle = document.getElementById('custom_field_table_popup');
                if (columnEle && columnEle.children) {
                    Array.from(columnEle.children).forEach((element, index) => {
                        if (endIndex > startIndex && endIndex > 0) {
                            if (index >= endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                        else if (endIndex !== 0) {
                            if (index > endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                    })
                }
            }
        }
    }

    removeTransition = () => {
        let columnEle = document.getElementById('custom_field_table_popup');
        if (columnEle && columnEle.children) {
            Array.from(columnEle.children).forEach((element, index) => {
                element.style.transform = null
                element.style.display = 'flex'
            })
        }
    }

    hideSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            if (textCol.innerHTML !== '&nbsp;') {
                this.startIndexText = textCol.innerHTML
            }
            textCol.innerHTML = '&nbsp;'
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'none'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'none'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'none'
        }
    }

    showSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            textCol.innerHTML = this.startIndexText
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'flex'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'flex'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'flex'
        }
    }

    onRowIndexChange(endIndex) {
        if (this.startIndex === undefined) {
            return
        }

        var startIndex = Number(this.startIndex.replace('row_id_', ''))
        if (this.startIndex === endIndex) {
            this.showSelectedRow(startIndex)
            this.startIndex = undefined
            return
        }

        this.showSelectedRow(startIndex)
        var newArray = []
        var chequeFields = this.state.chequeFields
        chequeFields.forEach((element) => {
            if (Number(element.index) !== startIndex) {
                newArray.push(element)
            }
        });
        var newIndex = Number(endIndex.replace('row_id_', ''))

        var sortedList = []
        var selectedItem = chequeFields.filter(x => x.index === startIndex)
        if (selectedItem.length > 0) {
            newArray.splice(newIndex, 0, selectedItem[0])
        }
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            chequeFields: [],
            selectedIndex: undefined,
        }, () => {
            this.startIndex = undefined
            this.dragIndex = undefined
            this.removeTransition()
            this.setState({
                chequeFields: sortedList,
            })
        })
    }

    onSelectClick(item) {
        var chequeFields = this.state.chequeFields

        chequeFields.forEach(element => {
            if (element.type === item.type) {
                element.isSelected = !element.isSelected
            }
        });
        this.setState({
            chequeFields: chequeFields,
        })
    }

    onRowChange(rowIndex, action) {
        var newArray = []
        var chequeFields = this.state.chequeFields
        chequeFields.forEach((element, index) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= chequeFields.length) {
                newIndex = chequeFields.length - 1
            }
        }
        var sortedList = []
        var selectedItem = chequeFields[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            chequeFields: chequeFields,
        })
    }

    onSave() {
        var data = this.props.data
        TemplateSettingsManager.updateChequeDetailsList(this.state.chequeFields)
        this.props.onSaveClicked(data)
    }
}
