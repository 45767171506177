import React, { Component } from 'react'
import "../../DKUILibrary/DKUILibrary.css"
// import CustomFieldManager from '../../Manager/CustomFieldManager';
// import TemplateSettingsManager from '../../Manager/TemplateSettingsManager';
// import { getLocalisedText } from '../../Translate/LanguageManager';
// import { getVW } from '../../Utilities/Utility';
import CustomFieldTablePopupRow from './CustomFieldTablePopupRow';
import PopupHeaderComponent from './PopupHeaderComponent';

import {
    CustomFieldManager,
    TemplateSettingsManager,
    getVW,
    getLocalisedText,    
} from 'deskera-doc-builder-lib';
import { Utility } from 'deskera-doc-builder-lib';

export default class CustomFieldTablePopup extends Component {

    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            customFields: this.getList(),
            selectedIndex: undefined,
            height: this.getScrollingHeight(this.getList().length)
        }
    }

    render() {
        return (
            <div className='ShadowMedium WindowPopup' style={{
                width: 350,
                height: 'auto',
                backgroundColor: 'white',
                padding: 0,
                paddingBottom: 10
            }}>
                <PopupHeaderComponent
                    headerText={this.getTitle()}
                    cancelText={'cancel'}
                    submitText={'save'}
                    onCancelClicked={this.props.onCancelClicked}
                    onSubmitClicked={() => this.onSave()}
                />
                <div
                    className="ColumnDiv"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: getVW(2),
                        height: this.state.height,
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}>
                    <div
                        id='custom_field_table_popup'
                        className="ColumnDiv"
                        style={{
                            backgroundColor: 'white',
                            borderRadius: getVW(2),
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        {this.getOptions()}
                    </div>
                </div>
            </div>
        )
    }

    getTitle() {
        var title = getLocalisedText('custom_field')
        if (this.props.customFieldPosition !== undefined && this.props.customFieldPosition !== null) {
            if (this.getIsTopPosition()) {
                title = title + " (" + getLocalisedText('top') + ')'
            }
            else {
                title = title + " (" + getLocalisedText('bottom') + ')'
            }
        }
        if (this.getIsChildCustomField()) {
            var childTitle = Utility.getChildCustomFieldType(this.props.data.type)

            if (childTitle !== '') {
                title = title + " (" + childTitle + ')'
            }
        }
        return title
    }

    getScrollingHeight(rowSize) {
        var defaultThreshold = 0 //default height without item level
        var innerHeight = window.innerHeight

        var rowHeight = 44
        var totalRowHeight = 0
        if (rowSize > 0) {
            totalRowHeight = rowHeight * rowSize
        }

        defaultThreshold = defaultThreshold + totalRowHeight

        if (defaultThreshold > (innerHeight * 0.8)) {
            return innerHeight * 0.8
        }
        return defaultThreshold
    }

    getList() {
        var rowItemList = []
        var draggableCustomFieldCodeList = this.getDraggableCustomFieldCodeList()
        var masterCustomFieldCodes = CustomFieldManager.getMaterCustomFieldCodes()
        var documentCustomFieldList = this.getIsTopPosition()
            ? TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList
            : TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldList

        if(documentCustomFieldList && documentCustomFieldList.length === 0) {
            var count = 0
            let data = CustomFieldManager.getCustomFields()

            if(this.getIsChildCustomField()) {
                data = CustomFieldManager.getChildCustomFields()
            }
            
            data.forEach((element, index) => {
                if (masterCustomFieldCodes.includes(element.code)) {
                    var newRowItem = {}
                    newRowItem.isSelected = draggableCustomFieldCodeList.includes(element.code)
                    newRowItem.code = element.code
                    newRowItem.index = count
                    newRowItem.label = element.label
                    newRowItem.value = element.value
                    newRowItem.type = element.type
                    newRowItem.id = element.id
                    rowItemList.push(newRowItem)
                    count = count + 1
                }
            });
        }
        else {
            //get the template list then adding other remaining from api
            var customFieldCodeList = []
            var customFieldList = [] 
            
            if (this.getIsChildCustomField()) {
                customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.childCustomFieldList
            }
            else {
                customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.customFieldList.sort((a, b) => a.index - b.index)
                if (!this.getIsTopPosition()) {
                    customFieldList = TemplateSettingsManager.defaultTemplateSettings.documentInfo.bottomCustomFieldList
                }
            }
            if (customFieldList === undefined || customFieldList === null) {
                customFieldList = []
            }

            var lastIndex = 0
            if (customFieldList.length > 0) {
                const lastElement = customFieldList[customFieldList.length - 1]
                lastIndex = lastElement.index
            }
            customFieldList.forEach((element, index) => {
                rowItemList.push(element)
                customFieldCodeList.push(element.code)
            });

            lastIndex = lastIndex + 1
            let data = CustomFieldManager.getCustomFields()

            if (this.getIsChildCustomField()) {
                data = CustomFieldManager.getChildCustomFields()
            }

            data.forEach((element, index) => {
                if (!customFieldCodeList.includes(element.code) && masterCustomFieldCodes.includes(element.code)) {
                    var newRowItem = {}
                    newRowItem.isSelected = draggableCustomFieldCodeList.includes(element.code)
                    newRowItem.code = element.code
                    newRowItem.index = lastIndex + index
                    newRowItem.label = element.label
                    newRowItem.value = element.value
                    newRowItem.type = element.type
                    newRowItem.id = element.id
                    rowItemList.push(newRowItem)
                }
            });
        }
        return rowItemList
    }

    getDraggableCustomFieldCodeList() {
        var draggableComponentList = []
        if(TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList !== undefined) {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList.length > 0) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.componentList.forEach(element => {
                    if (element.systemField !== null && element.systemField !== undefined) {
                        if (element.systemField !== '' ) {
                            draggableComponentList.push(element.systemField)
                        }
                    }
                });
            }
        }
        return draggableComponentList
    }

    getOptions() {
        var sortedList = this.state.customFields.sort((a,b) => a.index - b.index)
        return sortedList.map((item) => {
            return <CustomFieldTablePopupRow
                item={item}
                customFields={sortedList}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectClick(item)}
                onUpdateLabel={(name) => this.onUpdateLabel(item, name)}
                isTextFieldFocus={true}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                isTextEditable={item.originalLabel === undefined}
                isResetTitle={this.getIsContainEditableText(item)}
                onResetTitle={(item) => this.onResetTitle(item)}
            />
        })
    }

    getIsTopPosition() {
        var isTopPosition = true
        if (this.props.customFieldPosition !== undefined && this.props.customFieldPosition !== null) {
            if (this.props.customFieldPosition === 'bottom') {
                isTopPosition = false
            }
        }

        return isTopPosition
    }

    getIsContainEditableText(item) {
        if (item.originalLabel === undefined) {
            return true
        }
        return false
    }

    onResetTitle(item) {
        var customFields = this.state.customFields
        customFields.forEach(element => {
            if (element.code === item.code) {
                var customField = CustomFieldManager.getCustomFields().find(x => x.code === item.code)
                if (customField !== undefined) {
                    element.label = customField.label
                }
            }
        });

        this.setState({
            customFields: customFields,
        })
    }

    handleDrag = (ev) => {
        var startIndex = ev.currentTarget.id
        if(startIndex !== undefined && startIndex !== this.startIndex) {
            ev.dataTransfer.dropEffect = "move";
            this.startIndex = ev.currentTarget.id
        }
    };

    handleDrop = (ev) => {
        if (ev.currentTarget.id !== undefined) {
            this.onRowIndexChange(ev.currentTarget.id)
        }
    };

    onDragOver = (ev) => {
        var dragOverIndex = ev.currentTarget.id
        ev.preventDefault()
        if (dragOverIndex !== undefined) {
            if(dragOverIndex !== this.dragOverIndex && this.startIndex !== undefined) {
                this.removeTransition()
                this.addAnimation(ev.currentTarget.id, ev)
                this.dragOverIndex = dragOverIndex
            }
        }
    }

    onDragEnd = (ev) => {
        ev.preventDefault()
        this.removeTransition()
        if (this.startIndex !== undefined) {
            var startIndex = Number(this.startIndex.replace('row_id_', ''))
            this.showSelectedRow(startIndex)
            this.removeTransition()
        }
    }

    addAnimation = (targetIndex) => {
        var startIndex = undefined
        var endIndex = Number(targetIndex.replace('row_id_', ''))

        if(this.startIndex !== undefined) {
            startIndex = Number(this.startIndex.replace('row_id_', ''))
        }

        if (startIndex !== undefined && endIndex !== undefined) {
            if(startIndex !== endIndex) {
                let columnEle = document.getElementById('custom_field_table_popup');
                if (columnEle && columnEle.children) {
                    Array.from(columnEle.children).forEach((element, index) => {
                        if (endIndex > startIndex && endIndex > 0) {
                            if (index >= endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                        else if (endIndex !== 0) {
                            if (index > endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                    })
                }
            }
        }
    }

    removeTransition = () => {
        let columnEle = document.getElementById('custom_field_table_popup');
        if (columnEle && columnEle.children) {
            Array.from(columnEle.children).forEach((element, index) => {
                element.style.transform = null
                element.style.display = 'flex'
            })
        }
    }

    hideSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            if (textCol.innerHTML !== '&nbsp;') {
                this.startIndexText = textCol.innerHTML
            }
            textCol.innerHTML = '&nbsp;'
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'none'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'none'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'none'
        }
    }

    showSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol && this.startIndexText) {
            textCol.innerHTML = this.startIndexText
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'flex'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'flex'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'flex'
        }
    }

    onRowIndexChange(endIndex) {
        if (this.startIndex === undefined) {
            return
        }

        var startIndex = Number(this.startIndex.replace('row_id_', ''))
        if(this.startIndex === endIndex) {
            this.showSelectedRow(startIndex)
            this.startIndex = undefined
            return
        }

        this.showSelectedRow(startIndex)
        var newArray = []
        var customFields = this.state.customFields
        customFields.forEach((element) => {
            if (Number(element.index) !== startIndex) {
                newArray.push(element)
            }
        });
        var newIndex = Number(endIndex.replace('row_id_', ''))

        var sortedList = []
        var selectedItem = customFields.filter(x => x.index === startIndex)
        if(selectedItem.length > 0 ) {
            newArray.splice(newIndex, 0, selectedItem[0])
        }
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            customFields: sortedList,
            selectedIndex: undefined
        }, () => {
            this.startIndex = undefined
            this.dragIndex = undefined
            this.removeTransition()
        })
    }

    onUpdateLabel(item, name) {
        var customFields = this.state.customFields

        customFields.forEach(element => {
            if(element.code === item.code) {
                element.label = name
            }
        });

        this.setState({
            customFields: customFields
        })
    }

    onSelectClick(item) {
        var customFields = this.state.customFields

        customFields.forEach(element => {
            if (element.code === item.code) {
                element.isSelected = !element.isSelected
            }
        });

        this.setState({
            customFields: customFields
        })
    }

    onRowChange(rowIndex, action) {
        var newArray = []
        var customFields = this.state.customFields
        customFields.forEach((element, index) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= customFields.length) {
                newIndex = customFields.length - 1
            }
        }
        var sortedList = []
        var selectedItem = customFields[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            customFields: sortedList
        })
    }

    getIsChildCustomField() {
        let isChild = false
        isChild = this.props.childCustomField && this.props.childCustomField !== null
        return isChild
    }

    onSave() {
        if (this.getIsChildCustomField()) {
            TemplateSettingsManager.updateChildCustomFieldList(this.state.customFields)
        }
        else {
            if (this.getIsTopPosition()) {
                TemplateSettingsManager.updateCustomFieldList(this.state.customFields)
            }
            else {
                TemplateSettingsManager.updateBottomCustomFieldList(this.state.customFields)
            }
        }
        this.props.onSaveClicked()
    }
}
