import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpRepeatableFooter extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Repeatable Footer')}
                {this.renderRepeatableFooterDescription()}
                {this.renderTurnOnRepeatableFooter()}
                {this.renderTurnOffRepeatableFooter()}
            </div>
        </div>
    }

    renderRepeatableFooterDescription() {
        var data = {
            header: "Repeatable Footer",
            paragraph: [
                {
                    type: 'text',
                    value: "The repeatable footer allows you to print multipage and keep the table footer for each page just like Repeatable Table Header.",
                },
                {
                    type: 'text',
                    value: "*** The Repeatable Footer option is visible once Repeatable Header And Footer is selected.",
                    isBold: true
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderTurnOnRepeatableFooter() {
        var data = {
            header: "Turn on Repeatable Footer",
            paragraph: [
                {
                    type: 'text',
                    value: "You can turn on the option from the Repatable Footer. The Repeatable Footer showing 'Hide' as it already selected. The Repeatable Footer option is show if the Repeatable Header Footer is selected.",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-header-footer/repeatable-footer-on.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Once you save the template, kindly go to Book/Book+ to preview it. The preview should show table footer for each new page.",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-header-footer/repeatable-footer-on-preview.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

    renderTurnOffRepeatableFooter() {
        var data = {
            header: "Turn off Repeatable Footer",
            paragraph: [
                {
                    type: 'text',
                    value: "You can turn off the option from the Repatable Footer. The Repeatable Footer showing show as it already deselected",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-header-footer/repeatable-footer-off.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Once you save the template, kindly go to Book/Book+ to preview it. The preview should only has one footer at the last page.",
                },
                {
                    type: 'image',
                    value: require("./../asset/repeatable-header-footer/repeatable-footer-off-preview.png"),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}