import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpTermAndConditionNotes extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Term and Condition, Notes')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Signature",
            paragraph: [
                {
                    type: 'text',
                    value: "You can refer to term and condition section from the left panel. The actions that you can performance as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Show/Hide",
                        "Rename Title",
                        "Edit context",
                    ]
                },
                {
                    type: 'image',
                    value: require('./../asset/term-notes/term-notes.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Hide term and condtion, notes",
                },
                {
                    type: 'image',
                    value: require('./../asset/term-notes/term-hide.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Show term and condtion, notes",
                },
                {
                    type: 'image',
                    value: require('./../asset/term-notes/term-show.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Rename term and condtion, notes title",
                },
                {
                    type: 'image',
                    value: require('./../asset/term-notes/term-rename.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}