import React, { Component } from "react";
import "../../App.css";
// import ComponentManager from "../../Manager/ComponentManager";
import "../../CommonStyles/FontStyle.css"
// import {getCapitalized, getIsPayrollCheck} from '../../Utilities/Utility';
// import {getLocalisedText} from '../../Translate/LanguageManager';
// import PdfUtility from "../../Utilities/PdfUtility";
// import { TemplateRepository } from "../../API/TemplateRepository";
import PopupHeaderComponent from "./PopupHeaderComponent";
// import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";

import {
    ComponentManager,
    getCapitalized, getIsPayrollCheck,
    getLocalisedText,
    PdfUtility,
    TemplateRepository,
    TemplateSettingsManager,
    Asset
} from 'deskera-doc-builder-lib';

export default class SaveTemplatePopup extends Component {

    pdfUtility = new PdfUtility()
    templateRepo = new TemplateRepository()

    constructor(props) {
        super(props);
        this.state = {
            templateName: TemplateSettingsManager.getCustomTemplateName(),
            isSpinnerRequired: false,
            isDefaultTemplate: TemplateSettingsManager.getIsDefaultTemplate() !== null ? TemplateSettingsManager.getIsDefaultTemplate() : false,
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 320,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.state.isSpinnerRequired ? this.getSpinner() : this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={this.getTitle()}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={(async () => {
                    await this.saveButtonTapped()
                })}
            />
            {this.getMessageSection()}
            {this.getIsNeedDefaultOption() && this.getIsDefaultOption()}
        </>;
    }

    getMessageSection() {
        return <>
            <div style={{
                padding: 10,
            }}>
                <div
                    style={{
                        fontSize: 12,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 4,
                        paddingBottom: 10,
                    }}
                >
                    {getLocalisedText('please_enter_template_name_to_save')}
                </div>
                {this.getUserInputSection()}
            </div>
        </>
    }


    getUserInputSection() {
        return (
            <div style={{
                textAlign: 'left'
            }}>
                <input
                    className="TextFieldWithBorder"
                    type="text"
                    style={{ fontSize: 14, fontWeight: 500, width:'-webkit-fill-available'}}
                    placeholder={getLocalisedText('template_name')}
                    value={this.state.templateName}
                    onChange={e => this.handleInputChange(e)}
                />
            </div>
        )
    }

    getIsDefaultOption() {
        return (
            <div style={{
                padding: 10,
                paddingTop: 0,
                textAlign: 'left'
            }}>
                <input type="checkbox"
                    checked={this.state.isDefaultTemplate}
                    onClick={() => this.setState({ isDefaultTemplate: !this.state.isDefaultTemplate })}
                />
                {getLocalisedText('default_template')}
            </div>
        )
    }

    getIsNeedDefaultOption() {
        return !(getIsPayrollCheck(this.props.documentData.type) )
    }

    getSpinner() {
        return (
            <div style={{height: 55.5}}>
                <div className="Loading-logo" style={{opacity: 1, marginTop: 48.5}}>
                    {ComponentManager.getIcon(Asset.icon.ico_deskLogo, 20)}
                </div>
            </div>
        )
    }

    handleInputChange(e) {
        this.setState({templateName: getCapitalized(e.target.value)})
    }

    cancelButtonTapped = () => {
        TemplateSettingsManager.setIsSaveAsNewTemplate(false)
        this.props.onClosePressed()
    }

    getTitle = () => {
        if (TemplateSettingsManager.getIsSaveAsNewTemplate()) {
            return getLocalisedText('save_as_new_template')
        }
        return getLocalisedText('save_template')
    }

    async saveButtonTapped() {
        if(this.state.templateName.trim().length > 0) {
            if (TemplateSettingsManager.getIsSaveAsNewTemplate()) {
                TemplateSettingsManager.setIsSaveAsNewTemplate(false)
                TemplateSettingsManager.updateRemoteTemplateUID(undefined)
                if (this.getIsNeedDefaultOption()) {
                    TemplateSettingsManager.setIsDefaultTemplate(this.state.isDefaultTemplate)
                }
            }
            else if (this.state.isDefaultTemplate === true) {
                if (this.getIsNeedDefaultOption()) {
                    TemplateSettingsManager.setIsDefaultTemplate(this.state.isDefaultTemplate)
                }
            }
            TemplateSettingsManager.setCustomTemplateName(this.state.templateName)
            var customTemplateList = TemplateSettingsManager.getCustomTemplateList()
            customTemplateList.forEach(element => {
                if(element.id === TemplateSettingsManager.getRemoteTemplateUID()) {
                    element.templateName = this.state.templateName
                    if (this.getIsNeedDefaultOption()) {
                        element.defaultTemplate = this.state.isDefaultTemplate
                    }
                }
                else {
                    if(element.defaultTemplate) {
                        element.defaultTemplate = false
                    }
                }
            });
            TemplateSettingsManager.setCustomTemplateList(customTemplateList)
            this.props.onSaveTemplate(this.state.templateName)
        }
    }

}
