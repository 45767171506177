import React from "react";
import './helpLink.css'

export default class HelpUtil {

    static renderParagraphWithJsonData(data) {
        var paragraph = undefined

        if (data.paragraph !== undefined && data.paragraph !== null) {
            if(data.paragraph.length > 0) {
                paragraph = data.paragraph.map(element => {
                    if(element.type === 'text') {
                        return this.renderText(element.value, element.isBold)
                    }
                    else if (element.type === 'newLine') {
                        return this.renderNewLine()
                    }
                    else if (element.type === 'image') {
                        return this.renderImage(element.value, element.isFullWidth)
                    }
                    else if (element.type === 'list') {
                        return this.renderList(element.value, element.isSorted)
                    }
                    return <></>
                });
            }
        }
        return <>
            {data.header && this.renderSubheader(data.header)}
            {paragraph}
        </>
    }

    static renderParagraph(header, text) {
        return <>
            {this.renderSubheader(header)}
            {this.renderText(text)}
        </>
    }

    static renderHeader(text) {
        return <h1>
            {text}
        </h1>
    }

    static renderSubheader(text) {
        return <h2>
            {text}
        </h2>
    }

    static renderText(text, isBold) {
        return <p style={{
            fontWeight: isBold ? 'bold' : undefined,
        }}>
            {text}
        </p>
    }

    static renderNewLine() {
        return <br/>
    }

    static renderList(list, isSorted) {
        if(list === undefined || list === null) {
            return <></>
        }

        if(list.length === 0) {
            return <></>
        }

        var items = list.map((text) => {
            return <li>
                {text}
            </li>
        });

        if(isSorted) {
            return <ol>
                {items}
            </ol>
        }

        return <ul>
            {items}
        </ul>
    }

    static renderImage(value, isFullWidth) {
        var width = '100%'
        if(isFullWidth !== undefined && isFullWidth !== null) {
            if(!isFullWidth) {
                width = undefined
            }
        }
        return <img
            src={value}
            alt=""
            style={{
                width: width,
            }}
        />
    }
}