import React from 'react';
import { Constants } from './Constants';
import { WeekdayList } from 'deskera-doc-builder-lib';
import './css/DayContainer.css';
interface Props {
    dateList: string[]
    selectedDate: Date,
    selectedStartDate?: Date
    selectedEndDate?: Date
    month: number
    year: number
    isDateRange: boolean
    onSelectDate: (newDate: Date) => void
    onSelectDateRange: (newStartDate?: Date, newEndDate?: Date) => void
}

interface States {
    dayList: string[],
    itemWidthHeight: string
}
export default class DayContainer extends React.Component<Props, States> {

    constructor(props: any) {
        super(props);
        this.state = {
            dayList: WeekdayList,
            itemWidthHeight: this.getItemWidthHeight()
        };
    }

    getItemWidthHeight(): string {
        var text = Constants.calendar.width.replace('px', '')
        var width = Number(text)
        if (width === undefined || width === null) {
            return '28.5px'

        }
        else {
            return (width / 7).toFixed(2) + 'px'
        }
    }


    render() {
        return (
            <div className='dayDiv'>
                {this.renderDayList()}
                {this.renderDateList()}
            </div>
        );
    }

    renderDayList() {
        return this.state.dayList.map(element => {
            return this.renderDayItem(element)
        });
    }

    renderDateList() {
        return this.props.dateList.map(element => {
            return this.renderDateItem(element)
        });
    }

    renderDayItem(value: string) {
        return (
            <div
                className='dayItemDiv'
                style={{
                    width: this.state.itemWidthHeight,
                    padding: Constants.dayContainer.padding,
                    fontSize: Constants.dayContainer.textFontSize
                }}
            >
                <text id={value}>
                    {value}
                </text>
            </div>
        );
    }

    renderDateItem(value: string) {
        if (this.isSelectedDate(value)) {
            return (
                <div
                    style={{
                        backgroundColor: this.props.isDateRange ? Constants.dayContainer.multiSelectedColor : Constants.dayContainer.singleSelectedColor,
                        width: this.state.itemWidthHeight,
                        height: this.state.itemWidthHeight,
                        padding: Constants.dayContainer.padding,
                        color: Constants.dayContainer.selectedTextColor,
                        fontSize: Constants.dayContainer.textFontSize,
                        borderRadius: this.props.isDateRange ? undefined : '50%',
                    }}
                    className='selectedDateItemDiv'
                    onClick={() => {
                        this.onSelectDate(value)
                    }}>
                    <text
                        style={{
                            pointerEvents: 'none'
                        }}
                    >
                        {value}
                    </text>
                </div>
            );
        }
        else if (this.isToday(value)) {
            return (
                <div
                    style={{
                        backgroundColor: Constants.dayContainer.todayContainerColor,
                        width: this.state.itemWidthHeight,
                        height: this.state.itemWidthHeight,
                        padding: Constants.dayContainer.padding,
                        color: Constants.dayContainer.selectedTextColor,
                        fontSize: Constants.dayContainer.textFontSize,
                        borderRadius: '50%'
                    }}
                    className='selectedDateItemDiv'
                    onClick={() => {
                        this.onSelectDate(value)
                    }}>
                    <text
                        style={{
                            pointerEvents: 'none'
                        }}
                    >
                        {value}
                    </text>
                </div>
            );
        }
        else {
            return (
                <div
                    className='unselectedDateItemDiv'
                    style={{
                        width: this.state.itemWidthHeight,
                        height: this.state.itemWidthHeight,
                        padding: Constants.dayContainer.padding,
                        fontSize: Constants.dayContainer.textFontSize
                    }}
                    onClick={() => {
                        this.onSelectDate(value)
                    }}>
                    <text
                        style={{
                            pointerEvents: 'none'
                        }}
                    >
                        {value}
                    </text>
                </div>
            );
        }
    }

    isSelectedDate(value: string): boolean {
        if (!this.props.isDateRange) {
            if (this.props.selectedDate.getMonth() === this.props.month - 1 &&
                this.props.selectedDate.getFullYear() === this.props.year &&
                this.props.selectedDate.getDate() === Number(value)) {
                return true
            }
            else {
                return false
            }
        }
        else {
            if (value === '') {
                return false
            }
            var date = new Date(this.props.year, this.props.month - 1, Number(value))
            if (this.props.selectedStartDate !== undefined && this.props.selectedEndDate !== undefined) {

                if(this.compareDate(date, this.props.selectedStartDate)) {
                    return true
                }
                if (this.compareDate(date, this.props.selectedEndDate)) {
                    return true
                }
                if (date.valueOf() > this.props.selectedStartDate.valueOf() && date.valueOf() < this.props.selectedEndDate.valueOf()) {
                    return true
                }
            }
            return false
        }
    }

    compareDate(oldDate: Date, newDate : Date) {
        if (oldDate.getDate() === newDate.getDate()
            && oldDate.getMonth() === newDate.getMonth()
            && oldDate.getFullYear() === newDate.getFullYear())  {
                return true
            }

        return false
    }

    isToday(value: string): boolean {
        var today = new Date()
        var date = new Date(this.props.year, this.props.month - 1, Number(value))
        if (date.toDateString() === today.toDateString()) {
            return true
        }
        else {
            return false
        }
    }

    onSelectDate(value: string) {
        if (this.props.isDateRange) {
            if (value !== '') {
                var date = new Date(this.props.year, this.props.month - 1, Number(value))
                if (this.props.selectedStartDate === undefined) {
                    this.props.onSelectDateRange(date, date)
                }

                if (this.props.selectedStartDate !== undefined && this.props.selectedEndDate !== undefined) {
                    var valueOfStartDate = this.props.selectedStartDate.toLocaleString()
                    var valueOfEndDate = this.props.selectedEndDate.toLocaleString()
                    var valueOfDate = date.toLocaleString()

                    if (valueOfStartDate !== valueOfEndDate) {
                        this.props.onSelectDateRange(date, date)
                    }
                    else if (valueOfStartDate > valueOfDate) {
                        this.props.onSelectDateRange(date, date)
                    }
                    else if (valueOfStartDate > valueOfEndDate) {
                        this.props.onSelectDateRange(this.props.selectedStartDate, this.props.selectedEndDate)
                    }
                    else {
                        this.props.onSelectDateRange(this.props.selectedStartDate, date)
                    }
                    //check within the range then update end date
                    // if (valueOfDate > valueOfStartDate && valueOfDate < valueOfEndDate) {
                    //     this.props.onSelectDateRange(this.props.selectedStartDate, date)
                    // }
                    // else if (valueOfDate > valueOfStartDate && valueOfStartDate === valueOfEndDate) {
                    //     this.props.onSelectDateRange(this.props.selectedStartDate, date)
                    // }
                    // else if (valueOfDate === valueOfStartDate) {
                    //     this.props.onSelectDateRange(date, date)
                    // }
                    // else {
                    //     this.props.onSelectDateRange(undefined, undefined)
                    // }
                }
            }
            else {
                this.props.onSelectDateRange(undefined, undefined)
            }
        }
        else {
            if (value !== '') {
                this.props.onSelectDate(new Date(this.props.year, this.props.month - 1, Number(value)))
            }
        }
    }
}

