import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpThemeColor extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Theme Color')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Configure Theme Color",
            paragraph: [
                {
                    type: 'text',
                    value: "Each template has own theme color. However, you can change it from the section below,",
                },
                {
                    type: 'image',
                    value: require('./../asset/theme-color/theme-color.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample 1",
                },
                {
                    type: 'image',
                    value: require('./../asset/theme-color/theme-color-sample1.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample 2",
                },
                {
                    type: 'image',
                    value: require('./../asset/theme-color/theme-color-sample2.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample 3",
                },
                {
                    type: 'image',
                    value: require('./../asset/theme-color/theme-color-sample3.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample 1",
                },
                {
                    type: 'image',
                    value: require('./../asset/theme-color/theme-color-sample4.png'),
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}