

import { TemplateItemStyle } from "./TemplateItemStyle"
import { DocumentTemplateItem } from "./Mobile/TemplateData"
import { ScreenState } from "./Enum"

export class TemplateItem {
    constructor(type: string) {
        this.x  = 0.0
        this.y = 0.0
        this.originalWindowInnerWidth = window.innerWidth
        this.imageRatio = 100
        this.type= type
        this.style = new TemplateItemStyle(type)
    }

    init(data: DocumentTemplateItem, screenState: ScreenState) {
        this.type = data.documentType
        this.text = data.documentText
        this.newText = data.newDocumentText
        this.id = data.id
        this.x = Number(data.xPosition)
        this.y = Number(data.yPosition)
        this.width = data.width
        this.height = data.height
        this.systemField = data.systemField
        if (this.systemField !== undefined && data.documentText === '') {
            if (screenState === ScreenState.CREATE || screenState === ScreenState.EDIT) {
            this.text = data.systemField
            this.newText = data.systemField
            }
            this.width = this.setWidthForSystemField(data.systemField, data.width)
        }
        this.imagePath = data.imagePath
        this.image = data.image
        this.imageRatio = data.imageRatio
        this.isWaterMark = data.isWaterMark
        this.imgOpacity = data.imgOpacity
    }

    setWidthForSystemField(systemField: string, defaultWidth: number) {
        if (systemField === '#Invoice Number#' ||
            systemField === '#Invoice Date#' ||
            systemField === '#Due Date#') {
                return 150
            }
        else if (systemField === '#Tenant Billing Address#') {
            return 300
        }
        else {
            return defaultWidth
        }
    }

    type?: string
    text?: string
    newText?: string
    id?: number
    x: number
    y: number
    originalWindowInnerWidth: number
    systemField?: string
    style: TemplateItemStyle

    //image properties
    imagePath?: string
    image?: any //require image source
    attachmentId?: number
    width?: number
    height?: number
    imageRatio: number
    isWaterMark?: boolean
    imgOpacity?: number
}