import React, { Component } from "react";
import "../../App.css";
// import ComponentManager from "../../Manager/ComponentManager";
import "../../CommonStyles/FontStyle.css"
// import Utility, { getIsPayslip } from '../../Utilities/Utility';
// import {getLocalisedText} from '../../Translate/LanguageManager';
import PopupHeaderComponent from "./PopupHeaderComponent";
// import ApiConstants from "../../API/ApiConstants";

import {
    ComponentManager,
    Utility,
    getIsPayslip,
    getLocalisedText,
    ApiConstants
} from 'deskera-doc-builder-lib';

export default class AfterSaveTemplatePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templateName: '',
            isSpinnerRequired: false
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 350,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.state.isSpinnerRequired ? this.getSpinner() : this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>

            <PopupHeaderComponent
                headerText={'template_saved'}
                cancelText={undefined}
                submitText={undefined}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.getMessageSection()}
        </>;
    }

    getMessageSection() {
        return <>
            <div style={{
                padding: 10,
            }}>
                <div
                    style={{
                        fontSize: 12,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 4,
                        paddingBottom: 10,
                    }}
                >
                    {getLocalisedText(this.getMessage())}
                </div>
                <div className="RowReverseDiv" style={{ boxSizing: 'border-box' }}>
                    {/* {ComponentManager.getButton(getLocalisedText('go_to_books'), `rgba(55, 115, 225, 1.0)`, 'white', this.goToBooksTapped)} */}
                    {/* {ComponentManager.addHorizontalSpace(10)} */}
                    {/* {ComponentManager.getButton(getLocalisedText('cancel'), `rgba(150, 150, 150, 0.25)`, 'black', this.cancelButtonTapped)} */}
                    {ComponentManager.getButton(getLocalisedText('ok'), `rgba(55, 115, 225, 1.0)`, 'white', this.cancelButtonTapped)}
                </div>
            </div>
        </>
    }

    getMessage() {
        if (this.props.documentType !== undefined && this.props.documentType !== null) {
            if(getIsPayslip(this.props.documentType.toLowerCase())) {
                return 'you_design_template_has_been_saved_people'
            }
        }
        return 'you_design_template_has_been_saved'
    }
    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    goToBooksTapped = () => {
        this.props.onClosePressed()
        Utility.openInNewTab(ApiConstants.URL.BASE_WITHOUT_VERSION)
    }
}
