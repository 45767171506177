import React, {Component} from 'react';
import '../../App.css';
// import { getLocalisedText } from '../../Translate/LanguageManager';
// import Utility from '../../Utilities/Utility';

import {
    getLocalisedText,
    Utility
} from 'deskera-doc-builder-lib'
export default class ChequeColumnEditor extends Component {

    dataChanged(data) {
        this.props.dataChanged(this.props.data);
    }

    render() {
        return this.getChequeColumns(this.props.columnData)
    }

    getChequeColumns(columnsData = []) {
        var arr = [];
        for (let i = 0; i < columnsData.length; i++) {
            arr.push(this.getChequeColumn(i, columnsData[i]));
        }
        return arr;
    }

    getChequeColumn(index, columnData) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                }}
            >
                {this.getColumnTextfields(
                    "pay_to",
                    index,
                    columnData.payTo,
                    this.columnPayToChanged
                )}
                {this.getColumnTextfields(
                    "date",
                    index,
                    columnData.date,
                    this.columnDateChanged
                )}
                {this.getColumnTextfields(
                    "amount_in_words",
                    index,
                    columnData.amountInWords,
                    this.columnAmountInWordsChanged,
                )}
                {this.getColumnTextfields(
                    "amount",
                    index,
                    columnData.amount,
                    this.columnAmountChanged,
                )}

                <div className='RowDiv'
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                    }}>
                    <button
                        style={{
                            marginTop: 10,
                            cursor: "pointer",
                            color: "red",
                            border: "solid",
                            borderWidth: 1,
                            borderColor: "rgb(210, 210, 210)",
                            backgroundColor: "white",
                            borderRadius: 4,
                            marginBottom: 20,
                        }}
                        onClick={() => this.deleteColumn(index)}
                    >
                        {getLocalisedText("delete")}
                    </button>
                </div>
            </div>
        );
    }

    deleteColumn(index) {
        var data = this.props.data;
        data["lineItems"].splice(index, 1);
        this.dataChanged(data);
    }

    getColumnTextfields(placeholder, index, value, callback) {
        return (
            <input
                className="TextField"
                style={{
                    marginTop: 6,
                    borderRadius: 4,
                    color: 'rgb(30, 30, 30)'
                }}
                placeholder={getLocalisedText(placeholder)}
                type="text"
                dir={Utility.getInputDirection()}
                value={getLocalisedText(value)}
                onChange={(e) => callback(e, index)}
            />
        );
    }

    columnDateChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["date"] = event.target.value;
        this.dataChanged(data);
    };

    columnPayToChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["payTo"] = event.target.value;
        this.dataChanged(data);
    };

    columnAmountInWordsChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["amountInWords"] = event.target.value;
        this.dataChanged(data);
    };

    columnAmountChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["amount"] = value;
        this.dataChanged(data);
    };

}
