import React, { Component } from "react";
import "../../App.css";
// import {getLocalisedText} from "../../Translate/LanguageManager";
// import {COLOR_LIGHTGRAY} from "../../Constants/Constants";
// import Utility from "../../Utilities/Utility";

import {
    getLocalisedText,
    COLOR_LIGHTGRAY,
    Utility,
    Asset,
    AddressFieldType
} from 'deskera-doc-builder-lib';

export default class CustomFieldTablePopupRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropDownOptions: this.props.options,
            isTextFieldFocus: false,
        };
        this.updateOptionsArray()
    }

    render() {
        return (
            <div
                id={'row_id_' + this.props.item.index}
                className={"ColumnDiv"}
                onDragOver={this.props.onDragOver}
                draggable={!this.props.isNonDraggable}
                onDragStart={this.props.handleDrag}
                onDrop={this.props.handleDrop}
                onDragEnd={this.props.onDragEnd}
                style={{ width: '100%', paddingLeft: 13, paddingRight: 13, boxSizing: 'border-box', position: 'relative' }}>
                <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                    {this.getEditableSection()}
                    {(this.props.isTextEditable || this.getIsContainsOption()) && this.getIsItemTextEditable() && this.getEditButtonSection()}
                    {this.props.isRemovable && this.renderDeleteIcon()}
                    {this.props.isResetTitle && this.renderResetButton()}
                    {!this.props.isNonDraggable && this.getDragIndicator()}
                    {/* {this.renderArrowButtonBox(this.props.item.index)} */}
                </div>
                {this.renderVerticalLine()}
            </div>
        );
    }

    renderDropDownTextSection() {
        return <>
            {this.state.dropDownOptions && !this.state.isTextFieldFocus && this.getDropDown(this.removeDuplicateElement())}
            {(this.state.dropDownOptions === null || this.state.isTextFieldFocus) && this.getEditableTextField()}
        </>
    }


    getDropDown(arr) {
        return (
            <div className="row" style={{ fontSize: 14, position: 'relative', width: '100%' }}>
                <select
                    onChange={(e) => {
                        let textFieldValue = ''
                        if (e.target.value === "other") {
                            textFieldValue = ""
                            this.activateTextfield()
                        } else {
                            textFieldValue = e.target.value
                            this.props.onUpdateLabel(textFieldValue)
                        }
                    }}
                    className="TextField ListPicker"
                    style={{
                        fontSize: 14,
                        width: '97%',
                        textAlignLast: Utility.getIsArabicLang() ? 'right' : 'left',
                        direction: Utility.getIsArabicLang() ? 'rtl' : 'ltr',
                        paddingRight: Utility.getIsArabicLang() ? 24 : 0,
                    }}
                    value={getLocalisedText(this.props.item.label)}
                >
                    {arr.map(obj => {
                        return <option
                            value={obj}
                            selected={this.props.item.label === obj}
                        >
                            {getLocalisedText(obj)}
                        </option>
                    })}

                </select>
                {this.getListPickerArrow()}
            </div>
        )
    }

    //////////////////////////////////////////////////

    getListPickerArrow() {
        return (
            <img
                className="ListPickerArrowStyle"
                // style={{ top: 58, right: 25 }}
                src={Asset.icon.ic_listPickerArrow2}
                alt=""
            />
        );
    }


    renderTextOnySection() {
        return <>
            {this.state.isTextFieldFocus && this.getEditableTextField()}
            {!this.state.isTextFieldFocus && <div id={'row_text_id_' + this.props.item.index} className='TextField ListPicker' style={{
                fontSize: 14,
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
            }}>
                {this.props.item.label}
            </div>
            }
        </>
    }

    getEditableSection() {
        return(
            <div className="RowDiv" style={{paddingTop: 5, paddingBottom: 5, boxSizing: 'border-box' }}>
                {this.getCheckBox()}
                <div className="ColumnDiv" style={{width: '100%'}}>
                    {this.getIsContainsOption() && this.renderDropDownTextSection()}
                    {!this.getIsContainsOption() && this.renderTextOnySection()}
                </div>
            </div>
        )
    }

    getEditButtonSection() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: 30,
                    height: 30,
                    cursor: 'pointer',
                    backgroundColor: COLOR_LIGHTGRAY,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                id={'row_edit_id_' + this.props.item.index}
                onClick={() => {
                    this.activateTextfield()
                }}
            >
                <img src={Asset.icon.ic_edit} alt="edit" style={{width: 12}}/>
            </div>
        )
    }

    getIsContainsOption() {
        if (this.props.options !== null && this.props.options !== undefined) {
            if (this.props.options.length > 0) {
                return true
            }
        }
        return false
    }

    // To make state non editable and let it work with options
    getIsItemTextEditable() {
        if (this.props?.item?.code === AddressFieldType.state && this.props.options?.length > 0) {
            return false
        }

        return true
    }

    updateOptionsArray() {
        if (this.props.options !== null && this.props.options !== undefined) {
            let arrayToAssign = this.props.options
            if (!this.props.options.some(obj => obj === this.props.item.label || getLocalisedText(obj) === this.props.item.label)) {
                arrayToAssign.splice(arrayToAssign.length - 1, 0, this.props.item.label);
            }
            this.setState({ dropDownOptions: arrayToAssign })
        }
    }

    removeDuplicateElement() {
        var newData = []
        if (this.props.options !== undefined && this.props.options !== null) {
            if (this.props.options.length > 0) {
                let arrayToAssign = this.props.options.filter((item, index, self) => self.indexOf(item) === index);
                newData = arrayToAssign
                if (arrayToAssign !== undefined && arrayToAssign !== null) {
                    if (arrayToAssign.length > 0) {
                        if (this.props.item.label !== undefined) {
                            var newArray = [this.props.item.label]
                            arrayToAssign.filter(x => getLocalisedText(x) !== getLocalisedText(this.props.item.label)).forEach(element => {
                                newArray.push(element)
                            })
                            newData = newArray
                        }
                    }
                }
            }
        }
        return newData
    }

    getDragIndicator() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: 30,
                    height: 30,
                    cursor: 'pointer',
                    backgroundColor: COLOR_LIGHTGRAY,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 4,
                }}
                id={'row_drag_id_' + this.props.item.index}
            >
                <img src={Asset.icon.ic_drag_indicator} alt="drag" style={{ width: 16, pointerEvents: 'none' }} />
            </div>
        )
    }

    activateTextfield() {
        this.setState({ isTextFieldFocus: true }, () => {
            this.editTextField.focus()
        })
    }

    getCheckBox() {
        return (
            <input type="checkbox" 
                disabled={this.props.disabledCheckbox}
                checked={this.props.item.isSelected}
                style={{cursor: "pointer"}}
                id={'row_checkbox_id_' + this.props.item.index}
                onClick={ () => {
                    this.props.onSelectClick()
                }}/>
        )
    }

    getEditableTextField() {
        return (
            <input
                className="EditableLabelTableColumn"
                ref={inputElement => (this.editTextField = inputElement)}
                type="text"
                dir={Utility.getInputDirection()}
                name={this.props.item.label}
                style={{ fontSize: 14, padding: 9, width:'96%', fontWeight: 500, boxSizing: 'border-box' }}
                value={getLocalisedText(this.getItemName())}
                onChange={e => this.handleInputChange(e)}
                onBlur={()=>{
                    this.setState({isTextFieldFocus : false})
                }}
            />
        )
    }

    getItemName() {
        var name = this.props.item.label
        if (this.editTextField !== undefined && this.editTextField !== null) {
            if (this.editTextField.value !== undefined && this.editTextField.value !== null) {
                return this.editTextField.value
            }
        }
        return name
    }

    handleInputChange(e) {
        if (this.getIsContainsOption()) {
            this.textFieldFocusOut()
        }
        else {
            this.props.onUpdateLabel(e.target.value)
        }
    }

    textFieldFocusOut() {
        if (this.editTextField) {
            if (this.editTextField.value.length <= 0) {
                this.props.onUpdateLabel(this.editTextField.value)
                this.setState({ isTextFieldFocus: false })
            } else {
                if (getLocalisedText(this.props.item.name) === this.editTextField.value) {
                    this.setState({ isTextFieldFocus: false })
                } else {
                    // this.setState({ isTextFieldFocus: false }, () => {
                    //     this.updateOptionsArray()
                    // })
                    if (this.props.onUpdateOptions !== undefined && this.props.onUpdateOptions !== null) {
                        this.props.onUpdateOptions(this.editTextField.value)
                    }
                }
            }
        }
    }


    renderArrowButtonBox(rowIndex) {
        var arrowStyle = {
            width: 25,
            height: 25,
            cursor: 'pointer',
            backgroundColor: COLOR_LIGHTGRAY,
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 4,
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                {rowIndex > 0 &&
                    <div
                        className="ColumnDiv"
                        style={arrowStyle}
                        onClick={() => this.props.onUpdateRowIndex(rowIndex, 'up')}
                    >
                        <img
                            className='arrow-button'
                            src={Asset.icon.ic_upArrow}
                            alt={''}
                            style={{
                                width: 16,
                                height: 16,
                            }}
                        />
                    </div>
                }
                {rowIndex < this.props.customFields.length - 1 &&
                    <div
                        className="ColumnDiv"
                        style={arrowStyle}
                        onClick={() => this.props.onUpdateRowIndex(rowIndex, 'down')}
                    >
                        <img
                            className='arrow-button'
                            src={Asset.icon.ic_downArrow}
                            alt={''}
                        style={{
                            width: 16,
                            height: 16,
                        }}
                        />
                    </div>
                }
            </div>
        )
    }


    renderDeleteIcon() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: 30,
                    height: 30,
                    cursor: 'pointer',
                    backgroundColor: COLOR_LIGHTGRAY,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 4,
                }}
                id={'row_delete_id_' + this.props.item.index}
                onClick={() => {
                    this.props.onDeleteItem(this.props.item)
                }}
            >
                <img src={Asset.icon.ic_delete} alt="delete" style={{ width: 16, pointerEvents: 'none' }} />
            </div>
        )
    }

    renderResetButton() {
        return <div className="ColumnDiv"
            style={{
                width: 30,
                height: 30,
                cursor: 'pointer',
                backgroundColor: COLOR_LIGHTGRAY,
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 4,
            }}
            id={'row_reset_id_' + this.props.item.index}
            onClick={() => {
                this.props.onResetTitle(this.props.item)
            }}
        >
            <img src={Asset.icon.ic_refresh_black} alt="reset" style={{ width: 16, pointerEvents: 'none' }} />
        </div>
    }

    renderVerticalLine() {
        let marginLeft = 45
        let width = '75%'
        if (this.props.isTextEditable && this.props.isRemovable && this.props.isResetTitle) {
            marginLeft = 0
        }
        else if (this.props.isTextEditable && this.props.isRemovable) {
            marginLeft = 5
        }
        else if (this.props.isTextEditable) {
            marginLeft = 25
        }
        else if (this.getIsContainsOption()) {
            marginLeft = 25
        }
        else if (this.props.isNonDraggable && this.onResetTitle === undefined) {
            marginLeft = 25
            width = '90%'
        }
        return <div className="Line" style={{ marginLeft: marginLeft, width: width }} />
    }
}
