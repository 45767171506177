import React, {Component} from 'react'
// import ComponentManager from "../../Manager/ComponentManager";
// import Utility from "../../Utilities/Utility";
// import {getLocalisedText} from "../../Translate/LanguageManager";

import {
    ComponentManager,
    Utility,
    getLocalisedText,
    Asset
} from "deskera-doc-builder-lib";

export default class DeskeraSuitesPopup extends Component {

    componentWillReceiveProps(nextProp) {
        this.setState({
            // selectedTemplateID: parseInt(this.props.selectedTemplateID),
            templates: nextProp.templatesData
        });
    }

    render() {
        return (
            <div
                className="ShadowMedium ColumnDiv"
                style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    position: "absolute",
                    top: 65,
                    left: 13,
                    padding: 20,
                    // paddingBottom: getVW(30),
                    width: 300,
                    zIndex: 200,
                }}
            >
                <img
                    src={Asset.icon.ic_upArrow_1}
                    alt=""
                    style={{ width: 25, position: "absolute", top: -13, left: 10 }}
                />
                <text style={{fontSize: 14, fontWeight: "bold"}}>{getLocalisedText('deskera_suites')}</text>
                <div className="ColumnDiv" style={{fontSize: 13, width: '95%'}}>
                    {ComponentManager.addVerticalSpace(15)}
                    {ComponentManager.getClickableOptionsRow(Asset.icon.books, true,'deskera_books', 'try_now' , this.openDeskeraBooks)}
                    {ComponentManager.getClickableOptionsRow(Asset.icon.people, true, 'deskera_people', 'try_now', this.openDeskeraPeople)}
                    {ComponentManager.getClickableOptionsRow(Asset.icon.sales, true, 'deskera_sales', 'try_now', this.openDeskeraSales)}
                </div>
            </div>
        )
    }

    getOtherShareOptionRow(icon, text, callback) {
        return (
            <div className="RowDiv" style={{justifyContent: "space-between", cursor: "pointer", padding: 11, paddingRight: 0}} onClick={callback}>
                <div className="RowDiv" style={{alignItems: 'center', width: '80%'}}>
                    <img
                        src={icon}
                        alt=""
                        style={{ width: 25, marginRight: 10, borderRadius: 12.5 }}
                    />
                    <text style={{fontSize: 13, fontWeight: "bold"}}>{text}</text>
                </div>
                <text style={{fontSize: 13, color: 'gray'}}>
                    Try Now
                </text>
            </div>
        )
    }

    openDeskeraBooks = () => {
        this.props.onDeskeraSuitesOptionTapped()
        Utility.openInNewTab('https://www.deskera.com/books/')
    }

    openDeskeraPeople = () => {
        this.props.onDeskeraSuitesOptionTapped()
        Utility.openInNewTab('https://www.deskera.com/people/')
    }

    openDeskeraSales = () => {
        this.props.onDeskeraSuitesOptionTapped()
        Utility.openInNewTab('https://www.deskera.com/crm/')
    }
}
