import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpPrintDocumentWithSavedTemplate extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Print Document')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Print document with saved template",
            paragraph: [
                {
                    type: 'text',
                    value: "With Books+, users can print quotes, bills, orders, and even invoices documents to the respective recipients directly in the software.",
                },
                {
                    type: 'text',
                    value: "Once you have saved the document such as quote, invoice, and other, you can print the document with the customized template.",
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Follow the steps mentioned below:",
                },
                {
                    type: 'text',
                    value: "1. Go to your respective quote, order, bill, and invoice document.",
                },
                {
                    type: 'text',
                    value: "2. Click on the three dots and select print button.",
                },
                {
                    type: 'image',
                    value: require('./../asset/print-email-with-saved-template/print.png'),
                },
                {
                    type: 'text',
                    value: "3. This will open the pop-up menu.",
                },
                {
                    type: 'image',
                    value: require('./../asset/print-email-with-saved-template/print-popup.png'),
                },
                {
                    type: 'text',
                    value: "4. You can decide to print the document with default template or saved templates. Once you select the saved template, the popup reload the document accordingly.",
                },
                {
                    type: 'image',
                    value: require('./../asset/print-email-with-saved-template/print-template.png'),
                },
                {
                    type: 'image',
                    value: require('./../asset/print-email-with-saved-template/print-template1.png'),
                },
                {
                    type: 'text',
                    value: "5. You can click on the print button to navigate to print console. Once you click on print button at the bottom right, the document will send to print for printing.",
                },
                {
                    type: 'image',
                    value: require('./../asset/print-email-with-saved-template/print-console.png'),
                },
                {
                    type: 'text',
                    value: "6. You also can download the document (.pdf) locally by clicking the download button",
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}