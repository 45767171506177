import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
import PopupHeaderComponent from "./PopupHeaderComponent";
import QRCode from "react-qr-code";

import {
    getLocalisedText,
    getIsInvoice, 
    getIsQuotation,
    getIsSalesOrder, 
    getIsOrder,
    ApprovalSectionUtility,
} from 'deskera-doc-builder-lib'
import { getIsBill } from "deskera-doc-builder-lib";
import { TemplateSettingsManager } from "deskera-doc-builder-lib";
import { CustomApprovalSectionType } from "deskera-doc-builder-lib/src/Utilities/ApprovalSectionUtility";
import { Utility } from "deskera-doc-builder-lib";
import { HTMLTag } from "deskera-doc-builder-lib";
import { Asset } from "deskera-doc-builder-lib";
export default class CustomApprovalSectionPopup extends Component {

    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            data: this.getList(),
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 500,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={'approval_section'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.renderMainContainerSection()}
        </>;
    }

    getList () {
        var type = this.props.documentData.type
        if (getIsQuotation(type) ||
            getIsInvoice(type) ||
            getIsBill(type) ||
            getIsOrder(type) ||
            getIsSalesOrder(type)) {
            return ApprovalSectionUtility.getCustomApprovalSection()
        }

        return []
    }

    renderMainContainerSection() {
        return <>
            <div style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                <div
                    style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 5,
                        paddingBottom: 10,
                    }}
                >
                    {this.renderElementSection()}
                </div>
            </div>
        </>
    }

    renderElementSection() {
        return this.state.data.map((element, index) => {
            let title = element.originalLabel ?? element.label
            return (
                <div
                    id={'row_id_' + index}
                    className={"ColumnDiv"}
                    style={{ width: '100%', paddingLeft: 13, paddingRight: 13, boxSizing: 'border-box', position: 'relative' }}>
                    <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box' }}>
                        <div className="ColumnDiv parent-width" style={{ marginTop: 5, marginBottom: 5, boxSizing: 'border-box' }}>
                            <div className="RowDiv parent-width" style={{ width: '100%' }}>
                                {getLocalisedText(title)}
                            </div>
                            <div className="RowDiv parent-width" style={{ width: '100%' }}>
                                {this.renderCustomApprovalByType(element, index)}
                            </div>
                            <div className="Line parent-width" style={{ marginLeft: 0, width: '100%' }} />
                        </div>
                    </div>
                </div>
            )
        });
        
    }

    renderCustomApprovalByType(element, index) {
        const type = element.type ?? ''
        switch (type) {
            case CustomApprovalSectionType.bank:
                return this.renderItemDetailsSection(element, index)
            case CustomApprovalSectionType.qr:
                return this.renderQRCodeContainer(element, index)
            case CustomApprovalSectionType.companySeal:
                return this.renderLabelSection(element, index)
            case CustomApprovalSectionType.authorizedBy:
                return this.renderLabelSection(element, index)
            case CustomApprovalSectionType.companyInfo:
                return this.renderItemDetailsSection(element, index)
            case CustomApprovalSectionType.preparedBy:
                return this.renderLabelSection(element, index)
            case CustomApprovalSectionType.checkedBy:
                return this.renderLabelSection(element, index)
        
            default:
                return <>no type</>;
        }
    }

    renderQRCodeContainer(element, index) {
        return <>
            <div className="parent-width">
                <div className="parent-width">
                    <div className="RowDiv">
                        <div className="ColumnDiv"
                            style={{
                                width: '20%'
                            }}
                        >{getLocalisedText('qr_string')}:
                        </div>
                        <div className="ColumnDiv pt-s pb-s"
                            style={{
                                width: '80%'
                            }}>
                            {this.renderQRTextInput(element, index)}
                        </div>
                    </div>
                    {this.getIsContainQRCode(element.value) &&
                        <div className="RowDiv">
                            <div className="ColumnDiv"
                                style={{
                                    width: '20%'
                                }}
                            >
                                {getLocalisedText('generated_qr')}
                            </div>
                            <div className="ColumnDiv pt-s pb-s"
                                style={{
                                    width: '80%'
                                }}>
                                {this.renderQRCode(element.value, index)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    }

    renderQRCode(value, index) {
        if (this.getIsContainQRCode(value)) {
            return <QRCode
                id={HTMLTag.QR_CODE_PREFIX + index}
                value={value}
                size={100}
            />
        }
        return null
    }

    getIsContainQRCode(value) {
        if (value !== undefined && value !== null && value.trim().length > 0) {
            return true
        }
        return false
    }

    renderQRTextInput(element, index) {
        return (
            <input
                className="EditableLabelTableColumn"
                type="text"
                dir={Utility.getInputDirection()}
                name={element.label}
                style={{ fontSize: 14, padding: 4, width: '96%', fontWeight: 500, boxSizing: 'border-box' }}
                value={element.value ?? ''}
                onChange={e => {
                    let newData = this.state.data
                    newData.forEach((ele, eleIndex) => {
                        if (index === eleIndex) {
                            ele.value = e.target.value ?? ''
                        }
                    });
                    this.setState({
                        data: newData
                    })
                }}
                onBlur={() => {
                    this.setState({ isTextFieldFocus: false })
                }}
            />
        )
    }

    renderLabelSection(element, index) {
        return <div className="RowDiv">
            <div className="ColumnDiv"
                style={{
                    width: '20%'
                }}
            >
                {getLocalisedText('title')}:
            </div>
            <div className="RowDiv"
                style={{
                    width: '80%'
                }}>
                {this.renderLabelInput(element, index)}
                {element.originalLabel && this.renderResetButton(element, index)}
            </div>
        </div>
    }
    
    renderLabelInput(element, index) {
        return (
            <input
                className="EditableLabelTableColumn"
                type="text"
                dir={Utility.getInputDirection()}
                name={element.label}
                style={{ fontSize: 14, padding: 4, width: '96%', fontWeight: 500, boxSizing: 'border-box' }}
                value={element.label ?? ''}
                onChange={e => {
                    let newData = this.state.data
                    newData.forEach((ele, eleIndex) => {
                        if (index === eleIndex) {
                            ele.label = e.target.value ?? ''
                        }
                    });
                    this.setState({
                        data: newData
                    })
                }}
                onBlur={() => {
                    this.setState({ isTextFieldFocus: false })
                }}
            />
        )
    }

    renderResetButton(element, index) {
        return <div onClick={() => {
            let newData = this.state.data
            newData.forEach((ele, eleIndex) => {
                if (index === eleIndex) {
                    ele.label = ele.originalLabel
                }
            });
            this.setState({
                data: newData
            })
        }} className="HeaderButton" style={{
            marginRight: 5,
            display: 'flex'
        }}>
            <img src={Asset.icon.ic_refresh_black} alt="reset" style={{ width: 10 }} />
        </div>
    }


    renderItemDetailsSection(element, index) {
        return <div className="parent-width">
            <div className="parent-width">
                {element.label && this.renderLabelSection(element, index)}
                {this.renderItemSection(element, index)}
            </div>
        </div>
    }

    renderItemSection(element, index) {
        var rowData = undefined
        if(element.items && element.items !== null && element.items.length > 0) {
            rowData = element.items.map((item, itemIndex) => {
                return this.renderItemTextSection(element, index, item, itemIndex)
            });
        }
        return rowData
    }

    renderItemTextSection(element, eleIndex, item, itemIndex) {
        return <div className="RowDiv">
            {this.renderItemTextInput(element, eleIndex, item, itemIndex, true)}
            {this.renderItemTextInput(element, eleIndex, item, itemIndex, false)}
        </div>
    }

    renderItemTextInput(element, eleIndex, item, itemIndex, isLabel) {
        const value = isLabel ? item.label : item.value
        return (
            <input
                className="EditableLabelTableColumn"
                type="text"
                dir={Utility.getInputDirection()}
                name={item.label}
                style={{ fontSize: 14, padding: 4, width: '96%', fontWeight: 500, boxSizing: 'border-box' }}
                value={value}
                onChange={e => {
                    let newData = this.state.data
                    newData.forEach((ele, eleIdx) => {
                        if (eleIndex === eleIdx) {
                            ele.items.forEach((itm, itmIdx) => {
                                if (itmIdx === itemIndex) {
                                    if(isLabel) {
                                        itm.label = e.target.value ?? ''
                                    }
                                    else {
                                        itm.value = e.target.value ?? ''
                                    }
                                }
                            });
                        }
                    });
                    this.setState({
                        data: newData
                    })
                }}
                onBlur={() => {
                    this.setState({ isTextFieldFocus: false })
                }}
            />
        )
    }

    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    saveButtonTapped = () => {
        let approvalSettings = TemplateSettingsManager.getCustomApprovalSectionSettings()
        approvalSettings.sections = this.state.data
        TemplateSettingsManager.updateCustomApprovalSectionSettings(approvalSettings)

        this.props.onClosePressed()
    }
}
