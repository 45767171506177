import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpSignature extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Signature')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Signature",
            paragraph: [
                {
                    type: 'text',
                    value: "You can refer to signature section from the left panel. The actions that you can performance as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Signature resize",
                        "Show/Hide",
                    ]
                },
                {
                    type: 'image',
                    value: require('./../asset/signature/signature.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Signature Resize (Large)",
                },
                {
                    type: 'image',
                    value: require('./../asset/signature/signature-large.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Signature Resize (Small)",
                },
                {
                    type: 'image',
                    value: require('./../asset/signature/signature-small.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Hide Signature",
                },
                {
                    type: 'image',
                    value: require('./../asset/signature/signature-hide.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}