import React from 'react';
import { LongMonth, ShortMonth } from 'deskera-doc-builder-lib';
import './css/MonthContainer.css';
interface Props {
    month: number
    isLongFormat: boolean
    year: number
}

interface States {
}
export default class MonthContainer extends React.Component<Props, States> {

    render() {
        return (
            <div className='monthDiv'>
                <text>{this.getMonthString()}</text>
            </div>
        );
    }

    getMonthString(): string {
        var currentMonth = this.props.month - 1
        if (this.props.isLongFormat) {
            var mL = LongMonth
            if (currentMonth < 0 || currentMonth > mL.length) {
                return 'Error'
            }
            else {
                return mL[currentMonth] + ' ' + this.props.year
            }
        }
        else {
            var mS = ShortMonth
            if (currentMonth < 0 || currentMonth > mS.length) {
                return 'Error'
            }
            else {
                return mS[currentMonth] + ' ' + this.props.year
            }
        }
    }
}
