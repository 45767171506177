import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpMainComponent extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Main Component')}
                {this.renderTopSectionCompnent()}
                {this.renderLeftSectionCompnent()}
                {this.renderMiddleSectionCompnent()}
                {this.renderRightSectionCompnent()}
            </div>

        </div>
    }


    renderTopSectionCompnent() {
        var data = {
            header: "Top Section",
            paragraph: [
                {
                    type: 'text',
                    value: "The top section of the document builder consists of company logo, save template button, and language selection.",
                },
                {
                    type: 'image',
                    value: require('./../asset/main-component/top-section.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

    renderLeftSectionCompnent() {
        var data = {
            header: "Left Section",
            paragraph: [
                {
                    type: 'text',
                    value: "You can customize template with most of the setting from left panel such as edit text style, table padding and other.",
                },
                {
                    type: 'image',
                    value: require('./../asset/main-component/left-section.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderMiddleSectionCompnent() {
        var data = {
            header: "Middle Section",
            paragraph: [
                {
                    type: 'text',
                    value: "The middle section consists all the details of the document. User can preview the template before it saved. Besides, user can update table column, additional table and custom field from here.",
                },
                {
                    type: 'image',
                    value: require('./../asset/main-component/middle-section.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

    renderRightSectionCompnent() {
        var data = {
            header: "Right Section",
            paragraph: [
                {
                    type: 'text',
                    value: "The right section shows a list of prefined template and saved template.",
                },
                {
                    type: 'image',
                    value: require('./../asset/main-component/right-section.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}