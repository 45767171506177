import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpPredifinedTemplate extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Save Template')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "You can select the predefined template from the right panel. The left panel included 37 type of templates.",
                },
                {
                    type: 'image',
                    value: require('./../asset/predefined-template/predefined-template.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "For India tenant, addional templates is added which contains tax breakdown table.",
                },
                {
                    type: 'image',
                    value: require('./../asset/predefined-template/india-template.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}