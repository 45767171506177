import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpTable extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Table')}
                {this.renderContent()}
                {this.renderResizeContent()}
                {this.renderReorderContent()}
                {this.renderSelectOrDeSelectContent()}
                {this.renderEidtTitleContent()}
                {this.renderTotalRowContent()}
                {this.renderFooterRowContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Table Popup",
            paragraph: [
                {
                    type: 'text',
                    value: "You can edit the table content by click on the edit button top right of the table section.",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table.png"),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "Once you clicked, document builder prompt a popup for you to edit the table content.",
                },
                {
                    type: 'text',
                    value: "The actions that you can performance as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Reorder the table column",
                        "Select/Deselect table column",
                        "Edit table column title",
                        "Add total Row Column",
                        "Select/Deselect footer column",
                        "Add product custom fields",
                        "Combine product custom fields to one table column",
                    ]
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table-popup.png"),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "*** Each Module has different list of table column and footer column.",
                    isBold: true,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderResizeContent() {
        var data = {
            header: "Resize Table Column Width",
            paragraph: [
                {
                    type: 'text',
                    value: "You can change the table width by hover to the specify table column. Once it show a resize cursor, you can move your mouse left or right to reize the column width.",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_resize_width.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample Table Column resize (Large)",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_resize_large.png"),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "Sample Table Column resize (Small)",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_resize_small.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderReorderContent() {
        var data = {
            header: "Reorder Table Column",
            paragraph: [
                {
                    type: 'text',
                    value: "You can reorder the table column by hover to the reorder button. You can drag the table column up or down to reorder the it.",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_reorder.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample Reorder Table Column(Unit Price)",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_reorder_sample_1.png"),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "Sample Reorder Table Column(Description)",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_reorder_sample_2.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderSelectOrDeSelectContent() {
        var data = {
            header: "Select/Deselect Table Column",
            paragraph: [
                {
                    type: 'text',
                    value: "You can select the table column by check the checkbox for the specify table column. Meanwhile, you can deselect it by uncheck the checkbox.",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_checkbox.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample Select Table Column(Quantity and UOM, Name)",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_checkbox_sample_1.png"),
                    isFullWidth: false,
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_checkbox_sample_1_demo.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderEidtTitleContent() {
        var data = {
            header: "Edit Table Column Title",
            paragraph: [
                {
                    type: 'text',
                    value: "You can update the table column title by selecting a predifined title from the system or enter a custom title by clicking edit button.",
                },
                {
                    type: 'text',
                    value: "Select title from dropdown",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_name_dropdown.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_name_dropdown_selection.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Edit title with edit button",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_name_edit.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample result (Name and Description)",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_edit_title.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

    renderTotalRowContent() {
        var data = {
            header: "Add Total Row Section",
            paragraph: [
                {
                    type: 'text',
                    value: "You can add a total row by check the total row checkbox. Once it checked, system will display the total column that you wish to display",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_total_row.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample result",
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_total_row_sample.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_column_total_row_sample_demo.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderFooterRowContent() {
        var data = {
            header: "Edit Footer Row",
            paragraph: [
                {
                    type: 'text',
                    value: "You can select/deselect the footer column for the table. Besides, you can edit title for each footer.",
                },
                {
                    type: 'text',
                    value: "*** For tax compliance footer, document generator shows all the tax breakdown by default when the tax footer row is checked. Besides, document generator display all the global discount or additional tax by default if any.",
                    isBold: true,
                },
                {
                    type: 'text',
                    value: "*** For payment footer, kindly refer to left section -> Payment Footer.",
                    isBold: true,
                },
                {
                    type: 'image',
                    value: require("./../asset/table/table_footer_column.png"),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}