import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpDocumentDateRelated extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Document Date Related')}
                {this.renderDateFormat()}
                {this.renderDate()}
            </div>
        </div>
    }

    renderDateFormat() {
        var data = {
            header: "Date Format",
            paragraph: [
                {
                    type: 'text',
                    value: "You can change the date format for the date related fields such as document date, due date and etc.",
                },
                {
                    type: 'text',
                    value: "The date format that document generator is supported as below,",
                },
                {
                    type: 'list',
                    value: [
                        "dd/mm/yyyy",
                        "mm/dd/yyyy",
                        "mmddyyyy",
                        "ddmmyyyy",
                        "ddmmmmyyyy",
                        "dd-mm-yyyy",
                        "mm-dd-yyyy",
                        "ddmmmyyyy",
                        "mmmddyyyy",
                        "yyyy-mm-dd",
                        "dd/MM/yyyy hh:mm:ss a",
                        "d mmm yyyy",
                    ],
                },
                {
                    type: 'image',
                    value: require('./../asset/document-date/date-picker.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "You can select/unselect default date format value which you can print the document with tenant date format.",
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

    renderDate() {
        var data = {
            header: "Document Date",
            paragraph: [
                {
                    type: 'text',
                    value: "Different module has different date fields. Example invoice module,",
                },
                {
                    type: 'image',
                    value: require('./../asset/document-date/document-date.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Show all date related fields",
                },
                {
                    type: 'image',
                    value: require('./../asset/document-date/date-show-all.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Hide all date related fields",
                },
                {
                    type: 'image',
                    value: require('./../asset/document-date/date-hide-all.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}