import React, { Component } from "react";
import "../../App.css";
import {
    getLocalisedText,
    COLOR_LIGHTGRAY,
    Utility,
    Asset,
    TableColumnPopupType,
    NumberUtility
} from 'deskera-doc-builder-lib';

export default class TableColumnPopupRow extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isTextFieldFocus: false,
            dropDownOptions: this.props.options,
            isShowSortAndEditWidthOption: this.props.isShowSortAndEditWidthOption ? this.props.isShowSortAndEditWidthOption : false
        };
        this.updateOptionsArray()
    }

    render() {
        return (
            <div
                id={'row_id_' + this.props.item.index}
                className={"ColumnDiv"}
                onDragOver={this.props.onDragOver}
                draggable={true}
                onDragStart={this.props.handleDrag}
                onDrop={this.props.handleDrop}
                onDragEnd={this.props.onDragEnd}
                style={{ width: '100%', paddingLeft: 13, paddingRight: 13, boxSizing: 'border-box', position: 'relative'}}>
                <div className="RowDiv" style={{ justifyContent: 'space-between', boxSizing: 'border-box'}}>
                    {this.getEditableSection()}
                    {this.getIsCurrencyCustomField() && this.getMarkAsCurrencySection()}
                    {this.props.canEditTitle && this.getEditButtonSection()}
                    {this.props.isShowSortAndEditWidthOption && this.getDragIndicator()}
                </div>
                <div className="Line" style={{marginLeft: 25, width: '85%'}}/>
            </div>
        );
    }

    textFieldFocusOut(){
        if (this.editTextField) {
            if (this.editTextField.value.length <= 0) {
                this.props.onUpdateName(this.editTextField.value)
                this.setState({ isTextFieldFocus: false})
            } else {
                if (getLocalisedText(this.props.item.name) === this.editTextField.value) {
                    this.setState( {isTextFieldFocus: false})
                } else {
                    this.setState( {isTextFieldFocus: false}, () => {
                        this.updateOptionsArray()
                    })
                }
            }
        }
    }

    getDropDown(arr) {
        return (
            <div className="row" style={{fontSize: 14, position:'relative', width: '100%'}}>
                <select
                    onChange={(e) => {
                        if(!this.props.canEditTitle) {
                            return 
                        }
                        let textFieldValue = ''
                        if(e.target.value === "other") {
                            textFieldValue = ""
                            this.activateTextfield()
                        } else {
                            textFieldValue = e.target.value
                            this.props.onUpdateName(textFieldValue)
                        }
                    }}
                    disabled={!this.props.canEditTitle}
                    className="TextField ListPicker"
                    style={{
                        fontSize: 14,
                        width: '97%',
                        textAlignLast: Utility.getIsArabicLang() ? 'right' : 'left',
                        direction: Utility.getIsArabicLang() ? 'rtl' : 'ltr',
                        paddingRight: Utility.getIsArabicLang() ? 24 : 0,
                    }}
                    value={getLocalisedText(this.props.item.name)}
                >
                    {arr.map(obj => {
                        return <option
                            value={obj}
                            selected={this.props.item.name === obj}
                        >
                            {getLocalisedText(obj)}
                        </option>
                    })}

                </select>
                {this.props.canEditTitle && this.getListPickerArrow()}
            </div>
        )
    }

    //////////////////////////////////////////////////

    getListPickerArrow() {
        return (
            <img
                className="ListPickerArrowStyle"
                // style={{ top: 58, right: 25 }}
                src={Asset.icon.ic_listPickerArrow2}
                alt=""
            />
        );
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////


    getEditableSection() {
        return(
            <div className="RowDiv" style={{paddingTop: 5, paddingBottom: 5, boxSizing: 'border-box' }}>
                {this.getCheckBox()}
                <div className="ColumnDiv" style={{ width: '100%' }}>
                    {this.state.dropDownOptions && !this.state.isTextFieldFocus && this.getDropDown(this.removeDuplicateElement())}
                    {(this.state.dropDownOptions === null || this.state.isTextFieldFocus) && this.getEditableTextField()}
                </div>
            </div>
        )
    }

    getEditButtonSection() {
        return (
            <div className="ColumnDiv"
                style={{ width: 25, height: 25, cursor: 'pointer', backgroundColor: COLOR_LIGHTGRAY, borderRadius: '50%', justifyContent: 'center', alignItems: 'center' }}
                onClick={() => {
                    this.activateTextfield()
                }}
            >
                <img src={Asset.icon.ic_edit} alt="edit" style={{width: 12}}/>
            </div>
        )
    }

    activateTextfield() {
        if (this.state.dropDownOptions) {
            if (!this.editTextField) {
                this.setState({isTextFieldFocus: true}, () => {
                    this.editTextField.focus()
                })
            }
        } else {
            this.setState({isTextFieldFocus: true}, () => {
                this.editTextField.focus()
            })
        }
    }

    getCheckBox() {
        return (
            <input type="checkbox"
                checked={this.props.item.isSelected}
                style={{cursor: "pointer"}}
                onClick={ () => {
                    this.props.onUpdatedIsSelected(!this.props.item.isSelected)
                }
            }/>
        )
    }

    getEditableTextField() {
        return (
            <input
                className="EditableLabelTableColumn"
                ref={inputElement => (this.editTextField = inputElement)}
                type="text"
                dir={Utility.getInputDirection()}
                name={this.props.item.name}
                style={{ fontSize: 14, padding: 9, width:'96%', fontWeight: 500, boxSizing: 'border-box' }}
                value={getLocalisedText(this.getItemName())}
                onChange={e => this.props.onUpdateName(e.target.value)}
                onBlur={()=>{
                    this.textFieldFocusOut()
                }}
            />
        )
    }

    getItemName() {
        var name = this.props.item.name
        if (this.editTextField !== undefined && this.editTextField !== null) {
            if (this.editTextField.value !== undefined && this.editTextField.value !== null) {
                return this.editTextField.value
            }
        }
        return name
    }

    updateOptionsArray() {
        if (this.props.options !== null) {
            let arrayToAssign = this.props.options
            if(!this.props.options.some(obj => obj === this.props.item.name )) {
                arrayToAssign.splice(arrayToAssign.length - 1, 0, this.props.item.name);
            }
            this.setState({dropDownOptions : arrayToAssign})
        }
    }

    removeDuplicateElement() {
        var newData = []
        if (this.props.options !== undefined && this.props.options !== null) {
            if(this.props.options.length > 0) {
                let arrayToAssign = this.props.options.filter((item, index, self) => self.indexOf(item) === index);
                newData = arrayToAssign
                if (arrayToAssign !== undefined && arrayToAssign !== null) {
                    if (arrayToAssign.length > 0) {
                        if (this.props.item.name !== undefined) {
                            var newArray = [this.props.item.name]
                            arrayToAssign.filter(x => getLocalisedText(x) !== getLocalisedText(this.props.item.name)).forEach(element => {
                                newArray.push(element)
                            })
                            newData = newArray
                        }
                    }
                }
            }
        }
        return newData
    }


    getDragIndicator() {
        return (
            <div className="ColumnDiv"
                style={{
                    width: 30,
                    height: 30,
                    cursor: 'pointer',
                    backgroundColor: COLOR_LIGHTGRAY,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 4,
                }}
                id={'row_drag_id_' + this.props.item.index}
            >
                <img src={Asset.icon.ic_drag_indicator} alt="drag" style={{ width: 16, pointerEvents: 'none' }} />
            </div>
        )
    }

    getIsCurrencyCustomField() {
        return NumberUtility.getCurrency(this.props.documentData) &&
            this.props.item.type === TableColumnPopupType.productCustomField &&
            this.props.item.cfType === "NUMBER";
    }

    getMarkAsCurrencySection() {
        return (
            <div className="HeaderButton mr-s"
                style={{
                    backgroundColor: this.props.item.showAsCurrency ? 'rgb(55, 115, 225)' : 'rgb(230, 230, 230)',
                    color: this.props.item.showAsCurrency ? 'white' : 'rgb(50, 50, 50)'
                }}
                onClick={() => {
                    if (this.props.onCurrencySelect) {
                        this.props.onCurrencySelect(this.props.item, !this.props.item.showAsCurrency)
                    }
                }}>
                <span>{NumberUtility.getCurrency(this.props.documentData)}</span>
            </div>
        );
    }
}
