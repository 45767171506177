import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpWatermark extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Watermark')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Configure watermark",
            paragraph: [
                {
                    type: 'text',
                    value: "Please go to watermark section from the left panel in order to hide/show watermark. Once the watermark is selected, the edit button will be display within the section.",
                },
                {
                    type: 'image',
                    value: require('./../asset/watermark/watermark-on.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "You can click on the edit icon to edit watermark. A popup will display for editing the watermark text as well as position",
                },
                {
                    type: 'image',
                    value: require('./../asset/watermark/watermark-left.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "You can update the text for each type of watermark and click on reset button to reset the title with default text. The updated text will be display during printing. You can change the position from Top Left, Top Right, Bottom Left, Bottom Middle, and Bottom Right.",
                },
                {
                    type: 'text',
                    value: "Once you update the position, the preview document will display 'Sample Watermark' as reference.",
                },
                {
                    type: 'text',
                    value: "Top Left",
                    isBold: true
                },
                {
                    type: 'image',
                    value: require('./../asset/watermark/watermark-left-preview.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "Top Right",
                    isBold: true
                },
                {
                    type: 'image',
                    value: require('./../asset/watermark/watermark-right-preview.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Once the template is saved, please navigate to Book/Book+ to select a document and chose the watermark type.",
                },
                {
                    type: 'text',
                    value: "A list of selection of watermark is show as below. You can select multiple waterwark if you need. For all, it will print multiple copy with different type of watermark except custom.",
                },
                {
                    type: 'image',
                    value: require('./../asset/watermark/watermark-selection.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Orginal",
                    isBold: true
                },
                {
                    type: 'image',
                    value: require('./../asset/watermark/original-preview.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Custom",
                    isBold: true
                },
                {
                    type: 'image',
                    value: require('./../asset/watermark/custom-preview.png'),
                    isFullWidth: false,
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}