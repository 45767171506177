import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpTaxBreakdownTableColumn extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Tax Breakdown Table Column')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "You can add tax breakdown to the product table if you are using India template. You can add tax column by opening the table popup and selecting the tax field.",
                },
                {
                    type: 'image',
                    value: require('./../asset/tax-breakdown-table-column/tax-breakdown-table-column.png'),
                    isFullWidth: false
                },
                {
                    type: 'text',
                    value: "Sample result,",
                },
                {
                    type: 'image',
                    value: require('./../asset/tax-breakdown-table-column/tax-breakdown-table-column-demo.png'),
                    isFullWidth: false
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}