import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
// import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
// import { getLocalisedText } from "../../Translate/LanguageManager";
// import { getIsInvoice, getIsQuotation, getIsSalesOrder, getIsWorkOrder } from "../../Utilities/Utility";
import PopupHeaderComponent from "./PopupHeaderComponent";
// import ComponentManager from "../../Manager/ComponentManager";
// import DocumentLabelUtility, { documentLabelPosition } from "../../Utilities/DocumentLabelUtility";
import CustomFieldTablePopupRow from "./CustomFieldTablePopupRow";

import {
    TemplateSettingsManager,
    getLocalisedText,
    getIsInvoice, 
    getIsQuotation,
    getIsSalesOrder, 
    getIsWorkOrder ,
    ComponentManager,
    documentLabelPosition,
    DocumentLabelUtility,
} from 'deskera-doc-builder-lib'

export default class DocumentLabelPopup extends Component {

    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            data: this.getDocumentLabelList(),
            selectedPosition: this.getSelectedPosition(),
        };
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    width: 350,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent() {
        return <>
            <PopupHeaderComponent
                headerText={'document_label'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={this.saveButtonTapped}
            />
            {this.renderMainContainerSection()}
        </>;
    }

    getDocumentLabelList () {
        var type = this.props.documentData.type
        if (getIsQuotation(type) ||
            getIsSalesOrder(type) ||
            getIsInvoice(type) ||
            getIsWorkOrder(type)) {
            return DocumentLabelUtility.getDocumentList(this.props.documentData)
        }

        return []
    }

    getSelectedPosition() {
        var position = DocumentLabelUtility.getDefaultPositionList()[0].type
        var existingDocumentLabel = TemplateSettingsManager.getDocumentLabel()
        if (existingDocumentLabel.position !== undefined && existingDocumentLabel.position !== null) {
            if (existingDocumentLabel.position !== '') {
                position = existingDocumentLabel.position
            }
        }

        return position
    }

    renderMainContainerSection() {
        return <>
            <div style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}>
                <div
                    style={{
                        fontSize: 14,
                        color: "black",
                        fontWeight: "600",
                        textAlign: 'left',
                        paddingLeft: 5,
                        paddingBottom: 10,
                    }}
                >
                    {this.renderDocumentLabelListSection()}
                    {this.renderPositionPicker()}
                </div>
            </div>
        </>
    }

    renderDocumentLabelListSection() {
        if(this.state.data === undefined || this.state.data === null) {
            return <></>
        }

        if(this.state.data.length === 0) {
            return <></>
        }

        return this.state.data.map((item, index) => {
            return <CustomFieldTablePopupRow
                item={item}
                customFields={this.state.data}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectClick(item)}
                onUpdateLabel={undefined}
                isTextFieldFocus={true}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                options={undefined}
                onUpdateOptions={undefined}
                isTextEditable={false}
                isRemovable={false}
                onDeleteItem={undefined}
                isResetTitle={false}
                onResetTitle={undefined}
                disabledCheckbox
            />
        });
    }


    onSelectClick(item) {
        var documentLabels = this.state.data
        documentLabels.forEach((element) => {
            if (element.type === item.type) {
                element.isSelected = !element.isSelected
            }
        });
        this.setState({
            data: documentLabels,
        })
    }

    handleDrag = (ev) => {
        var startIndex = ev.currentTarget.id
        if (startIndex !== undefined && startIndex !== this.startIndex) {
            ev.dataTransfer.dropEffect = "move";
            this.startIndex = ev.currentTarget.id
        }
    };

    handleDrop = (ev) => {
        if (ev.currentTarget.id !== undefined) {
            this.onRowIndexChange(ev.currentTarget.id)
        }
    };

    onDragOver = (ev) => {
        var dragOverIndex = ev.currentTarget.id
        ev.preventDefault()
        if (dragOverIndex !== undefined) {
            if (dragOverIndex !== this.dragOverIndex && this.startIndex !== undefined) {
                this.removeTransition()
                this.addAnimation(ev.currentTarget.id, ev)
                this.dragOverIndex = dragOverIndex
            }
        }
    }

    onDragEnd = (ev) => {
        ev.preventDefault()
        this.removeTransition()
        if (this.startIndex !== undefined) {
            var startIndex = Number(this.startIndex.replace('row_id_', ''))
            this.showSelectedRow(startIndex)
            this.removeTransition()
        }
    }

    addAnimation = (targetIndex) => {
        var startIndex = undefined
        var endIndex = Number(targetIndex.replace('row_id_', ''))

        if (this.startIndex !== undefined) {
            startIndex = Number(this.startIndex.replace('row_id_', ''))
        }

        if (startIndex !== undefined && endIndex !== undefined) {
            if (startIndex !== endIndex) {
                let columnEle = document.getElementById('custom_field_table_popup');
                if (columnEle && columnEle.children) {
                    Array.from(columnEle.children).forEach((element, index) => {
                        if (endIndex > startIndex && endIndex > 0) {
                            if (index >= endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                        else if (endIndex !== 0) {
                            if (index > endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                    })
                }
            }
        }
    }

    removeTransition = () => {
        let columnEle = document.getElementById('custom_field_table_popup');
        if (columnEle && columnEle.children) {
            Array.from(columnEle.children).forEach((element, index) => {
                element.style.transform = null
                element.style.display = 'flex'
            })
        }
    }

    hideSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            if (textCol.innerHTML !== '&nbsp;') {
                this.startIndexText = textCol.innerHTML
            }
            textCol.innerHTML = '&nbsp;'
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'none'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'none'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'none'
        }
    }

    showSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            textCol.innerHTML = this.startIndexText
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'flex'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'flex'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'flex'
        }
    }

    onRowIndexChange(endIndex) {
        if (this.startIndex === undefined) {
            return
        }

        var startIndex = Number(this.startIndex.replace('row_id_', ''))
        if (this.startIndex === endIndex) {
            this.showSelectedRow(startIndex)
            this.startIndex = undefined
            return
        }

        this.showSelectedRow(startIndex)
        var newArray = []
        var documentLabels = this.state.data
        documentLabels.forEach((element) => {
            if (Number(element.index) !== startIndex) {
                newArray.push(element)
            }
        });
        var newIndex = Number(endIndex.replace('row_id_', ''))

        var sortedList = []
        var selectedItem = documentLabels.filter(x => x.index === startIndex)
        if (selectedItem.length > 0) {
            newArray.splice(newIndex, 0, selectedItem[0])
        }
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            data: []
        }, () => {
            this.startIndex = undefined
            this.dragIndex = undefined
            this.removeTransition()
            this.setState({
                data: sortedList,
            })
        })
    }

    renderPositionPicker() {
        return <div className="ColumnDiv pt-l pb-s"
            style={{
                paddingLeft: 13,
                paddingRight: 13,
                boxSizing: 'border-box',
            }}
        >
            <div className="RowDiv">
                {getLocalisedText('position')}
            </div>
            <div className="RowDiv pt-s">
                {getLocalisedText('top')}
            </div>
            <div className="RowDiv">
                {this.renderPortionList(documentLabelPosition.top)}
            </div>
            {ComponentManager.addVerticalSpace(60)}
            <div className="RowDiv">
                {getLocalisedText('bottom')}
            </div>
            <div className="RowDiv">
                {this.renderPortionList(documentLabelPosition.bottom)}
            </div>
        </div>
    }

    renderPortionList(portion) {
        var portionList = DocumentLabelUtility.getDefaultPositionList().filter(x => x.portion === portion)
        if (portionList.length > 0) {
            return portionList.map((element) => {
                return this.renderPositionText(element)
            });
        }
        return undefined
    }

    renderPositionText(element) {
        var position = 'center'
        if(element.label !== 'middle') {
            position = element.label
        }

        var className = 'RowDiv'
        if(element.label === 'right') {
            className = 'RowReverseDiv'
        }
        if (this.state.selectedPosition !== undefined && this.state.selectedPosition !== null) {
            if(element.type === this.state.selectedPosition) {
                return <div className={className} style={{ justifyContent: position }}>
                    {ComponentManager.getButton(element.label, 'rgba(55, 115, 225, 1.0)', 'white', () => this.onWatermarkPositionClick(element), undefined, false, false)}
                </div>
            }
        }
        return <div className={className} style={{ justifyContent: position }}>
            {ComponentManager.getButton(element.label, 'white', 'rgba(55, 115, 225, 1.0)', () => this.onWatermarkPositionClick(element), undefined, false, false)}
        </div>
    }

    onWatermarkPositionClick(element) {
        this.setState({
            selectedPosition: element.type
        })
    }


    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    saveButtonTapped = () => { 
        var documentLabel = TemplateSettingsManager.getDocumentLabel()

        documentLabel.elements = this.state.data
        documentLabel.position =  this.state.selectedPosition
        TemplateSettingsManager.updateDocumentLabel(documentLabel)

        this.props.onClosePressed()
    }
}
