import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpCompanyNameAndAddress extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Company Name and Address')}
                {this.renderCompanyName()}
                {this.renderCompanyAddress()}
                {this.renderCompanyAddressPopup()}
            </div>
        </div>
    }


    renderCompanyName() {
        var data = {
            header: "Company Name",
            paragraph: [
                {
                    type: 'text',
                    value: "You can refer to comapny name section from the left panel to show/hide company name.",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-name-address/company-name-address.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Show Company Name",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-name-address/company-name-show.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Hide Company Name",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-name-address/company-name-hide.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }

    renderCompanyAddress() {
        var data = {
            header: "Company Address",
            paragraph: [
                {
                    type: 'text',
                    value: "You can refer to comapny address section from the left panel to show/hide company name. The edit button appear if you shw company address.",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-name-address/company-address.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Show Company Address",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-name-address/company-address-show.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Hide Company Address",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-name-address/company-address-hide.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }


    renderCompanyAddressPopup() {
        var data = {
            header: "Company Address Popup",
            paragraph: [
                {
                    type: 'text',
                    value: "You can navigate to address popup screen by click on edit button of the company address section.",
                },
                {
                    type: 'text',
                    value: "The actions that you can performance from the address popup as as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Reorder address type",
                        "Select/unselect address type",
                        "Apply same setting to other address",
                    ]
                },
                {
                    type: 'text',
                    value: "The address type are supported for company address type as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Address 1",
                        "Address 2",
                        "City",
                        "State",
                        "Country",
                        "Postal Code",
                        "GSTIN - (only for India Tenant)",
                        "PAN Number - (only for India Tenant)",
                        "CIN Number - (only for India Tenant)",
                    ]
                },
                {
                    type: 'image',
                    value: require('./../asset/company-name-address/company-address-popup.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Sample 1",
                },
                {
                    type: 'image',
                    value: require('./../asset/company-name-address/company-address-sample-1.png'),
                    isFullWidth: false,
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'image',
                    value: require('./../asset/company-name-address/company-address-sample-1-preview.png'),
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}