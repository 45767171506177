import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpTableConfiguration extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Table Configuration')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            header: "Configure Table Text Style, Font Size, Table Padding and Height",
            paragraph: [
                {
                    type: 'text',
                    value: "You can refer to table section from the left panel. The actions that you can performance as below,",
                },
                {
                    type: 'list',
                    value: [
                        "Font Resize - increase or decrease font size",
                        "Italic - change all the text to italic font style",
                        "Bold - set all the text to bold",
                        "Padding - set the padding horizontal or vertical for table column",
                        "Height - set fix height for table column"
                    ]
                },
                {
                    type: 'image',
                    value: require('./../asset/table-customization/table-configuration.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "*** All the setting need to be checked before it can apply to the table. If unchecked, it will return to default setting.",
                    isBold: true,
                },
                {
                    type: 'text',
                    value: "For the text resize and text style, kindly refer to text configuration section for similar result.",
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Set Table Row Height",
                },
                {
                    type: 'image',
                    value: require('./../asset/table-customization/table-row-height.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Set Padding Horizontal",
                },
                {
                    type: 'image',
                    value: require('./../asset/table-customization/table-padding-horizontal.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'text',
                    value: "Set Padding Vertical",
                },
                {
                    type: 'image',
                    value: require('./../asset/table-customization/table-padding-vertical.png'),
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'newLine',
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}