import React, {Component} from 'react';
import '../../App.css';
// import {TableColumnPopupType} from '../Popup/TableColumnPopup';
// import { getLocalisedText } from '../../Translate/LanguageManager';
// import AppManager from '../../Manager/AppManager';
// import Utility from '../../Utilities/Utility';

import {
    Utility,
    AppManager,
    getLocalisedText,
    TableColumnPopupType
} from 'deskera-doc-builder-lib';
export default class ShippingColumnEditor extends Component {

    dataChanged(data) {
        this.props.dataChanged(this.props.data);
    }

    render() {
        return this.getShippingListColumns(this.props.columnData)
    }

    getShippingListColumns(columnsData = []) {
        var arr = [];
        for (let i = 0; i < columnsData.length; i++) {
            arr.push(this.getShippingListColumn(i, columnsData[i]));
        }
        return arr;
    }

    getShippingListColumn(index, columnData) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                }}
            >
                {this.getShowHideValueForTablePopup(TableColumnPopupType.delivery) && this.getColumnTextfields(
                    "delivery",
                    index,
                    columnData.delivery,
                    this.columnDeliveryChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.carrier) && this.getColumnTextfields(
                    "carrier",
                    index,
                    columnData.carrier,
                    this.columnCarrierChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.numberOfCarton) && this.getColumnTextfields(
                    "number_of_carton",
                    index,
                    columnData.numberOfCarton,
                    this.columnNumberOfCartonChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.weight) && this.getColumnTextfields(
                    "weight",
                    index,
                    columnData.weight,
                    this.columnWeightChanged
                )}
                {this.getShowHideValueForTablePopup(TableColumnPopupType.trackingNumber) && this.getColumnTextfields(
                    "tracking_number",
                    index,
                    columnData.trackingNumber,
                    this.columnTrackingNumberChanged,
                )}
                <div className='RowDiv'
                    style={{
                        flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
                    }}>
                    <button
                        style={{
                            marginTop: 10,
                            cursor: "pointer",
                            color: "red",
                            border: "solid",
                            borderWidth: 1,
                            borderColor: "rgb(210, 210, 210)",
                            backgroundColor: "white",
                            borderRadius: 4,
                            marginBottom: 20,
                        }}
                        onClick={() => this.deleteColumn(index)}
                    >
                        {getLocalisedText("delete")}
                    </button>
                </div>
            </div>
        );
    }

    deleteColumn(index) {
        var data = this.props.data;
        data["lineItems"].splice(index, 1);
        this.dataChanged(data);
    }

    getColumnTextfields(placeholder, index, value, callback) {
        return (
            <input
                className="TextField"
                style={{
                    marginTop: 6,
                    borderRadius: 4,
                    color: 'rgb(30, 30, 30)'
                }}
                placeholder={getLocalisedText(placeholder)}
                type="text"
                dir={Utility.getInputDirection()}
                value={getLocalisedText(value)}
                onChange={(e) => callback(e, index)}
            />
        );
    }

    columnDeliveryChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["delivery"] = event.target.value;
        this.dataChanged(data);
    };

    columnCarrierChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["carrier"] = event.target.value;
        this.dataChanged(data);
    };

    columnNumberOfCartonChanged = (event, index) => {
        let value = event.target.value
        if (isNaN(value)) {
            return
        }
        var data = this.props.data;
        data["lineItems"][index]["numberOfCarton"] = value;
        this.dataChanged(data);
    };

    columnWeightChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["weight"] = event.target.value;
        this.dataChanged(data);
    };

    columnTrackingNumberChanged = (event, index) => {
        var data = this.props.data;
        data["lineItems"][index]["trackingNumber"] = event.target.value;
        this.dataChanged(data);
    };

    getShowHideValueForTablePopup(tableColumnPopupType) {
        var item = AppManager.getTableColumnOptions(this.props.data.tableColumnPopup, tableColumnPopupType)
        if(item === undefined || item  === null) {
            return false
        }
        else {
            return item.isSelected
        }
    }

}
