import React, { Component } from "react";
import "../../App.css";
import "../../CommonStyles/FontStyle.css"
// import ComponentManager from "../../Manager/ComponentManager";
// import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
// import { getLocalisedText } from "../../Translate/LanguageManager";
// import Utility, { getPageWidth, getVW } from '../../Utilities/Utility';
// import HeaderFooterUtility from '../../Utilities/HeaderFooterUtility';
import PopupHeaderComponent from "./PopupHeaderComponent";

import {
    ComponentManager,
    TemplateSettingsManager,
    getLocalisedText,
    Utility,
    getPageWidth,
    getVW,
    Asset,
    HeaderFooterUtility,
    DocumentSectionType
} from 'deskera-doc-builder-lib'
import { PageCountFormattingType } from "deskera-doc-builder-lib";

export default class HeaderFooterPopup extends Component {

    constructor(props) {
        super(props);
        var initValue = this.initState()
        this.state = {
            container: initValue.container,
            containerStyle: initValue.containerStyle,
            type: initValue.type,
            customField: initValue.customField,
            additionalSettings: initValue.additionalSettings
        };
    }

    initState() {
        var value = {}
        value.containerStyle = this.getValueByKey('containerStyle')
        value.type = this.getValueByKey('type')
        value.customField = this.getValueByKey('customField')
        value.additionalSettings = this.getHeaderFooterAdditionalSettings()
        var newContainer = []
        if (value.type[DocumentSectionType.header] !== undefined && value.type[DocumentSectionType.header] !== null) {
            newContainer[DocumentSectionType.header] = HeaderFooterUtility.getPreviewHeaderFooterContainer(DocumentSectionType.header, value.type[DocumentSectionType.header], value.containerStyle[DocumentSectionType.header], value.customField[DocumentSectionType.header], this.props.documentData, value.additionalSettings)
        }
        if (value.type[DocumentSectionType.footer] !== undefined && value.type[DocumentSectionType.footer] !== null) {
            newContainer[DocumentSectionType.footer] = HeaderFooterUtility.getPreviewHeaderFooterContainer(DocumentSectionType.footer, value.type[DocumentSectionType.footer], value.containerStyle[DocumentSectionType.footer], value.customField[DocumentSectionType.footer], this.props.documentData, value.additionalSettings)
        }

        value.container = newContainer

        return value
    }

    getValueByKey(key) {
        var headerFooter = TemplateSettingsManager.defaultTemplateSettings.headerFooter
        var value = []
        if (headerFooter !== undefined && headerFooter !== null) {
            if(headerFooter.header !== undefined && headerFooter.header !== null) {
                value[DocumentSectionType.header] = headerFooter.header[key]
            }
            if (headerFooter.footer !== undefined && headerFooter.footer !== null) {
                value[DocumentSectionType.footer] = headerFooter.footer[key]
            }
        }
        return value
    }

    getHeaderFooterAdditionalSettings() {
        var headerFooter = TemplateSettingsManager.defaultTemplateSettings.headerFooter
        return headerFooter?.additionalSettings ?? undefined
    }

    render() {
        return (
            <div className="RowDiv" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10000000,
            }}>
                <div className="ShadowMedium WindowPopup" style={{
                    backgroundColor: 'white',
                    // width: 350,
                    height: 'auto',
                    // maxWidth: 370,
                    padding: 0,
                    paddingBottom: 5,
                    minWidth: getPageWidth(),
                    minHeight: '50%'

                }}>
                    {this.getPopupContent()}
                </div>
            </div>
        );
    }

    getPopupContent = () => {
        return <>
            <PopupHeaderComponent
                headerText={'header_footer_section'}
                cancelText={'cancel'}
                submitText={'save'}
                onCancelClicked={this.cancelButtonTapped}
                onSubmitClicked={() => {
                    this.saveButtonTapped()
                }}
            />
            <div
                className="ColumnDiv"
                style={{
                    backgroundColor: 'white',
                    borderRadius: getVW(2),
                    width: '100%',
                    maxHeight: 800,
                    alignItems: 'center',
                    overflowY: 'scroll',
                    overflowX: 'hidden'
                }}>
                {this.renderAdditionalSettingSelection()}
                {this.renderSection(DocumentSectionType.header)}
                {this.renderSection(DocumentSectionType.footer)}
            </div>
        </>;
    }

    renderAdditionalSettingSelection = (val) => {
        return <>
            <div style={{ width: '100%', paddingBottom: 10, paddingTop: 5 }}>
                <div className='fontStyleBold pl-r pr-r user-select-none RowDiv' style={{
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                    fontSize: 14,
                }}>
                    {getLocalisedText('page_count_settings') + ': '}
                    {this.renderDropdownListContainer(
                        this.renderPageCountFormattingPicker(),
                        230,
                    )}
                </div>
            </div>
        </>
    }
    renderPageCountFormattingPicker = () => {
        var pageCountFormattingTypeList = [
            { type: PageCountFormattingType.singleDigit, label: 'single_digit' },
            { type: PageCountFormattingType.pageWithNumeric, label: 'page_with_numeric' },
            { type: PageCountFormattingType.pageOfTotalPage, label: 'page_x_of_total_page' },
            { type: PageCountFormattingType.pageOfTotalPageSlash, label: 'page_x_of_total_page_slash' },
        ]

        return this.renderPicker(pageCountFormattingTypeList,
            (element) => this.getPageCountFormattingType(element.type),
            (event) => this.onSelectPageCountFormattingType(event))
    }

    getPageCountFormattingType(type) {
        const pageCountFormattingType = this.state.additionalSettings?.pageCountFormattingType ?? PageCountFormattingType.pageOfTotalPageSlash
        return pageCountFormattingType === type
    }

    onSelectPageCountFormattingType(event) {
        let additionalSettings = this.state.additionalSettings
        let newStyle = this.state.containerStyle
        let newContainer = this.state.container
        if(additionalSettings === undefined) {
            additionalSettings = {}
        }
        additionalSettings.pageCountFormattingType = event.target.value

        let headerFooter = [DocumentSectionType.header, DocumentSectionType.footer]
        headerFooter.forEach(val => {
            newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], newStyle[val], this.state.customField[val], this.props.documentData, additionalSettings)
        });
        
        this.setState({
            headerContainer: newContainer,
            additionalSettings: additionalSettings
        })
    }


    renderSection = (val) => {
        return <div style={{ width: '100%', paddingBottom: 10, paddingTop: 5 }}>
            <div className='fontStyleBold pl-r pr-r user-select-none' style={{
                textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                fontSize: 14,
            }}>
                {this.getSectionTitle(val)}
            </div>
            {this.renderPreviewSection(val)}
            {this.renderPresetSelection(val)}
            {this.getIsCustomType(val) && this.renderEditSection(val)}
        </div>
    }

    renderPreviewSection = (val) => {
        return <>
            <div className='pl-r pr-r user-select-none' style={{
                textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                paddingBottom: 10,
                paddingTop: 5
            }}>
                <div
                    className='pl-s pr-s pt-s pb-s'
                    style={{
                        height: getVW('4.7vw', true),
                        border: '0.5px solid lightgrey',
                        borderRadius: '2px'

                    }}
                    >
                    {this.state.container[val]}
                </div>
            </div>
        </>
    }

    renderPresetSelection = (val) => {
        return <>
            <div className='fontStyleBold pl-r pr-r user-select-none RowDiv' style={{
                textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                fontSize: 14,
            }}>
                {getLocalisedText('selection') + ': '}
                {this.renderDropdownListContainer(
                    this.renderPresetPicker(val),
                    230,
                )}
                {this.renderDropdownListContainer(
                    this.renderFontSizePicker(val),
                    80,
                )}
                {this.renderDropdownListContainer(
                    this.renderFontStylePicker(val),
                    80,
                )}
                {this.renderDropdownListContainer(
                    this.renderFontWeightPicker(val),
                    80,
                )}
                {this.renderDropdownListContainer(
                    this.renderTextAlignmentPicker(val),
                    80,
                )}
                {this.renderDropdownListContainer(
                    this.renderTextColorPicker(val),
                    80,
                )}
            </div>
        </>
    }

    renderDropdownListContainer(ddl, width) {
        return <div className='RowDiv' style={{
            width: width,
            paddingRight: 8,
        }}>
            {ddl}
        </div>
    }

    renderPresetPicker = (val) => {
        var presetList = [
            { type: 'none', label: 'None' },
            { type: 'date_only', label: 'date_only' },
            { type: 'title_only', label: 'title_only' },
            { type: 'page_count_only', label: 'page_count_only' },
            { type: 'date_title', label: 'date_title' },
            { type: 'date_page_count', label: 'date_page_count' },
            { type: 'title_page_count', label: 'title_page_count' },
            { type: 'date_title_space', label: 'date_title_space' },
            { type: 'date_page_count_space', label: 'date_page_count_space' },
            { type: 'title_page_count_space', label: 'title_page_count_space' },
            { type: 'date_title_page_count', label: 'date_title_page_count' },
            { type: 'custom', label: 'custom' },
        ]

        return this.renderPicker(presetList,
            (element) => this.getSelectedType(val, element.type),
            (event) => this.onSelectType(val, event))
    }

    getSelectedType(val, type) {
        return this.state.type[val] === type
    }

    onSelectType(val, event) {
        var type = event.target.value
        var newType = this.state.type
        newType[val] = type

        var newCustomField = this.state.customField
        if(type === 'custom') {
            if(newCustomField[val] === undefined || newCustomField[val] === null) {
                newCustomField[val] = []
                newCustomField[val].push({type: 'title'})
            }
        }

        var newStyle = this.state.containerStyle

        if (newStyle[val] === undefined) {
            newStyle[val] = this.getDefaultStyle()
        }

        var newContainer = this.state.container
        newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, type, newStyle[val], this.state.customField[val], this.props.documentData, this.state.additionalSettings)

        this.setState({
            type: newType,
            headerContainer: newContainer,
            customField: newCustomField,
            containerStyle: newStyle
        })
    }

    getPickerStyle() {
        if (Utility.getIsArabicLang()) {
            return {
                textAlignLast: 'right',
                direction: 'rtl',
                paddingRight: 24,
            }
        }
        return {}
    }

    renderEditSection = (val) => {
        return <>
            <div className='fontStyleBold pt-r pl-r pr-r user-select-none RowDiv' style={{
                textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                fontSize: 14,
            }}>
                {getLocalisedText('custom')}
            </div>
            <div className='pl-r pr-r ColumnDiv' style={{
                textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
            }}>
                {this.renderCustomSelectionList(val)}
                {this.renderAddCustomFieldButton(val)}
            </div>

        </>
    }

    renderCustomSelectionList(val) {
        if(this.getIsContainsCustomField(val)) {
            var list = this.state.customField[val]
            return list.map((element, index) => {
                return this.renderCustomRow(val, element, index)
            });
        }
        return <></>
    }

    renderCustomRow = (val, element, index) => {
        return <>
        <div className='RowDiv pt-r'>
                <div className='pl-s pr-r' >{index+1}</div>
                <div>
                    {this.renderDropdownListContainer(
                        this.renderCustomSelectionPicker(val, index),
                        200,
                    )}
                </div>
                {this.renderCustomTextField(val, index)}
                {index !== 0 && this.renderDeleteCustomFieldButton(val, index)}
        </div>
        </>
    }

    renderAddCustomFieldButton(val) {
        if(this.getIsContainsCustomField(val)) {
            return <div className='RowDiv pt-r pl-s pr-r'>
                {ComponentManager.getButton(getLocalisedText('add'), `rgba(55, 115, 225, 1.0)`, 'white', () => this.addCustomField(val))}
            </div>

        }
        return <></>
    }

    addCustomField(val) {
        if(this.getIsContainsCustomField(val)) {
            var newCustomField = this.state.customField
            newCustomField[val].push({ type: 'title' })

            var newContainer = this.state.container
            newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], this.state.containerStyle[val], newCustomField[val], this.props.documentData, this.state.additionalSettings)

            this.setState({
                customField: newCustomField,
                container: newContainer
            })
        }
    }

    renderDeleteCustomFieldButton(val, index) {
        return <div className='RowDiv pr-r pl-s'>
            {ComponentManager.getButton(getLocalisedText('X'), `red`, 'white', () => this.deleteCustomField(val, index))}
        </div>
    }

    deleteCustomField(val, fieldIndex) {
        if(this.getIsContainsCustomField(val)) {
            var newCustomField = this.state.customField
            var list = []

            newCustomField[val].forEach((element, index) => {
                if (index !== fieldIndex) {
                    list.push(element)
                }
            });

            newCustomField[val] = list

            var newContainer = this.state.container
            newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], this.state.containerStyle[val], this.state.customField[val], this.props.documentData, this.state.additionalSettings)

            this.setState({
                customField: newCustomField,
                container: newContainer
            })
        }
    }

    getSectionTitle(val) {
        switch (val) {
            case DocumentSectionType.header:
                return getLocalisedText('header')
            case DocumentSectionType.footer:
                return getLocalisedText('footer')
            default:
                return ''
        }
    }

    renderFontSizePicker(val) {
        var list = [
            { type: 1, label: '1px' },
            { type: 2, label: '2px' },
            { type: 3, label: '3px' },
            { type: 4, label: '4px' },
            { type: 5, label: '5px' },
            { type: 6, label: '6px' },
            { type: 7, label: '7px' },
            { type: 8, label: '8px' },
            { type: 9, label: '9px' },
            { type: 10, label: '10px' },
            // { type: 11, label: '11px' },
            // { type: 12, label: '12px' },
            // { type: 14, label: '14px' },
            // { type: 16, label: '16px' },
            // { type: 18, label: '18px' },
            // { type: 20, label: '20px' },
            // { type: 24, label: '24px' },
        ]

        return this.renderPicker(list,
            (element) => this.getFontSize(val, element.type),
            (event) => this.fontSizeChanged(val, event))
    }

    fontSizeChanged(val, event) {
        var fontSize = event.target.value
        var newStyle = this.state.containerStyle

        if (newStyle[val] === undefined) {
            newStyle[val] = this.getDefaultStyle()
        }

        newStyle[val].fontSize = parseInt(fontSize)
        var newContainer = this.state.container
        newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], newStyle[val], this.state.customField[val], this.props.documentData, this.state.additionalSettings)
        this.setState({
            style: newStyle,
            container: newContainer
        })
    }

    getFontSize(val, size) {
        var containerStyle = this.state.containerStyle[val]
        if(containerStyle !== undefined) {
            if (containerStyle.fontSize !== undefined) {
                return parseInt(containerStyle.fontSize) === parseInt(size)
            }
        }
        return false
    }

    renderFontStylePicker(type) {
        var list = [
            { type: 'normal', label: 'Normal' },
            { type: 'italic', label: 'Italic' },
            { type: 'oblique', label: 'Oblique' },
        ]

        return this.renderPicker(list,
            (element) => this.getFontStyle(type, element.type),
            (event) => this.fontStyleChanged(type, event))
    }

    fontStyleChanged(val, event) {
        var fontStyle = event.target.value
        var newStyle = this.state.containerStyle

        if (newStyle[val] === undefined) {
            newStyle[val] = this.getDefaultStyle()
        }

        newStyle[val].fontStyle = fontStyle.toLowerCase()
        var newContainer = this.state.container
        newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], newStyle[val], this.state.customField[val], this.props.documentData, this.state.additionalSettings)
        this.setState({
            style: newStyle,
            container: newContainer
        })
    }

    getFontStyle(val, style) {
        var containerStyle = this.state.containerStyle[val]
        if (containerStyle !== undefined) {
            if (containerStyle.fontStyle !== undefined) {
                return containerStyle.fontStyle.toLowerCase() === style.toLowerCase()
            }
        }
        return false
    }

    renderFontWeightPicker(type) {
        var list = [
            { type: 'normal', label: 'Normal' },
            { type: 'bold', label: 'Bold' },
            { type: 'bolder', label: 'Bolder' },
            { type: 'lighter', label: 'Lighter' },
            { type: '100', label: '100' },
            { type: '200', label: '200' },
            { type: '300', label: '300' },
            { type: '400', label: '400' },
            { type: '500', label: '500' },
            { type: '600', label: '600' },
            { type: '700', label: '700' },
            { type: '800', label: '800' },
            { type: '900', label: '900' },
        ]

        return this.renderPicker(list,
            (element) => this.getFontWeight(type, element.type),
            (event) => this.fontWeightChanged(type, event))
    }

    fontWeightChanged(val, event) {
        var fontWeight = event.target.value
        var newStyle = this.state.containerStyle

        if (newStyle[val] === undefined) {
            newStyle[val] = this.getDefaultStyle()
        }

        newStyle[val].fontWeight = fontWeight.toLowerCase()
        var newContainer = this.state.container
        newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], newStyle[val], this.state.customField[val], this.props.documentData, this.state.additionalSettings)
        this.setState({
            style: newStyle,
            container: newContainer
        })
    }

    getFontWeight(val, fontWeight) {
        var containerStyle = this.state.containerStyle[val]
        if (containerStyle !== undefined) {
            if (containerStyle.fontWeight !== undefined) {
                return containerStyle.fontWeight.toLowerCase() === fontWeight.toLowerCase()
            }
        }
        return false
    }

    renderTextAlignmentPicker(type) {
        var list = [
            { type: 'left', label: 'left' },
            { type: 'center', label: 'center' },
            { type: 'right', label: 'right' },
        ]

        return this.renderPicker(list,
            (element) => this.getTextAlign(type, element.type),
            (event) => this.textAlignChanged(type, event))
    }


    textAlignChanged(val, event) {
        var textAlign = event.target.value
        var newStyle = this.state.containerStyle

        if (newStyle[val] === undefined) {
            newStyle[val] = this.getDefaultStyle()
        }

        newStyle[val].textAlign = textAlign.toLowerCase()
        var newContainer = this.state.container
        newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], newStyle[val], this.state.customField[val], this.props.documentData, this.state.additionalSettings)
        this.setState({
            style: newStyle,
            container: newContainer
        })
    }

    getTextAlign(val, textAlign) {
        var containerStyle = this.state.containerStyle[val]
        if (containerStyle !== undefined) {
            if (containerStyle.textAlign !== undefined) {
                return containerStyle.textAlign.toLowerCase() === textAlign.toLowerCase()
            }
        }
        return false
    }


    renderTextColorPicker(val) {
        return (
            <div>
                <input
                    type="color"
                    className="ColorPicker"
                    value={this.getTextColor(val)}
                    onChange={(event) => this.textColorChanged(val, event)}
                />
            </div>
        );
    }

    textColorChanged(val, event) {
        var color = event.target.value
        var newStyle = this.state.containerStyle

        if (newStyle[val] === undefined) {
            newStyle[val] = this.getDefaultStyle()
        }

        newStyle[val].color = color.toLowerCase()
        var newContainer = this.state.container
        newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], newStyle[val], this.state.customField[val], this.props.documentData, this.state.additionalSettings)
        this.setState({
            style: newStyle,
            container: newContainer
        })
    }

    getTextColor(val) {
        var containerStyle = this.state.containerStyle[val]
        if (containerStyle !== undefined) {
            if (containerStyle.color !== undefined) {
                return containerStyle.color.toLowerCase()
            }
        }
        return '#ffffff'
    }


    renderCustomSelectionPicker(val,index) {
        var list = [
            { type: 'title', label: 'title' },
            { type: 'date', label: 'date' },
            { type: 'pageCount', label: 'page_count' },
            { type: 'currentPageCount', label: 'current_page_count' },
            { type: 'totalPageCount', label: 'total_page_count' },
            { type: 'customText', label: 'custom_text' },
        ]

        return this.renderPicker(list,
            (element) => this.getCustomSelection(val, index, element.type),
            (event) => this.customSelectionChanged(val, index, event))
    }

    customSelectionChanged(val, index, event) {
        var newCustomField = this.state.customField
        if (this.getIsContainsCustomField(val)) {
            var fieldType = event.target.value

            newCustomField[val][index].type = fieldType
        }

        var newContainer = this.state.container
        newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], this.state.containerStyle[val], newCustomField[val], this.props.documentData, this.state.additionalSettings)

        this.setState({
            customField: newCustomField,
            container: newContainer
        })
    }

    getCustomSelection(val, index, type) {
        if (this.getIsContainsCustomField(val)) {
            var customField = this.state.customField[val]
            if(customField.length > index) {
                return customField[index].type.toLowerCase() === type.toLowerCase()
            }
        }
        return false
    }


    renderCustomTextField(val, index) {
        if (this.getIsContainsCustomTextField(val, index)) {
            return <div className='RowDiv pl-s pr-s'>
                    <input
                        type="text"
                        className='pt-s pb-s pl-s pr-s'
                        style={{
                            fontSize: 13,
                            color: 'black',
                            width: '100%',
                        }}
                        value={this.getCustomTextFieldText(val, index)}
                        onChange={(event) => this.customTextChanged(val, index, event)}
                        placeholder={getLocalisedText('custom_text')}
                    />
            </div>
        }
        return <></>
    }

    customTextChanged(val, index, event) {
        var text = event.target.value
        var newCustomField = this.state.customField
        if (newCustomField[val] !== undefined && newCustomField[val] !== null) {
            if (newCustomField[val].length > 0) {
                if (newCustomField[val][index].text !== undefined && newCustomField[val][index].text !== null) {
                    newCustomField[val][index].text = text
                }
                else {
                    newCustomField[val][index]['text'] = text
                }

                var newContainer = this.state.container
                newContainer[val] = HeaderFooterUtility.getPreviewHeaderFooterContainer(val, this.state.type[val], this.state.containerStyle[val], newCustomField[val], this.props.documentData, this.state.additionalSettings)

                this.setState({
                    customField: newCustomField,
                    container: newContainer
                })
            }
        }
    }

    renderPicker = (List, isSelectedCallback, selectedCallback) => {
        var optionList = List.map((element, index) => {
            return <option value={element.type}
                selected={isSelectedCallback(element)}>
                {getLocalisedText(element.label)}
            </option>
        });
        return (
            <div
                style={{
                    marginTop: 0,
                }}
                className="ListPickerHolderStyle">
                <select
                    onChange={selectedCallback}
                    className="TextField ListPicker"
                    style={this.getPickerStyle()}
                >
                    {optionList}
                </select>
                {this.getListPickerArrow()}
            </div>
        );
    }

    getDefaultStyle = () => {
        return {
            fontSize: 14,
            textAlign: 'left',
            fontStyle: 'normal',
            fontWeight: 'normal',
            color: 'black',
        }
    }

    getDefaultStyleValue(style, value) {
        if(style !== undefined) {
            if(style[value] !== undefined) {
                return style[value]
            }
        }
        return undefined
    }

    //helper class
    getListPickerArrow() {
        return (
            <img
                className="ListPickerArrowStyle"
                src={Asset.icon.ic_listPickerArrow2}
                alt=""
            />
        );
    }

    getSectionHeader(str, fontSize = 12) {
        return (
            <text
                style={{
                    fontSize: fontSize,
                    color: "black",
                    fontWeight: "600",
                    textAlign: Utility.getIsArabicLang() ? 'right' : 'left',
                }}
            >
                { getLocalisedText(str)}
            </text>
        );
    }

    getIsContainsCustomField(val) {
        var list = this.state.customField[val]
        if (list !== undefined && list !== null) {
            if (list.length > 0) {
                return true
            }
        }
        return false
    }

    getIsCustomType(val) {
        var type = this.state.type[val]
        if (type !== undefined && type !== null) {
            return type.toLowerCase() === 'custom'
        }
        return false
    }

    getIsContainsCustomTextField(val, index) {
        var list = this.state.customField[val]
        if (list !== undefined && list !== null) {
            if (list.length > 0) {
                return list[index].type === 'customText'
            }
        }
        return false
    }

    getCustomTextFieldText(val, index) {
        var list = this.state.customField[val]
        if (list !== undefined && list !== null) {
            if (list.length > 0) {
                if (list[index].text !== undefined && list[index].text !== null ) {
                    return list[index].text
                }
            }
        }
        return ''
    }

    //add parser to convert preview data to print api format
    convertToPrintApiFormat() {
        var newPrintUIContainer = []
        if(this.state.container !== undefined && this.state.container !== null) {
            if(this.state.container[DocumentSectionType.header] !== undefined) {
                newPrintUIContainer[DocumentSectionType.header] = this.parseSelectedContainer(DocumentSectionType.header, this.state.type[DocumentSectionType.header], this.state.containerStyle[DocumentSectionType.header])
            }
            else {
                newPrintUIContainer[DocumentSectionType.header] = this.parseSelectedContainer(DocumentSectionType.header, 'none', undefined)
            }
            if (this.state.container[DocumentSectionType.footer] !== undefined) {
                newPrintUIContainer[DocumentSectionType.footer] = this.parseSelectedContainer(DocumentSectionType.footer, this.state.type[DocumentSectionType.footer], this.state.containerStyle[DocumentSectionType.footer])
            }
            else {
                newPrintUIContainer[DocumentSectionType.footer] = this.parseSelectedContainer(DocumentSectionType.footer, 'none', undefined)
            }
        }
        return newPrintUIContainer
    }

    parsePageCountContainerDiv(pageCountFormattingType) {
        let pageCountDiv = undefined
        switch (pageCountFormattingType) {
            case PageCountFormattingType.singleDigit:
                pageCountDiv = "<span class='pageNumber'></span>"
                break
            case PageCountFormattingType.pageWithNumeric:
                pageCountDiv = getLocalisedText('page') + " <span class='pageNumber'></span>"
                break
            case PageCountFormattingType.pageOfTotalPage:
                pageCountDiv = getLocalisedText('page') + " <span class='pageNumber'></span> of <span class='totalPages'></span>" 
                break
            case PageCountFormattingType.pageOfTotalPageSlash:
                pageCountDiv = getLocalisedText('page') + " <span class='pageNumber'></span> / <span class='totalPages'></span>" 
                break
            default:
                break;
        }

        return pageCountDiv
    }

    parseSelectedContainer(val, type, containerStyle) {
        const pageCountFormattingType = this.state.additionalSettings?.pageCountFormattingType ?? PageCountFormattingType.pageOfTotalPageSlash
        const pageCount = this.parsePageCountContainerDiv(pageCountFormattingType)
        switch (type) {
            case 'none':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "'></div>"
            case 'date_only':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyle(containerStyle, val) +
                "> <span class='date'></span></div>"
            case 'title_only':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyle(containerStyle, val) +
                    "> <span class='title'></span></div>"
            case 'page_count_only':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyle(containerStyle, val) +
                    ">" +
                    pageCount +
                    "</div>"
            case 'date_title':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyle(containerStyle, val) +
                    ">" +
                    "<span class='date'></span>" +
                    " &nbsp;&nbsp;" +
                    "<span class='title'></span>" +
                    "</div>"
            case 'date_page_count':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyle(containerStyle, val) +
                    ">" +
                    "<span class='date'></span>" +
                    " &nbsp;&nbsp;" +
                    pageCount +
                    "</div>"
            case 'title_page_count':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyle(containerStyle, val) +
                    ">" +
                    "<span class='title'></span>" +
                    " &nbsp;&nbsp;" +
                    pageCount +
                    "</div>"
            case 'date_title_space':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyleWithSpace(containerStyle, val) +
                    ">" +
                    "<span class='date' " + this.parseSpanStyle() + " ></span>" +
                    " &nbsp;&nbsp;" +
                    "<span class='title' " + this.parseSpanStyle() + " ></span>" +
                    "</div>"
            case 'date_page_count_space':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyleWithSpace(containerStyle, val) +
                    ">" +
                    "<span class='date' " + this.parseSpanStyle() + " ></span>" +
                    " &nbsp;&nbsp;" +
                    "<div " + this.parseSpanStyle() + " >" +
                    pageCount +
                    "</div>" +
                    "</div>"
            case 'title_page_count_space':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyleWithSpace(containerStyle, val) +
                    ">" +
                    "<span class='title' " + this.parseSpanStyle() + " ></span>" +
                    " &nbsp;&nbsp;" +
                    "<div " + this.parseSpanStyle() + " >" +
                    pageCount +
                    "</div>" +
                    "</div>"
            case 'date_title_page_count':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyleWithSpace(containerStyle, val) +
                    ">" +
                    "<span class='date' " + this.parseSpanStyle() + " ></span>" +
                    " &nbsp;&nbsp;" +
                    "<span class='title' " + this.parseSpanStyle() + " ></span>" +
                    " &nbsp;&nbsp;" +
                    "<div " + this.parseSpanStyle() + " >" +
                    pageCount +
                    "</div>" +
                    "</div>"
            case 'custom':
                return "<div id='" + HeaderFooterUtility.getTemplateId(val) + "' " + this.parseStyleWithSpace(containerStyle, val)
                + ">"
                + this.parseCustomFieldList(val) +
                "</div>"
            default:
                break;
        }
    }

    parseStyle(containerStyle, type) {

        return "style='font-size: " + containerStyle.fontSize +
                "px; text-align: " + containerStyle.textAlign +
                "; font-style: " + containerStyle.fontStyle +
                "; font-weight: " + containerStyle.fontWeight +
                "; color: " + containerStyle.color + ";" +
                this.getParseMarginTop(type) +
                " width: 100%; padding-left: 4.75%; padding-right: 4.75%'"
    }

    parseStyleWithSpace(containerStyle, type) {
        return "style='font-size: " + containerStyle.fontSize +
            "px; text-align: " + containerStyle.textAlign +
            "; font-style: " + containerStyle.fontStyle +
            "; font-weight: " + containerStyle.fontWeight +
            "; color: " + containerStyle.color + ";" +
            this.getParseMarginTop(type) +
            " padding-left: 4.75%; padding-right: 4.75%;" +
            " width: 100%; justify-content: space-between; display: flex; flex-direction: row;'"
    }

    getParseMarginTop(type) {
        if(type === DocumentSectionType.header) {
            return " margin-top: -6px; "
        }
        return ""
    }

    parseSpanStyle() {
        return "style='display: flex; flex-direction: row;'"
    }

    parseCustomFieldList(val) {
        var customFieldList = []
        if (this.getIsContainsCustomField(val)) {
            var list = this.state.customField[val]
            list.forEach((element, index) => {
                customFieldList.push(this.parseCustomField(element.type, element.text))
                if (list.length - 1 !== index) {
                    customFieldList.push("&nbsp;&nbsp;")
                }
            });
        }
        return customFieldList.join('')
    }

    parseCustomField(type, value) {
        switch (type) {
            case 'title':
                return "<span class='title'></span>"
            case 'date':
                return "<span class='date'></span>"
            case 'pageCount':
                return "<span> Page <span class='pageNumber'>1</span> / <span class='totalPages'>1</span></span>"
            case 'currentPageCount':
                return "<span class='pageNumber'></span>"
            case 'totalPageCount':
                return "<span class='totalPages'></span>"
            case 'customText':
                return "<span>" + value + "</span>"
            default:
                return ""
        }
    }

    cancelButtonTapped = () => {
        this.props.onClosePressed()
    }

    saveButtonTapped = () => {
        var headerFooter = TemplateSettingsManager.defaultTemplateSettings.headerFooter

        if(headerFooter !== undefined && headerFooter !== null) {
            var printContainer = this.convertToPrintApiFormat()
            var header = {}
            header.container = []
            header.containerStyle = this.state.containerStyle[DocumentSectionType.header]
            header.type = this.state.type[DocumentSectionType.header]
            header.customField = this.state.customField[DocumentSectionType.header]
            header.printContainer = btoa(encodeURIComponent(printContainer[DocumentSectionType.header]))

            var footer = {}
            footer.container = []
            footer.containerStyle = this.state.containerStyle[DocumentSectionType.footer]
            footer.type = this.state.type[DocumentSectionType.footer]
            footer.customField = this.state.customField[DocumentSectionType.footer]
            footer.printContainer = btoa(encodeURIComponent(printContainer[DocumentSectionType.footer]))

            headerFooter.header = header
            headerFooter.footer = footer
            headerFooter.additionalSettings = this.state.additionalSettings
            TemplateSettingsManager.updateHeaderFooter(headerFooter)
        }
        this.props.onClosePressed()
    }
}
