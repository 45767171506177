import React from "react";
import HelpPredifinedTemplate from "./HelpLink/help-predifined-template";
import HelpQuickStartGuide from "./HelpLink/help-quick-start-guide";
import HelpMainComponent from "./HelpLink/help-main-component";
import HelpPrintDocumentWithSavedTemplate from "./HelpLink/help-print-document-with-saved-template";
import HelpSaveTemplate from "./HelpLink/help-save-template";
import HelpSavedTemplateSelection from "./HelpLink/help-saved-template-selection";
import HelpSelectLanguage from "./HelpLink/help-select-language";
import HelpSendEmailWithSavedTemplate from "./HelpLink/help-send-email-with-saved-template";
import HelpSupportedDocument from "./HelpLink/help-supported-document";
import HelpSupportedPaperFormat from "./HelpLink/help-supported-paper-size";
import HelpCustomFields from "./HelpLink/help-custom-fields";
import HelpRepeatableTableHeader from "./HelpLink/help-repeatable-table-header";
import HelpRepeatableHeaderFooter from "./HelpLink/help-repeatable-header-footer";
import HelpRepeatableFooter from "./HelpLink/help-repeatable-footer";
import HelpWatermark from "./HelpLink/help-watermark";
import HelpSubTableConfiguration from "./HelpLink/help-sub-table-configuration";
import HelpThemeColor from "./HelpLink/help-theme-color";
import HelpTextConfiguration from "./HelpLink/help-text";
import HelpTableConfiguration from "./HelpLink/help-table-configuration";
import HelpCompanyLogo from "./HelpLink/help-company-logo";
import HelpCompanyNameAndAddress from "./HelpLink/help-company-name-address";
import HelpSignature from "./HelpLink/help-signature";
import HelpBillToAndShipToAddress from "./HelpLink/help-bill-ship-address";
import HelpCurrency from "./HelpLink/help-currency";
import HelpDecimalPoint from "./HelpLink/help-decimal-point";
import HelpDocumentNumberRelated from "./HelpLink/help-document-number-related";
import HelpDocumentDateRelated from "./HelpLink/help-document-date-related";
import HelpPaymentFooter from "./HelpLink/help-payment-footer";
import HelpTermAndConditionNotes from "./HelpLink/help-term-and-notes";
import HelpTable from "./HelpLink/help-table";
import HelpGeneratedMessage from "./HelpLink/help-generated-message";
import HelpTaxFieldsForAddress from "./HelpLink/help-tax-fields-for-address";
import HelpTaxBreakdownTableColumn from "./HelpLink/help-tax-breakdown-table-column";
import HelpTaxBreakdownTable from "./HelpLink/help-tax-breakdown-table";

export default class HelpNavigationLinkUtil {

    static getNavigationList() {
        return [
            {
                header: 'Guides',
                tag: 'guides',
                items: [
                    {
                        type: 'quick_start_guide',
                        label: 'Quick Start Guide',
                        href: 'quick-start-guide'
                    },
                    {
                        type: 'send_email_with_saved_template',
                        label: 'Send Email With Saved Template',
                        href: 'send-email-with-saved-template'
                    },
                    {
                        type: 'print_document_with_saved_template',
                        label: 'Print Document With Saved Template',
                        href: 'print-document-with-saved-template'
                    },
                    {
                        type: 'supported_document_module',
                        label: 'Supported Document Module',
                        href: 'supported-document-module',
                    },
                    {
                        type: 'supported_paper_format',
                        label: 'Supported Paper Format',
                        href: 'supported-paper-format',
                    },
                    {
                        type: 'main_component',
                        label: 'Main Component',
                        href: 'main-component',
                    },
                ]
            },
            {
                header: 'Top Section',
                tag: 'top-section',
                items: [
                    // {
                    //     type: 'deskera_logo',
                    //     label: 'Deskera Logo',
                    //     href: 'deskera-logo'
                    // },
                    {
                        type: 'save_template',
                        label: 'Save Template',
                        href: 'save-template',
                    },
                    {
                        type: 'language_selection',
                        label: 'Language Selection',
                        href: 'language-selection',
                    },
                ]
            },
            {
                header: 'Left Section',
                tag: 'left-section',
                items: [
                    {
                        type: 'custom_fields',
                        label: 'Custom Fields',
                        href: 'custom-fields',
                    },
                    {
                        type: 'repeatable_table_header',
                        label: 'Repeatable Table Header',
                        href: 'repeatable-table-header',
                    },
                    {
                        type: 'repeatable_header_footer',
                        label: 'Repeatable Header Footer',
                        href: 'repeatable-header-footer',
                    },
                    {
                        type: 'repeatable_footer',
                        label: 'Repeatable Footer',
                        href: 'repeatable-footer',
                    },
                    {
                        type: 'sub_table_configuration',
                        label: 'Sub-table Configuration',
                        href: 'sub-table-configuration',
                    },
                    {
                        type: 'watermark',
                        label: 'Watermark',
                        href: 'watermark',
                    },
                    {
                        type: 'theme_color',
                        label: 'Theme Color',
                        href: 'theme-color',
                    },
                    {
                        type: 'text_customization',
                        label: 'Text Customization',
                        href: 'text-customization',
                    },
                    {
                        type: 'table_customization',
                        label: 'Table Customization',
                        href: 'table-customization',
                    },
                    {
                        type: 'company_logo',
                        label: 'Company Logo',
                        href: 'company-logo',
                    },
                    {
                        type: 'company_name_and_address',
                        label: 'Company Name and Address',
                        href: 'company-name-and-address',
                    },
                    {
                        type: 'bill_to_and_ship_to_address',
                        label: 'Bill To and Ship To Address',
                        href: 'bill-to-and-ship-to-address',
                    },
                    {
                        type: 'currency',
                        label: 'currency',
                        href: 'currency',
                    },
                    {
                        type: 'decimal_point_and_qty_decimal_point',
                        label: 'Decimal Point And Qty Decimal Point',
                        href: 'decimal-point-and-qty-decimal-point',
                    },
                    {
                        type: 'document_number_related',
                        label: 'Document Number Related',
                        href: 'document-number-related',
                    },
                    {
                        type: 'document_date_related',
                        label: 'Document Date Related',
                        href: 'document-date-related',
                    },
                    {
                        type: 'payment_footer',
                        label: 'Payment Footer',
                        href: 'payment-footer',
                    },
                    // {
                    //     type: 'product_grouping',
                    //     label: 'Product Grouping',
                    //     href: 'product-grouping',
                    // },
                    {
                        type: 'signature',
                        label: 'Signature',
                        href: 'signature',
                    },
                    {
                        type: 'term_and_condition_and_notes',
                        label: 'Term And Condition, Notes',
                        href: 'term-and-condition-and-notes',
                    },
                    {
                        type: 'generated_message',
                        label: 'Generated Message',
                        href: 'generated-message',
                    },
                ]
            },
            {
                header: 'Middle Section',
                tag: 'middle-section',
                items: [
                    {
                        type: 'table',
                        label: 'table',
                        href: 'table',
                    },
                ]
            },
            {
                header: 'Right Section',
                tag: 'right-section',
                items: [
                    {
                        type: 'saved_template_selection',
                        label: 'Saved Template Selection',
                        href: 'saved-template-selection',
                    },
                    {
                        type: 'predifined_template_selection',
                        label: 'Predifined Template Selection',
                        href: 'predifined-template-selection',
                    },
                ]
            },
            {
                header: 'India Compliance',
                tag: 'india-compliance',
                items: [
                    {
                        type: 'tax_fields_for_address',
                        label: 'Tax Field For Address',
                        href: 'tax-fields-for-address',
                    },
                    {
                        type: 'tax_breakdown_table_column',
                        label: 'Tax Breakdown Table Column',
                        href: 'tax-breakdown-table-column',
                    },
                    {
                        type: 'tax_breakdown_table',
                        label: 'Tax Breakdown Table',
                        href: 'tax-breakdown-table',
                    },
                ]
            },
        ]
    }

    static getNavigationLinkPage(param) {
        if(param === undefined || param === null) {
            return <HelpQuickStartGuide />
        }
        var type = param.toLowerCase()
        switch (type) {
            case 'guides/quick-start-guide':
                return <HelpQuickStartGuide />
            case 'guides/send-email-with-saved-template':
                return <HelpSendEmailWithSavedTemplate />
            case 'guides/print-document-with-saved-template':
                return <HelpPrintDocumentWithSavedTemplate />
            case 'guides/supported-document-module':
                return <HelpSupportedDocument />
            case 'guides/main-component':
                return <HelpMainComponent />
            case 'guides/supported-paper-format':
                return <HelpSupportedPaperFormat />
            case 'top-section/save-template':
                return <HelpSaveTemplate />
            case 'top-section/language-selection':
                return <HelpSelectLanguage />
            case 'right-section/saved-template-selection':
                return <HelpSavedTemplateSelection />
            case 'right-section/predifined-template-selection':
                return <HelpPredifinedTemplate />
            case 'left-section/custom-fields':
                return <HelpCustomFields />
            case 'left-section/repeatable-table-header':
                return <HelpRepeatableTableHeader />
            case 'left-section/repeatable-header-footer':
                return <HelpRepeatableHeaderFooter />
            case 'left-section/repeatable-footer':
                return <HelpRepeatableFooter />
            case 'left-section/watermark':
                return <HelpWatermark />
            case 'left-section/sub-table-configuration':
                return <HelpSubTableConfiguration />
            case 'left-section/theme-color':
                return <HelpThemeColor />
            case 'left-section/text-customization':
                return <HelpTextConfiguration />
            case 'left-section/table-customization':
                return <HelpTableConfiguration />
            case 'left-section/company-logo':
                return <HelpCompanyLogo />
            case 'left-section/company-name-and-address':
                return <HelpCompanyNameAndAddress />
            case 'left-section/bill-to-and-ship-to-address':
                return <HelpBillToAndShipToAddress />
            case 'left-section/currency':
                return <HelpCurrency />
            case 'left-section/decimal-point-and-qty-decimal-point':
                return <HelpDecimalPoint />
            case 'left-section/document-number-related':
                return <HelpDocumentNumberRelated />
            case 'left-section/document-date-related':
                return <HelpDocumentDateRelated />
            case 'left-section/payment-footer':
                return <HelpPaymentFooter />
            case 'left-section/signature':
                return <HelpSignature />
            case 'left-section/term-and-condition-and-notes':
                return <HelpTermAndConditionNotes />
            case 'left-section/generated-message':
                return <HelpGeneratedMessage />
            case 'middle-section/table':
                return <HelpTable />
            case 'india-compliance/tax-fields-for-address':
                return <HelpTaxFieldsForAddress />
            case 'india-compliance/tax-breakdown-table-column':
                return <HelpTaxBreakdownTableColumn />
            case 'india-compliance/tax-breakdown-table':
                return <HelpTaxBreakdownTable />
            default:
                return <div className="noSelect"/>
        }
    }

}
