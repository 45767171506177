import React from 'react'
// import { COLOR_LIGHTGRAY2 } from '../../Constants/Constants'
// import ComponentManager from '../../Manager/ComponentManager'
// import { getLocalisedText } from '../../Translate/LanguageManager'
// import Utility, { getFontSizeClass } from '../../Utilities/Utility'

import {
    COLOR_LIGHTGRAY2,
    ComponentManager,
    getLocalisedText,
    Utility,
    getFontSizeClass

} from 'deskera-doc-builder-lib'

const PopupHeaderComponent = ({ headerText, cancelText, onCancelClicked, submitText, onSubmitClicked }) => {

    const getButtonSection = () => {
        return (
            <div>
                <div className="RowReverseDiv" style={{ boxSizing: 'border-box' }}>
                    {submitText !== undefined && ComponentManager.getButton(getLocalisedText(submitText), `rgba(55, 115, 225, 1.0)`, 'white', onSubmitClicked)}
                    {cancelText !== undefined && ComponentManager.addHorizontalSpace(5)}
                    {cancelText !== undefined && ComponentManager.getButton(getLocalisedText(cancelText), `rgba(150, 150, 150, 0.25)`, 'black', onCancelClicked)}
                </div>
            </div>
        )
    }

    return (
        <div className="RowDiv"
            style={{
                justifyContent: 'space-between',
                backgroundColor: COLOR_LIGHTGRAY2,
                padding: 10,
                boxSizing: 'border-box',
                flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row'
            }}>
                <text className={"user-select-none fontStyleBold" + getFontSizeClass()} style={{ fontSize: 14 }}> {getLocalisedText(headerText)} </text>
                {getButtonSection()}
        </div>
    )

}

export default PopupHeaderComponent;
