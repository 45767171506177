import React, { Component } from 'react';
import './HelpLinkUtil/helpLink.css'
import HelpUtil from './HelpLinkUtil/HelpUtil';

export default class HelpSelectLanguage extends Component{
    render() {
        return <div className='help-doc'>
            <div className='markdown'>
                {HelpUtil.renderHeader('Langauge Selection')}
                {this.renderContent()}
            </div>

        </div>
    }


    renderContent() {
        var data = {
            paragraph: [
                {
                    type: 'text',
                    value: "The document generator supports language as below,",
                },
                {
                    type: 'list',
                    isSorted: false,
                    value: [
                        "Arabic",
                        "English",
                        "French",
                        "Spanish",
                        "Indonesian",
                        "Chinese",
                        "Hindi",
                        "Portuguese",
                        "German",
                        "Italian",
                    ]
                },
                {
                    type: 'newLine',
                },
                {
                    type: 'image',
                    value: require('./../asset/select-language/language-selection.png'),
                    isFullWidth: false,
                },
                {
                    type: 'text',
                    value: "** The Arabic selection only appear if user created Saudi Arabia and Product related documents.",
                },
            ]
        }
        return <div>
            {HelpUtil.renderParagraphWithJsonData(data)}
        </div>
    }
}