import React from 'react';
import DayContainer from './DayContainer';
import MonthContainer from './MonthContainer';
import './css/DKCalendar.css';
import { Constants } from './Constants';
interface Props {
    selectedDate?: Date
    selectedStartDate?: Date
    selectedEndDate?: Date
    onSelectDate: (newDate: Date) => void
    onSelectDateRange: (newStartDate?: Date, newEndDate?: Date) => void
    isDateRange: boolean
    positionX?: number
    positionY?: number
}

interface States {
    month: number
    year: number
    dateList: string[]
}
export default class DKCalendar extends React.Component<Props, States> {

    constructor(props: any) {
        super(props);
        this.state = {
            month: this.getMonth(),
            year: this.props.selectedDate ? this.props.selectedDate.getFullYear() : new Date().getFullYear(), //add support for start date
            dateList: []
        };
    }
    getMonth(): number {
        if(this.props.isDateRange) {
            return this.props.selectedStartDate ? this.props.selectedStartDate.getMonth() + 1 : new Date().getMonth() + 1
        }
        else {
            return this.props.selectedDate ? this.props.selectedDate.getMonth() + 1 : new Date().getMonth() + 1
        }
    }
    componentDidMount() {
        this.calculateDateList()
    }
    render() {
        return (
            <div
                style={{
                    position: this.props.positionX ? 'fixed' : 'relative',
                    left: this.props.positionX,
                    top: this.props.positionY,
                    // maxHeight: Constants.calendar.maxHeight,
                    // maxWidth: Constants.calendar.maxWidth,
                    width: Constants.calendar.width,
                    height: Constants.calendar.height,
                    color: Constants.dayContainer.textColor,
                }}
                className='calendarDiv'>
                <div
                    className='calendarHeaderDiv' style={{
                        backgroundColor: Constants.calendar.backgroundColor,
                    }}>
                    <MonthContainer
                        isLongFormat={Constants.monthContainer.isLongMonth}
                        month={this.state.month}
                        year={this.state.year}
                    />
                    {this.renderMonthSelectionComponent()}
                </div>
                <div
                    className='calendarHeaderDiv' style={{
                        backgroundColor: Constants.calendar.backgroundColor,
                        height: '100%'
                    }}>
                    <DayContainer
                        selectedDate={this.props.selectedDate ? this.props.selectedDate : new Date()}
                        dateList={this.state.dateList}
                        month={this.state.month}
                        year={this.state.year}
                        onSelectDate={(val) => this.props.onSelectDate(val)}
                        isDateRange={this.props.isDateRange}
                        selectedStartDate={this.props.selectedStartDate}
                        selectedEndDate={this.props.selectedEndDate}
                        onSelectDateRange={(start, end) => {
                            this.props.onSelectDateRange(start, end)
                        }}
                    />
                </div>
            </div>
        );
    }

    renderMonthSelectionComponent() {
        return (
            <div>
                <img
                    className='optionImage'
                    src={require('./assets/ic_before.png')}
                    alt={''}
                    onClick={()=> this.onMonthChanged('before')}
                />
                <img
                    className='optionImage'
                    src={require('./assets/ic_next.png')}
                    alt={''}
                    onClick={() => this.onMonthChanged('next')}
                />
            </div>
        );
    }

    onMonthChanged(action: string){
        var newMonth = this.state.month
        var newYear = this.state.year
        if(action === 'before') {
            newMonth = this.state.month -1
            if(newMonth < 1) {
                newYear = newYear -1
                newMonth = 12
            }
        }
        else if (action === 'next') {
            newMonth = this.state.month + 1
            if (newMonth > 12) {
                newYear = newYear + 1
                newMonth = 1
            }
        }

        this.setState({
            month: newMonth,
            year: newYear
        }, ()=>{
            this.calculateDateList()
        })
    }


    calculateDateList() {
        const normalYear = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        const leapYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        var newDateList = []

        var date = new Date(this.state.year, this.state.month-1)
        var isLeapYear = date.getFullYear() % 4 === 0

        var prevMonth = date.getMonth()
        // var prevYear = date.getFullYear()

        var nextMonth = date.getMonth()
        // var nextYear = date.getFullYear()

        prevMonth = prevMonth - 1
        if (prevMonth < 1) {
            prevMonth = 12
            // prevYear = prevYear - 1
        }

        nextMonth = nextMonth + 1

        if (nextMonth > 12) {
            prevMonth = 1
            // prevYear = prevYear + 1
        }

        var totalDate = isLeapYear ? leapYear[date.getMonth()] : normalYear[date.getMonth()]

        if (date.getDay() === 0) {
            var arraySize = totalDate === 28 ? 28 : 35
            for (var i = 1; i < totalDate+1; i++) {
                newDateList.push(i.toString())
            }

            var offsetList = arraySize - newDateList.length

            for (var j = 1; j < offsetList+1; j++) {
                newDateList.push('')
            }

            this.setState({
                dateList: newDateList
            })
        }
        else {
            var totalPrevDate = date.getDay()

            for (var x = 1; x < totalPrevDate + 1; x++) {
                newDateList.push('')
            }
            for (var y = 1; y < totalDate + 1; y++) {
                newDateList.push(x.toString())
            }

            this.setState({
                dateList: newDateList
            })
        }
    }
}
